
import { CityInfo } from "../types/CommunityTypes";

export const WeslacoData: CityInfo = {
  headline: "Modern Living in Weslaco",
  description: "Weslaco offers an exceptional quality of life with its blend of new developments, established neighborhoods, and rich agricultural heritage. Located in the heart of the Rio Grande Valley, Weslaco provides residents with affordable housing options, excellent schools, and a growing economy.",
  marketInfo: {
    avgPrice: "$225,000",
    trend: "Rising 3.8% annually",
    daysOnMarket: "45 days"
  },
  communities: [
    {
      name: "Tierra Santa",
      description: "Modern planned community with contemporary homes and family-friendly amenities",
      features: ["Modern architecture", "Community park", "Walking trails"],
      priceRange: "$240,000 - $420,000",
      image: "/lovable-uploads/478b1e52-5766-4732-afec-e194759a3b78.png"
    },
    {
      name: "Villa Verde",
      description: "Established neighborhood with spacious lots and mature landscaping",
      features: ["Large yards", "Tree-lined streets", "Near shopping"],
      priceRange: "$190,000 - $350,000",
      image: "https://images.unsplash.com/photo-1564013799919-ab600027ffc6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGx1eHVyeSUyMGhvbWV8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
    }
  ],
  featuredProperties: [
    {
      id: 801,
      city: "Weslaco",
      title: "Contemporary New Construction",
      price: "$345,000",
      image: "/lovable-uploads/478b1e52-5766-4732-afec-e194759a3b78.png",
      beds: 4,
      baths: 3,
      sqft: "2,850"
    },
    {
      id: 802,
      city: "Weslaco",
      title: "Modern Family Home",
      price: "$295,000",
      image: "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8aG91c2V8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",
      beds: 3,
      baths: 2.5,
      sqft: "2,200"
    }
  ]
};
