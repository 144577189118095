
import React, { useEffect } from 'react';
import Hero from '@/components/Hero';
import Navbar from '@/components/Navbar';
import OurVision from '@/components/OurVision';
import Testimonials from '@/components/Testimonials';
import ContactSection from '@/components/sections/ContactSection';
import Footer from '@/components/Footer';
import { refreshGlowEffect } from '@/utils/glowTextUtils';
import { useTheme } from '@/context/ThemeContext';
import { useLanguage } from '@/context/LanguageContext';
import GlobalGlowFilter from '@/components/GlobalGlowFilter';
import DiscoverSection from '@/components/sections/DiscoverSection';
import BlogCarousel from '@/components/blog/BlogCarousel';
import QuickLinks from '@/components/common/QuickLinks';
import GlowingText from '@/components/common/GlowingText';

const Home: React.FC = () => {
  const { theme } = useTheme();
  const { language, setLanguage } = useLanguage();
  
  useEffect(() => {
    // Ensure English is set as default for homepage
    if (language !== 'en') {
      setLanguage('en');
    }
    
    // Apply glow effects when component mounts
    setTimeout(() => {
      refreshGlowEffect();
    }, 100);
    
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, [language, setLanguage]);

  return (
    <div className={theme === 'dark' ? 'dark' : 'light'}>
      <GlobalGlowFilter />
      {/* Navbar is now positioned absolutely over the Hero */}
      <Navbar />
      <Hero />
      <main>
        <OurVision />
        <DiscoverSection />
        
        {/* Blog Carousel Section with proper ID for navigation */}
        <section className="py-16 bg-black nav-section" id="latest-blogs">
          <div className="container mx-auto">
            <div className="text-center mb-12">
              <GlowingText
                text="Latest Blog Posts"
                as="h2"
                className="text-4xl font-display text-white mb-8"
                glowColor="gold"
                advanced={true}
              />
            </div>
            <BlogCarousel />
          </div>
        </section>
        
        <Testimonials />
        <ContactSection />
      </main>
      <Footer />
      
      {/* Floating Quick Links */}
      <QuickLinks />
    </div>
  );
};

export default Home;
