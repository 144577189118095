
import { useState, useEffect, useCallback } from "react";
import { supabase } from "@/integrations/supabase/client";

export interface CityMarketData {
  id: string;
  city_name: string;
  avg_price: string;
  trend: string;
  days_on_market: string;
  last_updated: string;
}

export const useMarketData = (cityName?: string, refreshInterval = 3600000) => { // default: refresh every hour
  const [marketData, setMarketData] = useState<CityMarketData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [lastRefreshed, setLastRefreshed] = useState<Date>(new Date());

  const fetchMarketData = useCallback(async () => {
    if (!cityName) {
      setIsLoading(false);
      return;
    }
    
    try {
      setIsLoading(true);
      
      let query = supabase
        .from('city_market_data')
        .select('*')
        .eq('city_name', cityName);
      
      const { data, error } = await query;
      
      if (error) {
        throw error;
      }
      
      if (data && data.length > 0) {
        // Only return the first match to ensure we have a single object
        setMarketData(data[0] as CityMarketData);
      } else {
        setMarketData(null);
      }
      
      setLastRefreshed(new Date());
    } catch (err) {
      console.error("Error fetching market data:", err);
      setError(err instanceof Error ? err.message : "Unknown error occurred");
    } finally {
      setIsLoading(false);
    }
  }, [cityName]);

  useEffect(() => {
    fetchMarketData();
    
    // Set up interval to refresh data periodically
    const intervalId = setInterval(() => {
      console.log(`Refreshing market data for ${cityName || 'default city'}...`);
      fetchMarketData();
    }, refreshInterval);
    
    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [cityName, fetchMarketData, refreshInterval]);

  return { marketData, isLoading, error, lastRefreshed, refreshData: fetchMarketData };
};
