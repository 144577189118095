
import React from "react";
import RelatedBlogPosts from "./RelatedBlogPosts";
import { BlogPost } from "@/types/blog";

interface BlogPostSidebarProps {
  currentPostId?: string | number;
  category?: string;
  allPosts?: BlogPost[];
  post?: BlogPost;
  isJuansBlog?: boolean;
}

const BlogPostSidebar: React.FC<BlogPostSidebarProps> = ({
  currentPostId,
  category,
  allPosts,
  post,
  isJuansBlog = false
}) => {
  return (
    <div>
      <RelatedBlogPosts 
        currentPostId={currentPostId || (post ? post.id : undefined)}
        category={category || (post ? post.category : undefined)}
        allPosts={allPosts}
        currentPost={post}
        isJuansBlog={isJuansBlog}
      />
    </div>
  );
};

export default BlogPostSidebar;
