
import { Bed, Bath, Home, MapPin } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { AspectRatio } from "@/components/ui/aspect-ratio";
import { Button } from "@/components/ui/button";
import { PropertyDetailsProps } from "./PropertyDetailsDialog";

interface PropertyCardProps {
  property: PropertyDetailsProps;
  onViewDetails: (property: PropertyDetailsProps) => void;
}

const PropertyCard = ({ property, onViewDetails }: PropertyCardProps) => {
  return (
    <Card key={property.id} className="overflow-hidden transition-all duration-300 hover:shadow-lg bg-black dark:bg-gray-900 border border-gray-800 dark:border-gray-700">
      <CardContent className="p-0">
        <div className="relative">
          <AspectRatio ratio={4/3}>
            <img 
              src={property.image}
              alt={property.title} 
              className="object-cover w-full h-full"
              onError={(e) => {
                // Fallback to a different image if the original fails
                e.currentTarget.src = "https://images.unsplash.com/photo-1582268611958-ebfd161ef9cf?q=80&w=800";
              }}
            />
          </AspectRatio>
          {property.featured && (
            <div className="absolute top-3 right-3 bg-gold-500 text-white text-sm font-semibold py-1 px-3 rounded-full">
              Featured
            </div>
          )}
          <div className="absolute top-3 left-3 bg-black/70 text-white text-sm font-semibold py-1 px-3 rounded-full">
            {property.priceFormatted}
          </div>
        </div>
        
        <div className="p-4">
          <h3 className="text-lg font-bold mb-1 text-white dark:text-white">
            {property.title}
          </h3>
          
          <div className="flex items-center text-gray-400 dark:text-gray-300 mb-2">
            <MapPin className="w-4 h-4 mr-1" />
            <span className="text-sm truncate">{property.address}, {property.location}</span>
          </div>
          
          <div className="flex items-center space-x-4 text-gray-400 dark:text-gray-300 mb-4">
            <div className="flex items-center">
              <Bed className="w-4 h-4 mr-1" />
              <span className="text-sm">{property.beds} Beds</span>
            </div>
            <div className="flex items-center">
              <Bath className="w-4 h-4 mr-1" />
              <span className="text-sm">{property.baths} Baths</span>
            </div>
            <div className="flex items-center">
              <Home className="w-4 h-4 mr-1" />
              <span className="text-sm">{property.sqft} sq ft</span>
            </div>
          </div>
          
          <Button 
            className="w-full bg-gold-500 hover:bg-gold-600 text-white transition duration-300 transform hover:-translate-y-1"
            onClick={() => onViewDetails(property)}
          >
            View Details
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default PropertyCard;
