
import React, { useState } from "react";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import { useLanguage } from "@/context/LanguageContext";
import { cityInfo } from "@/components/communities/cityData";
import CommunitiesList from "@/components/communities/CommunitiesList";
import CommunityHero from "@/components/communities/CommunityHero";
import MarketSnapshot from "@/components/communities/MarketSnapshot";
import FeaturedCommunities from "@/components/communities/FeaturedCommunities";
import FeaturedProperties from "@/components/communities/FeaturedProperties";
import CityTestimonials from "@/components/communities/CityTestimonials";
import AboutJuan from "@/components/communities/AboutJuan";
import ContactForm from "@/components/communities/ContactForm";
import { useCityState } from "@/hooks/useCityState";

const Communities = () => {
  const { t } = useLanguage();
  
  // Complete list of Rio Grande Valley cities
  const communities = [
    "South Padre Island", "Port Isabel", "Laguna Vista", "Brownsville",
    "Los Fresnos", "San Benito", "Harlingen", "La Feria", "Santa Rosa",
    "Combes", "Primera", "Palm Valley", "Rio Hondo", "Rangerville", 
    "Mercedes", "Weslaco", "Donna", "Alamo", "San Juan", "Pharr", 
    "McAllen", "Edinburg", "Mission", "Hidalgo", "Palmview", "Sullivan City",
    "Penitas", "La Joya", "Palmhurst", "Alton", "Rio Grande City", "Roma",
    "Progreso", "Progreso Lakes", "La Villa", "Edcouch", "Elsa", "Monte Alto",
    "Raymondville", "Lyford", "Sebastian"
  ].sort();

  const {
    activeCity,
    expandedCity,
    toggleCityExpansion,
    handleCitySelect
  } = useCityState(communities);

  // Handle scroll to communities list
  const scrollToCommunitiesList = () => {
    const aboutSection = document.getElementById("communities-list");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Determine hero content based on active city
  const getHeroContent = () => {
    if (activeCity && cityInfo[activeCity]) {
      return {
        headline: cityInfo[activeCity].headline,
        description: cityInfo[activeCity].description
      };
    }
    return {
      headline: "Explore Rio Grande Valley Communities",
      description: "Juan Elizondo provides expert real estate services throughout all communities in the Rio Grande Valley. Discover your perfect home in one of our beautiful cities."
    };
  };

  const heroContent = getHeroContent();

  return (
    <div className="min-h-screen bg-background">
      <Navbar />
      
      <div className="relative">
        <CommunityHero 
          activeCity={activeCity} 
          headline={heroContent.headline} 
          description={heroContent.description} 
          onViewAllClick={!activeCity ? scrollToCommunitiesList : undefined}
        />
        
        {activeCity && <MarketSnapshot cityName={activeCity} />}

        <div className="container mx-auto px-4 py-16 max-w-6xl">
          {activeCity && cityInfo[activeCity] && (
            <>
              <FeaturedCommunities 
                cityName={activeCity}
                communities={cityInfo[activeCity].communities}
              />
              <FeaturedProperties 
                cityName={activeCity}
                properties={cityInfo[activeCity].featuredProperties}
              />
              <CityTestimonials cityName={activeCity} />
            </>
          )}
          
          <CommunitiesList 
            communities={communities}
            activeCity={activeCity}
            expandedCity={expandedCity}
            toggleCityExpansion={toggleCityExpansion}
            onCitySelect={handleCitySelect}
          />
          
          <AboutJuan />
          <ContactForm communities={communities} activeCity={activeCity} />
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default Communities;
