
import React from 'react';

const AgentAttribution: React.FC = () => {
  return (
    <div className="absolute bottom-8 left-4 md:bottom-12 md:left-6">
      <p className="text-white text-xl font-medium drop-shadow-lg">
        Juan Elizondo, RE/MAX Agent
      </p>
    </div>
  );
};

export default AgentAttribution;
