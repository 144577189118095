
import React from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Send } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

const formSchema = z.object({
  name: z.string().min(2, { message: "Name must be at least 2 characters." }),
  email: z.string().email({ message: "Please enter a valid email address." }),
  phone: z.string().optional(),
  message: z.string().min(10, { message: "Message must be at least 10 characters." }),
});

const ContactForm = () => {
  const { toast } = useToast();
  
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    toast({
      title: "Message sent!",
      description: "We'll get back to you within 24 hours.",
    });
    form.reset();
  }

  return (
    <div className="bg-white/5 dark:bg-estate-900/20 p-8 rounded-xl border border-estate-200/20 dark:border-estate-700/30 shadow-lg h-full flex flex-col">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 flex-grow flex flex-col">
          <div className="grid grid-cols-1 gap-6">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-estate-700 dark:text-estate-300 text-lg">Your Name</FormLabel>
                  <FormControl>
                    <Input 
                      placeholder="John Doe" 
                      className="bg-white/80 dark:bg-estate-800/50 border-estate-200 dark:border-estate-700 py-6 text-lg"
                      {...field} 
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-estate-700 dark:text-estate-300 text-lg">Email Address</FormLabel>
                  <FormControl>
                    <Input 
                      type="email" 
                      placeholder="john@example.com" 
                      className="bg-white/80 dark:bg-estate-800/50 border-estate-200 dark:border-estate-700 py-6 text-lg"
                      {...field} 
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-estate-700 dark:text-estate-300 text-lg">Phone Number (Optional)</FormLabel>
                  <FormControl>
                    <Input 
                      type="tel" 
                      placeholder="(123) 456-7890" 
                      className="bg-white/80 dark:bg-estate-800/50 border-estate-200 dark:border-estate-700 py-6 text-lg"
                      {...field} 
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          
            <FormField
              control={form.control}
              name="message"
              render={({ field }) => (
                <FormItem className="flex-grow">
                  <FormLabel className="text-estate-700 dark:text-estate-300 text-lg">Message</FormLabel>
                  <FormControl>
                    <Textarea 
                      placeholder="Tell us about your real estate needs..." 
                      className="bg-white/80 dark:bg-estate-800/50 border-estate-200 dark:border-estate-700 min-h-[180px] text-lg resize-none"
                      {...field} 
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          
          <div className="mt-auto pt-4">
            <Button 
              type="submit" 
              className="w-full bg-estate-600 hover:bg-estate-700 text-white transition-all hover:shadow-lg py-7 text-xl"
            >
              <Send className="w-6 h-6 mr-3" />
              Send Message
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default ContactForm;
