
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "@/components/ui/card";
import { DollarSign } from "lucide-react";

interface PaymentSummaryProps {
  monthlyPayment: number;
  loanAmount: number;
  interestRate: number;
  loanTerm: number;
}

const PaymentSummary = ({
  monthlyPayment,
  loanAmount,
  interestRate,
  loanTerm,
}: PaymentSummaryProps) => {
  // Format number with commas
  const formatNumber = (num: number) => {
    return num.toLocaleString('en-US');
  };

  return (
    <Card className="bg-[#f0f5f9] shadow-lg">
      <CardHeader>
        <CardTitle className="flex items-center">
          <DollarSign className="mr-2 h-5 w-5 text-[#ffb347]" /> 
          Payment Summary
        </CardTitle>
        <CardDescription>
          Your estimated monthly mortgage payment
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="text-center py-10">
          <div className="text-5xl font-bold text-[#333333] mb-4">
            ${monthlyPayment.toFixed(2)}
            <span className="text-xl font-normal text-gray-500">/mo</span>
          </div>
          
          <div className="grid grid-cols-1 gap-4 mt-8 text-left">
            <div className="flex justify-between p-3 bg-white rounded-lg shadow-sm">
              <span className="text-gray-600">Loan Amount:</span>
              <span className="font-semibold">${formatNumber(loanAmount)}</span>
            </div>
            <div className="flex justify-between p-3 bg-white rounded-lg shadow-sm">
              <span className="text-gray-600">Interest Rate:</span>
              <span className="font-semibold">{interestRate}%</span>
            </div>
            <div className="flex justify-between p-3 bg-white rounded-lg shadow-sm">
              <span className="text-gray-600">Loan Term:</span>
              <span className="font-semibold">{loanTerm} years</span>
            </div>
            <div className="flex justify-between p-3 bg-white rounded-lg shadow-sm">
              <span className="text-gray-600">Total Paid:</span>
              <span className="font-semibold">${formatNumber(Math.round(monthlyPayment * loanTerm * 12))}</span>
            </div>
            <div className="flex justify-between p-3 bg-white rounded-lg shadow-sm">
              <span className="text-gray-600">Total Interest:</span>
              <span className="font-semibold">${formatNumber(Math.round(monthlyPayment * loanTerm * 12 - loanAmount))}</span>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default PaymentSummary;
