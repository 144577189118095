
import React from "react";
import { MediaType, VideoInfo, YouTubeVideoSequence } from "@/utils/media/types";

interface MediaAlertsProps {
  videoInfo: VideoInfo | null;
  videoError: string | null;
  mediaType: MediaType;
  mediaLoaded: boolean;
  youtubeSequence?: YouTubeVideoSequence | null;
}

const MediaAlerts = ({ videoInfo, videoError, mediaType, mediaLoaded, youtubeSequence }: MediaAlertsProps) => {
  // Don't show any alerts - we're hiding all error messages and debug info
  return null;
};

export default MediaAlerts;
