
import React from "react";
import { Button } from "@/components/ui/button";
import { useLanguage } from "@/context/LanguageContext";
import { 
  EDINBURG_IMAGE, 
  HARLINGEN_IMAGE,
  MERCEDES_IMAGE
} from "@/components/navbar/CommunitiesData";

interface CommunityHeroProps {
  activeCity: string;
  headline: string;
  description: string;
  onViewAllClick?: () => void;
}

const CommunityHero = ({ activeCity, headline, description, onViewAllClick }: CommunityHeroProps) => {
  const { language } = useLanguage();
  
  // Background image URLs based on active city 
  const getBackgroundImage = () => {
    // Keep our custom images for specific cities
    if (activeCity === "McAllen") {
      return {
        mobile: "/lovable-uploads/471b82af-992f-43e2-818b-55cf13a3dde6.png",
        desktop: "/lovable-uploads/471b82af-992f-43e2-818b-55cf13a3dde6.png"
      };
    } else if (activeCity === "South Padre Island") {
      return {
        mobile: "/lovable-uploads/c41db19d-3c71-4b2b-b77b-e2cb56891f36.png",
        desktop: "/lovable-uploads/c41db19d-3c71-4b2b-b77b-e2cb56891f36.png"
      };
    } else if (activeCity === "Hidalgo") {
      return {
        mobile: "/lovable-uploads/5d117e8e-0dc8-4903-bf68-638dccb00842.png",
        desktop: "/lovable-uploads/5d117e8e-0dc8-4903-bf68-638dccb00842.png"
      };
    } else if (activeCity === "Brownsville") {
      return {
        mobile: "/lovable-uploads/940d5d6d-ca53-4659-8542-20492c99cf6b.png",
        desktop: "/lovable-uploads/940d5d6d-ca53-4659-8542-20492c99cf6b.png"
      };
    } else if (activeCity === "Mission") {
      return {
        mobile: "/lovable-uploads/d26ea204-1a95-475e-b31c-eabca148d7d6.png",
        desktop: "/lovable-uploads/d26ea204-1a95-475e-b31c-eabca148d7d6.png"
      };
    } else if (activeCity === "Edinburg") {
      return {
        mobile: "/lovable-uploads/0f5438a9-1d76-44c0-a222-fb01f2287e9e.png",
        desktop: "/lovable-uploads/0f5438a9-1d76-44c0-a222-fb01f2287e9e.png"
      };
    } else if (activeCity === "Harlingen") {
      return {
        mobile: "/lovable-uploads/69e8c8b8-27bb-449d-953d-bed6e76760b5.png",
        desktop: "/lovable-uploads/69e8c8b8-27bb-449d-953d-bed6e76760b5.png"
      };
    } else if (activeCity === "Mercedes") {
      return {
        mobile: "/lovable-uploads/98b30f91-976e-4d00-89b9-98b18757afae.png",
        desktop: "/lovable-uploads/98b30f91-976e-4d00-89b9-98b18757afae.png"
      };
    } else if (activeCity === "Weslaco") {
      return {
        mobile: "/lovable-uploads/478b1e52-5766-4732-afec-e194759a3b78.png",
        desktop: "/lovable-uploads/478b1e52-5766-4732-afec-e194759a3b78.png"
      };
    } else if (activeCity === "Mexican Investors" || activeCity === "Inversionistas Mexicanos") {
      return {
        mobile: "https://images.unsplash.com/photo-1606836591695-4d58a73eba1e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fG1leGljbyUyMGNpdHl8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60",
        desktop: "https://images.unsplash.com/photo-1606836591695-4d58a73eba1e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fG1leGljbyUyMGNpdHl8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=1200&q=60"
      };
    }
    
    // Default image for all other cities
    return {
      mobile: "/lovable-uploads/5f6ac007-7d7c-46c2-a925-bf4a3e14afa1.png",
      desktop: "/lovable-uploads/5f6ac007-7d7c-46c2-a925-bf4a3e14afa1.png"
    };
  };

  const backgroundImage = getBackgroundImage();
  
  return (
    <div className="relative h-[60vh] bg-black">
      {/* Preload the desktop image */}
      <link 
        rel="preload" 
        href={backgroundImage.desktop} 
        as="image" 
        media="(min-width: 768px)" 
      />
      
      {/* Responsive background image handling */}
      <div 
        className="absolute inset-0 bg-cover bg-center opacity-60 md:opacity-80"
        style={{
          backgroundImage: `url(${backgroundImage.mobile})`,
        }}
      >
        {/* Use picture element for art direction */}
        <picture className="hidden">
          <source media="(min-width: 768px)" srcSet={backgroundImage.desktop} />
          <img 
            src={backgroundImage.mobile} 
            alt={`${activeCity || 'Rio Grande Valley'} background`}
            className="w-full h-full object-cover"
            loading="eager" // Load hero image eagerly as it's above the fold
          />
        </picture>
      </div>
      
      <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
      <div className="container mx-auto px-4 h-full flex flex-col justify-center items-center text-center relative z-10">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-display text-white mb-4 md:mb-6 max-w-4xl">
          {headline}
        </h1>
        <p className="text-lg md:text-xl text-white/90 max-w-2xl">
          {description}
        </p>
        
        {!activeCity && onViewAllClick && (
          <Button 
            className="mt-6 md:mt-8 bg-gold-500 hover:bg-gold-600 text-white px-6 md:px-8 py-4 md:py-6 text-lg rounded-md transition-all duration-300"
            onClick={onViewAllClick}
          >
            {language === 'es' ? 'Ver Todas Las Comunidades' : 'View All Communities'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default CommunityHero;
