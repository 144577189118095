
import React from 'react';
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import SEO from '@/components/SEO';
import ChatBot from '@/components/common/ChatBot';
import PropertySearchButton from '@/components/common/PropertySearchButton';
import QuickLinks from '@/components/common/QuickLinks';
import RenovationEstimatorContainer from '@/components/renovation-estimator/RenovationEstimatorContainer';

const RenovationEstimator: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col bg-black text-white">
      <SEO />
      <Navbar />
      
      <main className="flex-grow">
        <div className="pt-48 pb-28 bg-gradient-to-b from-black to-estate-900">
          <RenovationEstimatorContainer />
        </div>
      </main>
      
      <Footer />
      
      <ChatBot />
      <PropertySearchButton />
      <QuickLinks />
    </div>
  );
};

export default RenovationEstimator;
