
import { CityInfo } from "../types/CommunityTypes";

export const MercedesData: CityInfo = {
  headline: "Discover Business Opportunities in Mercedes",
  description: "Mercedes is a growing commercial hub in the Rio Grande Valley, offering excellent investment opportunities and business-friendly infrastructure. With its strategic location and development initiatives, Mercedes is ideal for commercial real estate investment.",
  marketInfo: {
    avgPrice: "$210,000",
    trend: "Rising 3.5% annually",
    daysOnMarket: "50 days"
  },
  communities: [
    {
      name: "Mercedes Business Park",
      description: "Modern business park with state-of-the-art facilities and logistics infrastructure",
      features: ["Commercial zoning", "Highway access", "Flexible spaces"],
      priceRange: "$300,000 - $1,500,000",
      image: "/lovable-uploads/98b30f91-976e-4d00-89b9-98b18757afae.png"
    },
    {
      name: "Outlet District",
      description: "Commercial district with high retail traffic and investment potential",
      features: ["Retail exposure", "Tourist attraction", "Growth area"],
      priceRange: "$250,000 - $800,000",
      image: "https://images.unsplash.com/photo-1555652736-e92021d28a39?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGNvbW1lcmNpYWwlMjBidWlsZGluZ3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
    }
  ],
  featuredProperties: [
    {
      id: 701,
      city: "Mercedes",
      title: "Modern Commercial Complex",
      price: "$850,000",
      image: "/lovable-uploads/98b30f91-976e-4d00-89b9-98b18757afae.png",
      beds: 0,
      baths: 2,
      sqft: "5,200"
    },
    {
      id: 702,
      city: "Mercedes",
      title: "Retail Space Near Outlets",
      price: "$425,000",
      image: "https://images.unsplash.com/photo-1497366754035-f200968a6e72?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Y29tbWVyY2lhbCUyMGJ1aWxkaW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      beds: 0,
      baths: 1,
      sqft: "2,800"
    }
  ]
};
