
import { useEffect, useState } from "react";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { 
  Carousel, 
  CarouselContent, 
  CarouselItem, 
  CarouselNext, 
  CarouselPrevious 
} from "@/components/ui/carousel";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { AspectRatio } from "@/components/ui/aspect-ratio";
import { Link } from "react-router-dom";
import { useBlogPosts } from "@/hooks/useBlogPosts";

const BlogCarousel = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { blogPosts, loading } = useBlogPosts();
  
  // Get the top 5 blog posts to display in the carousel
  const featuredPosts = blogPosts.slice(0, 5);
  
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <section className="py-8" id="latest-insights">
      <div className="container mx-auto px-4 max-w-6xl">
        <Carousel className="w-full blog-carousel">
          <CarouselContent>
            {loading ? (
              // Loading state
              Array.from({ length: 3 }).map((_, index) => (
                <CarouselItem key={`skeleton-${index}`} className="md:basis-1/2 lg:basis-1/3 blog-item">
                  <div className="p-1">
                    <Card className="overflow-hidden bg-estate-900 border-estate-800 animate-pulse">
                      <div className="relative">
                        <AspectRatio ratio={16 / 9}>
                          <div className="w-full h-full bg-estate-800" />
                        </AspectRatio>
                      </div>
                      <CardContent className="p-6">
                        <div className="h-4 bg-estate-800 rounded mb-3 w-1/3"></div>
                        <div className="h-6 bg-estate-800 rounded mb-3"></div>
                        <div className="h-4 bg-estate-800 rounded mb-3"></div>
                        <div className="h-4 bg-estate-800 rounded mb-3 w-2/3"></div>
                        <div className="h-10 bg-estate-800 rounded w-1/3 mt-4"></div>
                      </CardContent>
                    </Card>
                  </div>
                </CarouselItem>
              ))
            ) : featuredPosts.length > 0 ? (
              // Actual blog posts
              featuredPosts.map((post) => (
                <CarouselItem key={post.id} className="md:basis-1/2 lg:basis-1/3 blog-item">
                  <div className="p-1">
                    <Card 
                      className="overflow-hidden bg-estate-900 border-estate-800 h-full transition-all duration-300 hover:shadow-lg"
                      style={{
                        boxShadow: '0 0 12px 5px rgba(255, 179, 71, 0.35)',
                        transition: 'all 0.3s ease'
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.boxShadow = '0 0 20px 8px rgba(255, 179, 71, 0.6), 0 0 30px rgba(255, 179, 71, 0.4)';
                        e.currentTarget.style.transform = 'translateY(-3px)';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.boxShadow = '0 0 12px 5px rgba(255, 179, 71, 0.35)';
                        e.currentTarget.style.transform = 'translateY(0)';
                      }}
                    >
                      <div className="relative">
                        <AspectRatio ratio={16 / 9}>
                          <img 
                            src={post.image} 
                            alt={post.title} 
                            className="object-cover w-full h-full transition-transform duration-300 hover:scale-105"
                          />
                        </AspectRatio>
                        <div className="absolute top-3 right-3 bg-black/60 backdrop-blur-sm px-3 py-1 rounded-full text-sm font-medium text-white post-category">
                          {post.category}
                        </div>
                        
                        {/* Add overlay gradient effect */}
                        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/60 transition-opacity duration-500 z-10"></div>
                      </div>
                      
                      <CardContent className="p-6">
                        <div className="text-sm text-estate-400 mb-2 post-date">
                          {post.date}
                        </div>
                        
                        <h3 
                          className="text-xl font-bold text-white leading-tight mb-3 line-clamp-2 post-title transition-all duration-300"
                          style={{
                            textShadow: 'none'
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.textShadow = '0 0 5px rgba(255, 179, 71, 0.4)';
                            e.currentTarget.style.transform = 'translateY(-2px)';
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.textShadow = 'none';
                            e.currentTarget.style.transform = 'translateY(0)';
                          }}
                        >
                          {post.title}
                        </h3>
                        
                        <p className="text-estate-300 mb-4 line-clamp-2 post-excerpt">
                          {post.excerpt}
                        </p>
                        
                        <Link to={`/blog/${post.id}`} className="read-more-link">
                          <Button 
                            className="bg-gold-500 hover:bg-gold-700 text-black read-more-button transition-all duration-300"
                            style={{
                              boxShadow: '0 0 0 rgba(255, 179, 71, 0)'
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.boxShadow = '0 0 10px rgba(255, 179, 71, 0.7)';
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.boxShadow = '0 0 0 rgba(255, 179, 71, 0)';
                            }}
                          >
                            Read More
                          </Button>
                        </Link>
                      </CardContent>
                    </Card>
                  </div>
                </CarouselItem>
              ))
            ) : (
              // No posts found
              <CarouselItem className="md:basis-full blog-item">
                <Card className="overflow-hidden bg-estate-900 border-estate-800">
                  <CardContent className="p-6 text-center">
                    <p className="text-estate-300">No blog posts available at the moment.</p>
                  </CardContent>
                </Card>
              </CarouselItem>
            )}
          </CarouselContent>
          
          <div className="hidden lg:block carousel-controls">
            <CarouselPrevious className="left-0 bg-black/40 hover:bg-black/60 text-white border-none carousel-prev" />
            <CarouselNext className="right-0 bg-black/40 hover:bg-black/60 text-white border-none carousel-next" />
          </div>
        </Carousel>

        <div className="mt-8 text-center">
          <Link to="/blog">
            <Button 
              variant="outline" 
              className="border-gold-500 text-gold-500 hover:bg-gold-500 hover:text-black view-all-button transition-all duration-300"
              style={{
                boxShadow: '0 0 0 rgba(255, 179, 71, 0)'
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow = '0 0 15px rgba(255, 179, 71, 0.5)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = '0 0 0 rgba(255, 179, 71, 0)';
              }}
            >
              View All Posts
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default BlogCarousel;
