
import { useState, useEffect } from "react";
import { BlogPost } from "@/types/blog";
import { blogPosts as defaultBlogPosts } from "@/data/blogPosts";
import { supabase } from "@/integrations/supabase/client";
import { transformPostData } from "@/utils/blog/blogPostTransformer";
import { toast } from "sonner";

export const useBlogPosts = () => {
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [lastRefreshTime, setLastRefreshTime] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [dbPostsCount, setDbPostsCount] = useState(0);
  const [defaultPostsCount, setDefaultPostsCount] = useState(0);

  const fetchBlogPosts = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // First, get the default blog posts (these will always be kept)
      const defaultPosts = [...defaultBlogPosts];
      console.log("Loaded default posts:", defaultPosts.length);
      setDefaultPostsCount(defaultPosts.length);
      
      // Fetch from blog_posts table
      const { data: dbPosts, error: dbError } = await supabase
        .from('blog_posts')
        .select('*')
        .order('created_at', { ascending: false });
      
      if (dbError) {
        console.error("Error fetching blog posts:", dbError);
        setError(dbError.message);
        toast.error(`Failed to fetch blogs: ${dbError.message}`);
        // Still set default posts even if there's an error
        setBlogPosts([...defaultPosts]);
        setLoading(false);
        return;
      }
      
      if (dbPosts && dbPosts.length > 0) {
        console.log("Found DB posts:", dbPosts.length);
        
        // Process DB posts with a transformer
        const transformedDbPosts = dbPosts.map(post => {
          return transformPostData({
            ...post
          });
        });
        
        console.log("Transformed DB posts:", transformedDbPosts);
        setDbPostsCount(transformedDbPosts.length);
        
        // Combine DB posts and default posts
        const allPosts = [...transformedDbPosts, ...defaultPosts];
        
        // Sort by creation date (newest first)
        allPosts.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB.getTime() - dateA.getTime();
        });
        
        console.log("Total posts to display:", allPosts.length);
        setBlogPosts(allPosts);
        
        if (transformedDbPosts.length > 0) {
          toast.success(`Loaded ${transformedDbPosts.length} posts from database`);
        }
      } else {
        console.log("No DB blog posts found, using default posts only");
        setDbPostsCount(0);
        setBlogPosts([...defaultPosts]);
        toast.info("No posts found in database, showing default posts only");
      }
      
      setLastRefreshTime(new Date().toLocaleTimeString());
    } catch (error) {
      console.error("Unexpected error in fetchBlogPosts:", error);
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      setError(errorMessage);
      toast.error(`Error loading blogs: ${errorMessage}`);
      // Still set default posts even if there's an unexpected error
      setBlogPosts([...defaultBlogPosts]);
      setDefaultPostsCount(defaultBlogPosts.length);
      setDbPostsCount(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  return { 
    blogPosts, 
    loading, 
    error,
    refreshBlogPosts: fetchBlogPosts,
    lastRefreshTime,
    dbPostsCount,
    defaultPostsCount
  };
};
