
/**
 * Utilities for handling titles in blog content
 */

/**
 * Remove duplicate titles from the content
 * @param contentElement - The DOM element containing the blog content
 * @param titleText - The main title text to match against
 */
export function removeDuplicateTitles(contentElement: HTMLElement, titleText: string): void {
  if (!titleText) return;
  
  // Remove duplicate investment property titles - check all possible elements
  const removeInvestmentTitle = (el: Element) => {
    const textContent = el.textContent || '';
    // More precise matching to avoid removing content incorrectly
    if ((textContent.includes('Investment properties in') || textContent === titleText) && 
        !el.classList.contains('main-title') &&
        !el.classList.contains('content') &&
        !el.closest('.content')) { // Don't remove if inside content section
      console.log("Removing duplicate title:", textContent);
      el.remove();
    }
  };

  // Check headings, paragraphs and divs that might contain the duplicate title
  // Only target elements OUTSIDE the main content area
  contentElement.querySelectorAll('h1, h2, h3, p, div').forEach(el => {
    if (!el.closest('.content')) {
      removeInvestmentTitle(el);
    }
  });
}

/**
 * Remove duplicate titles after meta element
 * @param metaElement - The meta element in the blog content
 * @param titleText - The main title text to match against
 */
export function removeDuplicateTitlesAfterMeta(metaElement: Element, titleText: string): void {
  if (!metaElement || !titleText) return;
  
  let nextElement = metaElement.nextElementSibling;
  
  // Look at the next several elements after meta to find and remove duplicate titles
  for (let i = 0; i < 5 && nextElement; i++) {
    const current = nextElement;
    nextElement = nextElement.nextElementSibling;
    
    const currentText = current.textContent?.trim() || '';
    if ((currentText === titleText || currentText.includes('Investment properties in')) && 
        !current.classList.contains('content') &&
        !current.classList.contains('main-title')) {
      console.log("Removing duplicate title after meta");
      current.remove();
    }
  }
}
