
import { Slider } from "@/components/ui/slider";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useState, useEffect } from "react";

interface LoanAmountInputProps {
  loanAmount: number;
  onLoanAmountChange: (value: number) => void;
}

const LoanAmountInput = ({
  loanAmount,
  onLoanAmountChange,
}: LoanAmountInputProps) => {
  const [localLoanAmount, setLocalLoanAmount] = useState(loanAmount.toString());
  
  // Update local state when prop changes
  useEffect(() => {
    setLocalLoanAmount(loanAmount.toString());
  }, [loanAmount]);
  
  // Handle manual input of loan amount
  const handleLoanAmountInput = (value: string) => {
    setLocalLoanAmount(value);
    const numericValue = parseFloat(value.replace(/[^0-9.]/g, ''));
    if (!isNaN(numericValue)) {
      onLoanAmountChange(numericValue);
    }
  };
  
  // Format loan amount with commas
  const formatLoanAmount = (amount: number) => {
    return amount.toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 0
    });
  };

  return (
    <div className="space-y-2">
      <div className="flex justify-between items-center">
        <Label htmlFor="loanAmount" className="text-base font-medium">Loan Amount</Label>
        <Input
          id="loanAmountInput"
          className="w-28 text-right"
          value={localLoanAmount}
          onChange={(e) => handleLoanAmountInput(e.target.value)}
        />
      </div>
      <Slider
        id="loanAmount"
        min={50000}
        max={1000000}
        step={1000}
        value={[loanAmount]}
        onValueChange={(value) => {
          onLoanAmountChange(value[0]);
          setLocalLoanAmount(value[0].toString());
        }}
        className="py-4"
      />
      <div className="flex justify-between text-xs text-gray-500">
        <span>$50,000</span>
        <span>$1,000,000</span>
      </div>
    </div>
  );
};

export default LoanAmountInput;
