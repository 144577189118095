
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "@/context/LanguageContext";
import ThemeToggle from "../ThemeToggle";
import LanguageToggle from "../LanguageToggle";
import MobileCommunities from "./MobileCommunities";
import { Calculator } from "lucide-react";

interface MobileMenuProps {
  isOpen: boolean;
  toggleMenu: () => void;
  activeMobileSection: string;
  setActiveMobileSection: React.Dispatch<React.SetStateAction<string>>;
  onCategorySelect: (category: string) => void;
  onCitySelect: (city: string) => void;
  communitiesOpen: boolean;
  toggleCommunities: () => void;
  blogCategoriesOpen: boolean;
  toggleBlogCategories: () => void;
  handleCitySelect: (cityName: string) => void;
  handleCategorySelect: (category: string) => void;
  scrollToAbout: (e: React.MouseEvent) => void;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileMenu = ({
  isOpen,
  toggleMenu,
  activeMobileSection,
  setActiveMobileSection,
  onCategorySelect,
  onCitySelect,
  communitiesOpen,
  toggleCommunities,
  blogCategoriesOpen,
  toggleBlogCategories,
  handleCitySelect,
  handleCategorySelect,
  scrollToAbout,
  setIsMenuOpen
}) => {
  const { t } = useLanguage();
  const [calculatorsOpen, setCalculatorsOpen] = useState(false);

  const toggleCalculators = () => {
    setCalculatorsOpen(!calculatorsOpen);
  };

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-95 z-40 transition-transform duration-300 ease-in-out transform ${
        isOpen ? "translate-x-0" : "translate-x-full"
      } lg:hidden overflow-y-auto`}
      aria-hidden={!isOpen}
    >
      <div className="container mx-auto px-4 py-32">
        <nav className="flex flex-col gap-6">
          <Link
            to="/"
            className="text-white text-3xl hover:text-gold-500 transition-colors duration-300"
            onClick={() => setIsMenuOpen(false)}
          >
            {t("nav.home")}
          </Link>
          <Link
            to="/buy"
            className="text-white text-3xl hover:text-gold-500 transition-colors duration-300"
            onClick={() => setIsMenuOpen(false)}
          >
            {t("nav.buy")}
          </Link>
          <Link
            to="/sell"
            className="text-white text-3xl hover:text-gold-500 transition-colors duration-300"
            onClick={() => setIsMenuOpen(false)}
          >
            {t("nav.sell")}
          </Link>
          
          <Link
            to="/home-estimate"
            className="text-white text-3xl hover:text-gold-500 transition-colors duration-300"
            onClick={() => setIsMenuOpen(false)}
          >
            {t("nav.home-estimate") || "Home Estimates"}
          </Link>
          
          {/* Calculators dropdown */}
          <div>
            <button
              onClick={toggleCalculators}
              className="flex items-center justify-between w-full text-white text-3xl hover:text-gold-500 transition-colors duration-300"
            >
              <span>Calculators</span>
              <span>{calculatorsOpen ? "▲" : "▼"}</span>
            </button>
            {calculatorsOpen && (
              <div className="mt-2 pl-4 flex flex-col gap-3">
                <Link
                  to="/flip-calculator"
                  className="flex items-center text-white text-xl hover:text-gold-500 transition-colors duration-300"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <Calculator className="mr-2 h-5 w-5" />
                  House Flipping Calculator
                </Link>
                <Link
                  to="/rental-calculator"
                  className="flex items-center text-white text-xl hover:text-gold-500 transition-colors duration-300"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <Calculator className="mr-2 h-5 w-5" />
                  Rental Property Calculator
                </Link>
                <Link
                  to="/rent-vs-buy-calculator"
                  className="flex items-center text-white text-xl hover:text-gold-500 transition-colors duration-300"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <Calculator className="mr-2 h-5 w-5" />
                  Rent vs Buy Calculator
                </Link>
                <Link
                  to="/mortgage-calculator"
                  className="flex items-center text-white text-xl hover:text-gold-500 transition-colors duration-300"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <Calculator className="mr-2 h-5 w-5" />
                  Mortgage Calculator
                </Link>
                <Link
                  to="/split-calculator"
                  className="flex items-center text-white text-xl hover:text-gold-500 transition-colors duration-300"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <Calculator className="mr-2 h-5 w-5" />
                  Split View Calculator
                </Link>
              </div>
            )}
          </div>
          
          {/* Communities dropdown */}
          <div>
            <button
              onClick={toggleCommunities}
              className="flex items-center justify-between w-full text-white text-3xl hover:text-gold-500 transition-colors duration-300"
            >
              <span>{t("nav.communities")}</span>
              <span>{communitiesOpen ? "▲" : "▼"}</span>
            </button>
            {communitiesOpen && (
              <MobileCommunities 
                isOpen={communitiesOpen}
                onSelect={(city) => {
                  handleCitySelect(city);
                  setIsMenuOpen(false);
                }} 
              />
            )}
          </div>
          
          <Link
            to="/commercial"
            className="text-white text-3xl hover:text-gold-500 transition-colors duration-300"
            onClick={() => setIsMenuOpen(false)}
          >
            {t("nav.commercial")}
          </Link>
          
          <Link
            to="/residential"
            className="text-white text-3xl hover:text-gold-500 transition-colors duration-300"
            onClick={() => setIsMenuOpen(false)}
          >
            {t("nav.residential")}
          </Link>
          
          <Link
            to="/mexican-clients"
            className="text-white text-3xl hover:text-gold-500 transition-colors duration-300"
            onClick={() => setIsMenuOpen(false)}
          >
            {t("nav.mexican-investors") || "Paisanos"}
          </Link>
          
          {/* Blog Categories dropdown */}
          <div>
            <Link
              to="/blog"
              className="flex items-center justify-between w-full text-white text-3xl hover:text-gold-500 transition-colors duration-300"
              onClick={() => setIsMenuOpen(false)}
            >
              <span>{t("nav.blog")}</span>
            </Link>
          </div>
          
          <Link
            to="/contact"
            className="text-white text-3xl hover:text-gold-500 transition-colors duration-300"
            onClick={() => setIsMenuOpen(false)}
          >
            {t("nav.contact")}
          </Link>

          {/* Theme and Language toggles */}
          <div className="flex items-center gap-6 pt-6">
            <ThemeToggle />
            <LanguageToggle />
          </div>
        </nav>
      </div>
    </div>
  );
};

export default MobileMenu;
