
import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu, X } from "lucide-react";
import NavbarLogo from "./navbar/NavbarLogo";
import DesktopNavLinks from "./navbar/DesktopNavLinks";
import MobileMenu from "./navbar/MobileMenu";
import "../styles/navigation.css";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [communitiesOpen, setCommunitiesOpen] = useState(false);
  const [blogCategoriesOpen, setBlogCategoriesOpen] = useState(false);
  const [activeMobileSection, setActiveMobileSection] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const blogDropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 80 && !isScrolled) {
        setIsScrolled(true);
      } else if (scrollPosition <= 80 && isScrolled) {
        setIsScrolled(false);
      }
    };
    
    window.addEventListener("scroll", handleScroll, { passive: true });
    handleScroll();
    
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleCommunities = () => {
    setCommunitiesOpen(!communitiesOpen);
    if (communitiesOpen) {
      setBlogCategoriesOpen(false);
    }
  };
  
  const toggleBlogCategories = () => {
    setBlogCategoriesOpen(!blogCategoriesOpen);
    if (blogCategoriesOpen) {
      setCommunitiesOpen(false);
    }
  };
  
  const scrollToAbout = (e: React.MouseEvent) => {
    e.preventDefault();
    
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const aboutSection = document.getElementById("about");
        if (aboutSection) {
          aboutSection.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    } else {
      const aboutSection = document.getElementById("about");
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: "smooth" });
      }
    }
    
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setCommunitiesOpen(false);
      }
      if (blogDropdownRef.current && !blogDropdownRef.current.contains(event.target as Node)) {
        setBlogCategoriesOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCitySelect = (cityName: string) => {
    setCommunitiesOpen(false);
    setIsMenuOpen(false);
    if (cityName) {
      const citySlug = cityName.toLowerCase().replace(/\s+/g, '-');
      navigate(`/communities#${citySlug}`);
    }
  };
  
  const handleCategorySelect = (category: string) => {
    setBlogCategoriesOpen(false);
    setIsMenuOpen(false);
    if (category) {
      navigate(`/blog?category=${encodeURIComponent(category)}`);
    }
  };
  
  const navbarClasses = `
    fixed top-0 left-0 right-0 w-full z-50
    ${isScrolled ? 'scrolled' : 'bg-transparent'}
    transition-all duration-300 ease-in-out
  `;
  
  return (
    <nav 
      className={navbarClasses} 
      style={{ 
        transform: 'translateZ(0)', 
        backfaceVisibility: 'hidden',
        willChange: 'transform',
        perspective: '1000px',
        position: 'fixed' 
      }}
    >
      <div className="container mx-auto px-4 max-w-7xl"> {/* Added px-4 for better padding */}
        <div className="flex items-center justify-between h-18">
          <NavbarLogo isTransparent={true} />
          
          <div className="hidden lg:flex items-center justify-start flex-1 pl-12"> {/* Increased padding-left from pl-8 to pl-12 */}
            <DesktopNavLinks 
              communitiesOpen={communitiesOpen}
              toggleCommunities={toggleCommunities}
              setCommunitiesOpen={setCommunitiesOpen}
              blogCategoriesOpen={blogCategoriesOpen}
              toggleBlogCategories={toggleBlogCategories}
              setBlogCategoriesOpen={setBlogCategoriesOpen}
              scrollToAbout={scrollToAbout}
              handleCitySelect={handleCitySelect}
              handleCategorySelect={handleCategorySelect}
              dropdownRef={dropdownRef}
              blogDropdownRef={blogDropdownRef}
            />
          </div>
          
          <button
            onClick={toggleMenu}
            className={`lg:hidden focus:outline-none transition duration-300 text-white hover:text-gold-500`}
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          >
            {isMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>
      
      <MobileMenu 
        isOpen={isMenuOpen}
        toggleMenu={toggleMenu}
        activeMobileSection={activeMobileSection}
        setActiveMobileSection={setActiveMobileSection}
        onCategorySelect={handleCategorySelect}
        onCitySelect={handleCitySelect}
        communitiesOpen={communitiesOpen}
        toggleCommunities={toggleCommunities}
        blogCategoriesOpen={blogCategoriesOpen}
        toggleBlogCategories={toggleBlogCategories}
        handleCitySelect={handleCitySelect}
        handleCategorySelect={handleCategorySelect}
        scrollToAbout={scrollToAbout}
        setIsMenuOpen={setIsMenuOpen}
      />
    </nav>
  );
};

export default Navbar;
