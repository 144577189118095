/**
 * Utility functions to manipulate headings in blog content
 */

/**
 * Process headings in the blog content
 * @param contentElement - The DOM element containing the blog content
 */
export const processHeadings = (contentElement: HTMLDivElement): void => {
  // Extract the title from the hero section if it exists, and create a main title
  const heroTitle = contentElement.querySelector('.hero-text h1');
  if (heroTitle && heroTitle.textContent) {
    // Create a new main title element
    const mainTitle = document.createElement('h1');
    mainTitle.className = 'main-title';
    mainTitle.textContent = heroTitle.textContent;
    
    // Insert at the beginning of the container
    const container = contentElement.querySelector('.container');
    if (container && container.firstChild) {
      container.insertBefore(mainTitle, container.firstChild);
    }
  }
  
  // Find empty h1 tags
  const emptyHeadings = contentElement.querySelectorAll('h1:empty');
  emptyHeadings.forEach(heading => {
    // Look for Primary Keyword in meta section
    const metaKeyword = contentElement?.querySelector('.meta span:nth-child(2)');
    if (metaKeyword) {
      const keywordText = metaKeyword.textContent?.replace('Primary Keyword: ', '') || 'Blog Post';
      heading.textContent = keywordText;
    } else {
      heading.textContent = 'Blog Post';
    }
  });
  
  // Set title if it's empty in the document
  const title = contentElement.querySelector('title:empty');
  if (title) {
    const h1 = contentElement.querySelector('h1');
    if (h1?.textContent) {
      title.textContent = h1.textContent;
    } else {
      title.textContent = 'Blog Post';
    }
  }

  // Apply custom styling for headings if not already styled
  const headings = contentElement.querySelectorAll('h1, h2, h3');
  headings.forEach(heading => {
    // Only apply if not already styled with text-shadow
    if (!heading.getAttribute('style') || !heading.getAttribute('style')?.includes('text-shadow')) {
      const element = heading as HTMLElement;
      element.style.color = '#FFFFFF';
      element.style.textShadow = '0 -2px 2px gold';
      
      // Make headings more prominent based on level
      if (heading.tagName === 'H1') {
        element.style.fontSize = '48px';
        element.style.fontWeight = '700';
        element.style.lineHeight = '1.3';
        // Reduce spacing
        element.style.marginTop = '1em';
        element.style.marginBottom = '1.5em';
      } else if (heading.tagName === 'H2') {
        element.style.fontSize = '36px';
        element.style.fontWeight = '700';
        element.style.lineHeight = '1.35';
        element.style.marginTop = '2.5em';
        element.style.marginBottom = '1.5em';
      } else if (heading.tagName === 'H3') {
        element.style.fontSize = '24px';
        element.style.fontWeight = '700';
        element.style.lineHeight = '1.4';
        element.style.marginTop = '2.5em';
        element.style.marginBottom = '1.5em';
      }
    }
  });
  
  // Handle "Investment properties in Harlingen, TX" title specifically
  const investmentHeadings = Array.from(contentElement.querySelectorAll('h1, h2, h3')).filter(el => 
    el.textContent?.toLowerCase().includes('investment') && 
    (el.textContent?.toLowerCase().includes('harlingen') || el.textContent?.toLowerCase().includes('pharr'))
  );
  
  investmentHeadings.forEach(heading => {
    // First, check if this is in the content section (we want to keep it)
    const inContent = heading.closest('.content') !== null;
    
    if (inContent || heading.classList.contains('main-title')) {
      // If in content or it's the main title, ensure proper spacing
      (heading as HTMLElement).style.marginTop = '30px';
      (heading as HTMLElement).style.marginBottom = '30px';
      (heading as HTMLElement).style.lineHeight = '1.5';
      (heading as HTMLElement).style.padding = '15px 0';
      
      // If it contains Pharr, TX ensure words don't break
      if (heading.textContent?.includes('Pharr')) {
        (heading as HTMLElement).style.wordBreak = 'keep-all';
        (heading as HTMLElement).style.whiteSpace = 'normal';
        (heading as HTMLElement).style.overflow = 'visible';
        (heading as HTMLElement).style.textOverflow = 'clip';
      }
    } else {
      // If not in content, check if it's right after meta
      const isAfterMeta = heading.previousElementSibling && 
                           heading.previousElementSibling.classList.contains('meta');
      
      if (isAfterMeta) {
        // Keep it but with better spacing
        (heading as HTMLElement).style.marginTop = '20px';
        (heading as HTMLElement).style.marginBottom = '20px';
        (heading as HTMLElement).style.paddingTop = '15px';
        (heading as HTMLElement).style.paddingBottom = '15px';
        (heading as HTMLElement).style.lineHeight = '1.5';
        
        // Ensure the content comes right after this heading
        const content = contentElement.querySelector('.content');
        if (content && heading.nextElementSibling !== content) {
          // Remove anything between this heading and the content
          let nextEl = heading.nextElementSibling;
          while (nextEl && !nextEl.classList.contains('content')) {
            const toRemove = nextEl;
            nextEl = nextEl.nextElementSibling;
            toRemove.parentNode?.removeChild(toRemove);
          }
        }
      } else {
        // Not a main heading, remove it if it's in a hero or similar section
        const heroParent = heading.closest('.hero, .hero-text, .hero-content');
        if (heroParent) {
          heroParent.parentNode?.removeChild(heroParent);
        } else {
          heading.parentNode?.removeChild(heading);
        }
      }
    }
  });
  
  // Check if we need to create heading for the content
  const content = contentElement.querySelector('.content');
  if (content && !content.querySelector('h1, h2, h3')) {
    // Look for post title
    const title = contentElement.querySelector('title')?.textContent || 
                  contentElement.querySelector('.main-title')?.textContent;
    
    if (title && !title.includes('Investment properties in Harlingen') && !title.includes('Investment properties in Pharr')) {
      // Create heading inside content
      const contentHeading = document.createElement('h1');
      contentHeading.textContent = title;
      contentHeading.className = 'content-title';
      contentHeading.style.marginTop = '0';
      contentHeading.style.marginBottom = '30px';
      contentHeading.style.lineHeight = '1.5';
      content.insertBefore(contentHeading, content.firstChild);
    }
  }
};
