
/**
 * Utilities for transforming image prompts in blog content
 */

/**
 * Helper function to transform image prompts into proper image elements when there are blog images available
 * @param contentElement - The DOM element containing the blog content
 * @param images - Array of blog images to use for image prompts
 */
export const replaceImagePrompts = (contentElement: HTMLDivElement, images: { url: string; caption?: string }[]): void => {
  if (!images || images.length === 0) {
    console.log('No images available to replace image prompts');
    return;
  }
  
  const imagePrompts = contentElement.querySelectorAll('.image-prompt, .image-prompt-placeholder');
  if (imagePrompts.length === 0) {
    console.log('No image prompts found to replace');
    return;
  }
  
  console.log(`Found ${imagePrompts.length} image prompts to replace with ${images.length} available images`);
  
  let imageIndex = 0;
  imagePrompts.forEach(prompt => {
    if (imageIndex < images.length) {
      const image = images[imageIndex++];
      
      // Get prompt text - handle both text elements and image elements
      let promptText = '';
      if (prompt instanceof HTMLImageElement) {
        promptText = prompt.alt || '';
      } else {
        promptText = prompt.textContent || '';
      }
      
      const figureElement = document.createElement('figure');
      figureElement.className = 'blog-image';
      figureElement.style.margin = '2rem 0';
      
      const imgElement = document.createElement('img');
      imgElement.src = image.url;
      imgElement.alt = promptText;
      imgElement.style.maxWidth = '100%';
      imgElement.style.height = 'auto';
      imgElement.style.borderRadius = '0.5rem';
      figureElement.appendChild(imgElement);
      
      if (image.caption || promptText) {
        const captionElement = document.createElement('figcaption');
        // Use nullish coalescing to handle optional captions
        captionElement.textContent = image.caption ?? promptText;
        captionElement.style.textAlign = 'center';
        captionElement.style.marginTop = '0.5rem';
        captionElement.style.fontSize = '0.875rem';
        captionElement.style.color = 'rgba(255, 255, 255, 0.8)';
        figureElement.appendChild(captionElement);
      }
      
      prompt.parentNode?.replaceChild(figureElement, prompt);
    }
  });
  
  // If there are more images than prompts, append them at the end
  if (imageIndex < images.length) {
    console.log(`Appending ${images.length - imageIndex} additional images that didn't have prompts`);
    
    const additionalImagesContainer = document.createElement('div');
    additionalImagesContainer.className = 'additional-blog-images';
    additionalImagesContainer.style.marginTop = '2rem';
    
    const heading = document.createElement('h3');
    heading.textContent = 'Additional Images';
    heading.style.marginBottom = '1rem';
    additionalImagesContainer.appendChild(heading);
    
    for (let i = imageIndex; i < images.length; i++) {
      const image = images[i];
      
      const figureElement = document.createElement('figure');
      figureElement.className = 'blog-image';
      figureElement.style.margin = '1rem 0';
      
      const imgElement = document.createElement('img');
      imgElement.src = image.url;
      imgElement.alt = image.caption || 'Additional image';
      imgElement.style.maxWidth = '100%';
      imgElement.style.height = 'auto';
      imgElement.style.borderRadius = '0.5rem';
      figureElement.appendChild(imgElement);
      
      if (image.caption) {
        const captionElement = document.createElement('figcaption');
        captionElement.textContent = image.caption;
        captionElement.style.textAlign = 'center';
        captionElement.style.marginTop = '0.5rem';
        captionElement.style.fontSize = '0.875rem';
        captionElement.style.color = 'rgba(255, 255, 255, 0.8)';
        figureElement.appendChild(captionElement);
      }
      
      additionalImagesContainer.appendChild(figureElement);
    }
    
    contentElement.appendChild(additionalImagesContainer);
  }
};
