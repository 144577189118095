
import { useLanguage } from "@/context/LanguageContext";
import PropertyGrid from "../PropertyGrid";
import PulsingLightBar from "../common/PulsingLightBar";
import GlowingText from "../common/GlowingText";

const DiscoverSection = () => {
  const { t } = useLanguage();
  
  return (
    <section className="py-32 bg-background">
      <div className="container mx-auto px-4 max-w-6xl text-center">
        <h1 
          className="text-6xl font-display mb-6" 
          data-text={t('properties.discover')}
        >
          {t('properties.discover')}
        </h1>
        
        {/* Apply blue advanced glow to the subtitle */}
        <GlowingText 
          text={t('properties.discover.subtitle')}
          as="p"
          className="text-xl mb-16 max-w-2xl mx-auto"
          glowColor="blue"
          advanced={true}
        />
        
        <PropertyGrid />
      </div>
    </section>
  );
};

export default DiscoverSection;
