
import React, { useState, useEffect } from 'react';
import { useSEO } from '@/context/SEOContext';
import { useRentVsBuyCalculator } from '@/hooks/useRentVsBuyCalculator';
import CalculatorHeader from './CalculatorHeader';
import BuyingInputs from './BuyingInputs';
import RentingInputs from './RentingInputs';
import CalculatorButtons from './CalculatorButtons';
import ResultsSection from './ResultsSection';
import ChartSection from './ChartSection';
import UserGuide from './UserGuide';
import { Button } from '@/components/ui/button';
import { HelpCircle } from 'lucide-react';

const RentVsBuyCalculatorContainer: React.FC = () => {
  const { updateSEO } = useSEO();
  const [isGuideOpen, setIsGuideOpen] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [showResults, setShowResults] = useState(false);
  
  // Buying state
  const [purchasePrice, setPurchasePrice] = useState<number>(300000);
  const [downPayment, setDownPayment] = useState<number>(60000);
  const [interestRate, setInterestRate] = useState<number>(3.5);
  const [loanTerm, setLoanTerm] = useState<number>(30);
  const [propertyTaxes, setPropertyTaxes] = useState<number>(3600);
  const [homeInsurance, setHomeInsurance] = useState<number>(1900);
  const [maintenanceCosts, setMaintenanceCosts] = useState<number>(3000);
  const [hoaFees, setHoaFees] = useState<number>(200);
  const [priceAppreciation, setPriceAppreciation] = useState<number>(3);
  
  // Renting state
  const [monthlyRent, setMonthlyRent] = useState<number>(3000);
  const [rentGrowthRate, setRentGrowthRate] = useState<number>(3);
  const [rentersInsurance, setRentersInsurance] = useState<number>(200);
  const [investmentReturn, setInvestmentReturn] = useState<number>(13.75);
  const [investmentPeriod, setInvestmentPeriod] = useState<number>(10);
  
  useEffect(() => {
    // Update SEO metadata for this page
    updateSEO({
      title: 'Rent vs Buy Calculator | Juan Elizondo RE/MAX',
      description: 'Compare the costs of renting vs buying a home over time with Juan Elizondo\'s Rent vs Buy Calculator.',
      keywords: ['rent vs buy', 'housing calculator', 'real estate', 'McAllen real estate', 'home buying'],
    });
    
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  // Calculate results using the hook
  const results = useRentVsBuyCalculator({
    purchasePrice,
    downPayment,
    interestRate,
    loanTerm,
    propertyTaxes,
    homeInsurance,
    maintenanceCosts,
    hoaFees,
    monthlyRent,
    rentGrowthRate,
    rentersInsurance,
    investmentPeriod,
    investmentReturn,
    priceAppreciation
  });

  // Format currency
  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    }).format(value);
  };

  // Format percentage
  const formatPercent = (value: number): string => {
    return `${value}%`;
  };

  // Handle input changes with formatting
  const handleCurrencyInputChange = (value: string, setter: React.Dispatch<React.SetStateAction<number>>) => {
    const cleanValue = value.replace(/[^0-9.]/g, '');
    setter(parseFloat(cleanValue) || 0);
  };

  // Handle percentage input changes
  const handlePercentInputChange = (value: string, setter: React.Dispatch<React.SetStateAction<number>>) => {
    const cleanValue = value.replace(/[^0-9.]/g, '');
    setter(parseFloat(cleanValue) || 0);
  };

  // Calculate results
  const calculateResults = () => {
    setIsCalculating(true);
    // Simulate calculation delay for better UX
    setTimeout(() => {
      setShowResults(true);
      setIsCalculating(false);
    }, 500);
  };

  // Reset form
  const resetForm = () => {
    setPurchasePrice(300000);
    setDownPayment(60000);
    setInterestRate(3.5);
    setLoanTerm(30);
    setPropertyTaxes(3600);
    setHomeInsurance(1900);
    setMaintenanceCosts(3000);
    setHoaFees(200);
    setMonthlyRent(3000);
    setRentGrowthRate(3);
    setRentersInsurance(200);
    setInvestmentReturn(13.75);
    setInvestmentPeriod(10);
    setPriceAppreciation(3);
    setShowResults(false);
  };

  const cardClass = "bg-estate-900/70 p-6 rounded-2xl shadow-lg border border-gold-500/30 transition-all duration-300 hover:shadow-gold-500/20 hover:shadow-xl hover:bg-estate-900/90";

  return (
    <div className="container mx-auto px-4">
      <div className="flex justify-between items-center">
        <CalculatorHeader 
          title="Rent vs Buy Calculator" 
          subtitle="Compare the costs of renting vs buying over time"
        />
        <Button 
          variant="outline" 
          size="sm" 
          className="border-gold-500/40 text-gold-500" 
          onClick={() => setIsGuideOpen(true)}
        >
          <HelpCircle size={16} className="mr-2" />
          User Guide
        </Button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-12">
        {/* Card 1: Buying Inputs */}
        <div className={cardClass}>
          <BuyingInputs
            purchasePrice={purchasePrice}
            setPurchasePrice={setPurchasePrice}
            downPayment={downPayment}
            setDownPayment={setDownPayment}
            interestRate={interestRate}
            setInterestRate={setInterestRate}
            loanTerm={loanTerm}
            setLoanTerm={setLoanTerm}
            propertyTaxes={propertyTaxes}
            setPropertyTaxes={setPropertyTaxes}
            homeInsurance={homeInsurance}
            setHomeInsurance={setHomeInsurance}
            maintenanceCosts={maintenanceCosts}
            setMaintenanceCosts={setMaintenanceCosts}
            hoaFees={hoaFees}
            setHoaFees={setHoaFees}
            priceAppreciation={priceAppreciation}
            setPriceAppreciation={setPriceAppreciation}
            formatCurrency={formatCurrency}
            formatPercent={formatPercent}
            handleCurrencyInputChange={handleCurrencyInputChange}
            handlePercentInputChange={handlePercentInputChange}
          />
        </div>
        
        {/* Card 2: Renting Inputs */}
        <div className={cardClass}>
          <RentingInputs
            monthlyRent={monthlyRent}
            setMonthlyRent={setMonthlyRent}
            rentGrowthRate={rentGrowthRate}
            setRentGrowthRate={setRentGrowthRate}
            rentersInsurance={rentersInsurance}
            setRentersInsurance={setRentersInsurance}
            investmentReturn={investmentReturn}
            setInvestmentReturn={setInvestmentReturn}
            investmentPeriod={investmentPeriod}
            setInvestmentPeriod={setInvestmentPeriod}
            formatCurrency={formatCurrency}
            formatPercent={formatPercent}
            handleCurrencyInputChange={handleCurrencyInputChange}
            handlePercentInputChange={handlePercentInputChange}
          />
        </div>
      </div>
      
      {/* Calculator Buttons */}
      <div className="max-w-md mx-auto mb-12">
        <CalculatorButtons
          calculateResults={calculateResults}
          resetForm={resetForm}
          isLoading={isCalculating}
        />
      </div>
      
      {/* Results Section (conditionally rendered) */}
      {showResults && (
        <div className="space-y-12 mt-4">
          <div className={cardClass}>
            <ResultsSection
              totalBuyingCost={results.totalBuyingCost}
              totalRentingCost={results.totalRentingCost}
              netDifference={results.netDifference}
              homeEquity={results.homeEquity}
              investmentEquity={results.investmentEquity}
              formatCurrency={formatCurrency}
              isBuyingBetter={results.isBuyingBetter}
            />
          </div>
          
          <div className={cardClass}>
            <ChartSection data={results.yearByYearData} />
          </div>
        </div>
      )}
      
      {/* User Guide Dialog */}
      <UserGuide isOpen={isGuideOpen} onClose={() => setIsGuideOpen(false)} />
    </div>
  );
};

export default RentVsBuyCalculatorContainer;
