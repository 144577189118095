
import React, { useEffect } from 'react';
import EmptyContentMessage from './EmptyContentMessage';
import BlogContentProcessor from './BlogContentProcessor';
import { getBlogContentStyles } from './styles/blogContentStyles';
import { BlogImage } from '@/types/blog';

interface BlogPostContentProps {
  content: string;
  className?: string;
  images?: BlogImage[];
  postTitle?: string;
}

const BlogPostContent: React.FC<BlogPostContentProps> = ({
  content,
  className = '',
  images = [],
  postTitle
}) => {
  // Check if content is empty or just a placeholder
  const isEmptyContent = !content || 
    content === "No content provided" || 
    content === "No content available";
  
  // Log images for debugging
  useEffect(() => {
    if (images.length > 0) {
      console.log(`BlogPostContent rendering ${images.length} images for "${postTitle || 'Untitled'}"`);
      images.slice(0, 3).forEach((img, i) => {
        console.log(`Image ${i}: ${img.url?.substring(0, 50)}... (Caption: ${img.caption || 'none'})`);
      });
    } else {
      console.log(`BlogPostContent: No images available for "${postTitle || 'Untitled'}"`);
    }
  }, [images, postTitle]);

  return (
    <>
      {isEmptyContent ? (
        <EmptyContentMessage />
      ) : (
        <>
          {/* Inject custom CSS styles */}
          <style>{getBlogContentStyles()}</style>
          
          <BlogContentProcessor 
            content={content}
            className={className}
            images={images}
          />
        </>
      )}
    </>
  );
};

export default BlogPostContent;
