
import React, { createContext, useContext, useEffect, useState } from "react";
import { refreshGlowEffect } from "@/utils/glowTextUtils";

type Theme = "light" | "dark";

interface ThemeContextType {
  theme: Theme;
  toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(() => {
    // Check if theme is stored in localStorage
    if (typeof window !== 'undefined') {
      const savedTheme = localStorage.getItem("theme") as Theme | null;
      // Check if OS prefers dark mode
      const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
      return savedTheme || (prefersDark ? "dark" : "light");
    }
    return "dark"; // Default to dark for SSR
  });

  // Apply theme class to HTML element
  useEffect(() => {
    if (typeof window === 'undefined') return;
    
    const root = window.document.documentElement;
    
    if (theme === "dark") {
      root.classList.add("dark");
      root.classList.remove("light");
    } else {
      root.classList.remove("dark");
      root.classList.add("light");
    }
    
    // Save the user's preference to localStorage
    localStorage.setItem("theme", theme);
    
    // Dispatch a custom event when theme changes
    const event = new CustomEvent('themeChanged', { detail: { theme } });
    document.dispatchEvent(event);
    
    console.log(`Theme applied: ${theme}`);
    
    // Apply glow effect with multiple attempts to ensure it works
    setTimeout(() => refreshGlowEffect(), 100);
    setTimeout(() => refreshGlowEffect(), 500);
    setTimeout(() => refreshGlowEffect(), 1000);
    
    return () => {};
  }, [theme]);

  const toggleTheme = () => {
    setTheme(prevTheme => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
