
import React from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { AspectRatio } from "@/components/ui/aspect-ratio";
import { Community } from "./CityInfo";

interface FeaturedCommunitiesProps {
  cityName: string;
  communities: Community[];
}

const FeaturedCommunities = ({ cityName, communities }: FeaturedCommunitiesProps) => {
  return (
    <div className="mb-16">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-display mb-4">Featured Communities in {cityName}</h2>
        <p className="text-muted-foreground max-w-3xl mx-auto">
          Discover the most desirable neighborhoods and communities that {cityName} has to offer, each with its own unique character and charm.
        </p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {communities.map((community, index) => (
          <Card key={index} className="overflow-hidden hover:shadow-lg transition-all duration-300">
            <CardContent className="p-0">
              <div className="relative">
                <AspectRatio ratio={16/9}>
                  <img
                    src={community.image}
                    alt={community.name}
                    className="object-cover w-full h-full"
                  />
                </AspectRatio>
                <div className="absolute top-4 left-4 bg-gold-500/90 text-white text-sm font-semibold py-1 px-4 rounded-full">
                  {community.priceRange}
                </div>
              </div>
              <div className="p-6">
                <h3 className="text-2xl font-semibold mb-2">{community.name}</h3>
                <p className="text-muted-foreground mb-4">{community.description}</p>
                
                <div className="flex flex-wrap gap-2 mb-6">
                  {community.features.map((feature, i) => (
                    <span key={i} className="bg-secondary text-secondary-foreground text-sm py-1 px-3 rounded-full">
                      {feature}
                    </span>
                  ))}
                </div>
                
                <Button variant="outline" className="w-full hover:bg-gold-500 hover:text-white border-gold-500 text-gold-500 transition-colors">
                  Learn More About {community.name}
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default FeaturedCommunities;
