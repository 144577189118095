
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
import MexicanHero from "@/components/mexican/MexicanHero";
import MexicanIntroduction from "@/components/mexican/MexicanIntroduction";
import MexicanBenefits from "@/components/mexican/MexicanBenefits";
import MexicanContentTabs from "@/components/mexican/MexicanContentTabs";
import MexicanLegalConsiderations from "@/components/mexican/MexicanLegalConsiderations";
import MexicanSteps from "@/components/mexican/MexicanSteps";
import MexicanTestimonials from "@/components/mexican/MexicanTestimonials";
import MexicanContactSection from "@/components/mexican/MexicanContactSection";
import { useLanguage } from "@/context/LanguageContext";
import { useTheme } from "@/context/ThemeContext";
import { useEffect, useState } from "react";
import GlobalGlowFilter from "@/components/GlobalGlowFilter";
import GlowEffectDebug from "@/components/common/GlowEffectDebug";
import { refreshGlowEffect } from "@/utils/glowTextUtils";

const MexicanClients = () => {
  const { setLanguage } = useLanguage();
  const { theme } = useTheme();
  const [isPageReady, setIsPageReady] = useState(false);
  
  useEffect(() => {
    // Store the current language and force Spanish
    const previousLanguage = localStorage.getItem('preferredLanguage') || 'en';
    localStorage.setItem('previousLanguage', previousLanguage);
    
    // Set page as not ready during initial load
    setIsPageReady(false);
    
    // Always set language to Spanish for this page
    setLanguage('es');
    
    // Delay setting the page as ready to ensure all components have updated
    const readyTimer = setTimeout(() => {
      setIsPageReady(true);
      
      // Then refresh the glow effect
      setTimeout(() => {
        refreshGlowEffect();
      }, 200);
    }, 500);
    
    // Restore previous language when leaving
    return () => {
      clearTimeout(readyTimer);
      const savedPreviousLanguage = localStorage.getItem('previousLanguage');
      if (savedPreviousLanguage) {
        setLanguage(savedPreviousLanguage === 'en' ? 'en' : 'es');
      }
    };
  }, [setLanguage]);

  const bgClass = theme === 'dark' ? "bg-estate-900" : "bg-white";
  const textClass = theme === 'dark' ? "text-white" : "text-estate-900";

  return (
    <div 
      className={`min-h-screen ${bgClass} ${textClass}`} 
      style={{ 
        perspective: '1000px',
        opacity: isPageReady ? 1 : 0.8,
        transition: 'opacity 0.5s ease'
      }}
    >
      <GlobalGlowFilter />
      <GlowEffectDebug />
      
      <Navbar />
      <div className="content-wrapper" style={{ 
        transform: 'translateZ(0)',
        willChange: 'auto',
        backfaceVisibility: 'hidden'
      }}>
        <MexicanHero />
        <MexicanIntroduction />
        <MexicanBenefits />
        <MexicanContentTabs />
        <MexicanLegalConsiderations />
        <MexicanSteps />
        <MexicanTestimonials />
        <MexicanContactSection />
      </div>
      <Footer />
    </div>
  );
};

export default MexicanClients;
