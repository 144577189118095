
import React from "react";
import { Button } from "@/components/ui/button";

interface ContactFormProps {
  communities: string[];
  activeCity: string;
}

const ContactForm = ({ communities, activeCity }: ContactFormProps) => {
  return (
    <div className="mt-20">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-display mb-4">Get in Touch</h2>
        <p className="text-muted-foreground max-w-3xl mx-auto">
          Interested in learning more about properties or communities in the Rio Grande Valley? 
          Fill out the form below, and Juan will be in touch to assist you with your real estate needs.
        </p>
      </div>
      
      <div className="bg-card border rounded-lg p-8 max-w-2xl mx-auto">
        <form action="mailto:juan@juanjoseelizondo.com" method="post" encType="text/plain">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-muted-foreground mb-1">Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gold-500"
                placeholder="Your Name"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-muted-foreground mb-1">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gold-500"
                placeholder="Your Email"
              />
            </div>
          </div>
          
          <div className="mb-6">
            <label htmlFor="phone" className="block text-sm font-medium text-muted-foreground mb-1">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gold-500"
              placeholder="Your Phone Number"
            />
          </div>
          
          <div className="mb-6">
            <label htmlFor="community" className="block text-sm font-medium text-muted-foreground mb-1">Community of Interest</label>
            <select
              id="community"
              name="community"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gold-500"
              defaultValue={activeCity || ""}
            >
              <option value="">Select a Community</option>
              {communities.map((city) => (
                <option key={city} value={city}>{city}</option>
              ))}
            </select>
          </div>
          
          <div className="mb-6">
            <label htmlFor="message" className="block text-sm font-medium text-muted-foreground mb-1">Message</label>
            <textarea
              id="message"
              name="message"
              rows={4}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gold-500"
              placeholder="How can Juan help you?"
            ></textarea>
          </div>
          
          <Button type="submit" className="w-full bg-gold-500 hover:bg-gold-600 text-white transition duration-300">
            Send Message
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
