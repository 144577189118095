
import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

// Context Providers
import { ThemeProvider } from '@/context/ThemeContext'
import { LanguageProvider } from '@/context/LanguageContext'
import { SEOProvider } from '@/context/SEOContext'
import GlowFilterProvider from '@/components/common/GlowFilterProvider';

// Pages
import Index from '@/pages/Index'
import Communities from '@/pages/Communities'
import Contact from '@/pages/Contact'
import Buy from '@/pages/Buy'
import Sell from '@/pages/Sell'
import HomeEstimate from '@/pages/HomeEstimate'
import Blog from '@/pages/Blog'
import BlogPost from '@/pages/BlogPost'
import BlogAdmin from '@/pages/BlogAdmin'
import Commercial from '@/pages/Commercial'
import Residential from '@/pages/Residential'
import MexicanClients from '@/pages/MexicanClients'
import MortgageCalculator from '@/pages/MortgageCalculator'
import SplitCalculator from '@/pages/SplitCalculator'
import FlipCalculator from '@/pages/FlipCalculator'
import RentalCalculator from '@/pages/RentalCalculator'
import RentVsBuyCalculator from '@/pages/RentVsBuyCalculator'
import RenovationEstimator from '@/pages/RenovationEstimator'
import HouseHackingCalculator from '@/pages/HouseHackingCalculator'
import Sitemap from '@/pages/Sitemap'
import RobotsTxt from '@/pages/RobotsTxt'

// CSS
import './index.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Toaster } from 'sonner'

// Router
const router = createBrowserRouter([
  { path: '/', element: <Index />, },
  { path: '/communities', element: <Communities />, },
  { path: '/contact', element: <Contact />, },
  { path: '/buy', element: <Buy />, },
  { path: '/sell', element: <Sell />, },
  { path: '/home-estimate', element: <HomeEstimate />, },
  { path: '/blog', element: <Blog />, },
  { path: '/blog/:slug', element: <BlogPost />, },
  { path: '/blog-admin', element: <BlogAdmin />, },
  { path: '/commercial', element: <Commercial />, },
  { path: '/residential', element: <Residential />, },
  { path: '/mexican-clients', element: <MexicanClients />, },
  { path: '/mortgage-calculator', element: <MortgageCalculator />, },
  { path: '/split-calculator', element: <SplitCalculator />, },
  { path: '/flip-calculator', element: <FlipCalculator />, },
  { path: '/rental-calculator', element: <RentalCalculator />, },
  { path: '/rent-vs-buy-calculator', element: <RentVsBuyCalculator />, },
  { path: '/renovation-estimator', element: <RenovationEstimator />, },
  { path: '/house-hacking-calculator', element: <HouseHackingCalculator />, },
  { path: '/sitemap.xml', element: <Sitemap />, },
  { path: '/robots.txt', element: <RobotsTxt />, },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider>
      <LanguageProvider>
        <SEOProvider>
          <GlowFilterProvider>
            <RouterProvider router={router} />
            <Toaster position="top-right" />
          </GlowFilterProvider>
        </SEOProvider>
      </LanguageProvider>
    </ThemeProvider>
  </React.StrictMode>,
)
