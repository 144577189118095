
import React from 'react';
import { Input } from '@/components/ui/input';
import { PurchaseInfoSectionProps } from './types';

const PurchaseInfoSection: React.FC<PurchaseInfoSectionProps> = ({
  purchasePrice,
  setPurchasePrice,
  downPaymentPercent,
  setDownPaymentPercent,
  downPaymentAmount,
  closingCostPercent,
  setClosingCostPercent,
  closingCostAmount,
  repairs,
  setRepairs,
  firstMortgage,
  totalCapitalInvestment,
  formatCurrency,
  formatPercent,
  handleCurrencyInputChange,
  handlePercentInputChange
}) => {
  return (
    <div className="transition-transform hover:transform hover:scale-[1.02] hover:z-10">
      <h2 className="text-2xl font-bold text-gold-500 mb-4">Purchase Info</h2>
      
      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Purchase price</label>
        <div className="flex items-center">
          <span className="text-gold-500 mr-2">$</span>
          <Input
            type="text"
            value={purchasePrice.toLocaleString()}
            onChange={(e) => handleCurrencyInputChange(e.target.value, setPurchasePrice)}
            className="flex-1 text-white bg-estate-800 border-estate-700" 
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Down payment</label>
        <div className="flex gap-2">
          <div className="w-1/3 flex items-center">
            <Input
              type="text"
              value={downPaymentPercent}
              onChange={(e) => handlePercentInputChange(e.target.value, setDownPaymentPercent)}
              className="text-white bg-estate-800 border-estate-700"
            />
            <span className="ml-2 text-gold-500">%</span>
          </div>
          <div className="w-2/3 flex items-center">
            <span className="text-gold-500 mr-2">$</span>
            <Input 
              type="text" 
              value={downPaymentAmount.toLocaleString()} 
              readOnly 
              className="bg-estate-900 text-white border-estate-700"
            />
          </div>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Closing Costs</label>
        <div className="flex gap-2">
          <div className="w-1/3 flex items-center">
            <Input
              type="text"
              value={closingCostPercent}
              onChange={(e) => handlePercentInputChange(e.target.value, setClosingCostPercent)}
              className="text-white bg-estate-800 border-estate-700"
            />
            <span className="ml-2 text-gold-500">%</span>
          </div>
          <div className="w-2/3 flex items-center">
            <span className="text-gold-500 mr-2">$</span>
            <Input 
              type="text" 
              value={closingCostAmount.toLocaleString()} 
              readOnly 
              className="bg-estate-900 text-white border-estate-700"
            />
          </div>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Repairs</label>
        <div className="flex items-center">
          <span className="text-gold-500 mr-2">$</span>
          <Input
            type="text"
            value={repairs.toLocaleString()}
            onChange={(e) => handleCurrencyInputChange(e.target.value, setRepairs)}
            className="flex-1 text-white bg-estate-800 border-estate-700"
          />
        </div>
      </div>

      <div className="mt-6 space-y-3">
        <div>
          <label className="block text-gray-300 mb-1">1st Mortgage</label>
          <div className="text-xl font-bold text-gold-500">
            {formatCurrency(firstMortgage)}
          </div>
        </div>
        
        <div>
          <label className="block text-gray-300 mb-1">Total Capital Investment</label>
          <div className="text-xl font-bold text-gold-500">
            {formatCurrency(totalCapitalInvestment)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseInfoSection;
