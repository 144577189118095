
import React from 'react';
import { RentingInputProps } from './types';
import { Input } from '@/components/ui/input';
import { Slider } from '@/components/ui/slider';

const RentingInputs: React.FC<RentingInputProps> = ({
  monthlyRent,
  setMonthlyRent,
  rentGrowthRate,
  setRentGrowthRate,
  rentersInsurance,
  setRentersInsurance,
  investmentReturn,
  setInvestmentReturn,
  investmentPeriod,
  setInvestmentPeriod,
  formatCurrency,
  formatPercent,
  handleCurrencyInputChange,
  handlePercentInputChange
}) => {
  const yearlyRent = monthlyRent * 12;

  return (
    <div className="transition-transform hover:transform hover:scale-[1.01] hover:z-10">
      <h2 className="text-2xl font-bold text-gold-500 mb-4">Renting Parameters</h2>
      
      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="monthlyRent" className="block text-sm font-medium text-gray-300 mb-1">
              Monthly Rent ($)
            </label>
            <Input
              id="monthlyRent"
              type="text"
              className="border-gray-700 bg-gray-800 text-white"
              value={monthlyRent.toLocaleString()}
              onChange={(e) => handleCurrencyInputChange(e.target.value, setMonthlyRent)}
            />
          </div>
          <div>
            <label htmlFor="yearlyRent" className="block text-sm font-medium text-gray-300 mb-1">
              Yearly Rent
            </label>
            <Input
              id="yearlyRent"
              type="text"
              className="border-gray-700 bg-gray-800 text-white opacity-50"
              value={formatCurrency(yearlyRent)}
              readOnly
            />
          </div>
        </div>

        <div>
          <label htmlFor="rentGrowthRate" className="block text-sm font-medium text-gray-300 mb-1">
            Annual Rent Growth Rate ({rentGrowthRate}%)
          </label>
          <div className="flex items-center space-x-2">
            <Slider 
              id="rentGrowthRate" 
              defaultValue={[rentGrowthRate]} 
              max={10} 
              step={0.5}
              value={[rentGrowthRate]}
              onValueChange={(value) => setRentGrowthRate(value[0])}
              className="flex-grow"
            />
            <Input
              type="number"
              value={rentGrowthRate}
              onChange={(e) => setRentGrowthRate(parseFloat(e.target.value) || 0)}
              className="w-20 border-gray-700 bg-gray-800 text-white"
              step={0.5}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="rentersInsurance" className="block text-sm font-medium text-gray-300 mb-1">
              Renter's Insurance ($/year)
            </label>
            <Input
              id="rentersInsurance"
              type="text"
              className="border-gray-700 bg-gray-800 text-white"
              value={rentersInsurance.toLocaleString()}
              onChange={(e) => handleCurrencyInputChange(e.target.value, setRentersInsurance)}
            />
          </div>
          <div>
            <label htmlFor="investmentPeriod" className="block text-sm font-medium text-gray-300 mb-1">
              Investment Period (years)
            </label>
            <div className="flex items-center space-x-2">
              <Slider 
                id="investmentPeriod" 
                defaultValue={[investmentPeriod]} 
                min={1}
                max={30} 
                step={1}
                value={[investmentPeriod]}
                onValueChange={(value) => setInvestmentPeriod(value[0])}
                className="flex-grow"
              />
              <Input
                type="number"
                value={investmentPeriod}
                onChange={(e) => setInvestmentPeriod(parseInt(e.target.value) || 0)}
                className="w-20 border-gray-700 bg-gray-800 text-white"
                min={1}
                max={30}
              />
            </div>
          </div>
        </div>

        <div>
          <label htmlFor="investmentReturn" className="block text-sm font-medium text-gray-300 mb-1">
            Annual Investment Return ({investmentReturn}%)
          </label>
          <div className="flex items-center space-x-2">
            <Slider 
              id="investmentReturn" 
              defaultValue={[investmentReturn]} 
              max={20} 
              step={0.25}
              value={[investmentReturn]}
              onValueChange={(value) => setInvestmentReturn(value[0])}
              className="flex-grow"
            />
            <Input
              type="number"
              value={investmentReturn}
              onChange={(e) => setInvestmentReturn(parseFloat(e.target.value) || 0)}
              className="w-20 border-gray-700 bg-gray-800 text-white"
              step={0.25}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentingInputs;
