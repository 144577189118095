
import React from 'react';
import { UserGuideProps } from './types';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';

const UserGuide: React.FC<UserGuideProps> = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="bg-estate-900 text-white border-gold-500/30 max-w-3xl max-h-[90vh] overflow-hidden">
        <DialogHeader>
          <DialogTitle className="text-2xl text-gold-500">User Guide for Rent vs. Buy Calculator</DialogTitle>
          <DialogDescription className="text-gray-300">
            How to use this tool and understand the results
          </DialogDescription>
        </DialogHeader>
        
        <ScrollArea className="h-[60vh] pr-4">
          <div className="space-y-4">
            <p>
              This calculator helps you compare the long-term financial impact of renting versus buying a home. 
              Here's how to use the tool and understand the concepts:
            </p>
            
            <h3 className="text-xl font-bold text-gold-500 mt-4">Inputs</h3>
            <ul className="list-disc pl-6 space-y-2">
              <li><strong>Home Purchase Price:</strong> The price of the home you plan to buy.</li>
              <li><strong>Down Payment:</strong> The upfront payment you're making toward the home purchase.</li>
              <li><strong>Mortgage Interest Rate:</strong> The annual percentage rate (APR) you'll pay on your mortgage loan.</li>
              <li><strong>Loan Term:</strong> The number of years you plan to take to repay the loan (typically 30 years).</li>
              <li><strong>Property Taxes:</strong> Yearly taxes you'll pay based on the home's value.</li>
              <li><strong>Home Insurance:</strong> Annual insurance cost for protecting your home.</li>
              <li><strong>Maintenance Costs:</strong> Estimated annual maintenance expenses for your home.</li>
              <li><strong>HOA Fees:</strong> Monthly Homeowners Association fees if applicable.</li>
              <li><strong>Monthly Rent:</strong> Your current or expected monthly rent payment.</li>
              <li><strong>Annual Rent Growth Rate:</strong> The expected annual increase in your rent (typically 2-5%).</li>
              <li><strong>Renter's Insurance:</strong> The yearly insurance cost for renting.</li>
              <li><strong>Investment Period:</strong> The number of years for comparison.</li>
              <li><strong>Annual Investment Return:</strong> Expected return on investments like stocks or savings.</li>
              <li><strong>Home Price Appreciation:</strong> The percentage by which you expect your home's value to increase annually.</li>
            </ul>
            
            <h3 className="text-xl font-bold text-gold-500 mt-4">Outputs</h3>
            <ul className="list-disc pl-6 space-y-2">
              <li><strong>Total Cost of Buying:</strong> The cumulative cost including mortgage payments, taxes, insurance, maintenance, and HOA fees.</li>
              <li><strong>Total Cost of Renting:</strong> The total spent on rent and renter's insurance over the investment period.</li>
              <li><strong>Net Difference:</strong> Compares the total cost of buying versus renting. A positive number indicates buying costs more.</li>
              <li><strong>Home Equity:</strong> The value of your ownership in the home after subtracting the remaining mortgage balance.</li>
              <li><strong>Investment Growth:</strong> The amount your down payment and other funds could grow if invested instead.</li>
            </ul>
            
            <h3 className="text-xl font-bold text-gold-500 mt-4">Basic Concepts</h3>
            <ul className="list-disc pl-6 space-y-2">
              <li><strong>Mortgage:</strong> A loan used to buy a property, typically paid back over 15–30 years with interest.</li>
              <li><strong>Equity:</strong> The portion of the home you own outright. Over time, as you pay off your mortgage and your home appreciates, your equity increases.</li>
              <li><strong>Renting vs. Buying:</strong> Renting is often cheaper in the short term, but buying can be beneficial long-term as you build equity and the home's value appreciates.</li>
              <li><strong>Investment Returns:</strong> Money that could be invested in other assets (like stocks) instead of buying a home.</li>
            </ul>
            
            <h3 className="text-xl font-bold text-gold-500 mt-4">Chart</h3>
            <p>
              The chart provides a visual comparison of the costs and equity growth over time:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li><strong>Green Line:</strong> Cumulative cost of buying</li>
              <li><strong>Blue Line:</strong> Cumulative cost of renting</li>
              <li><strong>Green Dashed Line:</strong> Home equity growth</li>
              <li><strong>Purple Dashed Line:</strong> Investment equity growth</li>
            </ul>
            
            <p className="mt-4">
              This calculator helps you visualize how these costs evolve over time to guide your decision on whether buying or renting is the better financial choice for your specific situation.
            </p>
          </div>
        </ScrollArea>
        
        <DialogFooter>
          <Button onClick={onClose} className="bg-gold-500 hover:bg-gold-600 text-black">Close Guide</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default UserGuide;
