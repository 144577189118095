
import React from "react";
import { Link } from "react-router-dom";

interface NavbarLogoProps {
  isTransparent?: boolean;
}

const NavbarLogo: React.FC<NavbarLogoProps> = ({ isTransparent = false }) => {
  return (
    <div className="flex items-center gap-2 ml-[-250px]"> {/* Increased negative margin to push logo much further left */}
      <img 
        src="/lovable-uploads/967535c9-c31e-4c4f-81e2-8015abebcc88.png"
        alt="RE/MAX Elite"
        className="h-32 w-auto object-contain" 
      />
      <div className="text-2xl font-bold text-white transition-colors duration-300">
        <Link to="/" className="hover:text-gold-500 transition duration-300">Juan Elizondo</Link>
      </div>
    </div>
  );
};

export default NavbarLogo;
