
/**
 * Styles for blog post content
 */
export const getBlogContentStyles = () => `
  .blog-content h1, .blog-content h2, .blog-content h3 {
    color: #FFFFFF !important;
    text-shadow: 0 -2px 2px gold !important;
    font-weight: 700 !important;
    margin-top: 1.5em !important; /* Reduced from 2.5em */
    margin-bottom: 1em !important; /* Reduced from 1.5em */
    line-height: 1.4 !important;
    position: relative !important;
  }
  
  /* Apply the advanced glow effect to main headings */
  .blog-content h1 {
    font-size: 48px !important;
    margin-top: 1em !important;
    line-height: 1.3 !important;
    color: transparent !important;
    background: linear-gradient(0deg, #dfe5ee 0%, #fffaf6 50%) !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
    filter: url(#advanced-glow) !important;
  }
  
  .blog-content h1::after {
    display: none !important; /* Hide the standard glow effect */
  }
  
  .blog-content h2 {
    font-size: 36px !important;
    line-height: 1.35 !important;
  }
  
  .blog-content h3 {
    font-size: 24px !important;
    line-height: 1.4 !important;
  }
  
  .blog-content p {
    font-size: 18px !important;
    line-height: 2.1 !important;
    color: #FFFFFF !important;
    letter-spacing: 0.6px !important;
    margin-bottom: 1.5em !important; /* Reduced from 2.5em */
    font-weight: 400 !important;
  }
  
  .blog-content figcaption {
    display: none !important;
  }
  
  .blog-content figure {
    margin: 1.5em 0 !important; /* Reduced from 3em */
  }

  /* Force all text to bright white - but ONLY within blog-content */
  .blog-content * {
    color: #FFFFFF !important;
  }
  
  /* Main title styling with advanced glow effect */
  .blog-content .main-title {
    margin: 0 !important;
    padding: 30px 0 20px !important; /* Reduced padding */
    font-size: 48px !important;
    font-weight: 700 !important;
    color: transparent !important;
    background: linear-gradient(0deg, #dfe5ee 0%, #fffaf6 50%) !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
    filter: url(#advanced-glow) !important;
    font-family: 'Playfair Display', serif !important;
    text-align: center !important;
    line-height: 1.3 !important;
    text-shadow: none !important;
  }
  
  /* Fix spacing issues */
  .blog-content .meta {
    margin: 0 0 20px 0 !important; /* Reduced from 35px */
    padding: 0 !important;
  }
  
  /* Preserve content - EXTRA IMPORTANT FLAGS */
  .blog-content .content {
    display: block !important;
    visibility: visible !important;
    opacity: 1 !important;
    max-width: 900px !important; /* INCREASED from 760px for wider content */
    margin: 0 auto !important;
    padding: 20px !important;
    height: auto !important;
    overflow: visible !important;
    min-height: 200px !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important; /* Add subtle border */
    border-radius: 5px !important;
  }
  
  /* Remove the hero section completely */
  .blog-content .hero,
  .blog-content .hero-image,
  .blog-content .hero-text {
    display: none !important;
    height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  
  /* Ensure content is visible */
  .blog-content .content > * {
    display: block !important;
    visibility: visible !important;
    opacity: 1 !important;
    margin-bottom: 1em !important; /* Add consistent spacing between elements */
  }
  
  /* Remove duplicate investment title - more aggressive selector to catch all instances */
  .blog-content h1:contains("Investment properties in"), 
  .blog-content h2:contains("Investment properties in"), 
  .blog-content p:contains("Investment properties in"):first-of-type,
  .blog-content > div:contains("Investment properties in"):not(.main-title):not(.content):not(.meta) {
    display: none !important;
  }
  
  /* Add extra space for better readability but reduced from original */
  .blog-content ul, 
  .blog-content ol {
    margin-bottom: 1.5em !important; /* Reduced from 2.5em */
    padding-left: 2em !important; /* Reduced from 2.5em */
  }
  
  .blog-content li {
    margin-bottom: 0.8em !important; /* Reduced from 1.2em */
    line-height: 1.8 !important;
    color: #FFFFFF !important;
  }
  
  /* Add a font for Playfair Display */
  @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');
  
  /* Fix for the broken blog display - ensure the main content is visible */
  .blog-content .container {
    display: block !important;
    visibility: visible !important;
  }
  
  /* Fix the direct content after meta so it doesn't display duplicates */
  .blog-content .meta + *:not(.content) {
    display: none !important;
  }
  
  /* Ensure the content section is always displayed */
  .blog-content .meta + .content,
  .blog-content .content {
    display: block !important;
    margin-top: 20px !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    padding: 20px !important;
    border-radius: 5px !important;
  }
  
  /* Fix for any empty paragraphs that might cause spacing issues */
  .blog-content p:empty {
    display: none !important;
  }
  
  /* Remove extra line breaks */
  .blog-content br + br {
    display: none !important;
  }
  
  /* Add Apple-inspired glow effect for highlighted text in blog content */
  .blog-content .highlight {
    position: relative !important;
    display: inline-block !important;
    color: transparent !important;
    background: linear-gradient(0deg, #ffe0cc 0%, #fffaf6 50%) !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
    filter: url(#advanced-glow) !important;
    font-weight: 600 !important;
  }
`;
