
import React, { useEffect, useState } from 'react';
import GlobalGlowFilter from '@/components/GlobalGlowFilter';
import { initGlowText, refreshGlowEffect } from '@/utils/glowTextUtils';

interface GlowFilterProviderProps {
  children: React.ReactNode;
}

/**
 * GlowFilterProvider adds the global glow filter SVG to the DOM
 * and passes through children components.
 * This can be added at the app root level or any parent component.
 */
const GlowFilterProvider: React.FC<GlowFilterProviderProps> = ({ children }) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // Check if filter already exists to avoid duplicates
    if (!document.getElementById('global-glow-filters')) {
      console.log('Initializing global glow filter');
    }
    
    // Initialize the glow text effect
    const cleanup = initGlowText();
    setInitialized(true);
    
    // Force re-application of glow effect multiple times after component mounts
    // This ensures it works even if there are timing issues
    const timers = [
      setTimeout(() => {
        console.log('First glow text refresh');
        refreshGlowEffect();
      }, 100),
      
      setTimeout(() => {
        console.log('Second glow text refresh');
        refreshGlowEffect();
      }, 500),
      
      setTimeout(() => {
        console.log('Third glow text refresh');
        refreshGlowEffect();
      }, 1000),
      
      setTimeout(() => {
        console.log('Fourth glow text refresh');
        refreshGlowEffect();
      }, 2000)
    ];
    
    // Return cleanup function
    return () => {
      cleanup();
      timers.forEach(clearTimeout);
    };
  }, []);

  // Add a special method to the window object for debugging
  useEffect(() => {
    if (typeof window !== 'undefined') {
      (window as any).refreshGlowEffect = refreshGlowEffect;
      (window as any).debugGlowElements = () => {
        const headings = document.querySelectorAll('h1, h2');
        console.log(`Found ${headings.length} headings:`);
        headings.forEach((heading, i) => {
          console.log(`Heading ${i+1}:`, {
            text: heading.textContent,
            dataAttr: heading.getAttribute('data-text'),
            hasDataText: !!heading.getAttribute('data-text'),
            hasDarkParent: heading.closest('.dark') !== null,
            classes: heading.className,
            style: (heading as HTMLElement).style.cssText
          });
        });
        
        // Check if filter exists
        const filter = document.getElementById('global-glow-filters');
        console.log('Global filter exists:', !!filter);
        
        // Check if dark mode is active
        const isDarkMode = document.documentElement.classList.contains('dark');
        console.log('Dark mode active:', isDarkMode);
      };
    }
  }, [initialized]);

  return (
    <>
      <GlobalGlowFilter />
      {children}
    </>
  );
};

export default GlowFilterProvider;
