
import React from 'react';
import GlowingText from '@/components/common/GlowingText';

const CalculatorHeader: React.FC = () => {
  return (
    <div className="text-center mb-16 mt-4 overflow-visible">
      <div className="overflow-visible py-2">
        <GlowingText 
          text="Juan's House Flipping Calculator" 
          as="h1"
          className="text-4xl md:text-5xl font-bold overflow-visible"
          advanced={true} 
          glowColor="gold"
        />
      </div>
    </div>
  );
};

export default CalculatorHeader;
