import React, { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { 
  Table, 
  TableBody, 
  TableCaption, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Pencil, Eye, Trash2, RefreshCw, ArrowUpFromLine } from "lucide-react";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { importExistingBlogToJuansBlogs } from "@/utils/blog/juansBlog";

const JuansBlogPostsTable = () => {
  const [posts, setPosts] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        const { data, error } = await supabase
          .from('juans_blogs')
          .select('*')
          .order('created_at', { ascending: false });
        
        if (error) {
          console.error("Error fetching Juan's blog posts:", error);
          toast.error("Failed to load Juan's blog posts");
          return;
        }
        
        if (data) {
          setPosts(data);
        }
      } catch (error) {
        console.error("Unexpected error:", error);
        toast.error("An unexpected error occurred");
      } finally {
        setLoading(false);
      }
    };
    
    fetchPosts();
  }, [refreshKey]);

  const handleRefresh = () => {
    setRefreshKey(prev => prev + 1);
    toast.info("Refreshing Juan's blog posts...");
  };

  const handleDelete = async (id: string) => {
    if (!confirm(`Are you sure you want to delete post "${posts.find(p => p.id === id)?.title}"?`)) {
      return;
    }
    
    try {
      const { error } = await supabase
        .from('juans_blogs')
        .delete()
        .eq('id', id);
      
      if (error) {
        console.error("Error deleting post:", error);
        toast.error(`Failed to delete post: ${error.message}`);
        return;
      }
      
      toast.success("Post deleted successfully");
      setPosts(posts.filter(post => post.id !== id));
    } catch (error) {
      console.error("Unexpected error:", error);
      toast.error("An unexpected error occurred");
    }
  };

  const handleImportBlogPost = async () => {
    const blogId = prompt("Enter the ID of the blog post to import from blog_posts table:");
    if (!blogId) return;
    
    try {
      const result = await importExistingBlogToJuansBlogs(blogId);
      
      if (result.success) {
        toast.success("Blog post imported successfully", {
          description: "The post has been copied to Juan's dedicated blog table."
        });
        handleRefresh();
      } else {
        toast.error("Failed to import blog post", {
          description: result.error
        });
      }
    } catch (error) {
      console.error("Error during import:", error);
      toast.error("An unexpected error occurred during import");
    }
  };

  const truncateText = (text: string, maxLength: number) => {
    if (!text) return "N/A";
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Juan's Dedicated Blog Posts</h2>
        <div className="flex gap-2">
          <Button 
            onClick={handleImportBlogPost} 
            className="flex items-center gap-2"
            variant="outline"
          >
            <ArrowUpFromLine size={16} />
            Import Existing Post
          </Button>
          <Button 
            onClick={handleRefresh} 
            className="flex items-center gap-2"
            variant="outline"
            disabled={loading}
          >
            <RefreshCw size={16} className={loading ? "animate-spin" : ""} />
            Refresh
          </Button>
        </div>
      </div>
      
      <div className="border rounded-md overflow-hidden">
        <Table>
          <TableCaption>
            {loading 
              ? "Loading Juan's blog posts..." 
              : posts.length > 0 
                ? `Showing ${posts.length} of Juan's blog posts` 
                : "No blog posts found in Juan's dedicated table"}
          </TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">ID</TableHead>
              <TableHead>Title</TableHead>
              <TableHead>Author</TableHead>
              <TableHead>Category</TableHead>
              <TableHead>Date</TableHead>
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {posts.map((post) => (
              <TableRow key={post.id}>
                <TableCell className="font-mono text-xs">
                  {truncateText(post.id, 8)}
                </TableCell>
                <TableCell className="font-medium">{truncateText(post.title, 40)}</TableCell>
                <TableCell>{post.author || "Juan Elizondo"}</TableCell>
                <TableCell>{post.category || "Investment"}</TableCell>
                <TableCell>{new Date(post.created_at).toLocaleDateString()}</TableCell>
                <TableCell className="text-right">
                  <div className="flex justify-end gap-2">
                    <Button asChild variant="ghost" size="icon">
                      <Link to={`/blog/${post.id}`}>
                        <Eye size={16} />
                      </Link>
                    </Button>
                    <Button 
                      variant="ghost" 
                      size="icon"
                      onClick={() => handleDelete(post.id)}
                    >
                      <Trash2 size={16} className="text-red-500" />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default JuansBlogPostsTable;
