
/**
 * Utilities for cleaning up DOM elements in blog content
 */

/**
 * Remove empty divs from the content
 * @param contentElement - The DOM element containing the blog content
 */
export function removeEmptyDivs(contentElement: HTMLElement): void {
  // Remove any empty divs that might be causing gaps
  const emptyDivs = contentElement.querySelectorAll('div:empty');
  emptyDivs.forEach(div => {
    if (!div.classList.contains('content')) {
      console.log("Removing empty div");
      div.parentNode?.removeChild(div);
    }
  });
}

/**
 * Remove extra line breaks that might cause spacing issues
 * @param contentElement - The DOM element containing the blog content
 */
export function removeExtraLineBreaks(contentElement: HTMLElement): void {
  // Remove extra br tags that might cause spacing issues
  const brTags = contentElement.querySelectorAll('br + br');
  brTags.forEach(br => {
    console.log("Removing extra br tag");
    br.remove();
  });
}

/**
 * Decode HTML-encoded content if detected
 * @param contentSection - The content section DOM element
 */
export function decodeHtmlEncodedContent(contentSection: HTMLElement): void {
  // Check if content seems to be HTML-encoded or nested
  const contentHTML = contentSection.innerHTML;
  if (contentHTML.includes('&lt;') || contentHTML.includes('&gt;')) {
    console.log("Found HTML-encoded content, decoding it");
    // Decode HTML entities
    const decoded = document.createElement('div');
    decoded.innerHTML = contentHTML
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&');
    contentSection.innerHTML = decoded.innerHTML;
  }
}
