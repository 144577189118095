
import React from "react";
import { BlogPost } from "@/types/blog";

interface BlogDebugPanelProps {
  allPosts: BlogPost[];
  dbPosts: BlogPost[];
  defaultPosts: BlogPost[];
  loading: boolean;
  lastRefreshTime: string;
  combinedRefreshKey: number;
  activeCategory: string;
  searchQuery: string;
}

const BlogDebugPanel = ({
  allPosts,
  dbPosts,
  defaultPosts,
  loading,
  lastRefreshTime,
  combinedRefreshKey,
  activeCategory,
  searchQuery,
}: BlogDebugPanelProps) => {
  return (
    <div className="bg-gray-800 p-4 rounded-lg shadow-sm mb-6 border border-gray-700 text-white">
      <h3 className="text-lg font-semibold mb-2">Debug Information</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
        <div>
          <p><strong>Total Posts:</strong> {allPosts.length}</p>
          <p><strong>Database Posts:</strong> {dbPosts.length}</p>
          <p><strong>Default Posts:</strong> {defaultPosts.length}</p>
          <p><strong>Loading State:</strong> {loading ? 'Loading...' : 'Loaded'}</p>
        </div>
        <div>
          <p><strong>Last Refresh:</strong> {lastRefreshTime || 'Never'}</p>
          <p><strong>Refresh Key:</strong> {combinedRefreshKey}</p>
          <p><strong>Current Filter:</strong> {activeCategory}</p>
          <p><strong>Search Query:</strong> {searchQuery || 'None'}</p>
        </div>
        {dbPosts.length > 0 && (
          <div className="col-span-1 md:col-span-2">
            <p><strong>Database Post IDs:</strong></p>
            <div className="bg-gray-700 p-2 rounded text-xs overflow-x-auto text-gray-200">
              {dbPosts.map(post => post.id).join(', ')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogDebugPanel;
