
import React, { useEffect } from "react";
import { BlogPost } from "@/types/blog";
import { CalendarDays, User, Tag, Share2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import BlogPostImage from "./BlogPostImage";

interface BlogPostHeaderProps {
  post: BlogPost;
  onShare?: () => void;
  hasMultipleImages?: boolean;
  currentImage?: string;
  currentCaption?: string;
  currentImageIndex?: number;
  totalImages?: number;
  prevImage?: () => void;
  nextImage?: () => void;
}

const BlogPostHeader = ({ 
  post, 
  onShare,
  hasMultipleImages = false,
  currentImage,
  currentCaption,
  currentImageIndex = 0,
  totalImages = 0,
  prevImage,
  nextImage
}: BlogPostHeaderProps) => {
  // Debug post image data
  useEffect(() => {
    console.log("BlogPostHeader rendering post:", {
      title: post.title,
      hasImages: Boolean(post.images),
      imageCount: post.images?.length || 0,
      firstImageUrl: post.images?.[0]?.url?.substring(0, 30) + "..." || "none",
      currentImage: currentImage?.substring(0, 30) + "..." || "none",
      hasMultipleImages,
      totalImages,
      hasPrevNextFunctions: Boolean(prevImage && nextImage)
    });
  }, [post, currentImage, totalImages, hasMultipleImages, prevImage, nextImage]);

  return (
    <div className="mb-8">
      <div className="relative">
        <BlogPostImage 
          post={post}
          height="h-[350px] md:h-[500px]"
          currentImage={currentImage}
          currentCaption={currentCaption}
          hasMultipleImages={hasMultipleImages}
          currentImageIndex={currentImageIndex}
          totalImages={totalImages}
          prevImage={prevImage}
          nextImage={nextImage}
          altText={post.title}
        />
      </div>
      
      <div className="px-6 pt-6">
        <h1 className="text-3xl md:text-4xl font-bold text-white mb-4">
          {post.title}
        </h1>
        
        <div className="flex flex-wrap gap-4 text-sm text-gray-400 mb-4">
          {post.date && (
            <div className="flex items-center gap-1">
              <CalendarDays className="h-4 w-4" />
              <span>{post.date}</span>
            </div>
          )}
          
          {post.author && (
            <div className="flex items-center gap-1">
              <User className="h-4 w-4" />
              <span>{post.author}</span>
            </div>
          )}
          
          {post.category && (
            <div className="flex items-center gap-1">
              <Tag className="h-4 w-4" />
              <span>{post.category}</span>
            </div>
          )}
          
          {onShare && (
            <div className="ml-auto">
              <Button 
                variant="ghost" 
                size="sm" 
                onClick={onShare}
                className="flex items-center gap-1 text-gray-400 hover:text-white"
              >
                <Share2 className="h-4 w-4" />
                <span>Share</span>
              </Button>
            </div>
          )}
        </div>
        
        {post.excerpt && (
          <div className="bg-gray-700 p-4 rounded-md mb-6">
            <p className="text-gray-200 italic">{post.excerpt}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogPostHeader;
