
/**
 * Applies the glowing text effect to all h1 and h2 elements in dark mode
 * by setting the data-text attribute required for the ::after pseudo-element.
 */
export const initGlowText = () => {
  // Only execute in browser environment
  if (typeof window === 'undefined') return () => {};

  // Function to apply data attributes to headings
  const applyGlowDataAttributes = () => {
    // Check if dark mode is active
    const isDarkMode = document.documentElement.classList.contains('dark');
    console.log(`Dark mode active: ${isDarkMode}`);
    
    // Select all h1 and h2 elements across the entire document
    const headings = document.querySelectorAll('h1, h2');
    
    console.log(`Found ${headings.length} headings to apply glow effect`);
    
    if (isDarkMode) {
      // For each heading, set its text content as the data-text attribute
      headings.forEach(heading => {
        const text = heading.textContent || '';
        heading.setAttribute('data-text', text);
        
        // Add a direct class for basic glowing effect (fallback)
        heading.classList.add('glow-text');
        
        // Force filter application
        if (heading.closest('.apple-glow-text') || heading.classList.contains('apple-glow-text')) {
          (heading as HTMLElement).style.filter = 'url(#advanced-glow)';
        } else {
          (heading as HTMLElement).style.filter = 'url(#glow-filter)';
        }
        
        console.log(`Applied glow to: ${text.substring(0, 30)}${text.length > 30 ? '...' : ''}`);
      });
    }
  };

  // Run on initial load
  applyGlowDataAttributes();

  // Create a MutationObserver to detect DOM changes
  const observer = new MutationObserver(() => {
    // Small delay to ensure DOM is fully updated
    setTimeout(applyGlowDataAttributes, 50);
  });

  // Start observing the document with a simplified configuration
  observer.observe(document.documentElement, { 
    childList: true, 
    subtree: true,
    attributes: true,
    attributeFilter: ['class']
  });

  // Listen for theme changes
  const handleThemeChange = () => {
    console.log('Theme change detected, applying glow effect');
    setTimeout(applyGlowDataAttributes, 50);
  };
  
  document.addEventListener('themeChanged', handleThemeChange);

  return () => {
    observer.disconnect();
    document.removeEventListener('themeChanged', handleThemeChange);
  };
};

// Export a direct function to manually trigger the glow effect
export const refreshGlowEffect = () => {
  console.log('Manually refreshing glow effect');
  const event = new CustomEvent('themeChanged');
  document.dispatchEvent(event);
};

// Add a debug function to inspect what's happening with the glow effect
export const debugGlowEffect = () => {
  const isDarkMode = document.documentElement.classList.contains('dark');
  console.log('Dark mode active:', isDarkMode);
  
  const headings = document.querySelectorAll('h1, h2');
  console.log(`Found ${headings.length} headings`);
  
  headings.forEach((heading, i) => {
    console.log(`Heading ${i+1}:`, {
      text: heading.textContent,
      dataText: heading.getAttribute('data-text'),
      hasGlowClass: heading.classList.contains('glow-text'),
      hasAppleGlowClass: heading.classList.contains('apple-glow-text'),
      style: (heading as HTMLElement).style.cssText,
      filter: (heading as HTMLElement).style.filter
    });
  });
  
  const filter = document.getElementById('global-glow-filters');
  console.log('Filter exists:', !!filter);
  
  // Check if any elements have the filters applied
  const withAdvancedGlow = document.querySelectorAll('[style*="url(#advanced-glow)"]');
  const withBasicGlow = document.querySelectorAll('[style*="url(#glow-filter)"]');
  console.log('Elements with advanced glow:', withAdvancedGlow.length);
  console.log('Elements with basic glow:', withBasicGlow.length);
};

// Add a direct apply function for specific elements
export const applyGlowToElement = (element: HTMLElement) => {
  if (!element) return;
  
  const text = element.textContent || '';
  element.setAttribute('data-text', text);
  element.classList.add('glow-text');
  
  // Check if it should use advanced glow
  if (element.closest('.apple-glow-text') || element.classList.contains('apple-glow-text')) {
    element.style.filter = 'url(#advanced-glow)';
  } else {
    element.style.filter = 'url(#glow-filter)';
  }
};
