
import { 
  Home, 
  PaintBucket, 
  Camera, 
  DollarSign 
} from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { useTheme } from "@/context/ThemeContext";

const SellerTips = () => {
  const { theme } = useTheme();

  const tips = [
    {
      icon: <Home className="h-6 w-6 text-[#ffb347]" />,
      title: "Declutter Your Space",
      description: "Remove personal items and excess furniture to make your property appear more spacious and help buyers envision themselves living there."
    },
    {
      icon: <PaintBucket className="h-6 w-6 text-[#ffb347]" />,
      title: "Make Necessary Repairs",
      description: "Fix any visible issues such as leaky faucets, broken tiles, or damaged paint to create a positive first impression."
    },
    {
      icon: <Camera className="h-6 w-6 text-[#ffb347]" />,
      title: "Invest in Professional Photos",
      description: "High-quality photographs significantly increase buyer interest and can help your property stand out in online listings."
    },
    {
      icon: <DollarSign className="h-6 w-6 text-[#ffb347]" />,
      title: "Price Strategically",
      description: "Setting the right initial price is crucial. Too high, and you'll discourage buyers; too low, and you'll leave money on the table."
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {tips.map((tip, index) => (
        <Card 
          key={index} 
          className={`border-none shadow-md hover:shadow-lg transition-shadow duration-300 ${
            theme === 'dark' ? 'bg-estate-700' : 'bg-white'
          }`}
        >
          <CardContent className="p-6">
            <div className="flex items-start gap-4">
              <div className="mt-1">
                {tip.icon}
              </div>
              <div>
                <h3 className="text-lg font-bold mb-2">{tip.title}</h3>
                <p className={theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}>
                  {tip.description}
                </p>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default SellerTips;
