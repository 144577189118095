
import React from "react";
import { Button } from "@/components/ui/button";
import { ChevronRight } from "lucide-react";

interface BlogPostCardActionsProps {
  postId: string;
  title: string;
  isHovered: boolean;
  onReadMore: () => void;
  isJuansBlog?: boolean;
}

const BlogPostCardActions = ({ 
  postId, 
  title, 
  isHovered, 
  onReadMore,
  isJuansBlog
}: BlogPostCardActionsProps) => {
  return (
    <div className="mt-4 flex justify-between items-center">
      <Button
        variant="link"
        className="p-0 h-auto text-[#ffb347] hover:text-[#f59f33] transition-all duration-300 flex items-center"
        onClick={onReadMore}
      >
        Read More
        <ChevronRight 
          className="ml-1 transition-all duration-300" 
          style={{ 
            transform: isHovered ? 'translateX(3px)' : 'translateX(0)',
            opacity: isHovered ? 1 : 0.7
          }}
        />
      </Button>
      
      {isJuansBlog && (
        <div className="text-xs text-amber-600 font-medium">
          Juan's Blog
        </div>
      )}
    </div>
  );
};

export default BlogPostCardActions;
