
import { useLanguage } from "@/context/LanguageContext";

const TabExperience = () => {
  const { language } = useLanguage();

  return (
    <div className="text-white space-y-4">
      <h3 className="text-2xl font-semibold text-white mb-4">
        Experiencia y Conocimiento del Mercado
      </h3>
      <p className="text-lg">
        Con 15 años de experiencia en la industria inmobiliaria, Juan ha desarrollado una profunda expertise en el mercado estadounidense, especialmente en el Valle del Río Grande. Esta región, conocida por su proximidad a México y su economía en crecimiento, es un área principal para la inversión mexicana.
      </p>
      <p className="text-lg">
        Su conocimiento abarca tendencias de mercado, valuaciones de propiedades y requisitos legales, asegurando que los clientes tomen decisiones informadas. Su asociación con RE/MAX Elite refuerza aún más su credibilidad, ya que la marca es reconocida por su profesionalismo y redes extensas, lo que puede beneficiar a clientes internacionales que buscan financiamiento o apoyo legal.
      </p>
    </div>
  );
};

export default TabExperience;
