
import { findValidVideo } from "@/utils/media/videoUtils";
import { MediaType, VideoInfo } from "@/utils/media/types";

/**
 * Hook for finding valid videos from a list of sources
 */
export const useVideoFinder = () => {
  const findVideo = async (
    videoPaths: string[],
    onVideoFound: (path: string, mediaType: MediaType, videoInfo?: VideoInfo) => void,
    onVideoNotFound?: () => void
  ) => {
    console.log("Checking video paths:", videoPaths);
    
    const result = await findValidVideo(
      videoPaths,
      (path) => {
        console.log("✅ Valid video found:", path);
        
        // Determine if it's specifically an MP4 or other video format
        const extension = path.split('.').pop()?.toLowerCase();
        const mediaType = extension === 'mp4' ? 'mp4' : 'video';
        
        onVideoFound(path, mediaType);
      },
      () => {
        console.log("No valid videos found");
        if (onVideoNotFound) {
          onVideoNotFound();
        }
      }
    );
    
    return result;
  };
  
  return { findVideo };
};
