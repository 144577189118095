
import { useEffect } from 'react';
import { generateRobotsTxt } from '@/utils/sitemapGenerator';
import { Helmet } from 'react-helmet-async';

const RobotsTxtPage = () => {
  useEffect(() => {
    const hostname = window.location.origin;
    const robotsTxt = generateRobotsTxt(hostname);
    
    // Display the robots.txt content
    const pre = document.createElement('pre');
    pre.textContent = robotsTxt;
    
    document.body.innerHTML = '';
    document.body.appendChild(pre);
  }, []);
  
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/plain; charset=utf-8" />
      </Helmet>
      <div id="robots-container" />
    </>
  );
};

export default RobotsTxtPage;
