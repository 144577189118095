
import { useState } from "react";
import { MediaType, VideoInfo } from "@/utils/media/types";

/**
 * Hook to manage media state
 */
export const useMediaState = () => {
  const [mediaPath, setMediaPath] = useState("");
  const [mediaType, setMediaType] = useState<MediaType>("image");
  const [mediaLoaded, setMediaLoaded] = useState(false);
  const [videoInfo, setVideoInfo] = useState<VideoInfo | null>(null);
  
  const updateMediaState = (
    path: string, 
    type: MediaType, 
    loaded: boolean = true,
    info: VideoInfo | null = null
  ) => {
    setMediaPath(path);
    setMediaType(type);
    setMediaLoaded(loaded);
    if (info) {
      setVideoInfo(info);
    }
  };
  
  return {
    mediaPath,
    mediaType,
    mediaLoaded,
    videoInfo,
    setVideoInfo,
    updateMediaState
  };
};
