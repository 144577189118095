
import React from "react";
import { Link } from "react-router-dom";
import { MapPin, Building, ExternalLink } from "lucide-react";
import { featuredCommunities, additionalCommunities } from "./CommunitiesData";

interface DesktopCommunitiesProps {
  isOpen: boolean;
  onMouseLeave: () => void;
  onCitySelect: (cityName: string) => void;
}

const DesktopCommunities: React.FC<DesktopCommunitiesProps> = ({ 
  isOpen,
  onMouseLeave,
  onCitySelect 
}) => {
  return (
    <div 
      className={`absolute left-0 mt-1 bg-white bg-opacity-95 border border-gray-100 rounded-md shadow-2xl z-50 w-[90vw] max-w-[1200px] transition-all duration-300 transform ${isOpen ? 'opacity-100 visible scale-100' : 'opacity-0 invisible scale-95'} overflow-hidden`}
      style={{ left: '0', transform: 'translateX(-20%)' }}
      onMouseLeave={onMouseLeave}
    >
      <div className="p-6 max-h-[80vh] overflow-y-auto bg-gradient-to-br from-white to-gray-50">
        {/* Subtle background pattern */}
        <div className="absolute inset-0 opacity-5 pointer-events-none" 
             style={{ 
               backgroundImage: 'url("data:image/svg+xml,%3Csvg width=\'100\' height=\'100\' viewBox=\'0 0 100 100\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z\' fill=\'%23333333\' fill-opacity=\'0.15\' fill-rule=\'evenodd\'/%3E%3C/svg%3E")',
               backgroundSize: '20%'
             }}>
        </div>
        
        <div className="text-black">
          <h3 className="text-xl font-semibold mb-6 text-black border-b border-gray-200 pb-2 flex items-center">
            <MapPin className="mr-2 h-5 w-5 text-gold-600" /> 
            Featured Rio Grande Valley Communities
          </h3>
          
          {/* Featured communities grid with images - new elegant design matching blog categories */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-8">
            {featuredCommunities.map((city, index) => (
              <div 
                key={city.name}
                className="group relative cursor-pointer transition-all duration-300 ease-in-out hover:scale-[1.03] animate-fadeIn overflow-hidden rounded-lg shadow-md"
                style={{ animationDelay: `${index * 50}ms` }}
                onClick={() => onCitySelect(city.name)}
              >
                {/* Card with perspective effect */}
                <div className="relative h-32 overflow-hidden rounded-lg">
                  {/* Background image */}
                  <img 
                    src={city.image} 
                    alt={`${city.name} real estate`}
                    className="absolute inset-0 w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                  />
                  
                  {/* Updated gradient overlay - more subtle */}
                  <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black opacity-40 z-10 group-hover:opacity-30 transition-opacity duration-300" />
                  
                  {/* Bottom text with city name - improved visibility */}
                  <div className="absolute bottom-0 left-0 right-0 z-20 p-3 flex items-center justify-between">
                    <h4 className="text-white text-lg font-semibold drop-shadow-[0_2px_3px_rgba(0,0,0,0.9)] tracking-wide">
                      {city.name}
                    </h4>
                    {/* Decorative gold accent */}
                    <div className="absolute bottom-0 left-0 w-1/3 h-1 bg-gold-500 rounded-r-full transform transition-all duration-300 group-hover:w-full"></div>
                  </div>
                  
                  {/* Reveal effect on hover - subtle gold border */}
                  <div className="absolute inset-0 border-2 border-gold-500 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-5"></div>
                </div>
              </div>
            ))}
          </div>
          
          <div className="flex justify-between items-center mb-6 border-t border-gray-200 pt-4">
            <h3 className="text-lg font-medium text-black flex items-center">
              <Building className="mr-2 h-5 w-5 text-gold-600" /> 
              Additional Communities
            </h3>
          </div>
          
          {/* Additional communities in columns */}
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-2 text-black">
            {additionalCommunities.sort().map((city) => (
              <div 
                key={city}
                onClick={() => onCitySelect(city)}
                className="text-gray-700 hover:text-gold-600 cursor-pointer py-1 px-2 rounded transition-all duration-200 hover:bg-gray-100 flex items-center relative overflow-hidden group"
              >
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-gold-500 transition-all duration-300 group-hover:w-full"></span>
                <MapPin className="mr-1 h-3 w-3 flex-shrink-0" />
                <span>{city}</span>
              </div>
            ))}
          </div>
          
          <div className="mt-6 border-t border-gray-200 pt-4">
            <Link 
              to="/communities" 
              className="inline-flex items-center text-gold-600 font-semibold hover:text-gold-700 transition-colors group"
              onClick={() => onCitySelect("")}
            >
              <ExternalLink className="mr-2 h-4 w-4" />
              View All RGV Communities
              <span className="ml-1 transition-transform duration-300 group-hover:translate-x-1">→</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopCommunities;
