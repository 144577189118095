import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { 
  processImages, 
  processHeadings, 
  processTextAndSpacing, 
  processHtmlStructure,
  extractFromNestedHtml,
  replaceImagePrompts
} from './utils/dom';
import { BlogImage } from '@/types/blog';

interface BlogContentProcessorProps {
  content: string;
  className?: string;
  images?: BlogImage[];
}

const BlogContentProcessor: React.FC<BlogContentProcessorProps> = ({ 
  content, 
  className = '',
  images = [] 
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  
  // Pre-process content - memoize to avoid recalculation on re-renders
  const processedContent = useMemo(() => {
    if (!content) return '';
    
    try {
      // Extract content from nested HTML
      let processed = extractFromNestedHtml(content);
      
      // If we still have nested HTML tags after extraction, try a more direct approach
      if (processed.includes('<html') && processed.includes('</html>')) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(processed, 'text/html');
        processed = doc.body.innerHTML;
      }
      
      // Check if we have a div with class="content" at the root level
      if (!processed.includes('class="content"') && !processed.includes("class='content'")) {
        // Otherwise, wrap the content in a div for consistent styling
        processed = `<div class="content">${processed}</div>`;
      }
      
      // Ensure we have content after processing
      if (!processed.trim()) {
        console.warn('Extracted content is empty, falling back to original content');
        processed = content;
      }
      
      return processed;
    } catch (error) {
      console.error('Error pre-processing content:', error);
      return content; // Fallback to original content
    }
  }, [content]);
  
  // Optimize processing by using useCallback
  const processDom = useCallback(() => {
    if (!contentRef.current) return;
    
    // Use requestAnimationFrame to defer non-critical processing
    requestAnimationFrame(() => {
      try {
        // Remove duplicate h1 titles (keep only the first one)
        const titles = contentRef.current?.querySelectorAll('h1');
        if (titles && titles.length > 1) {
          for (let i = 1; i < titles.length; i++) {
            titles[i].parentNode?.removeChild(titles[i]);
          }
        }
        
        // Process in batches to avoid long-running tasks
        setTimeout(() => {
          if (contentRef.current) processHtmlStructure(contentRef.current);
          
          setTimeout(() => {
            if (contentRef.current) processHeadings(contentRef.current);
            
            setTimeout(() => {
              if (contentRef.current) processTextAndSpacing(contentRef.current);
              
              setTimeout(() => {
                if (contentRef.current) {
                  processImages(contentRef.current);
                  
                  // Replace image prompts with actual images if available
                  if (images && images.length > 0) {
                    replaceImagePrompts(contentRef.current, images);
                  }
                }
              }, 0);
            }, 0);
          }, 0);
        }, 0);
      } catch (error) {
        console.error('Error during DOM processing:', error);
      }
    });
  }, [images]);
  
  // Use a cleaner useEffect that depends on processedContent
  useEffect(() => {
    if (contentRef.current && processedContent) {
      processDom();
    }
  }, [processedContent, processDom]);

  return (
    <div 
      ref={contentRef}
      className={`blog-content prose max-w-none dark:prose-invert ${className}`}
      dangerouslySetInnerHTML={{ __html: processedContent }}
    />
  );
};

export default React.memo(BlogContentProcessor);
