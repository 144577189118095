
import React, { useState, useEffect } from 'react';
import { cn } from '@/lib/utils';

interface SEOImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  className?: string;
  lazy?: boolean;
  caption?: string;
  priority?: boolean;
}

const SEOImage: React.FC<SEOImageProps> = ({
  src,
  alt,
  width,
  height,
  className,
  lazy = true,
  caption,
  priority = false,
  ...props
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [imgSrc, setImgSrc] = useState(src);
  
  // Reset state when src changes
  useEffect(() => {
    setImgSrc(src);
    setIsLoaded(false);
    setError(false);
  }, [src]);
  
  // Fallback image if the original fails to load
  const fallbackSrc = "/lovable-uploads/cb95bfda-3353-4813-a1fc-572342919299.png";
  
  // Generate a more specific alt text if one isn't provided
  const enhancedAlt = !alt || alt === "" ? 
    "Juan Elizondo Real Estate - McAllen Texas Property" : 
    alt;
  
  // Handle image load errors
  const handleError = () => {
    console.error(`Failed to load image: ${imgSrc}`);
    setError(true);
    setImgSrc(fallbackSrc);
  };
  
  // Handle successful image loads
  const handleLoad = () => {
    setIsLoaded(true);
  };

  // Add special handling for transparent images
  // Enhanced the balloon image with a stronger glow effect - updated to handle new balloon image
  const isTransparent = src.includes("3b1dc9c1-78d5-45ec-a250-315d9e5df71a.png") || 
                        src.includes("967535c9-c31e-4c4f-81e2-8015abebcc88.png");

  return (
    <figure className={cn("relative", className)}>
      {!isLoaded && !error && (
        <div className="absolute inset-0 bg-gray-200 dark:bg-gray-800 animate-pulse" />
      )}
      <img
        src={error ? fallbackSrc : imgSrc}
        alt={enhancedAlt}
        width={width}
        height={height}
        loading={priority ? "eager" : lazy ? "lazy" : "eager"}
        onError={handleError}
        onLoad={handleLoad}
        className={cn(
          "transition-opacity duration-300",
          isLoaded ? "opacity-100" : "opacity-0",
          "max-w-full h-auto",
          isTransparent ? "drop-shadow-[0_0_25px_rgba(0,184,255,1.5)]" : ""
        )}
        decoding={priority ? "sync" : "async"}
        {...props}
      />
      {caption && (
        <figcaption className="text-sm text-center mt-2 text-muted-foreground">
          {caption}
        </figcaption>
      )}
    </figure>
  );
};

export default React.memo(SEOImage);
