
import React from 'react';
import { Input } from '@/components/ui/input';
import { SaleSectionProps } from './types';

const SaleSection: React.FC<SaleSectionProps> = ({
  salePrice,
  setSalePrice,
  closingCostPercentSell,
  setClosingCostPercentSell,
  closingCostSell,
  sellerConcessionPercent,
  setSellerConcessionPercent,
  sellerConcessionAmount,
  commissionsPercent,
  setCommissionsPercent,
  commissionsAmount,
  totalCostOfSale,
  netSalesPrice,
  formatCurrency,
  handleCurrencyInputChange,
  handlePercentInputChange
}) => {
  return (
    <div className="transition-transform hover:transform hover:scale-[1.02] hover:z-10">
      <h2 className="text-2xl font-bold text-gold-500 mb-4">Sale</h2>
      
      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Sale Price</label>
        <div className="flex items-center">
          <span className="text-gold-500 mr-2">$</span>
          <Input
            type="text"
            value={salePrice.toLocaleString()}
            onChange={(e) => handleCurrencyInputChange(e.target.value, setSalePrice)}
            className="flex-1 text-white bg-estate-800 border-estate-700"
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Closing costs (seller)</label>
        <div className="flex gap-2">
          <div className="w-1/3 flex items-center">
            <Input
              type="text"
              value={closingCostPercentSell}
              onChange={(e) => handlePercentInputChange(e.target.value, setClosingCostPercentSell)}
              className="text-white bg-estate-800 border-estate-700"
              step="0.01"
            />
            <span className="ml-2 text-gold-500">%</span>
          </div>
          <div className="w-2/3 flex items-center">
            <span className="text-gold-500 mr-2">$</span>
            <Input 
              type="text" 
              value={closingCostSell.toLocaleString()} 
              readOnly 
              className="bg-estate-900 text-white border-estate-700"
            />
          </div>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Seller Concessions</label>
        <div className="flex gap-2">
          <div className="w-1/3 flex items-center">
            <Input
              type="text"
              value={sellerConcessionPercent}
              onChange={(e) => handlePercentInputChange(e.target.value, setSellerConcessionPercent)}
              className="text-white bg-estate-800 border-estate-700"
              step="0.01"
            />
            <span className="ml-2 text-gold-500">%</span>
          </div>
          <div className="w-2/3 flex items-center">
            <span className="text-gold-500 mr-2">$</span>
            <Input 
              type="text" 
              value={sellerConcessionAmount.toLocaleString()} 
              readOnly 
              className="bg-estate-900 text-white border-estate-700"
            />
          </div>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Commissions</label>
        <div className="flex gap-2">
          <div className="w-1/3 flex items-center">
            <Input
              type="text"
              value={commissionsPercent}
              onChange={(e) => handlePercentInputChange(e.target.value, setCommissionsPercent)}
              className="text-white bg-estate-800 border-estate-700"
              step="0.01"
            />
            <span className="ml-2 text-gold-500">%</span>
          </div>
          <div className="w-2/3 flex items-center">
            <span className="text-gold-500 mr-2">$</span>
            <Input 
              type="text" 
              value={commissionsAmount.toLocaleString()} 
              readOnly 
              className="bg-estate-900 text-white border-estate-700"
            />
          </div>
        </div>
      </div>

      <div className="mt-6">
        <label className="block text-gray-300 mb-1">Total Cost of Sale</label>
        <div className="text-xl font-bold text-gold-500 mb-2">
          {formatCurrency(totalCostOfSale)}
        </div>
      </div>

      <div className="mt-4">
        <label className="block text-gray-300 mb-1">Net Sales Price</label>
        <div className="text-2xl font-bold text-gold-500">
          {formatCurrency(netSalesPrice)}
        </div>
      </div>
    </div>
  );
};

export default SaleSection;
