
import React, { useEffect, useRef, useState } from 'react';

interface YouTubeBackgroundProps {
  youtubeId: string | null;
}

const YouTubeBackground: React.FC<YouTubeBackgroundProps> = ({ youtubeId }) => {
  const playerRef = useRef<YT.Player | null>(null);
  const playerContainerRef = useRef<HTMLDivElement>(null);
  const [playerLoaded, setPlayerLoaded] = useState(false);

  useEffect(() => {
    if (!youtubeId || !playerContainerRef.current) return;
    
    // Check if YouTube IFrame API script is already loaded
    const existingScript = document.querySelector('script[src="https://www.youtube.com/iframe_api"]');
    
    if (!existingScript) {
      // Load YouTube IFrame API if it's not already loaded
      console.log("Loading YouTube IFrame API script...");
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);
    }
    
    // Function to initialize or update player
    const setupPlayer = () => {
      console.log('Setting up YouTube player with ID:', youtubeId);
      
      if (playerRef.current) {
        // Player already exists, just load new video
        console.log('Player already exists, loading new video ID:', youtubeId);
        playerRef.current.loadVideoById({
          videoId: youtubeId,
          startSeconds: 0,
          suggestedQuality: 'hd1080'
        });
        return;
      }
      
      // Create a new player instance
      if (playerContainerRef.current) {
        console.log('Creating new YouTube player instance');
        playerRef.current = new YT.Player(playerContainerRef.current, {
          videoId: youtubeId,
          playerVars: {
            autoplay: 1,
            mute: 1,
            controls: 0,
            showinfo: 0,
            rel: 0,
            iv_load_policy: 3,
            modestbranding: 1,
            loop: 1,
            playlist: youtubeId,
            disablekb: 1,
            playsinline: 1
          },
          events: {
            onReady: (event) => {
              console.log('YouTube player ready');
              event.target.playVideo();
              setPlayerLoaded(true);
            },
            onStateChange: (event) => {
              // If video ends, play it again
              if (event.data === YT.PlayerState.ENDED) {
                event.target.playVideo();
              }
            },
            onError: (event) => {
              console.error('YouTube player error:', event.data);
            }
          }
        });
      }
    };
    
    // Define the YouTube API callback function
    if (!window.onYouTubeIframeAPIReady) {
      window.onYouTubeIframeAPIReady = () => {
        console.log('YouTube API ready, initializing player');
        setupPlayer();
      };
    } else if (typeof YT !== 'undefined' && YT.Player) {
      // If API is already loaded, set up the player directly
      console.log('YouTube API already loaded, setting up player directly');
      setupPlayer();
    }
    
    // Cleanup function
    return () => {
      // Destroy the player instance when component unmounts
      if (playerRef.current) {
        playerRef.current.destroy();
        playerRef.current = null;
      }
    };
  }, [youtubeId]);

  return (
    <>
      {/* Fallback solid background color when video is loading */}
      <div className="absolute inset-0 bg-slate-900 z-0"></div>
      
      {/* YouTube video background container */}
      {youtubeId && (
        <div className="absolute inset-0 w-full h-full overflow-hidden pointer-events-none">
          <div 
            ref={playerContainerRef}
            className="absolute w-full h-full" 
            style={{ 
              position: 'absolute',
              width: '177.77777778vh', /* 16:9 aspect ratio */
              minWidth: '100%',
              height: '56.25vw', /* 16:9 aspect ratio */
              minHeight: '100%',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              objectFit: 'cover',
              pointerEvents: 'none',
            }}
          ></div>
        </div>
      )}
      
      {/* Dark overlay with optimized gradient for better text contrast */}
      <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black/50 z-1"></div>
      
      {/* Loading indicator - shows only when video is not loaded yet */}
      {youtubeId && !playerLoaded && (
        <div className="absolute inset-0 flex items-center justify-center z-1">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
        </div>
      )}
    </>
  );
};

export default YouTubeBackground;
