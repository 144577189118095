
import React, { useEffect, useRef, useMemo } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLanguage } from "@/context/LanguageContext";
import HeroContent from "./HeroContent";
import GlowingText from "../common/GlowingText";
import BackgroundMedia from "./BackgroundMedia";
import { useBackgroundMedia } from "@/hooks/useBackgroundMedia";
import MediaAlerts from "./MediaAlerts";

gsap.registerPlugin(ScrollTrigger);

const ParallaxHero = () => {
  const { t } = useLanguage();
  const parallaxRef = useRef<HTMLDivElement>(null);
  const layersRef = useRef<HTMLDivElement>(null);
  
  // Use the background media hook to get video functionality
  const {
    mediaPath,
    mediaType,
    mediaLoaded,
    videoRef,
    videoError,
    videoInfo,
    handleVideoError,
    youtubeId,
    youtubeSequence,
    onYouTubeVideoEnd
  } = useBackgroundMedia();

  // Use useMemo to avoid recreating layer settings on every render
  const layerSettings = useMemo(() => [
    { layer: "1", yPercent: 70 }, // Background layer (slow movement)
    { layer: "2", yPercent: 50 }, // Middle layer
    { layer: "3", yPercent: 30 }, // Title layer
    { layer: "4", yPercent: 10 }  // Foreground layer (fast movement)
  ], []);

  useEffect(() => {
    console.log("ParallaxHero - Media loaded:", mediaLoaded, "Media type:", mediaType, "Media path:", mediaPath, "YouTube ID:", youtubeId);
    
    // Simple parallax effect with reduced complexity
    if (!parallaxRef.current || !layersRef.current) return;

    const layers = layersRef.current.querySelectorAll("[data-parallax-layer]");
    
    // Create a simpler timeline
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: parallaxRef.current,
        start: "top top",
        end: "bottom top",
        scrub: true,
      }
    });

    // Apply animations with simplified approach
    layers.forEach((layer) => {
      const layerNum = layer.getAttribute("data-parallax-layer");
      const setting = layerSettings.find(s => s.layer === layerNum);
      
      if (setting) {
        tl.to(layer, {
          y: `${setting.yPercent}%`,
          ease: "none"
        }, 0);
      }
    });

    return () => {
      // Clean up the ScrollTrigger
      if (tl.scrollTrigger) {
        tl.scrollTrigger.kill();
      }
    };
  }, [layerSettings, mediaLoaded, mediaType, mediaPath, youtubeId]);

  return (
    <section 
      ref={parallaxRef} 
      className="relative min-h-screen overflow-hidden" 
      style={{ 
        width: '100vw',
        maxWidth: '100vw',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
        height: '100vh' // Ensure full viewport height
      }}
    >
      {/* Background Video Layer */}
      <BackgroundMedia
        mediaType={mediaType}
        mediaLoaded={mediaLoaded}
        mediaPath={mediaPath}
        videoRef={videoRef}
        handleVideoError={handleVideoError}
        videoInfo={videoInfo}
        youtubeId={youtubeId}
        youtubeSequence={youtubeSequence}
        onYouTubeVideoEnd={onYouTubeVideoEnd}
      />
      
      {/* Debug/Info indicator for video status - hidden in production */}
      <MediaAlerts 
        videoInfo={videoInfo} 
        videoError={videoError} 
        mediaType={mediaType} 
        mediaLoaded={mediaLoaded} 
      />
      
      {/* Parallax layers container - full width */}
      <div 
        ref={layersRef} 
        data-parallax-layers 
        className="absolute inset-0 overflow-hidden z-10"
        style={{ width: '100vw', maxWidth: '100vw', margin: 0, padding: 0 }}
      >
        {/* Layer 2 - Middle layer (medium movement) */}
        <div 
          className="absolute inset-0 bg-center bg-no-repeat"
          data-parallax-layer="2" 
          style={{ height: "130%", top: "-15%", width: "100vw" }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-black/20 to-transparent"></div>
        </div>
        
        {/* Layer 3 - Title layer (follows user) */}
        <div
          className="absolute inset-0 flex flex-col h-full"
          data-parallax-layer="3"
          style={{ height: "120%", top: "-5%", width: "100vw" }}
        >
          {/* Core values as regular text */}
          <div className="absolute top-36 md:top-44 left-0 right-0 z-10 px-8">
            <div className="max-w-4xl mx-auto">
              <div className="flex flex-wrap justify-center gap-x-10 md:gap-x-16 gap-y-3">
                <div className="core-value-item">
                  <span className="text-white font-medium text-xl md:text-2xl">Trust</span>
                </div>
                
                <div className="core-value-item relative">
                  <span className="text-white font-medium text-xl md:text-2xl">Communication</span>
                </div>
                
                <div className="core-value-item relative">
                  <span className="text-white font-medium text-xl md:text-2xl">Expertise</span>
                </div>
                
                <div className="core-value-item relative">
                  <span className="text-white font-medium text-xl md:text-2xl">Responsiveness</span>
                </div>
              </div>
            </div>
          </div>
          
          {/* Centered Juan Elizondo name with title underneath - LOWERED POSITION */}
          <div className="absolute top-[45%] left-0 right-0 z-10 text-center">
            <GlowingText 
              text="Juan Elizondo"
              className="text-5xl md:text-6xl font-display text-white"
              glowColor="gold"
              advanced={true}
            />
            <div className="text-white text-xl md:text-2xl mt-3 font-medium mx-auto bg-black/30 py-2 backdrop-blur-sm rounded-full max-w-xl">
              <span className="drop-shadow-[0_2px_6px_rgba(0,0,0,0.8)] px-6">Your Trusted Expert in Residential & Commercial Real Estate</span>
            </div>
          </div>
          
          {/* Use the HeroContent component directly */}
          <HeroContent />
        </div>
        
        {/* Layer 4 - Foreground (fastest movement) */}
        <div
          className="absolute inset-0"
          data-parallax-layer="4"
          style={{ height: "120%", width: "100vw" }}
        >
          {/* Removed dark overlay for better video visibility */}
        </div>
      </div>
      
      {/* Dark gradient fade at bottom for better text visibility - Made more transparent */}
      <div 
        className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-black/40 to-transparent"
        style={{ width: '100vw', maxWidth: '100vw' }}
      ></div>
    </section>
  );
};

export default React.memo(ParallaxHero);
