
import React, { useState, useEffect } from "react";
import { Loader2, ExternalLink } from "lucide-react";

interface MatrixIDXFrameProps {
  idxUrl: string;
  title?: string;
  searchQuery?: string;
}

const MatrixIDXFrame: React.FC<MatrixIDXFrameProps> = ({ 
  idxUrl,
  title,
  searchQuery
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [frameUrl, setFrameUrl] = useState(idxUrl);
  
  // Update URL with search query if provided
  useEffect(() => {
    if (searchQuery) {
      // Note: This is a simplified approach - the actual Matrix URL structure
      // may require a different parameter format depending on their API
      const updatedUrl = new URL(idxUrl, window.location.origin);
      updatedUrl.searchParams.append('search', searchQuery);
      setFrameUrl(updatedUrl.toString());
    } else {
      setFrameUrl(idxUrl);
    }
  }, [idxUrl, searchQuery]);
  
  // Handle iframe load completion
  const handleFrameLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="w-full h-full min-h-[800px] flex flex-col">
      {title && (
        <h2 className="text-2xl md:text-3xl font-display text-center mb-4 text-white dark:text-white">
          {title}
        </h2>
      )}
      
      <div className="relative w-full flex-1 bg-black dark:bg-gray-900 border border-gray-800 dark:border-gray-700 rounded-xl overflow-hidden shadow-lg">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black/80 dark:bg-gray-900/80 z-10">
            <div className="flex flex-col items-center">
              <Loader2 className="h-12 w-12 animate-spin text-gold-500" />
              <p className="mt-4 text-lg font-medium text-white dark:text-white">Loading property listings...</p>
              <p className="text-sm text-gray-400 dark:text-gray-400 mt-2">This may take a few moments</p>
            </div>
          </div>
        )}
        
        <div className="relative h-full">
          <iframe 
            src={frameUrl}
            title="GMAR Matrix Property Search"
            className="w-full h-full min-h-[800px] border-0"
            onLoad={handleFrameLoad}
          />
          
          <div className="absolute bottom-4 right-4 z-10">
            <a 
              href={frameUrl} 
              target="_blank" 
              rel="noopener noreferrer"
              className="flex items-center gap-1 text-xs bg-black/90 dark:bg-gray-900/90 px-3 py-1.5 rounded-full shadow text-white dark:text-gray-300 hover:bg-black/70 dark:hover:bg-gray-800 transition-colors"
            >
              <ExternalLink className="h-3 w-3" />
              Open in new window
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatrixIDXFrame;
