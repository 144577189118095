
/**
 * Attempts to load an image from a list of possible paths
 */
export const findValidImage = async (imagePaths: string[], onImageFound: (path: string) => void) => {
  let imageFound = false;
  
  for (const path of imagePaths) {
    console.log(`Trying to load image from: ${path}`);
    
    try {
      const response = await fetch(path, { method: 'HEAD' });
      
      if (response.ok) {
        // Verify content type is an image
        const contentType = response.headers.get('Content-Type');
        if (contentType && !contentType.startsWith('image/')) {
          console.warn(`⚠️ File at ${path} has incorrect content type: ${contentType}. Expected image/*`);
          continue; // Skip this file
        }
        
        console.log(`✅ Image file found at ${path}${contentType ? ` with content type: ${contentType}` : ''}`);
        onImageFound(path);
        imageFound = true;
        return { found: true, path };
      } else {
        console.log(`❌ Image file not found at ${path}`);
      }
    } catch (error) {
      console.error(`Error checking image at ${path}:`, error);
    }
  }
  
  if (!imageFound) {
    console.log("No images found. Using background color only.");
  }
  
  return { found: false };
};

/**
 * Default image paths to try
 */
export const defaultImagePaths = [
  "/lovable-uploads/78693ea3-3c38-4afd-84ac-c7aeafeb51f4.png",
  "/lovable-uploads/cb95bfda-3353-4813-a1fc-572342919299.png",
  "/lovable-uploads/hero-background.png",
  "/lovable-uploads/hero-bg.jpg",
  "/lovable-uploads/hero.jpg",
  "/images/hero-bg.jpg",
  "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b" // Fallback to Unsplash image
];
