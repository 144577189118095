
import { useLanguage } from "@/context/LanguageContext";

const TabTestimonials = () => {
  const { language } = useLanguage();

  return (
    <div className="text-white space-y-4">
      <h3 className="text-2xl font-semibold text-white mb-4">
        Confiabilidad y Testimonios
      </h3>
      <p className="text-lg">
        La reputación de Juan es un factor crítico para inversionistas mexicanos, quienes a menudo priorizan la confiabilidad en transacciones internacionales. Sus 15 años de experiencia están respaldados por un historial de acuerdos exitosos, particularmente con clientes de México.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <div className="bg-gray-800 p-4 rounded-lg">
          <p className="italic text-lg">
            "Juan hizo que comprar nuestra primera propiedad en EE. UU. fuera fácil. Su conocimiento de ambas culturas y mercados fue invaluable."
          </p>
          <p className="font-medium text-gold-300 mt-2 text-lg">— María S., Monterrey</p>
        </div>
        <div className="bg-gray-800 p-4 rounded-lg">
          <p className="italic text-lg">
            "La paciencia y minuciosidad de Juan al explicar cada paso del proceso fue exactamente lo que necesitaba como inversionista primerizo."
          </p>
          <p className="font-medium text-gold-300 mt-2 text-lg">— Carlos R., Guadalajara</p>
        </div>
      </div>
    </div>
  );
};

export default TabTestimonials;
