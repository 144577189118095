
import { useState, useEffect } from "react";
import { useBackgroundMedia } from "@/hooks/useBackgroundMedia";
import GlowingText from "@/components/common/GlowingText";
import { refreshGlowEffect } from "@/utils/glowTextUtils";

const MexicanHero = () => {
  const [scrollY, setScrollY] = useState(0);
  
  // Use the background media hook without parameters
  const { mediaPath, mediaLoaded } = useBackgroundMedia();

  // Add scroll effect for parallax
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section 
      className="relative h-[500px] flex items-center justify-center overflow-hidden" 
      style={{ 
        transform: 'translateZ(0)', 
        backfaceVisibility: 'hidden',
        perspective: '1000px',
        willChange: 'transform'
      }}
    >
      {/* Background Image with Fallback */}
      <div 
        className="absolute inset-0 bg-black z-0" 
        style={{ 
          transform: 'translateZ(0)',
          backfaceVisibility: 'hidden' 
        }}
      >
        {mediaLoaded && (
          <div 
            className="absolute inset-0 bg-cover bg-center transition-opacity duration-500 opacity-80"
            style={{ 
              backgroundImage: `url('${mediaPath}')`,
              transform: `translate3d(0, ${scrollY * 0.2}px, 0)`, // Reduced parallax effect
              backfaceVisibility: 'hidden',
              willChange: 'transform'
            }}
          ></div>
        )}
        <div className="absolute inset-0 bg-black opacity-30"></div>
      </div>
      
      {/* Hero Content with stabilized width */}
      <div 
        className="container mx-auto px-4 relative z-10 text-center" 
        style={{ 
          transform: 'translateZ(0)',
          backfaceVisibility: 'hidden',
          transition: 'opacity 0.3s ease'
        }}
      >
        <div style={{ minHeight: '150px', width: '100%' }}>
          <GlowingText
            text="Invirtiendo en Bienes Raíces en Estados Unidos desde México"
            as="h1"
            className="text-4xl md:text-5xl lg:text-6xl font-display text-white mb-4 text-shadow-gold"
            glowColor="gold"
            advanced={true}
          />
        </div>
        <div style={{ minHeight: '80px', width: '100%' }}>
          <GlowingText
            text="Explore oportunidades en McAllen para su doble ciudadanía o crecimiento empresarial"
            as="p"
            className="text-xl md:text-2xl text-white max-w-3xl mx-auto"
            glowColor="blue"
            advanced={true}
          />
        </div>
      </div>
    </section>
  );
};

export default MexicanHero;
