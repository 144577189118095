
import { useEffect, useRef } from "react";
import { useVideoPlayback } from "./useVideoPlayback";
import { MediaType, VideoInfo } from "@/utils/media/types";
import { extractYouTubeVideoId } from "@/utils/media/videoUtils";
import { useMediaState } from "./media/useMediaState";
import { useVideoErrorHandler } from "./media/useVideoErrorHandler";
import { useImageLoader } from "./media/useImageLoader";
import { useYouTubeVideo } from "./media/useYouTubeVideo";
import { useReliableVideo } from "./media/useReliableVideo";
import { useVideoFinder } from "./media/useVideoFinder";

/**
 * Hook to handle background media (images or videos)
 */
export const useBackgroundMedia = (customImagePaths?: string[], customVideoPaths?: string[]) => {
  // Use refs to track component state
  const firstVideoPlayed = useRef(false);
  
  // Use media state management hook
  const { 
    mediaPath, 
    mediaType, 
    mediaLoaded, 
    videoInfo, 
    setVideoInfo, 
    updateMediaState 
  } = useMediaState();
  
  // Use error handling hook
  const { videoError, handleVideoError } = useVideoErrorHandler(() => loadImage());
  
  // Use image loading hook
  const { loadImage } = useImageLoader();
  
  // Use YouTube video handling hook
  const { 
    youtubeId, 
    youtubeSequence, 
    loadYoutubeVideo, 
    loadYoutubeSequence, 
    handleVideoSequenceEnd 
  } = useYouTubeVideo();
  
  // Use reliable video loading hook
  const { loadReliableVideo, loadAlternativeVideos } = useReliableVideo(
    (path, type, info) => {
      updateMediaState(path, type, true, info);
    }
  );
  
  // Use video finder hook
  const { findVideo } = useVideoFinder();
  
  // Use the video playback hook
  const { videoRef } = useVideoPlayback(mediaType, mediaLoaded, () => loadImage());
  
  // Load media sources on component mount
  useEffect(() => {
    // Use the new YouTube video URL directly
    const realEstateYouTubeUrl = "https://www.youtube.com/watch?v=r6KZXcp95Qc"; 
    if (loadYoutubeVideo(realEstateYouTubeUrl)) {
      console.log("Successfully loaded real estate YouTube video");
      updateMediaState("", "youtube", true, {
        name: "YouTube - Real Estate Video",
        size: undefined,
        duration: undefined,
        format: "youtube"
      });
      return;
    }
    
    // If YouTube fails, try direct loading of reliable video
    if (loadReliableVideo()) {
      console.log("Successfully loaded reliable video");
      return;
    }
    
    // Run the backup video loading as well after a short delay
    setTimeout(async () => {
      const videoPaths = await loadAlternativeVideos(customVideoPaths);
      
      // Check if any of the provided URLs are YouTube videos
      for (const url of videoPaths) {
        if (extractYouTubeVideoId(url)) {
          if (loadYoutubeVideo(url)) {
            updateMediaState("", "youtube", true, {
              name: "YouTube - Real Estate Video",
              size: undefined,
              duration: undefined,
              format: "youtube"
            });
            return;
          }
        }
      }
      
      // Try loading videos from the list
      const result = await findVideo(
        videoPaths,
        (path, type, info) => {
          // Only update if no video is loaded yet
          if (!mediaLoaded) {
            updateMediaState(path, type, true, info);
          }
        }
      );
      
      if (result.found && result.videoInfo && !videoInfo) {
        console.log("Setting alternative video info:", result.videoInfo);
        
        // Add more descriptive name for real estate videos from Vimeo
        let enhancedInfo = {...result.videoInfo};
        if (result.path.includes('vimeo')) {
          enhancedInfo.name = "Luxury Real Estate Footage (Vimeo)";
        }
        
        setVideoInfo(enhancedInfo);
      }
    }, 1000);
  }, [customVideoPaths]);

  return {
    mediaPath,
    mediaType,
    mediaLoaded,
    videoError,
    videoInfo,
    videoRef,
    youtubeId,
    youtubeSequence,
    handleVideoError,
    onYouTubeVideoEnd: handleVideoSequenceEnd
  };
};
