
interface BlogPostCardCategoryProps {
  category: string;
  isHovered: boolean;
}

const BlogPostCardCategory = ({ category, isHovered }: BlogPostCardCategoryProps) => {
  return (
    <div className="absolute top-3 right-3 bg-white/80 backdrop-blur-sm px-3 py-1 rounded-full text-sm font-medium shadow-sm transition-all duration-300 text-gray-800 dark:text-gray-900"
         style={{
           transform: isHovered ? 'translateY(-3px)' : 'translateY(0)',
           boxShadow: isHovered ? '0 4px 6px rgba(0,0,0,0.1)' : 'none'
         }}>
      {category}
    </div>
  );
};

export default BlogPostCardCategory;
