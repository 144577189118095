
import { useEffect, useState } from "react";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import CommercialHero from "@/components/commercial/CommercialHero";
import CommercialGrid from "@/components/commercial/CommercialGrid";
import CommercialExpertise from "@/components/commercial/CommercialExpertise";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { useLanguage } from "@/context/LanguageContext";
import SEO from "@/components/SEO";
import { useSEO } from "@/context/SEOContext";
import { generateSEOTitle, generateMetaDescription } from "@/utils/slugUtils";
import { generateLocalBusinessData } from "@/utils/structuredDataUtils";

const Commercial = () => {
  const { toast } = useToast();
  const { t } = useLanguage();
  const [imageLoaded, setImageLoaded] = useState(false);
  const { updateSEO } = useSEO();
  
  useEffect(() => {
    window.scrollTo(0, 0);
    
    // Update SEO metadata for commercial page
    updateSEO({
      title: generateSEOTitle("Commercial Real Estate Services", "McAllen, TX"),
      description: generateMetaDescription(
        "Expert commercial real estate services in McAllen and the Rio Grande Valley. " +
        "Find the perfect office space, retail location, or industrial property with Juan Elizondo, " +
        "a leading commercial property specialist with extensive market knowledge."
      ),
      keywords: [
        'commercial real estate', 
        'McAllen commercial properties', 
        'office space', 
        'retail space', 
        'industrial property', 
        'Rio Grande Valley commercial',
        'investment properties',
        'commercial leasing',
        'property acquisition'
      ],
      structuredData: generateLocalBusinessData(),
      ogType: 'website',
      twitterCard: 'summary_large_image'
    });
    
    // Check if image is loading properly with more detailed diagnostics
    const img = new Image();
    img.src = '/lovable-uploads/40fd3f52-b469-4886-9097-40e4f7aa90d4.png';
    img.onload = () => {
      console.log('Commercial hero image loaded successfully', img.width, 'x', img.height);
      setImageLoaded(true);
    };
  }, [updateSEO]);

  return (
    <div className="min-h-screen bg-black text-white">
      <SEO />
      <Navbar />
      
      <CommercialHero />
      
      <section className="py-16 px-4 max-w-6xl mx-auto wp-block-lovable-commercial-intro">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-display mb-6 text-white">
            {t('commercial.expertise.main') || "Commercial Real Estate Excellence"}
          </h2>
          <p className="text-lg leading-relaxed text-white/90 mb-4">
            {t('commercial.intro') || 
              "As a seasoned commercial real estate agent in McAllen and the Rio Grande Valley, I provide comprehensive services to businesses seeking the ideal location for their operations. With deep local market knowledge and extensive industry connections, I help clients navigate the complex commercial property landscape with confidence."
            }
          </p>
          <p className="text-lg leading-relaxed text-white/90">
            {t('commercial.intro.second') || 
              "Whether you're searching for retail space in a high-traffic area, an office building with modern amenities, or industrial property with specific requirements, my expertise ensures you'll find the perfect commercial property to support your business goals."
            }
          </p>
        </div>
        
        <CommercialExpertise />
        
        <h2 className="text-3xl md:text-4xl font-display text-center mb-12 mt-16 text-white">{t('commercial.featured') || "Featured Commercial Properties"}</h2>
        
        <CommercialGrid />
        
        <div className="text-center mt-12">
          <Button 
            className="bg-[#ffb347] hover:bg-[#e69a30] text-white font-bold py-3 px-8 rounded-md transition duration-300"
            onClick={() => {
              toast({
                title: t('toast.coming.soon') || "Coming Soon",
                description: t('toast.coming.soon.desc') || "More commercial properties will be available shortly.",
              });
            }}
          >
            {t('commercial.view.all') || "View All Commercial Properties"}
          </Button>
        </div>
      </section>
      
      <Footer />
    </div>
  );
};

export default Commercial;
