
import React, { useEffect, useRef } from "react";
import { MediaType, YouTubeVideoSequence } from "@/utils/media/types";
import VideoPlayer from "../common/VideoPlayer";
import { createVideoSources } from "@/utils/media/videoUtils";

interface BackgroundMediaProps {
  mediaType: MediaType;
  mediaLoaded: boolean;
  mediaPath: string;
  videoRef: React.RefObject<HTMLVideoElement>;
  handleVideoError: (message?: string) => void;
  videoInfo?: any;
  youtubeId?: string | null;
  youtubeSequence?: YouTubeVideoSequence | null;
  onYouTubeVideoEnd?: () => void;
}

const BackgroundMedia = ({
  mediaType,
  mediaLoaded,
  mediaPath,
  videoRef,
  handleVideoError,
  videoInfo,
  youtubeId,
  youtubeSequence,
  onYouTubeVideoEnd
}: BackgroundMediaProps) => {
  // Determine if the media is a video type (either 'video' or 'mp4')
  const isVideoType = mediaType === "video" || mediaType === "mp4";
  const isYoutubeVideo = mediaType === "youtube" && youtubeId;
  
  // For advanced video player usage
  const videoSources = isVideoType ? createVideoSources(mediaPath) : [];
  
  // Reference to the YouTube iframe for API integration
  const youtubePlayerRef = useRef<HTMLIFrameElement>(null);
  
  // Listen for YouTube message events to detect when videos end
  useEffect(() => {
    if (!isYoutubeVideo || !onYouTubeVideoEnd) return;
    
    // Event listener for messages from the YouTube iframe
    const handleMessage = (event: MessageEvent) => {
      // Only process messages from YouTube
      if (event.origin !== "https://www.youtube.com") return;
      
      try {
        // Try to parse the message data
        const data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
        
        // Check if this is a video ended event (state = 0)
        if (data.event === "onStateChange" && data.info === 0) {
          console.log("YouTube video ended, triggering sequence handler");
          onYouTubeVideoEnd();
        }
      } catch (error) {
        // Ignore parse errors - not all messages are JSON
      }
    };
    
    // Add event listener
    window.addEventListener("message", handleMessage);
    
    // Clean up
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [isYoutubeVideo, onYouTubeVideoEnd]);
  
  // Build YouTube player URL with API enabled and loop enabled
  const buildYouTubeUrl = () => {
    if (!youtubeId) return '';
    
    // Include enablejsapi=1 to allow API access and ensure we get events
    // Added loop=1 to make video loop, and playlist parameter with the same ID to make looping work
    // Added modestbranding=1, showinfo=0, rel=0 to hide UI elements
    return `https://www.youtube.com/embed/${youtubeId}?autoplay=1&mute=1&loop=1&playlist=${youtubeId}&controls=0&showinfo=0&rel=0&modestbranding=1&iv_load_policy=3&disablekb=1&enablejsapi=1&origin=${window.location.origin}`;
  };
  
  return (
    <>
      {isYoutubeVideo && mediaLoaded ? (
        <div className="absolute inset-0 w-full h-full overflow-hidden" style={{
          width: '100vw',
          height: '100vh', // Ensure full viewport height
          maxWidth: '100vw',
          margin: 0,
          padding: 0,
          zIndex: 0
        }}>
          <iframe 
            ref={youtubePlayerRef}
            className="absolute"
            style={{ 
              position: 'absolute',
              width: '100vw', 
              height: '100vh', // Full viewport height
              maxWidth: '100vw',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              margin: 0,
              padding: 0,
              border: 0,
              pointerEvents: 'none',
              transform: 'scale(1.5)' // Increased scale to fill more space
            }}
            src={buildYouTubeUrl()}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ) : isVideoType && mediaLoaded ? (
        <div className="absolute inset-0 w-full h-full overflow-hidden" style={{
          width: '100vw',
          height: '100vh', // Ensure full viewport height
          maxWidth: '100vw',
          margin: 0,
          padding: 0,
          zIndex: 0
        }}>
          <video
            ref={videoRef}
            className="absolute object-cover"
            style={{ 
              position: 'absolute',
              width: '100vw', 
              height: '100vh', // Full viewport height
              maxWidth: '100vw',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              margin: 0,
              padding: 0,
              objectFit: 'cover',
              transform: 'scale(1.05)' // Slight scale to avoid gaps
            }}
            src={mediaPath}
            autoPlay
            muted
            loop
            playsInline
            onError={() => handleVideoError("Video playback failed")}
            aria-hidden="true"
          >
            {videoSources.map((source, index) => (
              <source key={index} src={source.src} type={source.type} />
            ))}
          </video>
        </div>
      ) : (
        <div
          className="absolute inset-0 bg-center bg-no-repeat will-change-transform"
          style={{
            backgroundImage: mediaLoaded ? `url('${mediaPath}')` : 'none',
            backgroundSize: 'cover',
            width: '100vw',
            height: '100vh', // Ensure full viewport height
            maxWidth: '100vw',
            left: 0,
            right: 0,
            margin: 0,
            padding: 0,
            zIndex: 0,
            backgroundColor: "#0E76BC" // Ocean blue fallback color
          }}
          aria-hidden="true"
        />
      )}
    </>
  );
};

export default React.memo(BackgroundMedia);
