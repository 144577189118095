import { validateAndPrepareJson, ValidationResult } from '@/utils/blog/validationUtils';
import { extractImagesFromHtmlContent, processAndMergeImages } from '@/utils/blog/imageExtractionUtils';
import { submitBlogPost, checkEdgeFunctionStatus } from '@/utils/blog/networkUtils';
import { 
  submitJuansBlogPost, 
  processAirtableForJuansBlogs 
} from '@/utils/blog/juansBlog';

/**
 * Process an Airtable export format into the blog post format expected by the system
 * @param airtableData - The raw Airtable export data
 * @returns Processed blog post data
 */
export const processAirtableExport = (airtableData: any): any => {
  try {
    // Check if the data is in the expected array format from Airtable
    if (Array.isArray(airtableData) && airtableData.length > 0) {
      const record = airtableData[0];
      
      // Extract fields from the Airtable record
      if (record.fields) {
        const processedData = {
          title: record.fields.title || "Untitled Post",
          content: record.fields.content || "",
          excerpt: record.fields.excerpt || "",
          author: record.fields.author || "Anonymous",
          category: record.fields.category || "Uncategorized",
          image: "",
          images: []
        };
        
        // Process image field (which might be a JSON string)
        if (record.fields.image) {
          try {
            // First attempt to parse as JSON string
            const imageData = typeof record.fields.image === 'string' && 
                            (record.fields.image.startsWith('[') || record.fields.image.startsWith('{')) ? 
                            JSON.parse(record.fields.image) : record.fields.image;
            
            if (Array.isArray(imageData) && imageData.length > 0) {
              // Set the main image from the first image in the array
              if (imageData[0].url) {
                processedData.image = imageData[0].url;
              }
              // Store the full image array
              processedData.images = imageData;
            } else if (typeof imageData === 'object' && imageData.url) {
              processedData.image = imageData.url;
              processedData.images = [imageData];
            } else if (typeof imageData === 'string') {
              // Direct URL string
              processedData.image = imageData;
              processedData.images = [{ url: imageData }];
            }
          } catch (e) {
            // If not valid JSON, treat as a direct URL
            processedData.image = record.fields.image;
            processedData.images = [{ url: record.fields.image }];
          }
        }
        
        // Process direct images array if available
        if (record.fields.images && Array.isArray(record.fields.images)) {
          // Extract urls from Airtable attachment format if needed
          const extractedImages = record.fields.images.map((img: any) => {
            // Handle Airtable attachment format
            if (img.url) return img;
            if (typeof img === 'string') return { url: img };
            
            // Extract from complex Airtable format
            if (img.id && img.url) {
              return {
                url: img.url,
                caption: img.filename || '',
                sequence: processedData.images.length + 1
              };
            }
            return null;
          }).filter(Boolean);
          
          // Add extracted images to the images array
          if (extractedImages.length > 0) {
            processedData.images = [...processedData.images, ...extractedImages];
            // If no main image is set yet, use the first one
            if (!processedData.image && extractedImages[0]?.url) {
              processedData.image = extractedImages[0].url;
            }
          }
        }
        
        // Extract images from content if available
        if (processedData.content) {
          const extractedImages = extractImagesFromHtmlContent(processedData.content);
          // Process and merge the extracted images with existing ones
          return processAndMergeImages(processedData, extractedImages);
        }
        
        return processedData;
      }
    }
    
    // If not in expected format, return the data as-is for standard processing
    return airtableData;
  } catch (error) {
    console.error("Error processing Airtable export:", error);
    return airtableData;
  }
};

/**
 * Submit blog post with option to use Juan's dedicated table
 */
export const submitBlogToSelectedTable = async (
  parsedContent: any, 
  useJuansTable: boolean = false,
  setSubmissionStatus?: (status: 'idle' | 'error' | 'success' | 'warning') => void,
  setErrorDetails?: (details: string) => void
) => {
  if (useJuansTable) {
    console.log("Submitting to Juan's dedicated blog table");
    return submitJuansBlogPost(parsedContent, setSubmissionStatus, setErrorDetails);
  } else {
    console.log("Submitting to regular blog_posts table");
    // Use traditional blog submission method via edge function
    const useAirtableProcessor = false;
    return submitBlogPost(
      parsedContent, 
      useAirtableProcessor,
      setSubmissionStatus || (() => {}),
      setErrorDetails || (() => {}),
      () => {},
      undefined,
      0,
      async () => {}
    );
  }
};

export {
  // Re-export other utilities
  validateAndPrepareJson,
  type ValidationResult,
  extractImagesFromHtmlContent,
  processAndMergeImages,
  submitBlogPost,
  checkEdgeFunctionStatus,
  // Export new Juan's blogs utilities
  submitJuansBlogPost,
  processAirtableForJuansBlogs
};
