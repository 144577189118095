
import React from 'react';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { DollarSign, Hammer, FileText } from 'lucide-react';

interface ManualCostsTabProps {
  materialCost: number;
  setMaterialCost: (value: number) => void;
  suggestedMaterialCost: number;
  laborCost: number;
  setLaborCost: (value: number) => void;
  suggestedLaborCost: number;
  permitsCost: number;
  setPermitsCost: (value: number) => void;
  suggestedPermitsCost: number;
}

const ManualCostsTab: React.FC<ManualCostsTabProps> = (props) => {
  // Handle numeric input changes
  const handleNumericChange = (setter: (value: number) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value !== '' ? parseFloat(e.target.value) : 0;
    if (!isNaN(value)) {
      setter(value);
    }
  };

  return (
    <div className="space-y-4">
      <Card className="bg-gradient-to-br from-blue-900/60 to-blue-950/80 border-blue-700 shadow-lg shadow-blue-900/20">
        <CardContent className="p-4">
          <div className="flex items-center justify-between mb-1">
            <Label htmlFor="materialCost" className="flex items-center gap-2 text-blue-300 font-semibold">
              <DollarSign size={16} className="text-blue-400" />
              Material Cost ($)
            </Label>
            <span className="text-xs text-blue-300">Suggestion: ${props.suggestedMaterialCost.toFixed(2)}</span>
          </div>
          <Input
            id="materialCost"
            type="number"
            value={props.materialCost}
            onChange={handleNumericChange(props.setMaterialCost)}
            className="bg-blue-900/30 border-blue-700/50 text-blue-100 focus:border-blue-500 focus:ring-2 focus:ring-blue-500/30"
          />
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => props.setMaterialCost(props.suggestedMaterialCost)} 
            className="mt-2 text-xs bg-blue-800/30 border-blue-700/50 hover:bg-blue-700 text-blue-200 hover:text-blue-100 transition-all duration-200"
          >
            Use Suggested
          </Button>
        </CardContent>
      </Card>

      <Card className="bg-gradient-to-br from-green-900/60 to-green-950/80 border-green-700 shadow-lg shadow-green-900/20">
        <CardContent className="p-4">
          <div className="flex items-center justify-between mb-1">
            <Label htmlFor="laborCost" className="flex items-center gap-2 text-green-300 font-semibold">
              <Hammer size={16} className="text-green-400" />
              Labor Cost ($)
            </Label>
            <span className="text-xs text-green-300">Suggestion: ${props.suggestedLaborCost.toFixed(2)}</span>
          </div>
          <Input
            id="laborCost"
            type="number"
            value={props.laborCost}
            onChange={handleNumericChange(props.setLaborCost)}
            className="bg-green-900/30 border-green-700/50 text-green-100 focus:border-green-500 focus:ring-2 focus:ring-green-500/30"
          />
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => props.setLaborCost(props.suggestedLaborCost)} 
            className="mt-2 text-xs bg-green-800/30 border-green-700/50 hover:bg-green-700 text-green-200 hover:text-green-100 transition-all duration-200"
          >
            Use Suggested
          </Button>
        </CardContent>
      </Card>

      <Card className="bg-gradient-to-br from-purple-900/60 to-purple-950/80 border-purple-700 shadow-lg shadow-purple-900/20">
        <CardContent className="p-4">
          <div className="flex items-center justify-between mb-1">
            <Label htmlFor="permitsCost" className="flex items-center gap-2 text-purple-300 font-semibold">
              <FileText size={16} className="text-purple-400" />
              Permits Cost ($)
            </Label>
            <span className="text-xs text-purple-300">Suggestion: ${props.suggestedPermitsCost.toFixed(2)}</span>
          </div>
          <Input
            id="permitsCost"
            type="number"
            value={props.permitsCost}
            onChange={handleNumericChange(props.setPermitsCost)}
            className="bg-purple-900/30 border-purple-700/50 text-purple-100 focus:border-purple-500 focus:ring-2 focus:ring-purple-500/30"
          />
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => props.setPermitsCost(props.suggestedPermitsCost)} 
            className="mt-2 text-xs bg-purple-800/30 border-purple-700/50 hover:bg-purple-700 text-purple-200 hover:text-purple-100 transition-all duration-200"
          >
            Use Suggested
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default ManualCostsTab;
