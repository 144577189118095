import React from "react";
import { toast } from "sonner";
import { Button } from "@/components/ui/button";
import { ToastContent } from "@/components/blog/ToastContent";

const API_ENDPOINT = 'https://svgsbaahxiaeljmfykzp.supabase.co/functions/v1/blog-webhook';
const API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InN2Z3NiYWFoeGlhZWxqbWZ5a3pwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDEyODc2ODksImV4cCI6MjA1Njg2MzY4OX0.S80GrL92vr2F-dwzWZqaz3Gt8RgttRi8ccC9y6sRQfI';
const FETCH_TIMEOUT = 30000;

/**
 * Submit blog post to the server
 */
export const submitBlogPost = async (
  parsedContent: any, 
  useAirtableProcessor: boolean,
  setSubmissionStatus: (status: 'idle' | 'error' | 'success' | 'warning') => void,
  setErrorDetails: (details: string) => void,
  setEdgeFunctionStatus: (status: 'unknown' | 'available' | 'unavailable') => void,
  setJsonContent?: (content: string) => void,
  retryCount?: number,
  handleSubmit?: () => Promise<void>
) => {
  const processorHeader = useAirtableProcessor ? 
    { 'X-Content-Processor': 'airtable' } : {};
  
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), FETCH_TIMEOUT);
  
  try {
    const response = await fetch(API_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${API_KEY}`,
        ...processorHeader
      },
      body: JSON.stringify(parsedContent),
      signal: controller.signal
    });
    
    clearTimeout(timeoutId);
    
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Server responded with status ${response.status}: ${errorText}`);
    }
    
    const result = await response.json();
    
    if (result.success) {
      setSubmissionStatus('success');
      toast.success("Blog post created successfully!");
      console.log("Blog post created:", result);
      if (setJsonContent) setJsonContent('');
      
      setEdgeFunctionStatus('available');
      
      if (result.post?.id) {
        toast.success(
          <ToastContent postId={result.post.id} />,
          {
            duration: 5000,
          }
        );
      }
    } else {
      setSubmissionStatus('error');
      setErrorDetails(result.error || "Unknown error from server");
      toast.error("Failed to create blog post: " + (result.error || "Unknown error"));
      console.error("Error creating blog post:", result);
    }
  } catch (fetchError) {
    clearTimeout(timeoutId);
    console.error("Fetch error:", fetchError);
    
    setEdgeFunctionStatus('unavailable');
    
    if (fetchError.name === 'AbortError') {
      setErrorDetails("Request timed out after 30 seconds. The server might be down or overloaded. You can try again shortly.");
      setSubmissionStatus('warning');
    } else {
      setErrorDetails(`Network error: ${fetchError.message}. The server might be unavailable.`);
      setSubmissionStatus('error');
    }
    
    toast.error("Failed to reach the server. Please try again later.");
    
    if (retryCount !== undefined && retryCount < 2 && handleSubmit) {
      toast.error(
        <div className="flex flex-col">
          <span>Connection failed. Would you like to retry?</span>
          <Button 
            variant="outline" 
            className="mt-2"
            onClick={() => {
              setTimeout(() => handleSubmit(), 1000);
            }}
          >
            Retry submission
          </Button>
        </div>,
        { duration: 10000 }
      );
    }
  }
};

/**
 * Check if the edge function is available
 */
export const checkEdgeFunctionStatus = async (
  setIsCheckingEdgeFunction: (isChecking: boolean) => void,
  setEdgeFunctionStatus: (status: 'unknown' | 'available' | 'unavailable') => void
) => {
  setIsCheckingEdgeFunction(true);
  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 5000);
    
    const response = await fetch(API_ENDPOINT, {
      method: 'OPTIONS',
      headers: {
        'Authorization': `Bearer ${API_KEY}`
      },
      signal: controller.signal
    });
    
    clearTimeout(timeoutId);
    
    if (response.ok) {
      setEdgeFunctionStatus('available');
      console.log('Edge function is available');
    } else {
      setEdgeFunctionStatus('unavailable');
      console.log('Edge function returned non-OK status:', response.status);
    }
  } catch (error) {
    console.error('Error checking edge function status:', error);
    setEdgeFunctionStatus('unavailable');
  } finally {
    setIsCheckingEdgeFunction(false);
  }
};
