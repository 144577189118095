
import React from "react";
import { MapPin, Home, Building, Trees, Landmark, ChevronDown, ChevronUp } from "lucide-react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

interface CommunityCardProps {
  city: string;
  isActive: boolean;
  isExpanded: boolean;
  onToggleExpand: () => void;
  onSelect: () => void;
}

const CommunityCard = ({ 
  city, 
  isActive, 
  isExpanded, 
  onToggleExpand, 
  onSelect 
}: CommunityCardProps) => {
  return (
    <div 
      className={cn(
        "bg-card border rounded-lg hover:shadow-md transition-shadow p-6",
        isActive ? "border-gold-500 bg-gold-50" : ""
      )}
    >
      <div className="flex items-start">
        <MapPin 
          className={cn(
            "w-5 h-5 mt-1 mr-3 flex-shrink-0", 
            isActive ? "text-gold-500" : "text-primary"
          )} 
        />
        <div className="flex-1">
          <div className="flex justify-between items-center">
            <h3 className={cn("font-medium text-xl mb-2", isActive ? "text-gold-700" : "")}>{city}</h3>
            <button 
              onClick={onToggleExpand}
              className="text-muted-foreground hover:text-primary"
              aria-expanded={isExpanded}
              aria-label={isExpanded ? "Collapse details" : "Expand details"}
            >
              {isExpanded ? 
                <ChevronUp className="h-5 w-5" /> : 
                <ChevronDown className="h-5 w-5" />
              }
            </button>
          </div>
          
          {!isExpanded ? (
            <p className="text-muted-foreground text-sm">
              Discover properties and real estate opportunities in {city}. Contact Juan Elizondo for personalized assistance.
            </p>
          ) : (
            <div className="mt-2 space-y-3">
              <p className="text-muted-foreground text-sm">
                {city} offers unique charm and living opportunities in the Rio Grande Valley. From local amenities to investment potential, Juan Elizondo can guide you through all this community has to offer.
              </p>
              
              <div className="flex flex-wrap gap-2 my-3">
                <span className="bg-secondary text-secondary-foreground text-xs py-1 px-2 rounded-full flex items-center">
                  <Home className="w-3 h-3 mr-1" /> Residential
                </span>
                <span className="bg-secondary text-secondary-foreground text-xs py-1 px-2 rounded-full flex items-center">
                  <Building className="w-3 h-3 mr-1" /> Commercial
                </span>
                <span className="bg-secondary text-secondary-foreground text-xs py-1 px-2 rounded-full flex items-center">
                  <Landmark className="w-3 h-3 mr-1" /> Investment
                </span>
                <span className="bg-secondary text-secondary-foreground text-xs py-1 px-2 rounded-full flex items-center">
                  <Trees className="w-3 h-3 mr-1" /> Land
                </span>
              </div>
              
              <Button 
                variant="outline" 
                className="w-full mt-3 border-gold-500 text-gold-500 hover:bg-gold-500 hover:text-white transition-colors"
                onClick={onSelect}
              >
                Explore {city} Properties
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommunityCard;
