
import React, { useState, useEffect } from "react";
import { useLanguage } from "@/context/LanguageContext";
import SEOImage from "@/components/SEOImage";

const ContactHero = () => {
  const { t } = useLanguage();
  const [imageLoaded, setImageLoaded] = useState(false);
  
  // Force image to load and track loading state
  useEffect(() => {
    const preloadImage = new Image();
    preloadImage.src = "/lovable-uploads/b9842edf-d648-42e5-9c26-0e02ca6ce7b4.png";
    preloadImage.onload = () => {
      console.log("Contact hero image preloaded successfully");
      setImageLoaded(true);
    };
    preloadImage.onerror = (e) => {
      console.error("Failed to load contact hero image:", e);
    };
  }, []);
  
  return (
    <section className="relative py-20 md:py-32 overflow-hidden">
      {/* Background color as fallback if image fails to load */}
      <div className="absolute inset-0 bg-estate-800 z-0"></div>
      
      {/* No overlay for maximum image visibility */}
      
      {/* Background image with direct explicit path */}
      <div className="absolute inset-0 z-5">
        <img 
          src="/lovable-uploads/b9842edf-d648-42e5-9c26-0e02ca6ce7b4.png"
          alt="Professional handshake with digital contact form on tablet"
          className="w-full h-full object-cover"
          onLoad={() => {
            console.log("Contact hero image loaded via img tag");
            setImageLoaded(true);
          }}
          onError={(e) => {
            console.error("Failed to load contact hero image via img tag:", e);
          }}
        />
      </div>
      
      {/* Content - with improved text visibility */}
      <div className="container relative z-20 mx-auto px-4 max-w-6xl text-center">
        <h1 className="text-5xl md:text-7xl font-display mb-6 animate-fadeIn text-white drop-shadow-lg">{t('contact.hero.title')}</h1>
        <p className="text-xl text-white max-w-2xl mx-auto mb-10 animate-fadeIn drop-shadow-lg">
          {t('contact.hero.subtitle')}
        </p>
      </div>
    </section>
  );
};

export default ContactHero;
