
import { useLanguage } from "@/context/LanguageContext";

const TabServices = () => {
  const { language } = useLanguage();

  return (
    <div className="text-white space-y-6">
      <h3 className="text-2xl font-semibold text-white mb-6">
        Apoyo Integral para Inversionistas Mexicanos
      </h3>
      <p className="text-xl text-white">
        Comprar propiedades en EE. UU. puede ser complejo para inversionistas mexicanos, particularmente debido a diferencias en sistemas legales, implicaciones fiscales y marcos regulatorios. Juan ofrece un conjunto completo de servicios para abordar estos desafíos:
      </p>
      <ul className="list-disc pl-8 space-y-4 text-xl text-white">
        <li><strong>Búsqueda y selección de propiedades:</strong> Ayuda a identificar propiedades que se alineen con los objetivos de inversión, ya sea para ingresos por alquiler, uso vacacional o visitas personales.</li>
        <li><strong>Negociación y manejo de contratos:</strong> Negocia en nombre de los clientes para asegurar términos favorables, garantizando que los contratos sean claros y cumplan con las leyes de EE. UU.</li>
        <li><strong>Cumplimiento legal y regulatorio:</strong> Guía a los clientes a través de las complejidades de las leyes de bienes raíces de EE. UU.</li>
        <li><strong>Asistencia de financiamiento:</strong> Conecta a los clientes con prestamistas experimentados en trabajar con inversionistas internacionales.</li>
        <li><strong>Soporte post-compra:</strong> Permanece disponible para responder preguntas, asistir con la gestión de propiedades o abordar problemas como mantenimiento o reventa.</li>
      </ul>
    </div>
  );
};

export default TabServices;
