
import { useState } from "react";
import LoanAmountInput from "./inputs/LoanAmountInput";
import InterestRateInput from "./inputs/InterestRateInput";
import LoanTermSelector from "./inputs/LoanTermSelector";
import ResetButton from "./inputs/ResetButton";

interface MortgageFormProps {
  loanAmount: number;
  interestRate: number;
  loanTerm: number;
  onLoanAmountChange: (value: number) => void;
  onInterestRateChange: (value: number) => void;
  onLoanTermChange: (value: number) => void;
}

const MortgageForm = ({
  loanAmount,
  interestRate,
  loanTerm,
  onLoanAmountChange,
  onInterestRateChange,
  onLoanTermChange,
}: MortgageFormProps) => {
  
  // Reset to default values
  const handleReset = () => {
    onLoanAmountChange(300000);
    onInterestRateChange(4.5);
    onLoanTermChange(30);
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-md">
      <div className="space-y-6">
        {/* Loan Amount Input */}
        <LoanAmountInput 
          loanAmount={loanAmount} 
          onLoanAmountChange={onLoanAmountChange} 
        />

        {/* Interest Rate Input */}
        <InterestRateInput 
          interestRate={interestRate} 
          onInterestRateChange={onInterestRateChange} 
        />

        {/* Loan Term Selector */}
        <LoanTermSelector 
          loanTerm={loanTerm} 
          onLoanTermChange={onLoanTermChange} 
        />

        {/* Reset Button */}
        <ResetButton onReset={handleReset} />
      </div>
    </div>
  );
};

export default MortgageForm;
