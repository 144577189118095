
import { useState, useEffect } from "react";
import { Slider } from "@/components/ui/slider";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Calculator, HelpCircle, DollarSign, Percent } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { 
  CREDIT_SCORE_RANGES, 
  LOAN_TERMS, 
  STATES, 
  LOAN_AMOUNT_RANGES,
  MORTGAGE_PROGRAMS,
  LOAN_STRUCTURES,
  CURRENT_MARKET_RATES,
  LoanType,
  CreditScoreValue,
  MortgageProgramValue,
  LoanStructureValue 
} from "./constants";
import { 
  formatCurrency, 
  getCurrentInterestRate, 
  getDownPaymentRequirement,
  getLoanProgramDescription,
  getLoanStructureDescription
} from "./utils";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

interface LoanFormProps {
  loanType: LoanType;
  loanAmount: number;
  loanTerm: string;
  state: string;
  creditScore: CreditScoreValue;
  comparisonScore: CreditScoreValue;
  onLoanAmountChange: (value: number) => void;
  onLoanTermChange: (value: string) => void;
  onStateChange: (value: string) => void;
  onCreditScoreChange: (value: CreditScoreValue) => void;
  onComparisonScoreChange: (value: CreditScoreValue) => void;
  onCalculate: () => void;
  mortgageProgram?: MortgageProgramValue;
  loanStructure?: LoanStructureValue;
  onMortgageProgramChange?: (value: MortgageProgramValue) => void;
  onLoanStructureChange?: (value: LoanStructureValue) => void;
}

const LoanForm = ({ 
  loanType, 
  loanAmount, 
  loanTerm, 
  state, 
  creditScore, 
  comparisonScore,
  onLoanAmountChange,
  onLoanTermChange,
  onStateChange,
  onCreditScoreChange,
  onComparisonScoreChange,
  onCalculate,
  mortgageProgram = "conventional",
  loanStructure = "fixed",
  onMortgageProgramChange,
  onLoanStructureChange
}: LoanFormProps) => {
  const { toast } = useToast();
  const [isHovered, setIsHovered] = useState(false);
  const [showMortgageOptions, setShowMortgageOptions] = useState(false);
  const [downPaymentPercent, setDownPaymentPercent] = useState(20);
  const [downPaymentAmount, setDownPaymentAmount] = useState(loanAmount * 0.2);
  const [currentRate, setCurrentRate] = useState<number | null>(null);
  
  // Calculate down payment when loan amount changes
  useEffect(() => {
    if (loanType === "mortgage" && mortgageProgram) {
      const minPercent = getDownPaymentRequirement(mortgageProgram) * 100;
      if (downPaymentPercent < minPercent) {
        setDownPaymentPercent(minPercent);
      }
      setDownPaymentAmount((downPaymentPercent / 100) * loanAmount);
    }
  }, [loanAmount, downPaymentPercent, loanType, mortgageProgram]);
  
  // Update current rate when relevant parameters change
  useEffect(() => {
    if (loanType === "mortgage" && mortgageProgram && loanStructure && loanTerm) {
      try {
        const rate = getCurrentInterestRate(mortgageProgram, loanStructure, loanTerm);
        setCurrentRate(rate);
      } catch (error) {
        setCurrentRate(null);
        console.error("Error getting current rate:", error);
      }
    }
  }, [loanType, mortgageProgram, loanStructure, loanTerm]);
  
  // Handle down payment percent change
  const handleDownPaymentPercentChange = (value: number[]) => {
    const percent = value[0];
    setDownPaymentPercent(percent);
    setDownPaymentAmount((percent / 100) * loanAmount);
  };
  
  // Handle down payment amount change
  const handleDownPaymentAmountChange = (amount: number) => {
    setDownPaymentAmount(amount);
    setDownPaymentPercent((amount / loanAmount) * 100);
  };
  
  // Toggle mortgage options
  const toggleMortgageOptions = () => {
    setShowMortgageOptions(!showMortgageOptions);
  };
  
  const handleCalculate = () => {
    if (!creditScore || !comparisonScore || creditScore === comparisonScore) {
      toast({
        title: "Please select different credit score ranges",
        description: "The comparison credit score needs to be different from your current credit score.",
        variant: "destructive",
      });
      return;
    }
    
    onCalculate();
  };

  return (
    <div className="space-y-6">
      {/* Loan amount */}
      <div className="space-y-2">
        <div className="flex justify-between">
          <Label htmlFor="loanAmount" className="text-base font-medium">
            Loan Amount: {formatCurrency(loanAmount)}
          </Label>
        </div>
        <Slider 
          id="loanAmount"
          value={[loanAmount]} 
          min={LOAN_AMOUNT_RANGES[loanType].min} 
          max={LOAN_AMOUNT_RANGES[loanType].max} 
          step={LOAN_AMOUNT_RANGES[loanType].step} 
          onValueChange={(value) => onLoanAmountChange(value[0])}
          className="py-4"
        />
        <div className="flex justify-between text-xs text-gray-500">
          <span>{formatCurrency(LOAN_AMOUNT_RANGES[loanType].min)}</span>
          <span>{formatCurrency(LOAN_AMOUNT_RANGES[loanType].max)}</span>
        </div>
      </div>

      {/* Mortgage-specific options */}
      {loanType === "mortgage" && (
        <>
          {/* Mortgage program selection */}
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <Label htmlFor="mortgageProgram" className="text-base font-medium">
                Loan Program
              </Label>
              
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button variant="ghost" size="icon" className="h-6 w-6">
                      <HelpCircle className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="max-w-xs">
                    <p>Select a loan program based on your eligibility. Different programs have different requirements and benefits.</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            
            <Select 
              value={mortgageProgram} 
              onValueChange={(value) => onMortgageProgramChange?.(value as MortgageProgramValue)}
            >
              <SelectTrigger className="w-full" id="mortgageProgram">
                <SelectValue placeholder="Select loan program" />
              </SelectTrigger>
              <SelectContent>
                {MORTGAGE_PROGRAMS.map((program) => (
                  <SelectItem key={program.value} value={program.value}>
                    <div>
                      <div className="font-medium">{program.label}</div>
                      <div className="text-xs text-gray-500">{program.description}</div>
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            
            {mortgageProgram && (
              <div className="text-sm text-gray-600 mt-1">
                {getLoanProgramDescription(mortgageProgram as MortgageProgramValue)}
              </div>
            )}
          </div>
          
          {/* Loan structure selection */}
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <Label htmlFor="loanStructure" className="text-base font-medium">
                Rate Type
              </Label>
              
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button variant="ghost" size="icon" className="h-6 w-6">
                      <HelpCircle className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="max-w-xs">
                    <p>Fixed rates remain the same throughout the loan term. ARMs (Adjustable Rate Mortgages) have a fixed rate for an initial period, then adjust periodically.</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            
            <Select 
              value={loanStructure} 
              onValueChange={(value) => onLoanStructureChange?.(value as LoanStructureValue)}
            >
              <SelectTrigger className="w-full" id="loanStructure">
                <SelectValue placeholder="Select rate type" />
              </SelectTrigger>
              <SelectContent>
                {LOAN_STRUCTURES.map((structure) => (
                  <SelectItem key={structure.value} value={structure.value}>
                    {structure.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            
            {loanStructure && (
              <div className="text-sm text-gray-600 mt-1">
                {getLoanStructureDescription(loanStructure as LoanStructureValue)}
              </div>
            )}
          </div>
          
          {/* Down payment slider */}
          <div className="space-y-2">
            <div className="flex justify-between">
              <Label htmlFor="downPayment" className="text-base font-medium">
                Down Payment: {downPaymentPercent.toFixed(1)}% ({formatCurrency(downPaymentAmount)})
              </Label>
            </div>
            <Slider 
              id="downPayment"
              value={[downPaymentPercent]} 
              min={mortgageProgram ? getDownPaymentRequirement(mortgageProgram as MortgageProgramValue) * 100 : 3} 
              max={50} 
              step={0.5} 
              onValueChange={handleDownPaymentPercentChange}
              className="py-4"
            />
            <div className="flex justify-between text-xs text-gray-500">
              <span>{(mortgageProgram ? getDownPaymentRequirement(mortgageProgram as MortgageProgramValue) * 100 : 3).toFixed(1)}%</span>
              <span>50%</span>
            </div>
          </div>
          
          {/* Current rate display */}
          {currentRate !== null && (
            <div className="bg-blue-50 dark:bg-blue-900/30 p-3 rounded-md mt-2">
              <div className="flex items-center justify-between">
                <span className="text-sm">Current {loanStructure === "fixed" ? `${loanTerm}-year fixed` : loanStructure} rate:</span>
                <span className="font-semibold">{currentRate.toFixed(2)}%</span>
              </div>
              <div className="text-xs text-gray-500 mt-1">
                Source: Freddie Mac • Last updated: {CURRENT_MARKET_RATES.date}
              </div>
            </div>
          )}
        </>
      )}

      {/* Loan term */}
      <div className="space-y-2">
        <Label htmlFor="loanTerm" className="text-base font-medium">
          Loan Term
        </Label>
        <Select value={loanTerm} onValueChange={onLoanTermChange}>
          <SelectTrigger className="w-full" id="loanTerm">
            <SelectValue placeholder="Select term" />
          </SelectTrigger>
          <SelectContent>
            {LOAN_TERMS[loanType].map((term) => (
              <SelectItem key={term.value} value={term.value}>
                {term.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {/* State selection (only for mortgage) */}
      {loanType === "mortgage" && (
        <div className="space-y-2">
          <Label htmlFor="state" className="text-base font-medium">
            State
          </Label>
          <Select value={state} onValueChange={onStateChange}>
            <SelectTrigger className="w-full" id="state">
              <SelectValue placeholder="Select state" />
            </SelectTrigger>
            <SelectContent>
              {STATES.map((stateName) => (
                <SelectItem key={stateName} value={stateName}>
                  {stateName}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}

      {/* Credit score range */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <Label htmlFor="creditScore" className="text-base font-medium">
            Your Credit Score
          </Label>
          <Select value={creditScore} onValueChange={(value) => onCreditScoreChange(value as CreditScoreValue)}>
            <SelectTrigger className="w-full" id="creditScore">
              <SelectValue placeholder="Select credit score range" />
            </SelectTrigger>
            <SelectContent>
              {CREDIT_SCORE_RANGES.map((range) => (
                <SelectItem key={range.value} value={range.value}>
                  {range.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-2">
          <Label htmlFor="comparisonScore" className="text-base font-medium">
            Compare With Score
          </Label>
          <Select value={comparisonScore} onValueChange={(value) => onComparisonScoreChange(value as CreditScoreValue)}>
            <SelectTrigger className="w-full" id="comparisonScore">
              <SelectValue placeholder="Select comparison score range" />
            </SelectTrigger>
            <SelectContent>
              {CREDIT_SCORE_RANGES.map((range) => (
                <SelectItem key={range.value} value={range.value}>
                  {range.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="relative overflow-hidden">
        <Button 
          onClick={handleCalculate} 
          className="w-full bg-[#FEF700] hover:bg-[#FEF700] text-black font-medium py-3 px-4 rounded-lg relative z-10 transition-transform duration-300"
          size="lg"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            transform: isHovered ? "translateY(-3px)" : "translateY(0)",
            boxShadow: isHovered 
              ? '0 0 20px 8px rgba(59, 130, 246, 0.6), 0 0 30px rgba(59, 130, 246, 0.4)' 
              : '0 0 12px 5px rgba(59, 130, 246, 0.35)'
          }}
        >
          <Calculator className="mr-2 h-5 w-5 text-black" /> 
          Calculate Savings
        </Button>
        
        {/* Blue glow effect on hover - only at the bottom */}
        {isHovered && (
          <div className="absolute inset-0 z-0 pointer-events-none">
            <div className="absolute bottom-0 left-0 right-0 h-6 bg-[#3b82f6] blur-xl opacity-60 transform translate-y-2"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoanForm;
