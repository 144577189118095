import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

type Language = 'en' | 'es';

interface LanguageContextType {
  language: Language;
  setLanguage: (language: Language) => void;
  t: (key: string) => string;
}

const translations = {
  en: {
    // Navigation
    'nav.home': 'Home',
    'nav.buy': 'Buy',
    'nav.sell': 'Sell',
    'nav.residential': 'Residential',
    'nav.commercial': 'Commercial',
    'nav.mexican-investors': 'Paisanos',
    'nav.calculator': 'Calculator',
    'nav.about': 'About',
    'nav.blog': 'Blog',
    'nav.contact': 'Contact',
    'nav.home-estimate': 'Home Estimates',
    
    // Home page
    'hero.title': 'Juan Elizondo - Trusted Real Estate Expert',
    'hero.subtitle': 'Discover exceptional properties and sustainable living spaces designed for modern lifestyles.',
    'search.placeholder': 'Search properties by location...',
    
    // Common sections
    'properties.discover': 'Discover',
    'properties.discover.subtitle': 'Explore our exclusive listings in McAllen, Sharyland, Hidalgo, and beyond. Luxury homes and commercial properties for your lifestyle or investment needs.',
    
    // About section
    'about.title': 'About Juan Elizondo',
    'about.subtitle': 'Your Trusted Real Estate Partner in McAllen, TX',
    'about.meet': 'Meet Juan Elizondo',
    'about.bio.1': 'Juan Elizondo is a premier commercial and residential real estate agent based in McAllen, TX, serving clients throughout the Rio Grande Valley with exceptional service and deep local knowledge.',
    'about.bio.2': 'With over a decade of experience in the real estate industry, Juan has established himself as a trusted advisor in the real estate community. He holds multiple certifications including the Certified Residential Specialist (CRS) designation and has consistently ranked among the top 5% of real estate professionals in the McAllen area.',
    'about.bio.3': 'Juan\'s approach to real estate is client-centered, focusing on building long-term relationships rather than quick transactions. His commitment extends beyond property deals to the local community, where he actively participates in neighborhood improvement initiatives and contributes to local charities including the McAllen Food Bank and habitat restoration projects.',
    'about.contact': 'Contact Me',
    'about.testimonials.title': 'What Clients Say',
    'about.work': 'Work With Juan',
    
    // Vision section
    'vision.title': 'Our Vision',
    'vision.subtitle': 'Our mission is to create innovative, sustainable communities that set new standards.',
    'vision.elevated': 'Elevated Living Spaces',
    'vision.elevated.desc': 'Creating exceptional living environments that blend luxury with functionality.',
    'vision.sustainable': 'Sustainable Communities',
    'vision.sustainable.desc': 'Building eco-conscious developments that respect and enhance their surroundings.',
    'vision.expert': 'Expert Guidance',
    'vision.expert.desc': 'Providing professional expertise and personalized service at every step.',
    
    // Testimonials
    'testimonials.title': 'Testimonials',
    
    // Contact section
    'contact.ready': 'Ready to Find Your Dream Property?',
    'contact.ready.subtitle': 'Connect with Juan Elizondo today to discuss your real estate needs and discover exceptional properties tailored to your preferences.',
    'contact.form.title': 'Send a Message',
    'contact.form.name': 'Your Name',
    'contact.form.email': 'Email Address',
    'contact.form.subject': 'Subject',
    'contact.form.message': 'Message',
    'contact.form.placeholder.name': 'John Doe',
    'contact.form.placeholder.email': 'john@example.com',
    'contact.form.placeholder.subject': 'Property Inquiry',
    'contact.form.placeholder.message': 'Tell us about your real estate needs...',
    'contact.form.send': 'Send Message',
    'contact.toast.success': 'Message sent!',
    'contact.toast.success.desc': 'We\'ll get back to you within 24 hours.',
    
    // Footer
    'footer.company': 'Elite Real Estate',
    'footer.tagline': 'Elevating the standard of luxury real estate services.',
    'footer.quicklinks': 'Quick Links',
    'footer.properties': 'Properties',
    'footer.services': 'Services',
    'footer.contact': 'Contact Info',
    'footer.follow': 'Follow Us',
    'footer.copyright': '© {year} Elite Real Estate. All rights reserved.',
    
    // Buy page
    'buy.title': 'Buying a Property',
    'buy.subtitle': 'Your guide to purchasing in McAllen, TX',
    'buy.intro': 'Buying a property can be an exciting yet complex process. From finding the right home to navigating negotiations and closing, there are many steps involved. As an experienced real estate agent, I can help simplify the process and ensure you make informed decisions every step of the way.',
    'buy.find': 'Find Your Perfect Property',
    'buy.tips': 'Tips for Buyers',
    'buy.contact': 'Contact Me to Start Your Buying Journey',
    
    // Sell page
    'sell.title': 'Selling a Property',
    'sell.subtitle': 'Maximize your property\'s value with expert guidance',
    'sell.intro': 'Selling a property requires careful planning and execution to achieve the best possible outcome. From pricing your property correctly to marketing it effectively and handling negotiations, there are many aspects to consider. As your real estate agent, I will handle all these steps to ensure a smooth and successful sale.',
    'sell.ready': 'Ready to Sell? Let\'s Get Started',
    'sell.tips': 'Tips for Sellers',
    'sell.testimonials': 'What Our Sellers Say',
    'sell.contact': 'Contact Me to Sell Your Property',
    
    // Residential page
    'residential.title': 'Residential Properties',
    'residential.subtitle': 'Find Your Dream Home in McAllen, TX',
    'residential.intro': 'As a dedicated real estate agent with a passion for helping families find their perfect home, I bring a wealth of experience and knowledge to the residential market in McAllen, TX. Whether you\'re looking for a cozy starter home or a luxurious estate, I am committed to finding the right property that fits your lifestyle and needs.',
    'residential.featured': 'Featured Properties',
    'residential.view.all': 'View All Properties',
    
    // Commercial page
    'commercial.title': 'Commercial Properties',
    'commercial.subtitle': 'Explore McAllen\'s Premier Commercial Spaces',
    'commercial.intro': 'As a seasoned real estate agent specializing in both commercial and residential properties, I bring a wealth of knowledge and experience to help businesses find their ideal commercial space in McAllen, TX. From office buildings to warehouses, I am committed to finding the perfect fit for your needs.',
    'commercial.featured': 'Featured Properties',
    'commercial.view.all': 'View All Properties',
    
    // Contact page
    'contact.hero.title': 'Let\'s Connect',
    'contact.hero.subtitle': 'Ready to find your dream property or discuss real estate opportunities? Get in touch with Juan Elizondo today.',
    
    // Coming soon toast
    'toast.coming.soon': 'Coming Soon!',
    'toast.coming.soon.desc': 'Our full property listing page is under development.',
    
    // Toggle language
    'language.toggle': 'Español',
  },
  es: {
    // Navigation
    'nav.home': 'Inicio',
    'nav.buy': 'Comprar',
    'nav.sell': 'Vender',
    'nav.residential': 'Residencial',
    'nav.commercial': 'Comercial',
    'nav.mexican-investors': 'Paisanos',
    'nav.calculator': 'Calculadora',
    'nav.about': 'Acerca de',
    'nav.blog': 'Blog',
    'nav.contact': 'Contacto',
    'nav.home-estimate': 'Valor de Casa',
    
    // Home page
    'hero.title': 'Juan Elizondo - Experto en Bienes Raíces de Confianza',
    'hero.subtitle': 'Descubre propiedades excepcionales y espacios habitables sostenibles diseñados para estilos de vida modernos.',
    'search.placeholder': 'Buscar propiedades por ubicación...',
    
    // Common sections
    'properties.discover': 'Descubre',
    'properties.discover.subtitle': 'Explora nuestras propiedades exclusivas en McAllen, Sharyland, Hidalgo y más. Casas de lujo y propiedades comerciales para tu estilo de vida o necesidades de inversión.',
    
    // About section
    'about.title': 'Acerca de Juan Elizondo',
    'about.subtitle': 'Tu Socio de Confianza en Bienes Raíces en McAllen, TX',
    'about.meet': 'Conoce a Juan Elizondo',
    'about.bio.1': 'Juan Elizondo es un agente principal de bienes raíces comerciales y residenciales con sede en McAllen, TX, que atiende a clientes en todo el Valle del Río Grande con un servicio excepcional y un profundo conocimiento local.',
    'about.bio.2': 'Con más de una década de experiencia en la industria de bienes raíces, Juan se ha establecido como un asesor de confianza en la comunidad inmobiliaria. Tiene múltiples certificaciones, incluida la designación de Especialista Residencial Certificado (CRS) y ha sido clasificado constantemente entre el 5% superior de los profesionales inmobiliarios en el área de McAllen.',
    'about.bio.3': 'El enfoque de Juan hacia los bienes raíces está centrado en el cliente, centrándose en construir relaciones a largo plazo en lugar de transacciones rápidas. Su compromiso se extiende más allá de las transacciones de propiedades a la comunidad local, donde participa activamente en iniciativas de mejora del vecindario y contribuye a organizaciones benéficas locales, incluido el Banco de Alimentos de McAllen y proyectos de restauración de hábitats.',
    'about.contact': 'Contáctame',
    'about.testimonials.title': 'Lo que Dicen los Clientes',
    'about.work': 'Trabaja con Juan',
    
    // Vision section
    'vision.title': 'Nuestra Visión',
    'vision.subtitle': 'Nuestra misión es crear comunidades innovadoras y sostenibles que establezcan nuevos estándares.',
    'vision.elevated': 'Espacios Habitacionales Elevados',
    'vision.elevated.desc': 'Creando entornos de vida excepcionales que combinan lujo con funcionalidad.',
    'vision.sustainable': 'Comunidades Sostenibles',
    'vision.sustainable.desc': 'Construyendo desarrollos eco-conscientes que respetan y mejoran su entorno.',
    'vision.expert': 'Guía Experta',
    'vision.expert.desc': 'Proporcionando experiencia profesional y servicio personalizado en cada paso.',
    
    // Testimonials
    'testimonials.title': 'Testimonios',
    
    // Contact section
    'contact.ready': '¿Listo para Encontrar tu Propiedad Soñada?',
    'contact.ready.subtitle': 'Conéctate con Juan Elizondo hoy para discutir tus necesidades inmobiliarias y descubrir propiedades excepcionales adaptadas a tus preferencias.',
    'contact.form.title': 'Enviar un Mensaje',
    'contact.form.name': 'Tu Nombre',
    'contact.form.email': 'Correo Electrónico',
    'contact.form.subject': 'Asunto',
    'contact.form.message': 'Mensaje',
    'contact.form.placeholder.name': 'Juan Pérez',
    'contact.form.placeholder.email': 'juan@ejemplo.com',
    'contact.form.placeholder.subject': 'Consulta sobre Propiedad',
    'contact.form.placeholder.message': 'Cuéntanos sobre tus necesidades inmobiliarias...',
    'contact.form.send': 'Enviar Mensaje',
    'contact.toast.success': '¡Mensaje enviado!',
    'contact.toast.success.desc': 'Nos pondremos en contacto contigo dentro de 24 horas.',
    
    // Footer
    'footer.company': 'Elite Real Estate',
    'footer.tagline': 'Elevando el estándar de servicios inmobiliarios de lujo.',
    'footer.quicklinks': 'Enlaces Rápidos',
    'footer.properties': 'Propiedades',
    'footer.services': 'Servicios',
    'footer.contact': 'Información de Contacto',
    'footer.follow': 'Síguenos',
    'footer.copyright': '© {year} Elite Real Estate. Todos los derechos reservados.',
    
    // Buy page
    'buy.title': 'Comprando una Propiedad',
    'buy.subtitle': 'Tu guía para comprar en McAllen, TX',
    'buy.intro': 'Comprar una propiedad puede ser un proceso emocionante pero complejo. Desde encontrar la casa adecuada hasta navegar por las negociaciones y el cierre, hay muchos pasos involucrados. Como agente inmobiliario experimentado, puedo ayudarte a simplificar el proceso y asegurar que tomes decisiones informadas en cada paso del camino.',
    'buy.find': 'Encuentra Tu Propiedad Perfecta',
    'buy.tips': 'Consejos para Compradores',
    'buy.contact': 'Contáctame para Comenzar Tu Viaje de Compra',
    
    // Sell page
    'sell.title': 'Vendiendo una Propiedad',
    'sell.subtitle': 'Maximiza el valor de tu propiedad con guía experta',
    'sell.intro': 'Vender una propiedad requiere una planificación y ejecución cuidadosas para lograr el mejor resultado posible. Desde fijar el precio de tu propiedad correctamente hasta comercializarla de manera efectiva y manejar las negociaciones, hay muchos aspectos a considerar. Como tu agente inmobiliario, manejaré todos estos pasos para garantizar una venta suave y exitosa.',
    'sell.ready': '¿Listo para Vender? Comencemos',
    'sell.tips': 'Consejos para Vendedores',
    'sell.testimonials': 'Lo que Dicen Nuestros Vendedores',
    'sell.contact': 'Contáctame para Vender Tu Propiedad',
    
    // Residential page
    'residential.title': 'Propiedades Residenciales',
    'residential.subtitle': 'Encuentra Tu Casa Soñada en McAllen, TX',
    'residential.intro': 'Como agente inmobiliario dedicado con pasión por ayudar a las familias a encontrar su hogar perfecto, aporto una gran experiencia y conocimiento al mercado residencial en McAllen, TX. Ya sea que estés buscando una acogedora casa inicial o una lujosa residencia, estoy comprometido a encontrar la propiedad adecuada que se ajuste a tu estilo de vida y necesidades.',
    'residential.featured': 'Propiedades Destacadas',
    'residential.view.all': 'Ver Todas las Propiedades',
    
    // Commercial page
    'commercial.title': 'Propiedades Comerciales',
    'commercial.subtitle': 'Explora los Principales Espacios Comerciales de McAllen',
    'commercial.intro': 'Como agente inmobiliario experimentado especializado tanto en propiedades comerciales como residenciales, aporto una gran cantidad de conocimiento y experiencia para ayudar a las empresas a encontrar su espacio comercial ideal en McAllen, TX. Desde edificios de oficinas hasta almacenes, estoy comprometido a encontrar el ajuste perfecto para tus necesidades.',
    'commercial.featured': 'Propiedades Destacadas',
    'commercial.view.all': 'Ver Todas las Propiedades',
    
    // Contact page
    'contact.hero.title': 'Conectémonos',
    'contact.hero.subtitle': '¿Listo para encontrar tu propiedad soñada o discutir oportunidades inmobiliarias? Ponte en contacto con Juan Elizondo hoy.',
    
    // Coming soon toast
    'toast.coming.soon': '¡Próximamente!',
    'toast.coming.soon.desc': 'Nuestra página completa de listado de propiedades está en desarrollo.',
    
    // Toggle language
    'language.toggle': 'English',
  }
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  // Use localStorage to persist language preference
  const [language, setLanguage] = useState<Language>(() => {
    // Read from localStorage or default to 'en'
    const savedLanguage = localStorage.getItem('preferredLanguage');
    return (savedLanguage === 'en' || savedLanguage === 'es') ? savedLanguage : 'en';
  });

  // Update localStorage when language changes
  useEffect(() => {
    localStorage.setItem('preferredLanguage', language);
  }, [language]);

  const t = (key: string): string => {
    const translation = translations[language][key as keyof typeof translations[typeof language]];
    
    // Handle year replacement in copyright text
    if (key === 'footer.copyright') {
      return (translation as string).replace('{year}', new Date().getFullYear().toString());
    }
    
    return translation || key;
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
