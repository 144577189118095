
import React from "react";
import ParallaxHero from "./hero/ParallaxHero";
import PropertySearchButton from "./common/PropertySearchButton";
import ChatBot from "./common/ChatBot";

const Hero = () => {
  return (
    <>
      <ParallaxHero />
      <PropertySearchButton />
      <ChatBot />
    </>
  );
};

export default Hero;
