
import { useEffect } from "react";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import MortgageCalculator from "@/components/mortgage/MortgageCalculator";
import LoanSavingsCalculator from "@/components/mortgage/LoanSavingsCalculator";

const MortgageCalculatorPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-background">
      <Navbar />
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl md:text-5xl font-display text-center mb-8">Mortgage Tools</h1>
        <p className="text-lg text-center mb-12 max-w-3xl mx-auto">
          Use our mortgage calculators to estimate your monthly payments and understand how your credit score affects your loan costs.
        </p>
        
        <div className="space-y-16">
          <section>
            <h2 className="text-3xl font-display text-center mb-8">Monthly Payment Calculator</h2>
            <MortgageCalculator />
          </section>
          
          <section>
            <h2 className="text-3xl font-display text-center mb-8">Credit Score Savings Calculator</h2>
            <p className="text-lg text-center mb-8 max-w-3xl mx-auto">
              See how improving your credit score can save you money on your loan. Compare different credit score ranges to see potential savings.
            </p>
            <LoanSavingsCalculator />
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MortgageCalculatorPage;
