
import { toast } from "sonner";
import { VideoInfo, MediaType, VideoSource } from "./types";

/**
 * Extracts the YouTube video ID from a URL
 */
export const extractYouTubeVideoId = (url: string): string | null => {
  if (!url) return null;
  
  // Handle different YouTube URL formats
  // Regular youtube.com URLs
  let match = url.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/);
  if (match && match[1]) {
    return match[1];
  }
  
  // Short youtu.be URLs
  match = url.match(/youtu\.be\/([^"&?\/\s]{11})/);
  if (match && match[1]) {
    return match[1];
  }
  
  return null;
};

/**
 * Attempts to load a video from a list of possible paths
 */
export const findValidVideo = async (
  videoPaths: string[], 
  onVideoFound: (path: string) => void,
  onFallback: () => void
) => {
  let videoFound = false;
  
  for (const path of videoPaths) {
    console.log(`Trying to load video from: ${path}`);
    
    // Check if it's a YouTube URL
    const youtubeId = extractYouTubeVideoId(path);
    if (youtubeId) {
      console.log(`✅ Valid YouTube video found: ${path} with ID: ${youtubeId}`);
      onVideoFound(path);
      videoFound = true;
      return { 
        found: true, 
        path, 
        videoInfo: {
          name: "YouTube Video",
          format: "youtube"
        } 
      };
    }
    
    // Standard video URL handling
    try {
      const response = await fetch(path, { method: 'HEAD' });
      
      if (response.ok) {
        // Verify content type is video/mp4 or other video format
        const contentType = response.headers.get('Content-Type');
        if (contentType && !contentType.startsWith('video/')) {
          console.warn(`⚠️ File at ${path} has incorrect content type: ${contentType}. Expected video/*`);
          continue; // Skip this file
        }
        
        console.log(`✅ Video file found at ${path}${contentType ? ` with content type: ${contentType}` : ''}`);
        
        // Check file size if Content-Length header is available
        const contentLength = response.headers.get('Content-Length');
        const videoInfo: VideoInfo = {};
        
        if (contentLength) {
          const fileSizeKB = Math.round(parseInt(contentLength, 10) / 1024);
          console.log(`Video size: ${fileSizeKB} KB`);
          videoInfo.size = fileSizeKB;
          videoInfo.name = path.split('/').pop();
          
          // Determine video format from extension or content type
          const fileExtension = path.split('.').pop()?.toLowerCase();
          if (fileExtension) {
            videoInfo.format = fileExtension;
          } else if (contentType) {
            // Extract format from content type (e.g., video/mp4 -> mp4)
            videoInfo.format = contentType.split('/')[1];
          }
          
          // Warn if file is too large (over 10MB)
          if (fileSizeKB > 10240) {
            console.warn(`⚠️ Video file is large (${fileSizeKB} KB). This may cause playback issues.`);
            toast(`The video is ${Math.round(fileSizeKB/1024)}MB, which may cause playback issues. We recommend videos under 10MB.`);
          }
        }
        
        onVideoFound(path);
        videoFound = true;
        return { found: true, path, videoInfo };
      } else {
        console.log(`❌ Video file not found at ${path}`);
      }
    } catch (error) {
      console.error(`Error checking video at ${path}:`, error);
    }
  }
  
  if (!videoFound) {
    console.log("No video files found. Falling back to images.");
    onFallback();
  }
  
  return { found: false };
};

/**
 * Default video paths to try
 */
export const defaultVideoPaths = [
  "/lovable-uploads/hero-video.mp4",
  "/lovable-uploads/hero.mp4",
  "/lovable-uploads/video.mp4",
  "/lovable-uploads/background-video.mp4",
  "/videos/hero-video.mp4",
  "/videos/hero.mp4"
];

/**
 * Handles video playback errors and displays appropriate messages
 */
export const handleVideoError = (
  message: string = "There was an issue playing the video.",
  onError: () => void
) => {
  console.error("Video playback error:", message);
  toast(message);
  onError(); // Fall back to image
};

/**
 * Creates video sources array from a URL
 */
export const createVideoSources = (url: string): VideoSource[] => {
  if (!url) return [];
  
  const extension = url.split('.').pop()?.toLowerCase();
  const mimeType = getMimeTypeFromExtension(extension);
  
  return [
    {
      src: url,
      type: mimeType || 'video/mp4' // Default to mp4 if type can't be determined
    }
  ];
};

/**
 * Get MIME type from file extension
 */
export const getMimeTypeFromExtension = (extension?: string): string => {
  if (!extension) return 'video/mp4';
  
  switch (extension.toLowerCase()) {
    case 'mp4':
      return 'video/mp4';
    case 'webm':
      return 'video/webm';
    case 'ogg':
      return 'video/ogg';
    case 'mov':
      return 'video/quicktime';
    case 'avi':
      return 'video/x-msvideo';
    case 'wmv':
      return 'video/x-ms-wmv';
    default:
      return `video/${extension}`;
  }
};

