// Default image path for blog categories
export const DEFAULT_BLOG_CATEGORY_IMAGE = "/lovable-uploads/8e1f1c51-05b6-4bf6-9820-9008ebf1477a.png";

// Category image mapping - with unique images for each category
export const categoryImages: Record<string, string> = {
  "Market Trends": "/lovable-uploads/5f6ac007-7d7c-46c2-a925-bf4a3e14afa1.png",
  "Buying": "/lovable-uploads/5180569b-6bb4-4bf0-98b3-4e2eab69acf2.png",
  "Selling": "/lovable-uploads/d43397b6-a154-4bec-829d-f291ae2a0b34.png",
  "Investment": "/lovable-uploads/c41db19d-3c71-4b2b-b77b-e2cb56891f36.png",
  "Commercial": "/lovable-uploads/471b82af-992f-43e2-818b-55cf13a3dde6.png",
  "Residential": "/lovable-uploads/940d5d6d-ca53-4659-8542-20492c99cf6b.png",
  "Luxury": "/lovable-uploads/326444d6-84da-4efd-99b9-a400be4196ae.png",
  "Sustainability": "/lovable-uploads/b6a1b7d9-d60d-40b6-8f49-2e0830cd737e.png",
  "Tips": DEFAULT_BLOG_CATEGORY_IMAGE,
  "News": DEFAULT_BLOG_CATEGORY_IMAGE,
  "Real Estate": DEFAULT_BLOG_CATEGORY_IMAGE,
  "McAllen": DEFAULT_BLOG_CATEGORY_IMAGE,
};

// Featured blog categories
export const featuredBlogCategories = Object.entries(categoryImages).map(([name, image]) => ({
  name,
  image
}));

// Consolidated list of all viable categories for the blog filter
export const allBlogCategories = [
  "All",
  "Market Trends",
  "Buying",
  "Selling",
  "Investment",
  "Commercial",
  "Residential",
  "Luxury",
  "Tips",
  "News",
  "Market Updates",
  "Real Estate",
  "Home Improvement",
  "Financing",
  "First-Time Buyers",
  "McAllen",
  "Property Management",
  "Interior Design"
];

// Additional blog categories - keeping a shorter, more focused list
export const additionalBlogCategories = [
  "Home Improvement", 
  "Financing", 
  "First-Time Buyers", 
  "Property Management", 
  "Interior Design", 
  "Technology", 
  "Vacation Homes", 
  "Rental Market"
];
