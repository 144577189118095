
import React from 'react';
import { Button } from "@/components/ui/button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Calculator, Phone, Home, RefreshCw } from "lucide-react";
import { HoverCard, HoverCardTrigger, HoverCardContent } from "@/components/ui/hover-card";

const QuickLinks: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const handleCalculatorClick = () => {
    // Navigate to mortgage calculator with current path as "from" state
    navigate('/mortgage-calculator', { 
      state: { 
        from: location.pathname,
      } 
    });
  };
  
  const handleFlipCalculatorClick = () => {
    // Check if already on flip calculator page
    if (location.pathname === '/flip-calculator') return;
    
    // Navigate to flip calculator
    navigate('/flip-calculator');
  };
  
  return (
    <div className="fixed left-5 bottom-[80px] flex flex-col gap-3 z-40">
      <HoverCard openDelay={100} closeDelay={100}>
        <HoverCardTrigger asChild>
          <div className="bg-black p-3 rounded-full shadow-lg border-2 border-black">
            <Button 
              variant="ghost" 
              size="icon" 
              className="h-10 w-10 rounded-full bg-yellow-400 hover:bg-yellow-500 text-black quick-button-glow"
              title="Home"
              style={{ 
                boxShadow: '0 0 0 2px black',
                transform: 'none',
                animation: 'none !important',
                transition: 'all 0.3s ease'
              }}
              onClick={() => navigate('/')}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow = '0 0 20px 8px rgba(255, 179, 71, 0.6), 0 0 30px rgba(255, 179, 71, 0.4)';
                e.currentTarget.style.transform = 'translateY(-3px)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = '0 0 0 2px black';
                e.currentTarget.style.transform = 'translateY(0)';
              }}
            >
              <Home className="h-5 w-5" />
            </Button>
          </div>
        </HoverCardTrigger>
        <HoverCardContent side="right" className="bg-black text-white border-gold-500/30">
          Return to Home
        </HoverCardContent>
      </HoverCard>
      
      <HoverCard openDelay={100} closeDelay={100}>
        <HoverCardTrigger asChild>
          <div className="bg-black p-3 rounded-full shadow-lg border-2 border-black">
            <Button 
              variant="ghost" 
              size="icon" 
              className="h-10 w-10 rounded-full bg-yellow-400 hover:bg-yellow-500 text-black quick-button-glow"
              title="Mortgage Calculator"
              style={{ 
                boxShadow: '0 0 0 2px black',
                transform: 'none',
                animation: 'none !important',
                transition: 'all 0.3s ease'
              }}
              onClick={handleCalculatorClick}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow = '0 0 20px 8px rgba(255, 179, 71, 0.6), 0 0 30px rgba(255, 179, 71, 0.4)';
                e.currentTarget.style.transform = 'translateY(-3px)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = '0 0 0 2px black';
                e.currentTarget.style.transform = 'translateY(0)';
              }}
            >
              <Calculator className="h-5 w-5" />
            </Button>
          </div>
        </HoverCardTrigger>
        <HoverCardContent side="right" className="bg-black text-white border-gold-500/30">
          Mortgage Calculator
        </HoverCardContent>
      </HoverCard>
      
      <HoverCard openDelay={100} closeDelay={100}>
        <HoverCardTrigger asChild>
          <div className="bg-black p-3 rounded-full shadow-lg border-2 border-black">
            <Button 
              variant="ghost" 
              size="icon" 
              className="h-10 w-10 rounded-full bg-yellow-400 hover:bg-yellow-500 text-black quick-button-glow"
              title="Flip Calculator"
              style={{ 
                boxShadow: '0 0 0 2px black',
                transform: 'none',
                animation: 'none !important',
                transition: 'all 0.3s ease'
              }}
              onClick={handleFlipCalculatorClick}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow = '0 0 20px 8px rgba(255, 179, 71, 0.6), 0 0 30px rgba(255, 179, 71, 0.4)';
                e.currentTarget.style.transform = 'translateY(-3px)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = '0 0 0 2px black';
                e.currentTarget.style.transform = 'translateY(0)';
              }}
            >
              <RefreshCw className="h-5 w-5" />
            </Button>
          </div>
        </HoverCardTrigger>
        <HoverCardContent side="right" className="bg-black text-white border-gold-500/30">
          Flip Calculator
        </HoverCardContent>
      </HoverCard>

      <HoverCard openDelay={100} closeDelay={100}>
        <HoverCardTrigger asChild>
          <div className="bg-black p-3 rounded-full shadow-lg border-2 border-black">
            <Link to="/contact">
              <Button 
                variant="ghost" 
                size="icon" 
                className="h-10 w-10 rounded-full bg-yellow-400 hover:bg-yellow-500 text-black quick-button-glow"
                title="Contact Us"
                style={{ 
                  boxShadow: '0 0 0 2px black',
                  transform: 'none',
                  animation: 'none !important',
                  transition: 'all 0.3s ease'
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.boxShadow = '0 0 20px 8px rgba(255, 179, 71, 0.6), 0 0 30px rgba(255, 179, 71, 0.4)';
                  e.currentTarget.style.transform = 'translateY(-3px)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.boxShadow = '0 0 0 2px black';
                  e.currentTarget.style.transform = 'translateY(0)';
                }}
              >
                <Phone className="h-5 w-5" />
              </Button>
            </Link>
          </div>
        </HoverCardTrigger>
        <HoverCardContent side="right" className="bg-black text-white border-gold-500/30">
          Contact Us
        </HoverCardContent>
      </HoverCard>
    </div>
  );
};

export default QuickLinks;
