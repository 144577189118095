
import React from 'react';
import { ArrowDownCircle, Home, DollarSign, TrendingUp, PiggyBank } from 'lucide-react';

interface ResultsSectionProps {
  monthlyHousingCosts: number;
  monthlyRentalIncome: number;
  netMonthlyCashFlow: number;
  annualROI: number;
}

const ResultsSection: React.FC<ResultsSectionProps> = ({
  monthlyHousingCosts,
  monthlyRentalIncome,
  netMonthlyCashFlow,
  annualROI
}) => {
  const formatCurrency = (value: number): string => {
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };
  
  const formatPercentage = (value: number): string => {
    return Math.abs(value).toFixed(2) + '%';
  };

  // Calculate the cost reduction (how much of your mortgage is being covered)
  const costReductionPercent = monthlyRentalIncome > 0 && monthlyHousingCosts > 0 
    ? (monthlyRentalIncome / monthlyHousingCosts) * 100 
    : 0;
  
  // Calculate effective monthly housing cost
  const effectiveHousingCost = monthlyHousingCosts - monthlyRentalIncome;

  const resultClass = "flex flex-col items-center justify-center bg-gradient-to-br p-6 rounded-lg text-center mb-6 shadow-lg";
  const headingClass = "text-lg font-semibold mb-4 flex items-center gap-2";
  const valueClass = "text-3xl font-bold";

  return (
    <div className="space-y-6">
      <h3 className="text-xl font-bold text-gold-300 mb-4 border-b border-gold-500/30 pb-2">House Hacking Benefits</h3>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Mortgage Coverage */}
        <div className={`${resultClass} from-green-900/40 to-green-800/20 border border-green-500/30`}>
          <h4 className={headingClass}>
            <PiggyBank className="w-5 h-5 text-green-400" /> 
            Mortgage Coverage
          </h4>
          <p className={valueClass}>{costReductionPercent.toFixed(1)}%</p>
          <p className="text-gray-300 mt-2 text-sm">
            Percentage of your housing costs offset by rental income
          </p>
        </div>
        
        {/* Rental Income */}
        <div className={`${resultClass} from-green-900/40 to-green-800/20 border border-green-500/30`}>
          <h4 className={headingClass}>
            <ArrowDownCircle className="w-5 h-5 text-green-400" /> 
            Rental Income
          </h4>
          <p className={valueClass}>{formatCurrency(monthlyRentalIncome)}</p>
          <p className="text-gray-300 mt-2 text-sm">
            Monthly income from your tenant(s)
          </p>
        </div>
      </div>
      
      {/* Effective Housing Cost */}
      <div className={`${resultClass} from-blue-900/40 to-blue-800/20 border border-blue-500/30`}>
        <h4 className={headingClass}>
          <Home className="w-5 h-5 text-blue-400" /> 
          Effective Monthly Housing Cost
        </h4>
        <p className={`${valueClass}`}>
          {formatCurrency(effectiveHousingCost)}
        </p>
        <p className="text-gray-300 mt-2 text-sm">
          Your out-of-pocket housing cost after rental income
        </p>
      </div>
      
      {/* Investment Performance */}
      <div className={`${resultClass} from-purple-900/40 to-purple-800/20 border border-purple-500/30`}>
        <h4 className={headingClass}>
          <TrendingUp className="w-5 h-5 text-purple-400" /> 
          Return On Investment
        </h4>
        <p className={valueClass}>
          {formatPercentage(annualROI)}
        </p>
        <p className="text-gray-300 mt-2 text-sm">
          Annual return relative to your down payment
        </p>
      </div>
    </div>
  );
};

export default ResultsSection;
