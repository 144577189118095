
import React from "react";
import { Link } from "react-router-dom";
import { MapPin, Building } from "lucide-react";
import { featuredCommunities, additionalCommunities } from "./CommunitiesData";

interface MobileCommunitiesProps {
  isOpen?: boolean;
  onSelect: (cityName: string) => void;
}

const MobileCommunities: React.FC<MobileCommunitiesProps> = ({ 
  isOpen,
  onSelect 
}) => {
  if (!isOpen) return null;
  
  return (
    <div className="mt-4 py-2 bg-gray-900 rounded-md overflow-auto max-h-[60vh]">
      <div className="px-4 py-2 text-gold-500 border-b border-gray-800 font-medium text-center sticky top-0 bg-gray-900 z-10">
        Featured Communities
      </div>
      
      {/* Featured communities for mobile - with new elegant design matching blogs */}
      <div className="grid grid-cols-2 gap-2 p-2">
        {featuredCommunities.map((city, index) => (
          <div 
            key={city.name}
            className="relative rounded-md overflow-hidden h-20 cursor-pointer group"
            onClick={() => onSelect(city.name)}
          >
            {/* Background image */}
            <img 
              src={city.image}
              alt={city.name}
              className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-500"
            />
            
            {/* Bottom gradient overlay - darkened for better text contrast */}
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-gray-900 opacity-50"></div>
            
            {/* City name with improved visibility */}
            <div className="absolute bottom-0 left-0 right-0 p-2 flex items-center justify-between">
              <span className="text-white text-sm font-semibold tracking-wide drop-shadow-[0_2px_3px_rgba(0,0,0,0.9)]">{city.name}</span>
              {/* Gold accent */}
              <div className="absolute bottom-0 left-0 w-1/3 h-0.5 bg-gold-500 transition-all duration-300 group-hover:w-full"></div>
            </div>
          </div>
        ))}
      </div>
      
      <div className="px-4 py-2 mt-2 text-gold-500 border-y border-gray-800 font-medium sticky top-10 bg-gray-900 z-10">
        More Communities
      </div>
      
      {/* Additional communities list for mobile */}
      <div className="grid grid-cols-2 gap-1 px-2 py-2">
        {additionalCommunities.sort().map(city => (
          <div 
            key={city}
            className="px-2 py-1.5 hover:bg-gray-800 hover:text-gold-500 transition-all duration-200 flex items-center group relative overflow-hidden"
            onClick={() => onSelect(city)}
          >
            <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-gold-500 transition-all duration-300 group-hover:w-full"></span>
            <MapPin className="mr-1 h-3 w-3" /> 
            <span className="text-sm truncate">{city}</span>
          </div>
        ))}
      </div>
      
      <div className="border-t border-gray-800 mt-1">
        <Link 
          to="/communities" 
          className="block py-2 px-4 text-gold-500 font-medium hover:bg-gray-800 flex items-center justify-center group"
          onClick={() => onSelect("")}
        >
          <Building className="mr-1.5 h-4 w-4" /> 
          View All RGV Communities
          <span className="ml-1 transition-transform duration-300 group-hover:translate-x-1">→</span>
        </Link>
      </div>
    </div>
  );
};

export default MobileCommunities;
