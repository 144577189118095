
import { useEffect, useState } from "react";
import { useLanguage } from "@/context/LanguageContext";

const CommercialHero = () => {
  const [scrollY, setScrollY] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { t } = useLanguage();
  
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Preload the image to ensure it's available
  useEffect(() => {
    const img = new Image();
    img.src = "/lovable-uploads/40fd3f52-b469-4886-9097-40e4f7aa90d4.png";
    img.onload = () => {
      setImageLoaded(true);
    };
    img.onerror = (e) => {
      console.error("Error loading image:", e);
      setImageLoaded(false);
    };
  }, []);

  return (
    <div className="relative h-[300px] md:h-[400px] overflow-hidden wp-block-lovable-commercial-hero">
      {/* Parallax background with image loading check */}
      <div 
        className={`absolute inset-0 bg-cover bg-center ${!imageLoaded ? 'bg-estate-800' : ''}`}
        style={{
          backgroundImage: imageLoaded ? "url('/lovable-uploads/40fd3f52-b469-4886-9097-40e4f7aa90d4.png')" : 'none',
          transform: `translateY(${scrollY * 0.3}px)`,
          backgroundPosition: `center ${50 + scrollY * 0.1}%`,
          backgroundRepeat: 'no-repeat'
        }}
      />
      
      {/* Light overlay to preserve the sunset colors while ensuring text readability */}
      <div className="absolute inset-0 bg-black/20" />
      
      {/* Content */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-white px-4">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-display mb-4 text-center">
          {t('commercial.title')}
        </h1>
        <p className="text-xl md:text-2xl opacity-90 text-center max-w-2xl">
          {t('commercial.subtitle')}
        </p>
      </div>
    </div>
  );
};

export default CommercialHero;
