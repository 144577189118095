
import React, { useEffect } from "react";
import { ChevronLeft, ChevronRight, PauseCircle, PlayCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import { BlogImage } from "@/types/blog";
import { useImageCarousel } from "@/hooks/blog/useImageCarousel";
import SEOImage from "@/components/SEOImage";

interface ImageCarouselProps {
  images: BlogImage[] | string[];
  height?: string;
  showControls?: boolean;
  showCaption?: boolean;
  showCounter?: boolean;
  className?: string;
  alt?: string;
  initialIndex?: number;
  autoScroll?: boolean;
  autoScrollInterval?: number;
  counterPosition?: 'top-right' | 'bottom-left' | 'bottom-right';
  onImageChange?: (imageUrl: string, index: number) => void;
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({
  images,
  height = "h-[300px]",
  showControls = true,
  showCaption = true,
  showCounter = true,
  className = "",
  alt = "Image",
  initialIndex = 0,
  autoScroll = true,
  autoScrollInterval = 5000,
  counterPosition = 'top-right',
  onImageChange
}) => {
  const {
    currentIndex,
    totalImages,
    currentImage,
    currentCaption,
    hasMultipleImages,
    isAutoScrolling,
    nextImage,
    prevImage,
    toggleAutoScroll,
    pauseAutoScroll
  } = useImageCarousel(images, initialIndex, autoScroll, autoScrollInterval);

  // Call the onImageChange callback when the image changes
  useEffect(() => {
    if (onImageChange && currentImage) {
      onImageChange(currentImage, currentIndex);
    }
  }, [currentImage, currentIndex, onImageChange]);

  // Debug image loading
  useEffect(() => {
    console.log("ImageCarousel component received:", { 
      imageCount: images.length, 
      hasMultipleImages,
      autoScroll,
      isAutoScrolling,
      firstImage: images.length > 0 ? 
        (typeof images[0] === 'string' ? 
          images[0].substring(0, 30) + "..." : 
          images[0].url.substring(0, 30) + "...") : 
        'none',
      currentImage: currentImage ? currentImage.substring(0, 30) + "..." : "none",
      imagesArray: JSON.stringify(images).substring(0, 200) + "..."
    });
  }, [images, currentImage, hasMultipleImages, isAutoScrolling]);

  // Handlers for navigation buttons
  const handlePrev = () => {
    pauseAutoScroll();
    prevImage();
  };

  const handleNext = () => {
    pauseAutoScroll();
    nextImage();
  };

  // If there are no images, display a placeholder
  if (totalImages === 0) {
    return (
      <div className={`${height} ${className} bg-gray-800 flex items-center justify-center`}>
        <p className="text-gray-400">No images available</p>
      </div>
    );
  }

  // Get counter position classes based on the counterPosition prop
  const getCounterPositionClasses = () => {
    switch (counterPosition) {
      case 'bottom-left':
        return 'bottom-3 left-3';
      case 'bottom-right':
        return 'bottom-3 right-3';
      case 'top-right':
      default:
        return 'top-3 right-3';
    }
  };

  return (
    <div className={`relative ${height} ${className} overflow-hidden bg-gray-900 group`}>
      {currentImage ? (
        <SEOImage
          src={currentImage}
          alt={currentCaption || alt}
          className="w-full h-full object-cover transition-transform duration-700"
          priority={initialIndex === 0}
        />
      ) : (
        <div className="w-full h-full bg-gray-800 flex items-center justify-center">
          <p className="text-gray-400">Image not available</p>
        </div>
      )}

      {hasMultipleImages && showControls && (
        <>
          <Button
            variant="ghost"
            size="icon"
            className="absolute left-2 top-1/2 -translate-y-1/2 bg-black/60 text-white rounded-full h-10 w-10 hover:bg-black/80 z-20 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            onClick={handlePrev}
            aria-label="Previous image"
          >
            <ChevronLeft className="h-6 w-6" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            className="absolute right-2 top-1/2 -translate-y-1/2 bg-black/60 text-white rounded-full h-10 w-10 hover:bg-black/80 z-20 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            onClick={handleNext}
            aria-label="Next image"
          >
            <ChevronRight className="h-6 w-6" />
          </Button>
          
          {/* Auto-scroll toggle button */}
          <Button
            variant="ghost"
            size="icon"
            className="absolute top-3 left-3 bg-black/60 text-white rounded-full h-8 w-8 hover:bg-black/80 z-20 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            onClick={toggleAutoScroll}
            aria-label={isAutoScrolling ? "Pause slideshow" : "Play slideshow"}
          >
            {isAutoScrolling ? (
              <PauseCircle className="h-5 w-5" />
            ) : (
              <PlayCircle className="h-5 w-5" />
            )}
          </Button>
        </>
      )}

      {showCaption && currentCaption && (
        <div className="absolute bottom-0 left-0 right-0 bg-black/70 text-white p-3 z-10">
          <p className="text-sm">{currentCaption}</p>
        </div>
      )}

      {showCounter && hasMultipleImages && (
        <div className={`absolute ${getCounterPositionClasses()} bg-black/70 text-white px-2 py-1 rounded-full text-sm z-10`}>
          {currentIndex + 1} / {totalImages}
        </div>
      )}
    </div>
  );
};

export default ImageCarousel;
