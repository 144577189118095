
import { PropertyDetailsProps } from "../PropertyDetailsDialog";

// Generate random properties for the property search results
export const generateProperties = (
  count: number = 6, 
  propertyType: string = "", 
  location: string = "",
  priceRange: number[] = [1000000]
): PropertyDetailsProps[] => {
  
  const properties: PropertyDetailsProps[] = [];
  
  // Sample property images to choose from
  const propertyImages = [
    "https://images.unsplash.com/photo-1582268611958-ebfd161ef9cf",
    "https://images.unsplash.com/photo-1564013799919-ab600027ffc6",
    "https://images.unsplash.com/photo-1512917774080-9991f1c4c750",
    "https://images.unsplash.com/photo-1600596542815-ffad4c1539a9",
    "https://images.unsplash.com/photo-1600585154340-be6161a56a0c",
    "https://images.unsplash.com/photo-1568605114967-8130f3a36994",
    "/lovable-uploads/6e725c39-f7a8-48d1-b4d2-e5cd1cf43fbd.png",
    "/lovable-uploads/5eb1e5e4-922e-4f70-8e96-ad74c47098f8.png",
    "/lovable-uploads/326444d6-84da-4efd-99b9-a400be4196ae.png",
    "/lovable-uploads/f912b308-0152-4006-ac12-c1116f6932f5.png"
  ];
  
  // Sample property types
  const propertyTypes = ["House", "Condo", "Townhouse", "Villa", "Multi-family"];
  
  // Sample locations
  const locations = ["McAllen", "Mission", "Edinburg", "Harlingen", "Brownsville", "South Padre Island"];
  
  // Sample audio tours
  const audioTours = [
    "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
    "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3",
    "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3",
  ];
  
  // Sample amenities
  const possibleAmenities = [
    "Swimming Pool", "Garden", "Garage", "Balcony", "Air Conditioning", 
    "Fireplace", "Home Office", "Gym", "Spa", "Smart Home System",
    "High Ceilings", "Marble Countertops", "Hardwood Floors", "Security System",
    "Stainless Steel Appliances", "Walk-in Closet", "Jacuzzi", "Patio"
  ];
  
  // Sample school districts
  const schoolDistricts = [
    "McAllen ISD", "Mission CISD", "Edinburg CISD", "Harlingen CISD", 
    "Brownsville ISD", "Point Isabel ISD"
  ];
  
  // Sample neighborhoods
  const neighborhoods = [
    "Downtown", "Westside", "North Heights", "Southridge", "East End",
    "Palm Valley", "Lakeside", "University District", "Historic District"
  ];
  
  for (let i = 0; i < count; i++) {
    // Generate a price below the maximum price range
    const maxPrice = priceRange[0];
    let price = Math.floor(Math.random() * (maxPrice * 0.9)) + (maxPrice * 0.1);
    
    // Adjust price for property type if specified
    if (propertyType) {
      if (propertyType.toLowerCase().includes('house')) price *= 1.2;
      if (propertyType.toLowerCase().includes('condo')) price *= 0.8;
      if (propertyType.toLowerCase().includes('townhouse')) price *= 0.9;
      if (propertyType.toLowerCase().includes('villa')) price *= 1.5;
    }
    
    // Format price with commas
    const priceFormatted = `$${price.toLocaleString()}`;
    
    // Determine property type
    const type = propertyType || propertyTypes[Math.floor(Math.random() * propertyTypes.length)];
    
    // Determine location
    const propertyLocation = location || locations[Math.floor(Math.random() * locations.length)];
    
    // Generate random beds and baths
    const beds = Math.floor(Math.random() * 4) + 2; // 2-5 beds
    const baths = Math.floor(Math.random() * 3) + 1; // 1-3 baths
    
    // Generate random square footage
    const sqft = Math.floor(Math.random() * 2000) + 1000; // 1000-3000 sq ft
    
    // Generate a random year built between 1990 and 2023
    const yearBuilt = Math.floor(Math.random() * 33) + 1990;
    
    // Pick a main image
    const mainImage = propertyImages[Math.floor(Math.random() * propertyImages.length)];
    
    // Generate 2-4 additional images
    const additionalImagesCount = Math.floor(Math.random() * 3) + 2;
    const additionalImages = Array(additionalImagesCount).fill(0).map(() => {
      return propertyImages[Math.floor(Math.random() * propertyImages.length)];
    });
    
    // Randomly decide if this property has an audio tour
    const hasAudioTour = Math.random() > 0.5;
    const audioTour = hasAudioTour ? audioTours[Math.floor(Math.random() * audioTours.length)] : undefined;
    
    // Generate 3-6 random amenities
    const amenitiesCount = Math.floor(Math.random() * 4) + 3;
    const amenities = Array.from(new Set(
      Array(amenitiesCount).fill(0).map(() => 
        possibleAmenities[Math.floor(Math.random() * possibleAmenities.length)]
      )
    ));
    
    // Pick a school district
    const schoolDistrict = schoolDistricts[Math.floor(Math.random() * schoolDistricts.length)];
    
    // Pick a neighborhood
    const neighborhood = neighborhoods[Math.floor(Math.random() * neighborhoods.length)];
    
    // Generate a property
    properties.push({
      id: `prop-${Date.now()}-${i}`,
      title: `${beds} Bedroom ${type} in ${propertyLocation}`,
      address: `${Math.floor(Math.random() * 9000) + 1000} ${['Main', 'Oak', 'Maple', 'Cedar', 'Pine'][Math.floor(Math.random() * 5)]} ${['St', 'Ave', 'Blvd', 'Dr', 'Ln'][Math.floor(Math.random() * 5)]}`,
      location: propertyLocation,
      price,
      priceFormatted,
      beds,
      baths,
      sqft,
      image: mainImage,
      propertyType: type,
      yearBuilt,
      featured: Math.random() > 0.75, // 25% chance of being featured
      status: Math.random() > 0.15 ? "For Sale" : "Pending", // 85% For Sale, 15% Pending
      additionalImages,
      audioTour,
      amenities,
      schoolDistrict,
      neighborhood,
      description: `This beautiful ${beds} bedroom, ${baths} bathroom ${type.toLowerCase()} in ${propertyLocation} offers ${sqft.toLocaleString()} square feet of living space. Built in ${yearBuilt}, this property features ${amenities.slice(0, 3).join(', ')}, and more. Located in the desirable ${neighborhood} neighborhood, it's close to shopping, dining, and top-rated ${schoolDistrict} schools. This is a perfect opportunity for families, investors, or anyone looking for a great property in the rapidly appreciating ${propertyLocation} market.`
    });
  }
  
  return properties;
};
