
/**
 * Utilities for styling DOM elements in blog content
 */

/**
 * Apply styles to paragraph elements
 * @param paragraphs - NodeList of paragraph elements to style
 */
export function styleParagraphs(paragraphs: NodeListOf<Element>): void {
  paragraphs.forEach(p => {
    const element = p as HTMLElement;
    element.style.color = '#FFFFFF';
    element.style.fontSize = '18px';
    element.style.lineHeight = '2.1';
    element.style.letterSpacing = '0.6px';
    element.style.fontWeight = '400';
    element.style.marginBottom = '1.5em'; // Reduced from 2.5em
  });
}

/**
 * Apply styles to heading elements
 * @param headings - NodeList of heading elements to style
 */
export function styleHeadings(headings: NodeListOf<Element>): void {
  headings.forEach(heading => {
    const element = heading as HTMLElement;
    element.style.lineHeight = '1.4';
    
    // Give more breathing room for headings but reduce from original
    if (heading.tagName === 'H1') {
      element.style.marginTop = '1em';
      element.style.marginBottom = '1em'; // Reduced from 1.5em
      element.style.lineHeight = '1.3';
    } else if (heading.tagName === 'H2') {
      element.style.marginTop = '2em'; // Reduced from 2.5em
      element.style.marginBottom = '1em'; // Reduced from 1.5em
      element.style.lineHeight = '1.35';
    } else if (heading.tagName === 'H3') {
      element.style.marginTop = '1.5em'; // Reduced from 2.5em
      element.style.marginBottom = '1em'; // Reduced from 1.5em
      element.style.lineHeight = '1.4';
    }
  });
}
