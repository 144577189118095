
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { ResizablePanelGroup, ResizablePanel, ResizableHandle } from "@/components/ui/resizable";
import PropertyCard from "@/components/PropertyCard";
import MortgageCalculator from "@/components/mortgage/MortgageCalculator";
import ResidentialCard from "@/components/residential/ResidentialCard";
import CommercialCard from "@/components/commercial/CommercialCard";

// Default property to show if none is passed
const DEFAULT_PROPERTY = {
  id: 1,
  image: "/lovable-uploads/6e725c39-f7a8-48d1-b4d2-e5cd1cf43fbd.png",
  title: "Luxury Villa",
  location: "McAllen, TX",
  price: "$1,950,000",
  bedrooms: 4,
  bathrooms: 3,
  sqft: "3,200 sq ft",
  featured: true
};

interface SplitViewCalculatorProps {
  property?: any;
  onClose?: () => void;
  initialPrice?: string;
}

const SplitViewCalculator: React.FC<SplitViewCalculatorProps> = ({ 
  property = DEFAULT_PROPERTY, 
  onClose,
  initialPrice
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [propertyPrice, setPropertyPrice] = useState<string>(initialPrice || property.price || "$1,950,000");
  const [initialLoanAmount, setInitialLoanAmount] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
  
  // Track screen size for responsive layout
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  // Extract numeric value from price string
  useEffect(() => {
    const priceString = initialPrice || property.price || "$1,950,000";
    const numericValue = parseInt(priceString.replace(/[$,]/g, ""), 10);
    setInitialLoanAmount(isNaN(numericValue) ? 300000 : numericValue);
    
    // Also update the propertyPrice state
    setPropertyPrice(priceString);
    
    console.log(`Calculated initialLoanAmount: ${numericValue} from price: ${priceString}`);
  }, [initialPrice, property.price]);
  
  // Handle close button click
  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      // Return to previous page or homepage
      const prevPath = location.state?.from || "/";
      navigate(prevPath);
    }
  };

  // Determine property card type
  const renderPropertyCard = () => {
    // Check if it's a residential property
    if (property.bedrooms !== undefined) {
      return <ResidentialCard property={property} className="h-full max-w-md mx-auto" />;
    }
    
    // Check if it's a commercial property
    if (property.type !== undefined) {
      return <CommercialCard property={property} />;
    }
    
    // Default property card
    return <PropertyCard {...property} />;
  };

  return (
    <div className="h-screen w-screen fixed inset-0 bg-background z-50">
      <div className="absolute top-4 right-4 z-10">
        <Button 
          variant="outline" 
          size="icon" 
          onClick={handleClose}
          className="rounded-full"
        >
          <X className="h-4 w-4" />
        </Button>
      </div>
      
      {isMobile ? (
        // Mobile layout: vertical with property on top, calculator below
        <div className="h-full flex flex-col overflow-auto">
          <div className="p-4 flex-shrink-0" style={{ maxHeight: "40%" }}>
            <div className="h-full max-w-md mx-auto">
              {renderPropertyCard()}
            </div>
          </div>
          <div className="flex-grow overflow-auto p-2">
            <MortgageCalculator initialLoanAmount={initialLoanAmount} />
          </div>
        </div>
      ) : (
        // Desktop layout: horizontal split with resizable panels
        <ResizablePanelGroup direction="horizontal" className="h-full">
          <ResizablePanel defaultSize={40} minSize={30}>
            <div className="h-full flex items-center justify-center p-6 overflow-auto">
              {renderPropertyCard()}
            </div>
          </ResizablePanel>
          
          <ResizableHandle withHandle />
          
          <ResizablePanel defaultSize={60} minSize={30}>
            <div className="h-full overflow-auto">
              <MortgageCalculator initialLoanAmount={initialLoanAmount} />
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      )}
    </div>
  );
};

export default SplitViewCalculator;
