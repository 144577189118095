
import { CityInfo } from "../types/CommunityTypes";

export const HarlingenData: CityInfo = {
  headline: "Experience Modern Elegance in Harlingen",
  description: "Harlingen combines small-town charm with modern amenities, offering beautiful neighborhoods, excellent schools, and a growing economy. Located in the heart of the Rio Grande Valley, Harlingen provides convenient access to major attractions while maintaining its distinctive character.",
  marketInfo: {
    avgPrice: "$235,000",
    trend: "Rising 4% annually",
    daysOnMarket: "42 days"
  },
  communities: [
    {
      name: "Treasure Hills",
      description: "Established upscale neighborhood with beautiful homes and a prestigious golf course",
      features: ["Golf course views", "Mature landscaping", "Spacious lots"],
      priceRange: "$275,000 - $450,000",
      image: "https://images.unsplash.com/photo-1561753757-d8880c5a3551?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Z29sZiUyMGNvdXJzZSUyMGhvbWV8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
    },
    {
      name: "Stuart Place",
      description: "Modern community with contemporary architectural designs and family amenities",
      features: ["Modern designs", "Community park", "Near schools"],
      priceRange: "$230,000 - $400,000",
      image: "/lovable-uploads/69e8c8b8-27bb-449d-953d-bed6e76760b5.png"
    }
  ],
  featuredProperties: [
    {
      id: 601,
      city: "Harlingen",
      title: "Contemporary Home with Modern Finishes",
      price: "$375,000",
      image: "/lovable-uploads/69e8c8b8-27bb-449d-953d-bed6e76760b5.png",
      beds: 4,
      baths: 3,
      sqft: "2,700"
    },
    {
      id: 602,
      city: "Harlingen",
      title: "Elegant Family Residence",
      price: "$325,000",
      image: "https://images.unsplash.com/photo-1605276374104-dee2a0ed3cd6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGhvdXNlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      beds: 3,
      baths: 2.5,
      sqft: "2,300"
    }
  ]
};
