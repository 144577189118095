
import React from 'react';
import { useLocation } from 'react-router-dom';
import SplitViewCalculator from '@/components/mortgage/SplitViewCalculator';

const SplitCalculator: React.FC = () => {
  const location = useLocation();
  const property = location.state?.property || undefined;
  const initialPrice = location.state?.price || undefined;
  
  return <SplitViewCalculator property={property} initialPrice={initialPrice} />;
};

export default SplitCalculator;
