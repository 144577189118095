
import { Check } from "lucide-react";
import { useLanguage } from "@/context/LanguageContext";

const OurVision = () => {
  const { t } = useLanguage();
  
  const values = [
    {
      title: t('vision.elevated'),
      description: t('vision.elevated.desc'),
    },
    {
      title: t('vision.sustainable'),
      description: t('vision.sustainable.desc'),
    },
    {
      title: t('vision.expert'),
      description: t('vision.expert.desc'),
    },
  ];

  return (
    <section className="py-20 bg-estate-900">
      <div className="container mx-auto px-4 max-w-6xl text-center">
        <h2 className="text-4xl font-display text-white mb-4">{t('vision.title')}</h2>
        <p className="text-estate-300 mb-12 mx-auto max-w-2xl">
          {t('vision.subtitle')}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {values.map((value, index) => (
            <div key={index} className="flex flex-col items-center text-center space-y-4">
              <div className="w-12 h-12 rounded-full bg-estate-700 flex items-center justify-center">
                <Check className="w-6 h-6 text-white" />
              </div>
              <div>
                <h3 className="text-xl font-semibold text-white mb-2">{value.title}</h3>
                <p className="text-estate-300">{value.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurVision;
