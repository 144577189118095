
/**
 * Utilities for handling content sections in blog content
 */

/**
 * Ensure the content section is visible and properly styled
 * @param contentSection - The content section DOM element
 * @param titleText - The main title text to check against for duplicates
 */
export function ensureContentSectionVisibility(contentSection: HTMLElement, titleText: string): void {
  // Force the content to be visible - extra aggressive approach
  contentSection.style.display = 'block';
  contentSection.style.visibility = 'visible';
  contentSection.style.opacity = '1';
  contentSection.style.height = 'auto';
  contentSection.style.overflow = 'visible';
  contentSection.style.marginTop = '20px'; // Add space after meta, but not too much
  
  // Make sure all child elements are also visible
  const children = contentSection.querySelectorAll('*');
  children.forEach(child => {
    const childElement = child as HTMLElement;
    childElement.style.display = 'block';
    childElement.style.visibility = 'visible';
    childElement.style.opacity = '1';
  });
  
  // Only remove headings that match title, not all content
  if (titleText) {
    const headingsInContent = contentSection.querySelectorAll('h1, h2, h3');
    headingsInContent.forEach(heading => {
      const headingText = heading.textContent?.trim() || '';
      if (headingText === titleText || headingText.includes('Investment properties in')) {
        console.log("Removing duplicate heading inside content:", headingText);
        heading.remove();
      }
    });
  }
}

/**
 * Create a new content section if one doesn't exist
 * @param contentElement - The root DOM element for the blog content
 * @returns The newly created content section or null if not needed
 */
export function createContentSectionIfNeeded(contentElement: HTMLElement): HTMLElement | null {
  // Find content elements (not title, meta, or hero) to move to a new content section
  const contentElementsToMove = Array.from(contentElement.children).filter(el => 
    !el.classList.contains('main-title') && 
    !el.classList.contains('meta') && 
    !el.classList.contains('hero'));
  
  if (contentElementsToMove.length > 0) {
    console.log(`Found ${contentElementsToMove.length} elements to move to new content section`);
    const newContentSection = document.createElement('div');
    newContentSection.className = 'content';
    
    // Setup proper styling
    const newContentEl = newContentSection as HTMLElement;
    newContentEl.style.display = 'block';
    newContentEl.style.visibility = 'visible';
    newContentEl.style.opacity = '1';
    newContentEl.style.marginTop = '20px';
    newContentEl.style.padding = '20px';
    newContentEl.style.border = '1px solid rgba(255, 255, 255, 0.2)';
    newContentEl.style.borderRadius = '5px';
    
    // Move all these elements into the new content section
    contentElementsToMove.forEach(el => {
      console.log("Moving element to content section:", el.tagName);
      newContentSection.appendChild(el.cloneNode(true));
      el.remove();
    });
    
    return newContentSection;
  }
  
  return null;
}

/**
 * Insert content section in the appropriate place
 * @param contentElement - The root DOM element for the blog content
 * @param contentSection - The content section to insert
 */
export function insertContentSection(contentElement: HTMLElement, contentSection: HTMLElement): void {
  // Add the new content section after meta if it exists
  const meta = contentElement.querySelector('.meta');
  if (meta) {
    console.log("Adding content section after meta");
    meta.parentNode?.insertBefore(contentSection, meta.nextSibling);
  } else {
    console.log("No meta found, appending content section to root");
    contentElement.appendChild(contentSection);
  }
}

/**
 * Create a placeholder content section when no content is found
 * @param contentElement - The root DOM element for the blog content
 */
export function createPlaceholderContent(contentElement: HTMLElement): void {
  console.log("No content elements found to move, creating placeholder content");
  // Create a placeholder content section
  const placeholderContent = document.createElement('div');
  placeholderContent.className = 'content';
  placeholderContent.innerHTML = '<p style="color: red !important; font-weight: bold; border: 2px solid red; padding: 10px;">No content found in the blog post. Please check the original content or try refreshing the page.</p>';
  
  // Add it to the DOM
  const meta = contentElement.querySelector('.meta');
  if (meta) {
    meta.parentNode?.insertBefore(placeholderContent, meta.nextSibling);
  } else {
    contentElement.appendChild(placeholderContent);
  }
}
