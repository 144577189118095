
import React from 'react';
import { Button } from '@/components/ui/button';
import { ResultsSectionProps } from './types';

const ResultsSection: React.FC<ResultsSectionProps> = ({
  netIncomeMonthly,
  netIncomeYearly,
  returnOnInvestment,
  capRate,
  totalCapitalInvestment,
  resetForm,
  formatCurrency,
  formatPercent
}) => {
  return (
    <div className="transition-transform hover:transform hover:scale-[1.02] hover:z-10">
      <h2 className="text-2xl font-bold text-gold-500 mb-4">Net annual income</h2>
      
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <h3 className="text-gray-300 mb-2">Monthly</h3>
          <div className="text-2xl font-bold text-gold-500">
            {formatCurrency(netIncomeMonthly)}
          </div>
        </div>
        <div>
          <h3 className="text-gray-300 mb-2">Yearly</h3>
          <div className="text-2xl font-bold text-gold-500">
            {formatCurrency(netIncomeYearly)}
          </div>
        </div>
      </div>
      
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <h3 className="text-gray-300 mb-2">Return on investment</h3>
          <div className="text-2xl font-bold text-gold-500">
            {formatPercent(returnOnInvestment)}
          </div>
        </div>
        <div>
          <h3 className="text-gray-300 mb-2">CAP Rate</h3>
          <div className="text-2xl font-bold text-gold-500">
            {formatPercent(capRate)}
          </div>
        </div>
      </div>
      
      <Button 
        onClick={resetForm}
        className="bg-gold-500 hover:bg-gold-600 text-black font-bold px-6 w-full mb-2 calculator-reset-glow"
      >
        RESET FORM
      </Button>
    </div>
  );
};

export default ResultsSection;
