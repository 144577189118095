
import { useEffect, useState } from "react";
import { useLanguage } from "@/context/LanguageContext";

const BuyHero = () => {
  const [scrollY, setScrollY] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { t } = useLanguage();
  
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Preload the image to ensure it's available
  useEffect(() => {
    const img = new Image();
    img.src = "/lovable-uploads/21bfcf29-5d0d-4f60-80f2-37d85bf160a9.png";
    img.onload = () => {
      setImageLoaded(true);
    };
    img.onerror = (e) => {
      console.error("Error loading image:", e);
      setImageLoaded(false);
    };
  }, []);

  return (
    <div className="relative h-[300px] md:h-[400px] overflow-hidden">
      {/* Parallax background with image loading check */}
      <div 
        className={`absolute inset-0 bg-cover bg-center ${!imageLoaded ? 'bg-gray-800' : ''}`}
        style={{
          backgroundImage: imageLoaded ? "url('/lovable-uploads/21bfcf29-5d0d-4f60-80f2-37d85bf160a9.png')" : 'none',
          transform: `translateY(${scrollY * 0.3}px)`,
          backgroundPosition: `center ${50 + scrollY * 0.1}%`
        }}
      />
      
      {/* Dark overlay - using a lighter overlay to show off the sunset colors */}
      <div className="absolute inset-0 bg-black/30" />
      
      {/* Content */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-white px-4">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-display mb-4 text-center">
          {t('buy.title')}
        </h1>
        <p className="text-xl md:text-2xl opacity-90 text-center max-w-2xl">
          {t('buy.subtitle')}
        </p>
      </div>
    </div>
  );
};

export default BuyHero;
