
import { useLanguage } from "@/context/LanguageContext";

const AgentProfile = () => {
  const { language } = useLanguage();

  return (
    <div className="mb-12 flex flex-col items-center">
      <div className="relative w-[20rem] h-[20rem] rounded-full overflow-hidden border-4 border-[#d4af37] mb-6">
        {/* Remax background */}
        <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
          <img
            src="/lovable-uploads/8e1f1c51-05b6-4bf6-9820-9008ebf1477a.png"
            alt="RE/MAX balloon"
            className="w-full h-full object-contain opacity-40"
          />
        </div>
        <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
          <img 
            src="/lovable-uploads/d43397b6-a154-4bec-829d-f291ae2a0b34.png" 
            alt="Juan Elizondo" 
            className="w-[120%] h-[120%] object-cover"
          />
        </div>
      </div>
      <h2 className="text-3xl font-display text-center mb-4 text-shadow-gold">
        Juan Elizondo
      </h2>
      <p className="text-lg text-center text-gray-300 max-w-2xl">
        Especialista en inversiones inmobiliarias para clientes mexicanos con más de 15 años de experiencia.
      </p>
    </div>
  );
};

export default AgentProfile;
