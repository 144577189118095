
import { Building, MapPin, ArrowRight, Calculator } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { AspectRatio } from "@/components/ui/aspect-ratio";
import { useNavigate } from "react-router-dom";

interface CommercialProperty {
  id: number;
  image: string;
  title: string;
  location: string;
  type: string;
  sqft: string;
  price: string;
}

interface CommercialCardProps {
  property: CommercialProperty;
}

const CommercialCard = ({ property }: CommercialCardProps) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  
  const handleViewDetails = () => {
    toast({
      title: "Property Details",
      description: `You selected: ${property.title}`,
    });
  };
  
  const handleCalculatorClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate('/calculator', { 
      state: { 
        property,
        price: property.price,
        from: window.location.pathname
      } 
    });
  };

  return (
    <Card className="overflow-hidden group cursor-pointer border border-gray-200 hover:shadow-lg transition-all duration-300 elementor-widget-lovable-property-card wp-block-lovable-commercial-property">
      <CardContent className="p-0">
        <div className="relative overflow-hidden">
          <AspectRatio ratio={16/9}>
            <img
              src={property.image}
              alt={property.title}
              className="object-cover w-full h-full group-hover:scale-110 transition-transform duration-500"
              loading="lazy"
              width="800"
              height="450"
              decoding="async"
            />
          </AspectRatio>
        </div>
        
        <div className="p-6">
          <h3 className="text-xl font-bold mb-2">{property.title}</h3>
          
          <div className="flex items-center text-gray-600 mb-2">
            <MapPin className="w-4 h-4 mr-1" />
            <span className="text-sm">{property.location}</span>
          </div>
          
          <div className="flex items-center text-gray-600 mb-2">
            <Building className="w-4 h-4 mr-1" />
            <span className="text-sm">{property.type} • {property.sqft}</span>
          </div>
          
          <div className="font-bold text-lg mb-4 text-[#ffb347]">
            {property.price}
          </div>
          
          <div className="flex gap-2">
            <Button 
              className="flex-1 bg-[#ffb347] hover:bg-[#e69a30] text-white font-bold py-2 transition duration-300"
              onClick={handleViewDetails}
            >
              View Details
              <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
            
            <Button 
              variant="outline" 
              className="px-3 border-[#ffb347] text-[#ffb347] hover:bg-[#fff8e6]"
              onClick={handleCalculatorClick}
              title="Calculate mortgage"
            >
              <Calculator className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default CommercialCard;
