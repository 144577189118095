
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { extractImagesFromHtmlContent, processAndMergeImages } from '@/utils/blog/imageExtractionUtils';

/**
 * Submit a blog post to Juan's dedicated table
 */
export const submitJuansBlogPost = async (
  blogData: any,
  setSubmissionStatus?: (status: 'idle' | 'error' | 'success' | 'warning') => void,
  setErrorDetails?: (details: string) => void
) => {
  try {
    if (setSubmissionStatus) setSubmissionStatus('idle');
    console.log("Submitting post to Juan's blog table:", blogData);
    
    // Extract images from content if needed
    const extractedImages = extractImagesFromHtmlContent(blogData.content);
    
    // Merge any extracted images with existing ones
    const processedData = processAndMergeImages(blogData, extractedImages);
    
    // Prepare the post object
    const postData = {
      title: processedData.title || "Untitled Post",
      excerpt: processedData.excerpt || "",
      content: processedData.content || "",
      author: processedData.author || "Juan Elizondo",
      category: processedData.category || "Uncategorized",
      image: processedData.image || "",
      images: processedData.images || []
    };
    
    console.log("Final post data to submit:", postData);
    
    const { data, error } = await supabase
      .from('juans_blogs')
      .insert([postData])
      .select();
      
    if (error) {
      console.error("Error submitting to Juan's blog table:", error);
      if (setSubmissionStatus) setSubmissionStatus('error');
      if (setErrorDetails) setErrorDetails(error.message);
      
      toast.error("Failed to submit blog post", {
        description: error.message
      });
      
      return { success: false, error: error.message, post: null };
    }
    
    console.log("Successfully submitted post to Juan's blog table:", data);
    if (setSubmissionStatus) setSubmissionStatus('success');
    
    toast.success("Blog post submitted successfully", {
      description: "Your blog post has been saved to Juan's dedicated blog table."
    });
    
    return { success: true, error: null, post: data?.[0] || null };
  } catch (error) {
    console.error("Unexpected error submitting to Juan's blog table:", error);
    if (setSubmissionStatus) setSubmissionStatus('error');
    
    const errorMessage = error instanceof Error ? error.message : "An unexpected error occurred";
    if (setErrorDetails) setErrorDetails(errorMessage);
    
    toast.error("Failed to submit blog post", {
      description: errorMessage
    });
    
    return { success: false, error: errorMessage, post: null };
  }
};
