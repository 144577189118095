
/**
 * Utilities for processing base HTML structure in blog content
 */

/**
 * Fix HTML structure issues in blog content
 * @param contentElement - The DOM element containing the blog content
 */
export const processHtmlStructure = (contentElement: HTMLDivElement): void => {
  // Find any remaining nested HTML documents in the DOM and extract their content
  const nestedHtmlElements = contentElement.querySelectorAll('html');
  if (nestedHtmlElements.length > 0) {
    console.log(`Found ${nestedHtmlElements.length} remaining nested HTML elements, extracting content`);
    nestedHtmlElements.forEach(nestedHtml => {
      // Get the body of the nested HTML
      const nestedBody = nestedHtml.querySelector('body');
      if (nestedBody) {
        // Replace the nested HTML with its body content
        const parent = nestedHtml.parentNode;
        if (parent) {
          // Create a container to hold the extracted content
          const container = document.createElement('div');
          container.className = 'extracted-content';
          container.innerHTML = nestedBody.innerHTML;
          parent.replaceChild(container, nestedHtml);
        }
      } else {
        // If no body found, just remove the nested HTML
        nestedHtml.parentNode?.removeChild(nestedHtml);
      }
    });
  }
  
  // Process image prompts in content - convert to actual images when possible
  const imagePrompts = contentElement.querySelectorAll('.image-prompt');
  if (imagePrompts.length > 0) {
    console.log(`Found ${imagePrompts.length} image prompts, processing them`);
    imagePrompts.forEach(prompt => {
      const promptText = prompt.textContent || '';
      const imgElement = document.createElement('img');
      imgElement.alt = promptText;
      imgElement.className = 'image-prompt-placeholder';
      imgElement.style.maxWidth = '100%';
      imgElement.style.borderRadius = '0.5rem';
      imgElement.style.border = '2px dashed #3b82f6';
      imgElement.style.padding = '1rem';
      imgElement.style.backgroundColor = 'rgba(59, 130, 246, 0.1)';
      
      // Try to find an image URL in the prompt text
      const urlMatch = promptText.match(/https?:\/\/[^\s"]+/);
      if (urlMatch) {
        imgElement.src = urlMatch[0];
      } else {
        // Use a placeholder image if no URL found
        imgElement.src = "https://via.placeholder.com/800x450?text=Image:+" + encodeURIComponent(promptText.substring(0, 30));
      }
      prompt.parentNode?.replaceChild(imgElement, prompt);
    });
  }
  
  // Ensure all links open in a new tab
  const links = contentElement.querySelectorAll('a');
  links.forEach(link => {
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');
  });
};
