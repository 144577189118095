
import { CreditScoreValue, CREDIT_SCORE_RANGES, MortgageProgramValue, LoanStructureValue, CURRENT_MARKET_RATES, PROGRAM_RATE_ADJUSTMENTS, STRUCTURE_RATE_ADJUSTMENTS, DOWN_PAYMENT_REQUIREMENTS } from "./constants";

// Calculate monthly payment
export const calculateMonthlyPayment = (principal: number, annualRate: number, years: number) => {
  const monthlyRate = annualRate / 100 / 12;
  const numberOfPayments = years * 12;
  
  if (monthlyRate === 0) {
    return principal / numberOfPayments;
  }
  
  const monthlyPayment = principal * 
    (monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / 
    (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
  
  return monthlyPayment;
};

// Format currency
export const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat('en-US', { 
    style: 'currency', 
    currency: 'USD',
    maximumFractionDigits: 0
  }).format(Math.round(amount));
};

// Get credit score range label
export const getCreditScoreLabel = (value: CreditScoreValue) => {
  return CREDIT_SCORE_RANGES.find(range => range.value === value)?.label || value;
};

// Get current interest rate based on loan program, structure, and term
export const getCurrentInterestRate = (
  program: MortgageProgramValue,
  structure: LoanStructureValue,
  term: string
) => {
  // Base rate from current market data
  let baseRate: number;
  
  if (structure === "fixed") {
    // Fixed rate based on program and term
    baseRate = CURRENT_MARKET_RATES.rates[program][term];
  } else {
    // ARM rate
    baseRate = CURRENT_MARKET_RATES.rates[structure];
  }
  
  return baseRate;
};

// Get down payment requirement based on program
export const getDownPaymentRequirement = (program: MortgageProgramValue) => {
  return DOWN_PAYMENT_REQUIREMENTS[program] || 0.03; // Default to 3%
};

// Get adjusted interest rate based on credit score
export const getAdjustedInterestRate = (
  baseRate: number,
  creditScore: CreditScoreValue
) => {
  // Adjust rate based on credit score
  let adjustment = 0;
  
  switch (creditScore) {
    case "excellent":
      adjustment = -0.5;
      break;
    case "very-good":
      adjustment = -0.25;
      break;
    case "good":
      adjustment = 0;
      break;
    case "fair":
      adjustment = 0.5;
      break;
    case "poor":
      adjustment = 1.5;
      break;
  }
  
  return baseRate + adjustment;
};

// Calculate estimated mortgage insurance (PMI)
export const calculateMortgageInsurance = (
  loanAmount: number,
  downPaymentPercent: number,
  program: MortgageProgramValue
) => {
  // No PMI for VA loans
  if (program === "va") {
    return 0;
  }
  
  // No PMI if down payment >= 20%
  if (downPaymentPercent >= 20) {
    return 0;
  }
  
  // Approximate PMI rates based on program and down payment
  let pmiRate = 0;
  
  if (program === "conventional") {
    if (downPaymentPercent < 5) {
      pmiRate = 1.1;
    } else if (downPaymentPercent < 10) {
      pmiRate = 0.8;
    } else if (downPaymentPercent < 15) {
      pmiRate = 0.5;
    } else {
      pmiRate = 0.3;
    }
  } else if (program === "fha") {
    // FHA MIP is typically around 0.85% annually for most loans
    pmiRate = 0.85;
  } else if (program === "usda") {
    // USDA has an annual fee of 0.35%
    pmiRate = 0.35;
  }
  
  // Calculate monthly PMI
  return (loanAmount * (pmiRate / 100)) / 12;
};

// Calculate additional fees based on program
export const calculateProgramFees = (
  loanAmount: number,
  program: MortgageProgramValue
) => {
  let upfrontFee = 0;
  
  // Calculate upfront fees
  switch (program) {
    case "fha":
      // FHA has 1.75% upfront mortgage insurance premium
      upfrontFee = loanAmount * 0.0175;
      break;
    case "va":
      // VA funding fee varies, using 2.3% as an average
      upfrontFee = loanAmount * 0.023;
      break;
    case "usda":
      // USDA has 1% upfront guarantee fee
      upfrontFee = loanAmount * 0.01;
      break;
    default:
      upfrontFee = 0;
  }
  
  return {
    upfrontFee,
    upfrontFeeFormatted: formatCurrency(upfrontFee)
  };
};

// Calculate loan results
export interface LoanResults {
  currentRate: number;
  currentMonthly: number;
  currentTotal: number;
  comparisonRate: number;
  comparisonMonthly: number;
  comparisonTotal: number;
  monthlySavings: number;
  totalSavings: number;
}

export interface MortgageDetails {
  interestRate: number;
  downPaymentPercent: number;
  downPaymentAmount: number;
  loanAmount: number;
  monthlyPrincipalAndInterest: number;
  monthlyMortgageInsurance: number;
  upfrontFees: number;
  totalMonthlyPayment: number;
  totalLoanCost: number;
}

export interface ChartDataItem {
  name: string;
  "Monthly Payment": number;
  "Total Cost": number;
}

// Get loan program description
export const getLoanProgramDescription = (program: MortgageProgramValue) => {
  switch (program) {
    case "conventional":
      return "Traditional mortgage loans offered by private lenders and typically requiring at least 3% down.";
    case "fha":
      return "FHA loans are insured by the Federal Housing Administration and allow down payments as low as 3.5% with more flexible credit requirements. Ideal for first-time homebuyers.";
    case "va":
      return "VA loans are guaranteed by the Department of Veterans Affairs for eligible veterans, active duty service members, and surviving spouses with no down payment required.";
    case "usda":
      return "USDA loans are for rural properties with 0% down payment, backed by the United States Department of Agriculture for low to moderate-income homebuyers.";
    case "jumbo":
      return "Jumbo loans exceed the conforming loan limits set by Fannie Mae and Freddie Mac, typically requiring larger down payments and excellent credit.";
    default:
      return "";
  }
};

// Get loan structure description
export const getLoanStructureDescription = (structure: LoanStructureValue) => {
  switch (structure) {
    case "fixed":
      return "Fixed rate loans maintain the same interest rate for the entire loan term.";
    case "arm-5-1":
      return "5/1 ARM has a fixed rate for the first 5 years, then adjusts annually. Initial rates are typically lower than fixed rates.";
    case "arm-7-1":
      return "7/1 ARM has a fixed rate for the first 7 years, then adjusts annually. Offers a longer initial fixed period than the 5/1 ARM.";
    case "arm-10-1":
      return "10/1 ARM has a fixed rate for the first 10 years, then adjusts annually. Provides the longest initial fixed period among common ARMs.";
    default:
      return "";
  }
};
