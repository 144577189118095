
const MexicanTestimonials = () => {
  return (
    <section className="py-16 bg-black">
      <div className="container mx-auto px-4 max-w-5xl">
        <h2 className="text-3xl font-display text-center mb-12 text-shadow-gold">Historias de Éxito</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Story 1 - Large */}
          <div className="bg-gray-900 p-6 rounded-lg shadow-md border border-gray-800 md:col-span-2 lg:row-span-2">
            <p className="text-lg italic mb-6">
              "Trabajar con Juan hizo que la inversión en propiedades de McAllen fuera sencilla. Entendió nuestras necesidades como empresa mexicana de productos agrícolas que se expandía al mercado estadounidense y nos encontró la instalación de distribución perfecta con espacio para crecer. El proceso fue fluido a pesar de las complejidades transfronterizas."
            </p>
            <div className="flex items-center">
              <div className="w-12 h-12 bg-[#d4af37] rounded-full mr-4"></div>
              <div>
                <h4 className="font-semibold">Carlos Mendoza</h4>
                <p className="text-sm text-gray-400">CEO, Exportación de Productos Frescos</p>
              </div>
            </div>
          </div>
          
          {/* Story 2 - Medium */}
          <div className="bg-gray-900 p-6 rounded-lg shadow-md border border-gray-800">
            <p className="italic mb-6">
              "Juan nos ayudó a encontrar un hermoso hogar en McAllen que funciona tanto como nuestra propiedad vacacional como fuente de ingresos por alquiler. Su conocimiento de los mercados mexicano y estadounidense fue invaluable."
            </p>
            <div className="flex items-center">
              <div className="w-12 h-12 bg-[#d4af37] rounded-full mr-4"></div>
              <div>
                <h4 className="font-semibold">Ana y Miguel Fuentes</h4>
                <p className="text-sm text-gray-400">Monterrey, México</p>
              </div>
            </div>
          </div>
          
          {/* Story 3 - Medium */}
          <div className="bg-gray-900 p-6 rounded-lg shadow-md border border-gray-800 lg:col-span-2">
            <p className="italic mb-6">
              "Como alguien que busca la doble ciudadanía, encontrar la propiedad adecuada en los Estados Unidos era crucial. Juan navegó las complejidades perfectamente y nos encontró una inversión que cumple con nuestros requisitos legales mientras proporciona excelentes rendimientos."
            </p>
            <div className="flex items-center">
              <div className="w-12 h-12 bg-[#d4af37] rounded-full mr-4"></div>
              <div>
                <h4 className="font-semibold">Luisa Rodríguez</h4>
                <p className="text-sm text-gray-400">Empresaria de la Ciudad de México</p>
              </div>
            </div>
          </div>
          
          {/* Story 4 - Small */}
          <div className="bg-gray-900 p-6 rounded-lg shadow-md border border-gray-800">
            <p className="italic mb-6">
              "El conocimiento de Juan sobre bienes raíces comerciales en McAllen ayudó a que nuestro negocio minorista se expandiera sin problemas de México a los Estados Unidos."
            </p>
            <div className="flex items-center">
              <div className="w-12 h-12 bg-[#d4af37] rounded-full mr-4"></div>
              <div>
                <h4 className="font-semibold">Roberto Vega</h4>
                <p className="text-sm text-gray-400">Propietario de Cadena Minorista</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MexicanTestimonials;
