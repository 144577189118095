
import React, { useEffect } from 'react';
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import HomeEstimateHero from '@/components/estimate/HomeEstimateHero';
import HowItWorks from '@/components/estimate/HowItWorks';
import WhyChooseUs from '@/components/estimate/WhyChooseUs';
import WhatToDoNext from '@/components/estimate/WhatToDoNext';
import TrustCredibility from '@/components/estimate/TrustCredibility';
import { useSEO } from '@/context/SEOContext';
import SEO from '@/components/SEO';
import ChatBot from '@/components/common/ChatBot';
import QuickLinks from '@/components/common/QuickLinks';
import PropertySearchButton from '@/components/common/PropertySearchButton';
import { Link } from 'react-router-dom';

const HomeEstimate: React.FC = () => {
  const { updateSEO } = useSEO();
  
  useEffect(() => {
    // Update SEO metadata for this page
    updateSEO({
      title: 'Home Value Estimate | Juan Elizondo RE/MAX',
      description: 'Find out what your home is really worth with a free home value estimate from Juan Elizondo, your trusted RE/MAX agent in McAllen, TX.',
      keywords: ['home value', 'property estimate', 'home worth', 'McAllen real estate', 'home valuation'],
    });
    
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []); // Remove updateSEO from dependency array to prevent infinite loops

  return (
    <div className="min-h-screen flex flex-col">
      <SEO />
      <Navbar />
      
      {/* Hero section */}
      <HomeEstimateHero />
      
      {/* Content sections */}
      <HowItWorks />
      <WhyChooseUs />
      <WhatToDoNext />
      <TrustCredibility />
      
      {/* Flip Calculator Callout */}
      <section className="bg-gray-50 py-12">
        <div className="container mx-auto px-4 max-w-4xl text-center">
          <h3 className="text-2xl font-bold mb-4">Thinking of Flipping a Property?</h3>
          <p className="mb-6 text-gray-700">
            Use Juan's House Flipping Calculator to estimate your potential profits and make informed investment decisions.
          </p>
          <Link to="/flip-calculator">
            <button className="bg-gold-500 hover:bg-gold-600 text-white px-8 py-3 rounded-lg font-medium">
              Try Our Flip Calculator
            </button>
          </Link>
        </div>
      </section>
      
      <Footer />
      
      {/* Add ChatBot */}
      <ChatBot />
      
      {/* Add PropertySearchButton and QuickLinks */}
      <PropertySearchButton />
      <QuickLinks />
    </div>
  );
};

export default HomeEstimate;
