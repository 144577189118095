
import AgentProfile from "./tabs/AgentProfile";
import TabsContainer from "./tabs/TabsContainer";
import ContactCallout from "./tabs/ContactCallout";

const MexicanContentTabs = () => {
  return (
    <section className="py-24 bg-black">
      <div className="container mx-auto px-4 max-w-6xl">
        <AgentProfile />

        <div className="text-center mb-12">
          <h2 className="text-4xl font-display text-shadow-gold">
            Por Qué Elegir a Juan Elizondo
          </h2>
        </div>
        
        <div className="my-10">
          <TabsContainer />
        </div>
        
        <ContactCallout />
      </div>
    </section>
  );
};

export default MexicanContentTabs;
