
/**
 * Generate structured data for a local business (real estate agency)
 * @returns Local business structured data
 */
export const generateLocalBusinessData = () => {
  return {
    "@context": "https://schema.org",
    "@type": "RealEstateAgent",
    "name": "Juan Elizondo - RE/MAX Elite",
    "image": "/lovable-uploads/d43397b6-a154-4bec-829d-f291ae2a0b34.png",
    "logo": "/lovable-uploads/8e1f1c51-05b6-4bf6-9820-9008ebf1477a.png",
    "url": window.location.origin,
    "telephone": "+1-956-555-5555",
    "priceRange": "$$",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "123 Realty Lane",
      "addressLocality": "McAllen",
      "addressRegion": "TX",
      "postalCode": "78501",
      "addressCountry": "US"
    },
    "email": "juan@juanjoseelizondo.com",
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 26.2034,
      "longitude": -98.2301
    },
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        "opens": "09:00",
        "closes": "18:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": ["Saturday"],
        "opens": "10:00",
        "closes": "15:00"
      }
    ],
    "sameAs": [
      "https://www.facebook.com/JuanElizondoRealtor",
      "https://www.instagram.com/juanelizondorealtor",
      "https://www.linkedin.com/in/juan-elizondo-realtor"
    ]
  };
};

/**
 * Generate structured data for a real estate listing
 * @param listing - Property listing details
 * @returns Property listing structured data
 */
export const generatePropertyListingData = (listing: {
  name: string;
  description: string;
  price: number;
  priceCurrency?: string;
  image: string[];
  address: {
    streetAddress: string;
    addressLocality: string;
    addressRegion: string;
    postalCode: string;
    addressCountry: string;
  };
  numberOfRooms?: number;
  floorSize?: {
    value: number;
    unitCode: string;
  };
}) => {
  return {
    "@context": "https://schema.org",
    "@type": "RealEstateListing",
    "name": listing.name,
    "description": listing.description,
    "image": listing.image,
    "offers": {
      "@type": "Offer",
      "price": listing.price,
      "priceCurrency": listing.priceCurrency || "USD"
    },
    "address": {
      "@type": "PostalAddress",
      ...listing.address
    },
    ...(listing.numberOfRooms && { "numberOfRooms": listing.numberOfRooms }),
    ...(listing.floorSize && { 
      "floorSize": {
        "@type": "QuantitativeValue",
        ...listing.floorSize
      }
    })
  };
};

/**
 * Generate structured data for a blog post
 * @param post - Blog post details
 * @returns Blog post structured data
 */
export const generateBlogPostData = (post: {
  title: string;
  description: string;
  url: string;
  imageUrl: string;
  datePublished: string;
  dateModified?: string;
  authorName: string;
}) => {
  return {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline": post.title,
    "description": post.description,
    "image": post.imageUrl,
    "datePublished": post.datePublished,
    "dateModified": post.dateModified || post.datePublished,
    "author": {
      "@type": "Person",
      "name": post.authorName
    },
    "publisher": {
      "@type": "Organization",
      "name": "Juan Elizondo Real Estate",
      "logo": {
        "@type": "ImageObject",
        "url": "/lovable-uploads/8e1f1c51-05b6-4bf6-9820-9008ebf1477a.png"
      }
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": post.url
    }
  };
};
