
import React, { useLayoutEffect, useRef } from "react";
import { cn } from "@/lib/utils";
import { useTheme } from "@/context/ThemeContext";
import { refreshGlowEffect } from "@/utils/glowTextUtils";

type ElementTypeMap = {
  h1: HTMLHeadingElement;
  h2: HTMLHeadingElement;
  h3: HTMLHeadingElement;
  h4: HTMLHeadingElement;
  h5: HTMLHeadingElement;
  h6: HTMLHeadingElement;
  span: HTMLSpanElement;
  p: HTMLParagraphElement;
  div: HTMLDivElement;
};

type ElementType = keyof ElementTypeMap;

interface GlowingTextProps<T extends ElementType> {
  text: string;
  as?: T;
  className?: string;
  glowColor?: "gold" | "blue" | "purple";
  advanced?: boolean;
}

const GlowingText = <T extends ElementType>({
  text,
  as: elementType = "h1" as T,
  className = "",
  glowColor = "gold",
  advanced = false
}: GlowingTextProps<T>) => {
  const { theme } = useTheme();
  const componentRef = useRef<ElementTypeMap[T]>(null);
  
  // Determine color values based on the glowColor prop - increased shadow opacity
  const colorMap = {
    gold: {
      gradientFrom: "#dfe5ee",
      gradientTo: "#fffaf6",
      shadow: "rgba(238, 200, 175, 0.7)" // Increased opacity from 0.6 to 0.7
    },
    blue: {
      gradientFrom: "#c8f7ff",
      gradientTo: "#f0ffff",
      shadow: "rgba(100, 217, 255, 0.7)" // Increased opacity from 0.6 to 0.7
    },
    purple: {
      gradientFrom: "#e8d0ff",
      gradientTo: "#fff0ff",
      shadow: "rgba(188, 100, 255, 0.7)" // Increased opacity from 0.6 to 0.7
    }
  };
  
  const colors = colorMap[glowColor];

  // Determine which filter to use based on color and advanced setting
  const getFilterId = () => {
    if (!advanced) return "glow-filter"; // Use basic filter if not advanced
    
    if (glowColor === "blue") return "advanced-glow-blue";
    if (glowColor === "purple") return "advanced-glow-purple";
    return "advanced-glow"; // Default to gold/orange for advanced
  };
  
  // Use useLayoutEffect for immediate DOM manipulation
  useLayoutEffect(() => {
    if (componentRef.current) {
      // Set data-text attribute directly on the element
      componentRef.current.setAttribute('data-text', text);
      
      // Force refresh glow effect
      setTimeout(() => {
        refreshGlowEffect();
      }, 50);
    }
  }, [text]);

  // For advanced Apple-style glow, we use a different structure
  if (advanced) {
    return (
      <div className="inline-block overflow-visible">
        {React.createElement(
          elementType,
          {
            ref: componentRef,
            className: cn(
              "apple-glow-text",
              className
            ),
            "data-text": text,
            style: {
              background: `linear-gradient(0deg, ${colors.gradientFrom} 0%, ${colors.gradientTo} 50%)`,
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              filter: `url(#${getFilterId()})`,
              textShadow: `0 0 15px ${colors.shadow}, 0 0 30px ${colors.shadow}`, // Increased from 12px/24px
              paddingBottom: "0.15em", // Add padding to prevent text cut-off
            }
          },
          text
        )}
      </div>
    );
  }
  
  // Standard glow effect with wrapper and duplicate elements
  return (
    <div 
      className={cn(
        "glowing-text-wrapper relative inline-block overflow-visible",
        "dark" // Always use dark class
      )}
    >
      {/* Main text element */}
      {React.createElement(
        elementType,
        {
          ref: componentRef,
          className: cn(
            "glowing-text relative inline-block",
            "text-transparent", // Make text transparent for basic glow
            className
          ),
          "data-text": text,
          style: {
            position: "relative",
            zIndex: 1,
            paddingBottom: "0.15em", // Add padding to prevent text cut-off
          }
        },
        text
      )}
      
      {/* Glowing version of the text that's always visible */}
      {React.createElement(
        elementType,
        {
          className: cn(
            "absolute inset-0 pointer-events-none direct-glow-effect"
          ),
          "aria-hidden": true,
          style: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2, // Higher than the original text
            color: colors.gradientTo,
            background: `linear-gradient(0deg, ${colors.gradientFrom} 0%, ${colors.gradientTo} 50%)`,
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            filter: `url(#${getFilterId()})`,
            textShadow: `0 0 15px ${colors.shadow}, 0 0 30px ${colors.shadow}`, // Increased from 12px/24px
            opacity: 1, // Increased from 0.95 to ensure full visibility
            paddingBottom: "0.15em", // Add padding to prevent text cut-off
          }
        },
        text
      )}
    </div>
  );
};

export default GlowingText;
