
import { useLanguage } from "@/context/LanguageContext";
import { useState, useEffect } from "react";
import { refreshGlowEffect } from "@/utils/glowTextUtils";
import GlowingText from "@/components/common/GlowingText";

const ContactCallout = () => {
  const { language } = useLanguage();
  const [isHovered, setIsHovered] = useState(false);
  
  // Force refresh the glow effect after component mounts
  useEffect(() => {
    const timer = setTimeout(() => {
      refreshGlowEffect();
    }, 500);
    
    return () => clearTimeout(timer);
  }, []);

  return (
    <div 
      className="mt-10 bg-estate-900/90 p-6 rounded-lg flex flex-col md:flex-row items-center justify-between neon-glow-container"
      style={{
        boxShadow: isHovered 
          ? '0 0 15px 8px rgba(59, 130, 246, 0.6), 0 0 30px rgba(59, 130, 246, 0.4)' 
          : '0 0 10px 5px rgba(59, 130, 246, 0.35)',
        transition: 'all 0.3s ease'
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="neon-border"></div>
      <div className="text-center md:text-left mb-6 md:mb-0 relative z-10">
        <GlowingText 
          text="Contacte a Juan Elizondo Hoy" 
          as="h3" 
          className="text-xl font-semibold text-white"
          glowColor="gold"
          advanced={true}
        />
        <p className="text-blue-300 mt-2">
          Para discutir sus oportunidades de inversión en bienes raíces en Estados Unidos
        </p>
        <p className="text-blue-300 mt-1">
          <a href="mailto:juan@juanjoseelizondo.com" className="hover:text-blue-200 transition">
            juan@juanjoseelizondo.com
          </a>
        </p>
      </div>
      <div className="relative z-10">
        <a 
          href="tel:+19565221481" 
          className="inline-flex items-center px-4 py-2 rounded-md bg-[#d4af37] text-black font-medium hover:bg-[#b8941f] transition-colors"
        >
          Llamar: (956) 522-1481
        </a>
      </div>
    </div>
  );
};

export default ContactCallout;
