
import React from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import DesktopCommunities from "./DesktopCommunities";

interface CommunitiesDropdownProps {
  linkStyle: React.CSSProperties;
  isActive: (path: string) => string;
  communitiesOpen: boolean;
  toggleCommunities: () => void;
  handleCloseCommunities: () => void;
  handleCitySelect: (cityName: string) => void;
  dropdownRef: React.RefObject<HTMLDivElement>;
}

const CommunitiesDropdown: React.FC<CommunitiesDropdownProps> = ({
  linkStyle,
  isActive,
  communitiesOpen,
  toggleCommunities,
  handleCloseCommunities,
  handleCitySelect,
  dropdownRef
}) => {
  return (
    <div 
      className="relative" 
      ref={dropdownRef}
      style={{
        transform: 'translateZ(0)',
        backfaceVisibility: 'hidden'
      }}
    >
      <button
        onClick={toggleCommunities}
        className={`${isActive("/communities")} font-medium hover:text-gold-500 transition-colors duration-300 flex items-center`}
        style={linkStyle}
      >
        Communities
        <span className="ml-1">{communitiesOpen ? <ChevronUp size={14} /> : <ChevronDown size={14} />}</span>
      </button>
      {communitiesOpen && (
        <DesktopCommunities 
          isOpen={communitiesOpen} 
          onMouseLeave={handleCloseCommunities} 
          onCitySelect={handleCitySelect} 
        />
      )}
    </div>
  );
};

export default CommunitiesDropdown;
