
/**
 * Utilities for finding and manipulating text nodes
 */

/**
 * Find text nodes containing a specific string
 * @param element - The element to search in
 * @param searchText - The text to search for
 * @returns An array of text nodes containing the search text
 */
export function findTextNodes(element: HTMLElement, searchText: string): Text[] {
  const result: Text[] = [];
  const walker = document.createTreeWalker(
    element,
    NodeFilter.SHOW_TEXT,
    {
      acceptNode: function(node) {
        return node.textContent && node.textContent.includes(searchText)
          ? NodeFilter.FILTER_ACCEPT
          : NodeFilter.FILTER_REJECT;
      }
    }
  );
  
  let node;
  while (node = walker.nextNode()) {
    result.push(node as Text);
  }
  
  return result;
}
