
import { Slider } from "@/components/ui/slider";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useState, useEffect } from "react";

interface InterestRateInputProps {
  interestRate: number;
  onInterestRateChange: (value: number) => void;
}

const InterestRateInput = ({
  interestRate,
  onInterestRateChange,
}: InterestRateInputProps) => {
  const [localInterestRate, setLocalInterestRate] = useState(interestRate.toString());
  
  // Update local state when prop changes
  useEffect(() => {
    setLocalInterestRate(interestRate.toString());
  }, [interestRate]);
  
  // Handle manual input of interest rate
  const handleInterestRateInput = (value: string) => {
    setLocalInterestRate(value);
    const numericValue = parseFloat(value.replace(/[^0-9.]/g, ''));
    if (!isNaN(numericValue)) {
      onInterestRateChange(numericValue);
    }
  };

  return (
    <div className="space-y-2">
      <div className="flex justify-between items-center">
        <Label htmlFor="interestRate" className="text-base font-medium">Interest Rate (%)</Label>
        <Input
          id="interestRateInput"
          className="w-28 text-right"
          value={localInterestRate}
          onChange={(e) => handleInterestRateInput(e.target.value)}
        />
      </div>
      <Slider
        id="interestRate"
        min={1}
        max={10}
        step={0.1}
        value={[interestRate]}
        onValueChange={(value) => {
          onInterestRateChange(value[0]);
          setLocalInterestRate(value[0].toString());
        }}
        className="py-4"
      />
      <div className="flex justify-between text-xs text-gray-500">
        <span>1%</span>
        <span>10%</span>
      </div>
    </div>
  );
};

export default InterestRateInput;
