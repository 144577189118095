
import React from 'react';
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import SEO from '@/components/SEO';
import ChatBot from '@/components/common/ChatBot';
import PropertySearchButton from '@/components/common/PropertySearchButton';
import QuickLinks from '@/components/common/QuickLinks';
import FlipCalculatorContainer from '@/components/flip-calculator/FlipCalculatorContainer';

const FlipCalculator: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col bg-black text-white">
      <SEO />
      <Navbar />
      
      <main className="flex-grow">
        <div className="pt-48 pb-28">
          <FlipCalculatorContainer />
        </div>
      </main>
      
      <Footer />
      
      {/* Add ChatBot */}
      <ChatBot />
      
      {/* Add PropertySearchButton and QuickLinks */}
      <PropertySearchButton />
      <QuickLinks />
    </div>
  );
};

export default FlipCalculator;
