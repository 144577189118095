
import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Trash2, AlertCircle, RefreshCw } from "lucide-react";
import { deleteAllNonDefaultBlogPosts } from "@/integrations/supabase/client";
import { toast } from "sonner";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import BlogPostsTable from "@/components/blog/BlogPostsTable";
import JuansBlogPostsTable from "@/components/blog/JuansBlogPostsTable";
import AddNewBlogPost from "@/components/blog/AddNewBlogPost";
import BlogDebugPanel from "@/components/blog/BlogDebugPanel";
import JsonSubmitForm from "@/components/blog/JsonSubmitForm";
import CustomBlogSubmitForm from "@/components/blog/CustomBlogSubmitForm";
import { useBlogPosts } from "@/hooks/useBlogPosts";

interface BlogAdminPanelProps {
  onRefresh: () => void;
}

const BlogAdminPanel = ({ onRefresh }: BlogAdminPanelProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("Blog Posts");
  
  // Add state for JsonSubmitForm
  const [jsonContent, setJsonContent] = useState<string>("");
  const [useAirtableProcessor, setUseAirtableProcessor] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isOnline, setIsOnline] = useState<boolean>(true);
  const [edgeFunctionStatus, setEdgeFunctionStatus] = useState<'unknown' | 'available' | 'unavailable'>('unknown');
  
  // Get blog posts data for the debug panel
  const { 
    blogPosts: allPosts, 
    loading, 
    lastRefreshTime, 
    dbPostsCount, 
    defaultPostsCount 
  } = useBlogPosts();
  
  const handleExampleClick = () => {
    // Provide an example JSON for the form
    setJsonContent(JSON.stringify({
      title: "Example Blog Post",
      content: "<p>This is an example blog post content.</p>",
      excerpt: "This is an example excerpt.",
      author: "Juan Elizondo",
      category: "Residential",
      image: "https://example.com/image.jpg"
    }, null, 2));
  };
  
  const handleSubmit = () => {
    // Placeholder for JSON submission
    console.log("Submitting JSON:", jsonContent);
    // You would typically call your submission function here
  };

  const tabs = [
    { name: "Blog Posts", component: <BlogPostsTable /> },
    { name: "Juan's Blogs", component: <JuansBlogPostsTable /> },
    { name: "Add New Post", component: <AddNewBlogPost /> },
    { name: "Debug Panel", component: <BlogDebugPanel 
      allPosts={allPosts} 
      dbPosts={allPosts.filter(post => !String(post.id).includes('default-'))}
      defaultPosts={allPosts.filter(post => String(post.id).includes('default-'))}
      loading={loading}
      lastRefreshTime={lastRefreshTime || "Never"}
      combinedRefreshKey={0}
      activeCategory={"All"}
      searchQuery={""}
    /> },
    { name: "Submit JSON", component: <JsonSubmitForm 
      jsonContent={jsonContent}
      setJsonContent={setJsonContent}
      useAirtableProcessor={useAirtableProcessor}
      setUseAirtableProcessor={setUseAirtableProcessor}
      handleExampleClick={handleExampleClick}
      isSubmitting={isSubmitting}
      isOnline={isOnline}
      edgeFunctionStatus={edgeFunctionStatus}
      handleSubmit={handleSubmit}
    /> },
    { name: "Custom Submit", component: <CustomBlogSubmitForm /> }
  ];

  const handleDeleteAllPosts = async () => {
    try {
      setIsDeleting(true);
      const result = await deleteAllNonDefaultBlogPosts();
      
      if (result.success) {
        toast.success(`Successfully deleted ${result.deleted} blog posts`, {
          description: "Only the default sample posts remain."
        });
        // Refresh the blog posts list
        onRefresh();
      } else {
        toast.error("Failed to delete blog posts", {
          description: result.error
        });
      }
    } catch (error) {
      toast.error("An unexpected error occurred", {
        description: error instanceof Error ? error.message : "Unknown error"
      });
    } finally {
      setIsDeleting(false);
      setShowConfirmDialog(false);
    }
  };

  return (
    <div className="mb-8 border border-red-200 bg-red-50 dark:bg-red-950/20 dark:border-red-900 rounded-lg p-4">
      <div className="flex items-start gap-4">
        <AlertCircle className="text-red-500 h-6 w-6 mt-1 flex-shrink-0" />
        <div className="flex-1">
          <h3 className="text-lg font-medium text-red-700 dark:text-red-400">Database Administration</h3>
          <p className="text-sm text-red-600 dark:text-red-300 mt-1 mb-4">
            Warning: The actions below are irreversible and will permanently delete data from your database.
          </p>
          
          <Button 
            variant="destructive" 
            size="sm"
            className="flex items-center gap-2"
            onClick={() => setShowConfirmDialog(true)}
            disabled={isDeleting}
          >
            {isDeleting ? <RefreshCw className="h-4 w-4 animate-spin" /> : <Trash2 className="h-4 w-4" />}
            Delete All Blog Posts (Keep Defaults)
          </Button>
        </div>
      </div>

      <AlertDialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action will permanently delete ALL blog posts from the database except for the default sample posts.
              <span className="block mt-2 font-medium text-red-600 dark:text-red-400">
                This action cannot be undone.
              </span>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction 
              onClick={handleDeleteAllPosts}
              className="bg-red-500 hover:bg-red-600"
            >
              {isDeleting ? "Deleting..." : "Yes, Delete All Posts"}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default BlogAdminPanel;
