
import React from "react";
import { Link } from "react-router-dom";

interface NavLinkProps {
  to: string;
  text: string;
  isActive: (path: string) => string;
  style: React.CSSProperties;
}

const NavLink: React.FC<NavLinkProps> = ({ to, text, isActive, style }) => {
  return (
    <Link 
      to={to} 
      className={`${isActive(to)} font-medium hover:text-gold-500 transition-colors duration-300`}
      style={style}
    >
      {text}
    </Link>
  );
};

export default NavLink;
