
import React from "react";
import { formatDistanceToNow } from "date-fns";
import { Calendar, User } from "lucide-react";

interface BlogPostCardMetaProps {
  date: string;
  author: string;
}

const BlogPostCardMeta = ({ date, author }: BlogPostCardMetaProps) => {
  // Format the date to relative time
  const formattedDate = date 
    ? formatDistanceToNow(new Date(date), { addSuffix: true })
    : "Unknown date";

  return (
    <div className="flex items-center gap-4 mb-2 text-sm text-gray-300">
      <div className="flex items-center gap-1">
        <Calendar className="h-3.5 w-3.5 text-gray-300" />
        <span>{formattedDate}</span>
      </div>
      
      <div className="flex items-center gap-1">
        <User className="h-3.5 w-3.5 text-gray-300" />
        <span>{author || "Anonymous"}</span>
      </div>
    </div>
  );
};

export default BlogPostCardMeta;
