
import { CityInfo } from "../types/CommunityTypes";

export const BrownsvilleData: CityInfo = {
  headline: "Discover Coastal Charm in Brownsville",
  description: "As the southernmost city in Texas, Brownsville offers a unique blend of coastal living, rich history, and international culture. With its proximity to South Padre Island and Mexico, residents enjoy diverse recreational opportunities and a growing economy.",
  marketInfo: {
    avgPrice: "$220,000",
    trend: "Rising 4% annually",
    daysOnMarket: "55 days"
  },
  communities: [
    {
      name: "Rancho Viejo",
      description: "Peaceful country club living with golf course access",
      features: ["Golf community", "Nature preserves", "Spacious lots"],
      priceRange: "$250,000 - $500,000",
      image: "https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8bHV4dXJ5JTIwaG9tZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
    },
    {
      name: "Lakeway",
      description: "Upscale waterfront community with resort-style amenities",
      features: ["Lake views", "Private docks", "Community center"],
      priceRange: "$300,000 - $650,000",
      image: "https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGx1eHVyeSUyMGhvbWV8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
    }
  ],
  featuredProperties: [
    {
      id: 201,
      city: "Brownsville",
      title: "Spanish-Style Villa",
      price: "$365,000",
      image: "https://images.unsplash.com/photo-1599427303058-f04cbcf4756f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHNwYW5pc2glMjBob21lfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      beds: 3,
      baths: 2.5,
      sqft: "2,800"
    },
    {
      id: 202,
      city: "Brownsville",
      title: "Waterfront Paradise",
      price: "$525,000",
      image: "https://images.unsplash.com/photo-1583608205776-bfd35f0d9f83?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGx1eHVyeSUyMGhvbWV8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",
      beds: 4,
      baths: 3,
      sqft: "3,600"
    }
  ]
};
