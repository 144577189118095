
import { findValidImage, defaultImagePaths } from "@/utils/media/imageUtils";

/**
 * Hook to handle image loading functionality
 */
export const useImageLoader = () => {
  // Function to load an image
  const loadImage = async (
    customImagePaths?: string[], 
    onImageFound?: (path: string) => void
  ) => {
    const imagePaths = customImagePaths || defaultImagePaths;
    
    const result = await findValidImage(imagePaths, (path) => {
      if (onImageFound) {
        onImageFound(path);
      }
    });
    
    if (!result.found) {
      console.warn("No valid images found in the provided paths.");
    }
    
    return result;
  };

  return { loadImage };
};
