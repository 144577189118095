// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://svgsbaahxiaeljmfykzp.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InN2Z3NiYWFoeGlhZWxqbWZ5a3pwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDEyODc2ODksImV4cCI6MjA1Njg2MzY4OX0.S80GrL92vr2F-dwzWZqaz3Gt8RgttRi8ccC9y6sRQfI";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);

/**
 * Deletes all blog posts from the database created after March 2, 2025,
 * keeping only the posts created on or before that date (when website development started)
 * as well as default sample posts which have IDs starting with "default-"
 */
export const deleteAllNonDefaultBlogPosts = async () => {
  try {
    console.log("Attempting to delete all blog posts created after March 2, 2025");
    
    // First, let's fetch to see what's there to debug
    const { data: existingPosts, error: fetchError } = await supabase
      .from('blog_posts')
      .select('id, title, created_at')
      .limit(10);
    
    if (fetchError) {
      console.error("Error fetching blog posts for debug:", fetchError);
    } else {
      console.log("Sample of existing posts:", existingPosts);
    }
    
    // Delete posts created after March 2, 2025 (assumed start date)
    // This keeps posts from March 2, 2025 and earlier, plus all default sample posts
    // since default posts only exist in the frontend
    const { error, count } = await supabase
      .from('blog_posts')
      .delete({ count: 'exact' })
      .gt('created_at', '2025-03-02T23:59:59Z');
    
    if (error) {
      console.error("Error deleting blog posts:", error);
      return { success: false, error: error.message };
    }
    
    console.log(`Successfully deleted ${count} blog posts created after March 2, 2025.`);
    return { success: true, deleted: count };
  } catch (error) {
    console.error("Unexpected error during posts deletion:", error);
    return { 
      success: false, 
      error: error instanceof Error ? error.message : "Unknown error" 
    };
  }
};

/* 
// Preserved code for future reference (DO NOT UNCOMMENT)
// Function to delete posts created after March 2, 2025
export const deletePostsAfterMarch2 = async () => {
  try {
    // Delete posts where the date part of created_at is after March 2, 2025
    const { error, count } = await supabase
      .from('blog_posts')
      .delete({ count: 'exact' })
      .gt('created_at', '2025-03-02T23:59:59Z');
    
    if (error) {
      console.error("Error deleting posts after March 2:", error);
      return { success: false, error: error.message };
    }
    
    console.log(`Successfully deleted ${count} blog posts created after March 2, 2025.`);
    return { success: true, deleted: count };
  } catch (error) {
    console.error("Unexpected error during posts deletion:", error);
    return { 
      success: false, 
      error: error instanceof Error ? error.message : "Unknown error" 
    };
  }
};
*/
