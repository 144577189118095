
import React from "react";
import CommunityCard from "./CommunityCard";

interface CommunitiesListProps {
  communities: string[];
  activeCity: string;
  expandedCity: string | null;
  toggleCityExpansion: (city: string) => void;
  onCitySelect: (city: string) => void;
}

const CommunitiesList = ({ 
  communities, 
  activeCity, 
  expandedCity, 
  toggleCityExpansion,
  onCitySelect
}: CommunitiesListProps) => {
  return (
    <div id="communities-list" className="mt-8">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-display mb-6">
          {activeCity ? `Other Communities in the Rio Grande Valley` : `Rio Grande Valley Communities`}
        </h2>
        <p className="text-muted-foreground max-w-3xl mx-auto">
          Juan Elizondo provides expert real estate services throughout all communities in the Rio Grande Valley. 
          Whether you're looking to buy, sell, or invest in any of these vibrant areas, Juan is here to help you 
          navigate the local market with confidence.
        </p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {communities.map((city) => (
          <CommunityCard
            key={city}
            city={city}
            isActive={activeCity === city}
            isExpanded={expandedCity === city}
            onToggleExpand={() => toggleCityExpansion(city)}
            onSelect={() => onCitySelect(city)}
          />
        ))}
      </div>
    </div>
  );
};

export default CommunitiesList;
