
import React from "react";
import { Facebook, Twitter, Linkedin, Github } from "lucide-react";

interface GlowingSocialIconsProps {
  className?: string;
  iconSize?: number;
}

const GlowingSocialIcons = ({ className = "", iconSize = 32 }: GlowingSocialIconsProps) => {
  return (
    <div className={`glow-icons-container flex items-center justify-center gap-6 ${className}`}>
      <a 
        href="https://www.facebook.com/search/top?q=juan%20jose%20elizondo%20re%2Fmax%20elite" 
        target="_blank" 
        rel="noopener noreferrer"
        className="glow-icon facebook"
        aria-label="Facebook"
      >
        <Facebook size={iconSize} />
      </a>
      
      <a 
        href="#" 
        className="glow-icon twitter"
        aria-label="Twitter"
      >
        <Twitter size={iconSize} />
      </a>
      
      <a 
        href="#" 
        className="glow-icon github"
        aria-label="Github"
      >
        <Github size={iconSize} />
      </a>
      
      <a 
        href="#" 
        className="glow-icon linkedin"
        aria-label="LinkedIn"
      >
        <Linkedin size={iconSize} />
      </a>
    </div>
  );
};

export default GlowingSocialIcons;
