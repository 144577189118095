
import React from 'react';
import { Button } from '@/components/ui/button';
import { CalculatorButtonsProps } from './types';

const CalculatorButtons: React.FC<CalculatorButtonsProps> = ({
  calculateResults,
  resetForm,
  isLoading
}) => {
  return (
    <div className="flex flex-col md:flex-row gap-4 mt-4">
      <Button
        onClick={calculateResults}
        disabled={isLoading}
        className="flex-1 bg-gold-500 hover:bg-gold-600 text-black font-bold px-8 py-3 calculator-calculate-glow"
      >
        {isLoading ? 'Calculating...' : 'Calculate'}
      </Button>
      <Button
        onClick={resetForm}
        disabled={isLoading}
        className="flex-1 bg-estate-700 hover:bg-estate-600 text-white font-bold px-8 py-3"
      >
        Reset
      </Button>
    </div>
  );
};

export default CalculatorButtons;
