
import React from "react";
import { MapPin, Home, Building } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { AspectRatio } from "@/components/ui/aspect-ratio";
import { Property } from "./CityInfo";

interface FeaturedPropertiesProps {
  cityName: string;
  properties: Property[];
}

const FeaturedProperties = ({ cityName, properties }: FeaturedPropertiesProps) => {
  return (
    <div className="mb-16">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-display mb-4">Featured Properties in {cityName}</h2>
        <p className="text-muted-foreground max-w-3xl mx-auto">
          Explore our selection of exceptional properties currently available in {cityName}.
        </p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {properties.map((property) => (
          <Card key={property.id} className="overflow-hidden group cursor-pointer hover:shadow-lg transition-all duration-300">
            <CardContent className="p-0">
              <div className="relative overflow-hidden">
                <AspectRatio ratio={16/9}>
                  <img
                    src={property.image}
                    alt={property.title}
                    className="object-cover w-full h-full group-hover:scale-110 transition-transform duration-500"
                  />
                </AspectRatio>
                <div className="absolute top-3 left-3 bg-gold-500 text-white text-sm font-semibold py-1 px-3 rounded-full">
                  {property.price}
                </div>
              </div>
              
              <div className="p-6">
                <h3 className="text-xl font-bold mb-2">{property.title}</h3>
                
                <div className="flex items-center text-gray-600 mb-2">
                  <MapPin className="w-4 h-4 mr-1" />
                  <span className="text-sm">{property.city}</span>
                </div>
                
                <div className="flex items-center space-x-4 text-gray-600 mb-4">
                  <div className="flex items-center">
                    <Home className="w-4 h-4 mr-1" />
                    <span className="text-sm">{property.beds} Beds</span>
                  </div>
                  <div className="flex items-center">
                    <MapPin className="w-4 h-4 mr-1" />
                    <span className="text-sm">{property.baths} Baths</span>
                  </div>
                  <div className="flex items-center">
                    <Building className="w-4 h-4 mr-1" />
                    <span className="text-sm">{property.sqft} sq ft</span>
                  </div>
                </div>
                
                <Button className="w-full bg-gold-500 hover:bg-gold-600 text-white transition duration-300">
                  View Property Details
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default FeaturedProperties;
