
import React from 'react';
import { Home, Lightbulb, DollarSign, Calculator } from 'lucide-react';
import { useLanguage } from '@/context/LanguageContext';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

const WhatToDoNext = () => {
  const { t } = useLanguage();
  
  const actions = [
    {
      icon: DollarSign,
      title: "Plan to Sell",
      description: "Ready to sell? Connect with Juan Elizondo to list your home and get the best price in McAllen's market."
    },
    {
      icon: Home,
      title: "Explore Buying",
      description: "Looking to buy? Use your home's value to plan your next move with Juan's expert guidance."
    },
    {
      icon: Lightbulb,
      title: "Make Informed Decisions",
      description: "Use your estimate to refinance, invest, or plan for the future with confidence."
    },
    {
      icon: Calculator,
      title: "Try Our Flip Calculator",
      description: "Planning to flip a property? Use Juan's House Flipping Calculator to estimate your potential profits."
    }
  ];

  const scrollToForm = () => {
    const formElement = document.querySelector('form');
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-4 max-w-6xl">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
          What to Do with Your Home Value Estimate
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          {actions.map((action, index) => (
            <div key={index} className="flex flex-col items-center text-center">
              <div className="mb-4 bg-gold-100 p-4 rounded-full">
                <action.icon size={32} className="text-gold-500" />
              </div>
              <h3 className="text-xl font-bold mb-2">{action.title}</h3>
              <p className="text-gray-700">{action.description}</p>
              
              {action.title === "Try Our Flip Calculator" && (
                <Link to="/flip-calculator" className="mt-4">
                  <Button variant="outline" className="border-gold-500 text-gold-700 hover:bg-gold-50 mt-2">
                    Open Calculator
                  </Button>
                </Link>
              )}
            </div>
          ))}
        </div>
        
        <div className="text-center">
          <Button 
            onClick={scrollToForm}
            className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-6 h-auto text-lg font-medium rounded-lg"
          >
            Get Your Free Estimate Now
          </Button>
        </div>
      </div>
    </section>
  );
};

export default WhatToDoNext;
