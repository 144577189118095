
import React from 'react';
import { useLanguage } from '@/context/LanguageContext';

const TrustCredibility = () => {
  const { t } = useLanguage();

  return (
    <section className="bg-gray-50 py-16">
      <div className="container mx-auto px-4 max-w-6xl">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">
          Trusted by Homeowners in McAllen and Beyond
        </h2>
        
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl md:text-3xl font-bold text-center mb-8">
            Helped 500+ Families Buy and Sell in McAllen
          </p>
          
          <div className="bg-white p-6 rounded-lg shadow-sm max-w-3xl mb-8">
            <p className="text-lg text-gray-700 italic">
              "Juan made selling our home in McAllen so easy! His expertise got us a great price quickly."
            </p>
            <p className="text-right font-medium mt-4">– Maria G., McAllen Resident</p>
          </div>
          
          <div className="flex flex-wrap justify-center items-center gap-8 mt-4">
            <img 
              src="/lovable-uploads/967535c9-c31e-4c4f-81e2-8015abebcc88.png" 
              alt="RE/MAX Elite" 
              className="h-16 object-contain"
            />
            <div className="text-lg font-semibold">McAllen Chamber of Commerce</div>
            <div className="text-lg font-semibold">Texas REALTORS®</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustCredibility;
