
import React from 'react';
import { ChartSectionProps } from './types';
import { ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';

const ChartSection: React.FC<ChartSectionProps> = ({ data, height = 300 }) => {
  const formatYAxis = (value: number) => {
    if (value >= 1000000) return `$${(value / 1000000).toFixed(1)}M`;
    if (value >= 1000) return `$${(value / 1000).toFixed(0)}K`;
    return `$${value}`;
  };

  const chartConfig = {
    buying: { color: "#22c55e" },
    renting: { color: "#3b82f6" },
    buyingEquity: { color: "#10b981" },
    investmentEquity: { color: "#6366f1" }
  };

  return (
    <div className="w-full">
      <h2 className="text-2xl font-bold text-gold-500 mb-4">Cost Comparison Over Time</h2>
      
      <div className="bg-estate-800/60 p-4 rounded-md border border-estate-700">
        <ChartContainer className="w-full h-[350px]" config={chartConfig}>
          <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#444444" />
            <XAxis dataKey="year" label={{ value: 'Years', position: 'insideBottomRight', offset: -5 }} />
            <YAxis tickFormatter={formatYAxis} />
            <ChartTooltip content={<ChartTooltipContent />} />
            <Line type="monotone" dataKey="buying" name="Cost of Buying" stroke="#22c55e" strokeWidth={2} dot={false} />
            <Line type="monotone" dataKey="renting" name="Cost of Renting" stroke="#3b82f6" strokeWidth={2} dot={false} />
            <Line type="monotone" dataKey="buyingEquity" name="Home Equity" stroke="#10b981" strokeWidth={2} dot={false} strokeDasharray="5 5" />
            <Line type="monotone" dataKey="investmentEquity" name="Investment Equity" stroke="#6366f1" strokeWidth={2} dot={false} strokeDasharray="5 5" />
            <Legend />
          </LineChart>
        </ChartContainer>
      </div>
    </div>
  );
};

export default ChartSection;
