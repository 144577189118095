
import { CityInfo } from "../types/CommunityTypes";

export const MissionData: CityInfo = {
  headline: "Explore Beautiful Mission, Texas",
  description: "Mission offers a perfect blend of Texas heritage and modern living. With its affordable housing, family-friendly neighborhoods, and proximity to international commerce, Mission is an ideal place to live, work, and invest.",
  marketInfo: {
    avgPrice: "$245,000",
    trend: "Rising 3.5% annually",
    daysOnMarket: "40 days"
  },
  communities: [
    {
      name: "Sharyland Plantation",
      description: "Upscale community with beautiful homes and premium amenities",
      features: ["Golf course access", "Gated security", "Community pools"],
      priceRange: "$300,000 - $600,000",
      image: "https://images.unsplash.com/photo-1600566753376-12c8ab7fb75b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGhvdXNlJTIwc3VidXJifGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
    },
    {
      name: "Bentsen Palm",
      description: "Nature-focused community near Bentsen-Rio Grande Valley State Park",
      features: ["Nature trails", "Birdwatching", "Eco-friendly designs"],
      priceRange: "$220,000 - $400,000",
      image: "https://images.unsplash.com/photo-1568605114967-8130f3a36994?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aG91c2V8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
    }
  ],
  featuredProperties: [
    {
      id: 401,
      city: "Mission",
      title: "Elegant Family Home in Sharyland",
      price: "$375,000",
      image: "https://images.unsplash.com/photo-1576941089067-2de3c901e126?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGhvdXNlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      beds: 4,
      baths: 3,
      sqft: "2,800"
    },
    {
      id: 402,
      city: "Mission",
      title: "Modern Ranch-Style Home",
      price: "$295,000",
      image: "https://images.unsplash.com/photo-1602343168117-bb8ffe3e2e9f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fGhvdXNlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      beds: 3,
      baths: 2,
      sqft: "2,100"
    }
  ]
};
