
import React from "react";
import { Phone, Mail, MapPin } from "lucide-react";
import GlowingSocialIcons from "../common/GlowingSocialIcons";

const ContactInfo = () => {
  // Office address
  const officeLocation = {
    address: "2575 E Griffin Pkwy, Suite 14, Mission, TX 78572"
  };

  return (
    <div className="space-y-10">
      <div className="bg-estate-900 p-8 rounded-lg border border-gold-500 shadow-lg animate-fadeIn">
        <h2 className="text-3xl font-display mb-6">Contact Information</h2>
        <div className="space-y-6">
          <div className="flex items-start">
            <Phone className="w-6 h-6 text-gold-500 mr-4 mt-1" />
            <div>
              <h3 className="text-xl font-semibold">Phone</h3>
              <p className="text-estate-300">
                <a href="tel:9565221481" className="hover:text-gold-500 transition">
                  (956) 522-1481
                </a>
              </p>
            </div>
          </div>
          
          <div className="flex items-start">
            <Mail className="w-6 h-6 text-gold-500 mr-4 mt-1" />
            <div>
              <h3 className="text-xl font-semibold">Email</h3>
              <p className="text-estate-300">
                <a href="mailto:juan@juanjoseelizondo.com" className="hover:text-gold-500 transition">
                  juan@juanjoseelizondo.com
                </a>
              </p>
            </div>
          </div>
          
          <div className="flex items-start">
            <MapPin className="w-6 h-6 text-gold-500 mr-4 mt-1" />
            <div>
              <h3 className="text-xl font-semibold">Office Location</h3>
              <p className="text-estate-300">
                2575 E Griffin Pkwy, Suite 14<br />
                Mission, TX 78572
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Social Media with Glowing Icons */}
      <div className="bg-estate-900 p-8 rounded-lg border border-gold-500 shadow-lg animate-fadeIn">
        <h2 className="text-3xl font-display mb-6">Connect with Juan</h2>
        <GlowingSocialIcons iconSize={24} />
      </div>
      
      {/* Google Maps Embed */}
      <div className="bg-estate-900 p-8 rounded-lg border border-gold-500 shadow-lg animate-fadeIn">
        <h2 className="text-3xl font-display mb-6">Visit Us</h2>
        <div className="h-80 rounded-lg overflow-hidden relative">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3580.3208339529275!2d-98.32807462556475!3d26.230219077256285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8665a0ee906f1703%3A0x7a0c08b0e2edac6e!2s2575%20E%20Griffin%20Pkwy%20%2314%2C%20Mission%2C%20TX%2078572!5e0!3m2!1sen!2sus!4v1716608895771!5m2!1sen!2sus" 
            width="100%" 
            height="100%" 
            style={{ border: 0 }} 
            allowFullScreen 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade"
            title="Office Location"
            className="rounded-lg"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
