
import { useLanguage } from "@/context/LanguageContext";
import GlowingSocialIcons from "./common/GlowingSocialIcons";

const Footer = () => {
  const { t } = useLanguage();

  return (
    <footer className="bg-estate-800 text-white py-16">
      <div className="container mx-auto px-4 max-w-6xl">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-center md:text-left">
          <div>
            <h3 className="font-display text-xl mb-4">{t('footer.company')}</h3>
            <p className="text-estate-300">
              {t('footer.tagline')}
            </p>
          </div>
          <div>
            <h4 className="font-semibold mb-4">{t('footer.quicklinks')}</h4>
            <ul className="space-y-2 text-estate-300">
              <li><a href="#" className="hover:text-white transition-colors">{t('footer.properties')}</a></li>
              <li><a href="#" className="hover:text-white transition-colors">{t('nav.about')}</a></li>
              <li><a href="#" className="hover:text-white transition-colors">{t('footer.services')}</a></li>
              <li><a href="#" className="hover:text-white transition-colors">{t('nav.contact')}</a></li>
            </ul>
          </div>
          <div>
            <h4 className="font-semibold mb-4">{t('footer.contact')}</h4>
            <ul className="space-y-2 text-estate-300">
              <li>2575 E Griffin Pkwy, Suite 14</li>
              <li>Mission, TX 78572</li>
              <li>(956) 522-1481</li>
              <li>juan@juanjoseelizondo.com</li>
            </ul>
          </div>
          <div>
            <h4 className="font-semibold mb-4">{t('footer.follow')}</h4>
            <GlowingSocialIcons className="justify-center md:justify-start" iconSize={20} />
          </div>
        </div>
        <div className="mt-12 pt-8 border-t border-estate-700 text-center text-estate-300">
          <p>{t('footer.copyright')}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
