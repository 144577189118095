
import CommercialCard from "./CommercialCard";

const CommercialGrid = () => {
  const commercialProperties = [
    {
      id: 1,
      image: "https://images.unsplash.com/photo-1497604401993-f2e922e5cb0a?auto=format&fit=crop&q=80",
      title: "Neuhaus Tower Office",
      location: "Downtown McAllen, TX",
      type: "Office Space",
      sqft: "2,500 sq ft",
      price: "$4,500/month"
    },
    {
      id: 2,
      image: "https://images.unsplash.com/photo-1431576901776-e539bd916ba2?auto=format&fit=crop&q=80",
      title: "Warehouse District",
      location: "North McAllen, TX",
      type: "Warehouse",
      sqft: "10,000 sq ft",
      price: "$8,700/month"
    },
    {
      id: 3,
      image: "https://images.unsplash.com/photo-1460574283810-2aab119d8511?auto=format&fit=crop&q=80",
      title: "Retail Plaza Unit",
      location: "South McAllen, TX",
      type: "Retail Space",
      sqft: "1,800 sq ft",
      price: "$3,200/month"
    },
    {
      id: 4,
      image: "https://images.unsplash.com/photo-1493397212122-2b85dda8106b?auto=format&fit=crop&q=80",
      title: "Modern Office Complex",
      location: "Sharyland, TX",
      type: "Office Space",
      sqft: "3,200 sq ft",
      price: "$5,600/month"
    },
    {
      id: 5,
      image: "https://images.unsplash.com/photo-1551038247-3d9af20df552?auto=format&fit=crop&q=80",
      title: "Business Center Suite",
      location: "Downtown McAllen, TX",
      type: "Office Space",
      sqft: "1,500 sq ft",
      price: "$2,900/month"
    },
    {
      id: 6,
      image: "https://images.unsplash.com/photo-1488972685288-c3fd157d7c7a?auto=format&fit=crop&q=80",
      title: "Distribution Center",
      location: "Hidalgo, TX",
      type: "Warehouse",
      sqft: "15,000 sq ft",
      price: "$12,500/month"
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 wp-block-lovable-commercial-grid">
      {commercialProperties.map((property) => (
        <CommercialCard key={property.id} property={property} />
      ))}
    </div>
  );
};

export default CommercialGrid;
