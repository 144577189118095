
import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import { submitBlogToSelectedTable } from "@/utils/blogSubmissionUtils";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

const CustomBlogSubmitForm = () => {
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [excerpt, setExcerpt] = useState<string>("");
  const [category, setCategory] = useState<string>("Investment");
  const [author, setAuthor] = useState<string>("Juan Elizondo");
  const [mainImage, setMainImage] = useState<string>("");
  const [additionalImage, setAdditionalImage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [useJuansTable, setUseJuansTable] = useState<boolean>(true);
  const [status, setStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!title || !content) {
      setStatus('error');
      setErrorMessage('Title and content are required');
      return;
    }
    
    setIsSubmitting(true);
    setStatus('idle');
    setErrorMessage("");
    
    try {
      // Prepare blog post data
      const blogPostData = {
        title,
        content,
        excerpt: excerpt || title,
        category,
        author,
        image: mainImage,
        images: additionalImage 
          ? [
              { url: mainImage, caption: "Main image", sequence: 0 },
              { url: additionalImage, caption: "Additional image", sequence: 1 }
            ]
          : [{ url: mainImage, caption: "Main image", sequence: 0 }]
      };
      
      // Submit to selected table
      const success = await submitBlogToSelectedTable(
        blogPostData, 
        useJuansTable,
        (newStatus) => {
          setStatus(newStatus === 'success' ? 'success' : newStatus === 'error' ? 'error' : 'idle');
        },
        (details) => {
          setErrorMessage(details);
        }
      );
      
      if (success) {
        // Reset form on success
        setTitle("");
        setContent("");
        setExcerpt("");
        setCategory("Investment");
        setAuthor("Juan Elizondo");
        setMainImage("");
        setAdditionalImage("");
      }
    } catch (error) {
      console.error("Error submitting blog post:", error);
      setStatus('error');
      setErrorMessage(error instanceof Error ? error.message : "Unknown error occurred");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Custom Blog Submission</CardTitle>
        <CardDescription>
          Create a new blog post with custom fields
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-2">
            <Label htmlFor="destination">Destination Table</Label>
            <div className="flex items-center space-x-2">
              <Checkbox 
                id="juans-table" 
                checked={useJuansTable} 
                onCheckedChange={(checked) => setUseJuansTable(checked as boolean)}
              />
              <Label htmlFor="juans-table" className="cursor-pointer">
                Use Juan's dedicated blog table
              </Label>
            </div>
          </div>
          
          <div className="space-y-2">
            <Label htmlFor="title">Title</Label>
            <Input
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter blog post title"
              required
            />
          </div>
          
          <div className="space-y-2">
            <Label htmlFor="excerpt">Excerpt</Label>
            <Textarea
              id="excerpt"
              value={excerpt}
              onChange={(e) => setExcerpt(e.target.value)}
              placeholder="Brief description of the blog post"
              className="min-h-[80px]"
            />
            <p className="text-sm text-gray-500">
              If left empty, the title will be used as excerpt
            </p>
          </div>
          
          <div className="space-y-2">
            <Label htmlFor="content">Content</Label>
            <Textarea
              id="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="Enter blog post content (HTML supported)"
              className="min-h-[300px]"
              required
            />
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="category">Category</Label>
              <Select 
                value={category} 
                onValueChange={setCategory}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a category" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Investment">Investment</SelectItem>
                  <SelectItem value="Real Estate">Real Estate</SelectItem>
                  <SelectItem value="Residential">Residential</SelectItem>
                  <SelectItem value="Commercial">Commercial</SelectItem>
                  <SelectItem value="McAllen">McAllen</SelectItem>
                  <SelectItem value="Market Update">Market Update</SelectItem>
                </SelectContent>
              </Select>
            </div>
            
            <div className="space-y-2">
              <Label htmlFor="author">Author</Label>
              <Input
                id="author"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
                placeholder="Author name"
              />
            </div>
          </div>
          
          <div className="space-y-2">
            <Label htmlFor="mainImage">Main Image URL</Label>
            <Input
              id="mainImage"
              value={mainImage}
              onChange={(e) => setMainImage(e.target.value)}
              placeholder="https://example.com/image.jpg"
            />
          </div>
          
          <div className="space-y-2">
            <Label htmlFor="additionalImage">Additional Image URL (Optional)</Label>
            <Input
              id="additionalImage"
              value={additionalImage}
              onChange={(e) => setAdditionalImage(e.target.value)}
              placeholder="https://example.com/additional-image.jpg"
            />
          </div>
          
          <Button 
            type="submit" 
            className="w-full" 
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Submitting...
              </>
            ) : (
              `Submit to ${useJuansTable ? "Juan's" : "Regular"} Blog Table`
            )}
          </Button>
          
          {status === 'success' && (
            <p className="text-green-500 text-center">
              Blog post submitted successfully!
            </p>
          )}
          
          {status === 'error' && (
            <p className="text-red-500 text-center">
              {errorMessage || "An error occurred while submitting the blog post."}
            </p>
          )}
        </form>
      </CardContent>
    </Card>
  );
};

export default CustomBlogSubmitForm;
