
import React from "react";

interface CityTestimonialsProps {
  cityName: string;
}

const CityTestimonials = ({ cityName }: CityTestimonialsProps) => {
  return (
    <div className="mb-16 bg-estate-50 py-12 px-6 rounded-lg">
      <div className="text-center mb-10">
        <h2 className="text-4xl font-display mb-4">What Clients Say About {cityName}</h2>
        <p className="text-muted-foreground max-w-3xl mx-auto">
          Hear from satisfied clients who found their perfect home in {cityName} with Juan Elizondo.
        </p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-white p-8 rounded-lg shadow-md">
          <div className="flex items-center mb-4">
            <div className="w-12 h-12 bg-gold-500 text-white rounded-full flex items-center justify-center mr-4">
              <span className="text-xl font-bold">S</span>
            </div>
            <div>
              <h4 className="font-semibold">Sarah Johnson</h4>
              <p className="text-sm text-muted-foreground">Homeowner in {cityName}</p>
            </div>
          </div>
          <p className="italic text-muted-foreground mb-4">
            "Juan found us our dream home in {cityName} when we thought we'd never find the perfect place. His knowledge of the local communities was invaluable!"
          </p>
        </div>
        
        <div className="bg-white p-8 rounded-lg shadow-md">
          <div className="flex items-center mb-4">
            <div className="w-12 h-12 bg-gold-500 text-white rounded-full flex items-center justify-center mr-4">
              <span className="text-xl font-bold">M</span>
            </div>
            <div>
              <h4 className="font-semibold">Michael Rodriguez</h4>
              <p className="text-sm text-muted-foreground">Investor in {cityName}</p>
            </div>
          </div>
          <p className="italic text-muted-foreground mb-4">
            "As an investor, I needed someone who understood the {cityName} market inside and out. Juan delivered beyond my expectations with his market knowledge and connections."
          </p>
        </div>
      </div>
    </div>
  );
};

export default CityTestimonials;
