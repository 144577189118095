
import { Button } from "@/components/ui/button";

interface LoanTermSelectorProps {
  loanTerm: number;
  onLoanTermChange: (value: number) => void;
}

const LoanTermSelector = ({
  loanTerm,
  onLoanTermChange,
}: LoanTermSelectorProps) => {
  const termOptions = [15, 20, 30];

  return (
    <div className="space-y-2">
      <label htmlFor="loanTerm" className="text-base font-medium">Loan Term (Years)</label>
      <div className="flex gap-2">
        {termOptions.map((term) => (
          <Button
            key={term}
            variant={loanTerm === term ? "default" : "outline"}
            className={`flex-1 transition-all duration-300 calculator-button-glow ${
              loanTerm === term ? 'bg-yellow-400 text-black hover:bg-yellow-500' : ''
            }`}
            onClick={() => onLoanTermChange(term)}
            style={{
              transition: 'all 0.3s ease'
            }}
            onMouseEnter={(e) => {
              if (loanTerm !== term) {
                e.currentTarget.style.boxShadow = '0 0 15px rgba(255, 179, 71, 0.5)';
              }
            }}
            onMouseLeave={(e) => {
              if (loanTerm !== term) {
                e.currentTarget.style.boxShadow = 'none';
              }
            }}
          >
            {term} Years
          </Button>
        ))}
      </div>
    </div>
  );
};

export default LoanTermSelector;
