
import React, { useState } from "react";
import { Search, Home, Building2, Landmark, Warehouse } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select";
import { Slider } from "@/components/ui/slider";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface SearchFormProps {
  propertyType: string;
  setPropertyType: (value: string) => void;
  location: string;
  setLocation: (value: string) => void;
  priceRange: number[];
  setPriceRange: (value: number[]) => void;
  onSubmit: (e: React.FormEvent) => void;
}

const SearchForm = ({
  propertyType,
  setPropertyType,
  location,
  setLocation,
  priceRange,
  setPriceRange,
  onSubmit
}: SearchFormProps) => {
  const [expanded, setExpanded] = useState(false);
  
  const propertyTypes = [
    { value: "residential", label: "Residential", icon: Home },
    { value: "commercial", label: "Commercial", icon: Building2 },
    { value: "land", label: "Land", icon: Landmark },
    { value: "investment", label: "Investment", icon: Warehouse },
  ];

  return (
    <form onSubmit={onSubmit} className="space-y-6 bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 border border-gray-100 dark:border-gray-700">
      <h2 className="text-2xl font-display font-bold mb-4 text-estate-800 dark:text-white">Find Your Dream Property</h2>
      
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="flex-1">
          <label htmlFor="location" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Location
          </label>
          <div className="relative">
            <Input
              id="location"
              placeholder="City, neighborhood, or ZIP code"
              className="w-full pl-10 h-12 border-2 border-gray-200 dark:border-gray-700 rounded-lg focus:border-gold-500 focus:ring focus:ring-gold-200 dark:focus:border-gold-600 dark:focus:ring-gold-900/20 transition-all"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
            <div className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">
              <Search className="h-5 w-5" />
            </div>
          </div>
        </div>
        
        <div className="w-full lg:w-64">
          <label htmlFor="property-type" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Property Type
          </label>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button 
                variant="outline" 
                className="w-full h-12 justify-between border-2 border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 hover:border-gold-500 dark:hover:border-gold-600 transition-all"
              >
                {propertyType ? 
                  <span className="flex items-center">
                    {propertyTypes.find(pt => pt.value === propertyType)?.icon && 
                      React.createElement(propertyTypes.find(pt => pt.value === propertyType)?.icon as any, { className: "mr-2 h-4 w-4" })
                    }
                    {propertyTypes.find(pt => pt.value === propertyType)?.label || "Select property type"}
                  </span> 
                  : 
                  "Select property type"
                }
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-[--trigger-width]">
              {propertyTypes.map((type) => (
                <DropdownMenuItem 
                  key={type.value}
                  onClick={() => setPropertyType(type.value)}
                  className="cursor-pointer hover:bg-gold-50 dark:hover:bg-gold-900/20"
                >
                  <type.icon className="mr-2 h-4 w-4" />
                  {type.label}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      
      <div>
        <label className="flex justify-between">
          <span className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Price Range
          </span>
          <span className="text-sm font-medium text-gold-500">
            up to ${priceRange[0].toLocaleString()}
          </span>
        </label>
        <Slider
          defaultValue={[200000]}
          max={3000000}
          step={50000}
          onValueChange={setPriceRange}
          className="py-4"
        />
        <div className="flex justify-between text-sm text-gray-500 dark:text-gray-400 mt-1">
          <span>$0</span>
          <span>$3,000,000+</span>
        </div>
      </div>
      
      <Button 
        type="submit" 
        className="w-full h-12 bg-gold-500 hover:bg-gold-600 text-white font-semibold text-lg rounded-lg shadow-md transition-all duration-300 hover:shadow-lg transform hover:-translate-y-1"
      >
        <Search className="mr-2 h-5 w-5" />
        Search Properties
      </Button>
    </form>
  );
};

export default SearchForm;
