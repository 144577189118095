
import React from 'react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { MortgagePaymentSectionProps } from './types';

const MortgagePaymentSection: React.FC<MortgagePaymentSectionProps> = ({
  mortgagePaymentMonthly,
  mortgagePaymentYearly,
  interestRate,
  setInterestRate,
  loanTerm,
  setLoanTerm,
  firstMortgage,
  isCalculatingPayment,
  formatCurrency,
  handlePercentInputChange
}) => {
  return (
    <div className="transition-transform hover:transform hover:scale-[1.02] hover:z-10">
      <h2 className="text-2xl font-bold text-gold-500 mb-4">Mortgage Payment</h2>
      
      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Monthly</label>
        <div className="flex items-center">
          <span className="text-gold-500 mr-2">$</span>
          <Input
            type="text"
            value={mortgagePaymentMonthly.toLocaleString()}
            readOnly
            className="flex-1 text-white bg-estate-900 border-estate-700" 
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Yearly</label>
        <div className="flex items-center">
          <span className="text-gold-500 mr-2">$</span>
          <Input
            type="text"
            value={mortgagePaymentYearly.toLocaleString()}
            readOnly
            className="flex-1 text-white bg-estate-900 border-estate-700" 
          />
        </div>
      </div>
      
      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Interest Rate</label>
        <div className="flex items-center">
          <Input
            type="text"
            value={interestRate}
            onChange={(e) => handlePercentInputChange(e.target.value, setInterestRate)}
            className="flex-1 text-white bg-estate-800 border-estate-700"
            disabled={!isCalculatingPayment}
          />
          <span className="ml-2 text-gold-500">%</span>
        </div>
      </div>
      
      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Loan Term (years)</label>
        <div className="flex items-center">
          <Input
            type="text"
            value={loanTerm.toString()}
            onChange={(e) => {
              const value = parseInt(e.target.value) || 0;
              setLoanTerm(value);
            }}
            className="flex-1 text-white bg-estate-800 border-estate-700"
            disabled={!isCalculatingPayment}
          />
        </div>
      </div>
      
      <div className="mt-6">
        <Button 
          type="button"
          className="bg-gold-500 hover:bg-gold-600 text-black font-bold px-6 w-full mb-2 calculator-button-glow"
          onClick={() => {}}
          disabled={!isCalculatingPayment}
        >
          Calculate Payment
        </Button>
      </div>
    </div>
  );
};

export default MortgagePaymentSection;
