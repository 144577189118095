
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { 
  Bar, 
  BarChart, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip as RechartsTooltip, 
  ResponsiveContainer, 
  Legend 
} from "recharts";
import { ChartDataItem } from "./utils";
import { 
  Tooltip, 
  TooltipContent, 
  TooltipProvider, 
  TooltipTrigger 
} from "@/components/ui/tooltip";
import { Info } from "lucide-react";

interface SavingsChartProps {
  chartData: ChartDataItem[];
}

const SavingsChart = ({ chartData }: SavingsChartProps) => {
  if (!chartData || chartData.length === 0) return null;

  // Modern vibrant color palette
  const colors = {
    monthlyPayment: "#2563EB", // Bright blue
    totalCost: "#F59E0B",      // Amber/yellow
    grid: "#e2e8f0",           // Soft gray for grid
    text: "#64748b"            // Slate for text
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    }).format(value);
  };

  return (
    <Card className="shadow-md p-4 bg-gradient-to-r from-white to-blue-50 dark:from-gray-900 dark:to-blue-950/40 border-blue-200 dark:border-blue-900 mt-8">
      <CardHeader className="pb-0">
        <div className="flex items-center justify-between">
          <CardTitle className="text-xl font-display">Cost Comparison</CardTitle>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <span>
                  <Info className="h-4 w-4 text-blue-500" />
                </span>
              </TooltipTrigger>
              <TooltipContent className="max-w-xs">
                <p>Compare monthly payments and total costs between different credit score ranges.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </CardHeader>
      <CardContent>
        <div className="w-full h-80 mt-4">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chartData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barGap={16}
              barSize={50} // Increased from 38 to 50 for thicker bars
            >
              <CartesianGrid strokeDasharray="3 3" stroke={colors.grid} vertical={false} />
              <XAxis 
                dataKey="name" 
                axisLine={false}
                tickLine={false}
                tick={{ fill: colors.text, fontSize: 12 }}
                dy={10}
              />
              <YAxis 
                yAxisId="left" 
                orientation="left" 
                stroke={colors.monthlyPayment}
                tickFormatter={(value) => `$${value}`}
                axisLine={false}
                tickLine={false}
                tick={{ fill: colors.text, fontSize: 12 }}
              />
              <YAxis 
                yAxisId="right" 
                orientation="right" 
                stroke={colors.totalCost}
                tickFormatter={(value) => `$${value}k`}
                axisLine={false}
                tickLine={false}
                tick={{ fill: colors.text, fontSize: 12 }}
              />
              <RechartsTooltip 
                formatter={(value, name) => {
                  if (name === "Monthly Payment") {
                    return [formatCurrency(value as number), "Monthly Payment"];
                  }
                  return [formatCurrency((value as number) * 1000), "Total Cost"];
                }}
                contentStyle={{ 
                  backgroundColor: "rgba(255, 255, 255, 0.95)",
                  borderRadius: "8px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                  border: "1px solid rgba(37, 99, 235, 0.2)"
                }}
                cursor={{ fill: "rgba(37, 99, 235, 0.05)" }}
              />
              <Legend 
                iconType="circle"
                iconSize={12} // Slightly larger legend icons
                wrapperStyle={{ paddingTop: "15px" }}
              />
              <Bar 
                yAxisId="left" 
                dataKey="Monthly Payment" 
                fill={colors.monthlyPayment}
                radius={[6, 6, 0, 0]} // More rounded corners
                animationDuration={1500}
                animationEasing="ease-out"
                strokeWidth={1}
                stroke="rgba(0, 0, 0, 0.05)"
              />
              <Bar 
                yAxisId="right" 
                dataKey="Total Cost" 
                fill={colors.totalCost}
                radius={[6, 6, 0, 0]} // More rounded corners
                animationDuration={1500}
                animationEasing="ease-out"
                strokeWidth={1}
                stroke="rgba(0, 0, 0, 0.05)"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default SavingsChart;
