
import { useEffect } from 'react';
import { generateSitemap } from '@/utils/sitemapGenerator';
import { useBlogPosts } from '@/hooks/useBlogPosts';
import { Helmet } from 'react-helmet-async';

const SitemapPage = () => {
  const { blogPosts } = useBlogPosts();
  
  useEffect(() => {
    // Define the sitemap generation
    const generateAndServeSitemap = () => {
      const hostname = window.location.origin;
      
      // Define static routes
      const routes = [
        '/',
        '/buy',
        '/sell',
        '/contact',
        '/commercial',
        '/residential',
        '/mortgage-calculator',
        '/mexican-clients',
        '/blog',
        '/communities'
      ];
      
      // Add dynamic routes for blog posts
      const dynamicRoutes = [
        {
          route: '/blog/:id',
          items: blogPosts.map(post => ({
            id: post.id,
            lastmod: new Date().toISOString().split('T')[0]
          }))
        }
      ];
      
      const sitemapXml = generateSitemap({
        hostname,
        routes,
        dynamicRoutes
      });
      
      // Create a Blob with the XML content
      const blob = new Blob([sitemapXml], { type: 'application/xml' });
      const url = URL.createObjectURL(blob);
      
      // Download the sitemap
      const a = document.createElement('a');
      a.href = url;
      a.download = 'sitemap.xml';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    };
    
    // Display the sitemap in a pre element for viewing in browser
    const pre = document.createElement('pre');
    pre.textContent = generateSitemap({
      hostname: window.location.origin,
      routes: ['/', '/buy', '/sell', '/contact', '/commercial', '/residential', '/mortgage-calculator', '/mexican-clients', '/blog', '/communities'],
      dynamicRoutes: [{
        route: '/blog/:id',
        items: blogPosts.map(post => ({ id: post.id }))
      }]
    });
    
    document.body.innerHTML = '';
    document.body.appendChild(pre);
  }, [blogPosts]);
  
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/xml; charset=utf-8" />
      </Helmet>
      <div id="sitemap-container" />
    </>
  );
};

export default SitemapPage;
