
const MexicanSteps = () => {
  return (
    <section className="py-16 bg-black">
      <div className="container mx-auto px-4 max-w-5xl">
        <h2 className="text-3xl font-display text-center mb-12 text-shadow-gold">Pasos para Comenzar</h2>
        
        <div className="space-y-8">
          {/* Step 1 */}
          <div className="flex flex-col md:flex-row items-start bg-gray-900 p-6 rounded-lg shadow-md border border-gray-800">
            <div className="flex justify-center items-center bg-[#d4af37] text-white rounded-full w-12 h-12 shrink-0 md:mr-6 mb-4 md:mb-0">
              <span className="text-xl font-bold">1</span>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Consulta Inicial</h3>
              <p className="text-gray-300">
                Programe una llamada o reunión para discutir sus objetivos de inversión, presupuesto, cronograma y preferencias. Explicaré el proceso y responderé cualquier pregunta que pueda tener sobre invertir en el mercado inmobiliario de McAllen.
              </p>
            </div>
          </div>
          
          {/* Step 2 */}
          <div className="flex flex-col md:flex-row items-start bg-gray-900 p-6 rounded-lg shadow-md border border-gray-800">
            <div className="flex justify-center items-center bg-[#d4af37] text-white rounded-full w-12 h-12 shrink-0 md:mr-6 mb-4 md:mb-0">
              <span className="text-xl font-bold">2</span>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Análisis de Mercado y Selección de Propiedades</h3>
              <p className="text-gray-300">
                Basado en sus criterios, realizaré un análisis exhaustivo del mercado y prepararé una selección cuidadosa de propiedades que coincidan con sus objetivos de inversión, ya sea para vivienda residencial, operaciones comerciales o ingresos por alquiler.
              </p>
            </div>
          </div>
          
          {/* Step 3 */}
          <div className="flex flex-col md:flex-row items-start bg-gray-900 p-6 rounded-lg shadow-md border border-gray-800">
            <div className="flex justify-center items-center bg-[#d4af37] text-white rounded-full w-12 h-12 shrink-0 md:mr-6 mb-4 md:mb-0">
              <span className="text-xl font-bold">3</span>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Visitas a Propiedades y Diligencia Debida</h3>
              <p className="text-gray-300">
                Visite propiedades en persona o a través de recorridos virtuales, seguido de una debida diligencia integral que incluye inspecciones de propiedad, revisión de documentos y análisis de rendimientos potenciales. Coordinaré todo para que este proceso sea fluido.
              </p>
            </div>
          </div>
          
          {/* Step 4 */}
          <div className="flex flex-col md:flex-row items-start bg-gray-900 p-6 rounded-lg shadow-md border border-gray-800">
            <div className="flex justify-center items-center bg-[#d4af37] text-white rounded-full w-12 h-12 shrink-0 md:mr-6 mb-4 md:mb-0">
              <span className="text-xl font-bold">4</span>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Transacción y Cierre</h3>
              <p className="text-gray-300">
                Lo guiaré a través de los procesos de oferta, negociación y cierre, coordinando con abogados, compañías de títulos y otros profesionales para garantizar una transacción sin problemas que cumpla con todos los requisitos legales para inversionistas internacionales.
              </p>
            </div>
          </div>
          
          {/* Step 5 */}
          <div className="flex flex-col md:flex-row items-start bg-gray-900 p-6 rounded-lg shadow-md border border-gray-800">
            <div className="flex justify-center items-center bg-[#d4af37] text-white rounded-full w-12 h-12 shrink-0 md:mr-6 mb-4 md:mb-0">
              <span className="text-xl font-bold">5</span>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Soporte Post-Compra</h3>
              <p className="text-gray-300">
                Mi servicio no termina al cierre. Lo conectaré con servicios de administración de propiedades si es necesario, lo ayudaré a establecer cuentas de servicios públicos y brindaré apoyo continuo mientras navega por la propiedad de bienes raíces en Estados Unidos.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MexicanSteps;
