
import React from 'react';
import GlowingText from '@/components/common/GlowingText';
import { CalculatorHeaderProps } from './types';

const CalculatorHeader: React.FC<CalculatorHeaderProps> = ({ title, subtitle }) => {
  return (
    <div className="text-center mb-16 mt-4 overflow-visible">
      <div className="overflow-visible py-2">
        <GlowingText 
          text={title} 
          as="h1"
          className="text-4xl md:text-5xl font-bold overflow-visible"
          advanced={true} 
          glowColor="gold"
        />
        {subtitle && (
          <p className="text-gray-300 mt-4 text-xl">
            {subtitle}
          </p>
        )}
      </div>
    </div>
  );
};

export default CalculatorHeader;
