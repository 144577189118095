
import { useEffect } from "react";
import { refreshGlowEffect, debugGlowEffect } from "@/utils/glowTextUtils";
import { useLanguage } from "@/context/LanguageContext";
import { useTheme } from "@/context/ThemeContext";

const GlowEffectDebug = () => {
  const { language } = useLanguage();
  const { theme } = useTheme();
  
  useEffect(() => {
    console.log("Home page loaded with theme:", theme);
    
    if (document.getElementById('global-glow-filters') === null) {
      console.warn("Global glow filters not found, may need to add GlobalGlowFilter component");
    }
    
    setTimeout(() => {
      refreshGlowEffect();
    }, 100);
    
    setTimeout(() => {
      refreshGlowEffect();
      debugGlowEffect();
    }, 1000);
    
    if (typeof window !== 'undefined') {
      (window as any).forceGlowRefresh = () => {
        console.log("Manual glow refresh triggered");
        refreshGlowEffect();
        debugGlowEffect();
      };
    }
  }, [language, theme]);

  return (
    <div id="filter-debug" style={{ display: 'none' }}>
      <svg width="0" height="0">
        <filter id="debug-filter">
          <feGaussianBlur stdDeviation="4" />
        </filter>
      </svg>
    </div>
  );
};

export default GlowEffectDebug;
