
import { CityInfo } from "../types/CommunityTypes";

export const SouthPadreIslandData: CityInfo = {
  headline: "Experience Beachfront Luxury on South Padre Island",
  description: "South Padre Island offers the ultimate beach lifestyle with pristine sandy beaches, turquoise waters, and spectacular sunsets. This premier resort destination provides exclusive waterfront living opportunities and strong rental investment potential.",
  marketInfo: {
    avgPrice: "$450,000",
    trend: "Rising 7% annually",
    daysOnMarket: "60 days"
  },
  communities: [
    {
      name: "Beach Resort Condos",
      description: "Luxury beachfront condominiums with spectacular ocean views",
      features: ["Direct beach access", "Resort amenities", "Rental potential"],
      priceRange: "$300,000 - $1,200,000",
      image: "https://images.unsplash.com/photo-1545153996-e01b50d6ec38?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YmVhY2glMjBjb25kb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
    },
    {
      name: "Gulf View Estates",
      description: "Exclusive single-family homes with premium oceanfront locations",
      features: ["Panoramic views", "Private beach access", "Luxury finishes"],
      priceRange: "$750,000 - $2,500,000+",
      image: "https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YmVhY2glMjBob3VzZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
    }
  ],
  featuredProperties: [
    {
      id: 301,
      city: "South Padre Island",
      title: "Luxury Beachfront Condo",
      price: "$675,000",
      image: "https://images.unsplash.com/photo-1584738766473-61c083514bf4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bHV4dXJ5JTIwY29uZG98ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",
      beds: 3,
      baths: 2,
      sqft: "1,950"
    },
    {
      id: 302,
      city: "South Padre Island",
      title: "Oceanfront Dream Home",
      price: "$1,250,000",
      image: "https://images.unsplash.com/photo-1560185007-c5ca9d2c014d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8YmVhY2glMjBob3VzZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
      beds: 5,
      baths: 4.5,
      sqft: "4,200"
    }
  ]
};
