
import { generateSlug } from './slugUtils';

interface SitemapUrl {
  url: string;
  lastmod?: string;
  changefreq?: 'always' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly' | 'never';
  priority?: number;
}

interface SitemapOptions {
  hostname: string;
  routes: string[];
  dynamicRoutes?: {
    route: string;
    items: Array<{ id: string | number; lastmod?: string }>;
  }[];
}

/**
 * Generates an XML sitemap for the website
 * @param options - Sitemap configuration options
 * @returns XML sitemap as a string
 */
export const generateSitemap = (options: SitemapOptions): string => {
  const { hostname, routes, dynamicRoutes = [] } = options;
  const urls: SitemapUrl[] = [];

  // Add static routes
  routes.forEach(route => {
    urls.push({
      url: route === '/' ? '' : route,
      changefreq: 'monthly',
      priority: route === '/' ? 1.0 : 0.8
    });
  });

  // Add dynamic routes
  dynamicRoutes.forEach(dynamicRoute => {
    const { route, items } = dynamicRoute;
    items.forEach(item => {
      const path = route.replace(':id', String(item.id));
      urls.push({
        url: path,
        lastmod: item.lastmod || new Date().toISOString().split('T')[0],
        changefreq: 'weekly',
        priority: 0.7
      });
    });
  });

  // Generate XML
  let xml = '<?xml version="1.0" encoding="UTF-8"?>\n';
  xml += '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n';

  urls.forEach(url => {
    xml += '  <url>\n';
    xml += `    <loc>${hostname}${url.url}</loc>\n`;
    if (url.lastmod) xml += `    <lastmod>${url.lastmod}</lastmod>\n`;
    if (url.changefreq) xml += `    <changefreq>${url.changefreq}</changefreq>\n`;
    if (url.priority !== undefined) xml += `    <priority>${url.priority}</priority>\n`;
    xml += '  </url>\n';
  });

  xml += '</urlset>';
  return xml;
};

/**
 * Create a robots.txt file content
 * @param hostname - The website hostname
 * @returns robots.txt content as a string
 */
export const generateRobotsTxt = (hostname: string): string => {
  return `User-agent: *
Allow: /
Sitemap: ${hostname}/sitemap.xml`;
};
