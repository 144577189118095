
import { 
  CreditCard, 
  Home, 
  Calculator, 
  ClipboardCheck 
} from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";

const BuyerTips = () => {
  const tips = [
    {
      icon: <CreditCard className="h-6 w-6 text-[#ffb347]" />,
      title: "Get Pre-Approved",
      description: "Before house hunting, get pre-approved for a mortgage to understand your budget and show sellers you're serious."
    },
    {
      icon: <Home className="h-6 w-6 text-[#ffb347]" />,
      title: "Consider All Costs",
      description: "Remember to factor in property taxes, insurance, maintenance, and potential renovation costs beyond the purchase price."
    },
    {
      icon: <Calculator className="h-6 w-6 text-[#ffb347]" />,
      title: "Understand Your Budget",
      description: "Plan your finances carefully to ensure you can comfortably afford your monthly payments and still have savings for emergencies."
    },
    {
      icon: <ClipboardCheck className="h-6 w-6 text-[#ffb347]" />,
      title: "Get a Home Inspection",
      description: "Always conduct a thorough home inspection to identify any potential issues before finalizing your purchase."
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {tips.map((tip, index) => (
        <Card key={index} className="border-none shadow-md hover:shadow-lg transition-shadow duration-300">
          <CardContent className="p-6">
            <div className="flex items-start gap-4">
              <div className="mt-1">
                {tip.icon}
              </div>
              <div>
                <h3 className="text-lg font-bold mb-2 text-card-foreground">{tip.title}</h3>
                <p className="text-muted-foreground">{tip.description}</p>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default BuyerTips;
