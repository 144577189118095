
import React from "react";
import { useBlogPosts } from "@/hooks/useBlogPosts";
import { useBlogListingState } from "@/hooks/useBlogListingState";
import { Button } from "@/components/ui/button";
import BlogFilter from "./BlogFilter";
import BlogPostCard from "./BlogPostCard";

const BlogListing = () => {
  const { blogPosts, loading } = useBlogPosts();
  const {
    searchQuery,
    setSearchQuery,
    visiblePosts,
    activeCategory,
    setActiveCategory,
    loadMorePosts,
    processPosts
  } = useBlogListingState();

  const { categories, filteredPosts, displayedPosts } = processPosts(blogPosts);

  if (loading) {
    return (
      <div className="space-y-8">
        <BlogFilter
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          categories={categories}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[...Array(6)].map((_, i) => (
            <div key={i} className="bg-gray-800 rounded-lg overflow-hidden animate-pulse h-96"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <BlogFilter
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        categories={categories}
      />

      {filteredPosts.length === 0 ? (
        <div className="text-center py-12">
          <h3 className="text-xl font-semibold mb-4">No blog posts found</h3>
          <p className="text-gray-400 mb-6">Try adjusting your search or filter criteria</p>
          <Button variant="outline" onClick={() => {
            setSearchQuery("");
            setActiveCategory("All");
          }}>
            Reset Filters
          </Button>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {displayedPosts.map((post) => (
              <BlogPostCard key={post.id} post={post} />
            ))}
          </div>

          {displayedPosts.length < filteredPosts.length && (
            <div className="flex justify-center mt-8">
              <Button onClick={loadMorePosts} className="bg-[#ffb347] hover:bg-[#f5a030] text-white">
                Load More
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BlogListing;
