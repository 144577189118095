
import React from 'react';
import { Input } from '@/components/ui/input';
import { LenderSectionProps } from './types';

const LenderSection: React.FC<LenderSectionProps> = ({
  arvPercent,
  setArvPercent,
  arvLend,
  gapFunds,
  miscExpense,
  setMiscExpense,
  insurance,
  setInsurance,
  staging,
  setStaging,
  interestRate,
  setInterestRate,
  monthlyInterest,
  handleCurrencyInputChange,
  handlePercentInputChange,
  formatCurrency
}) => {
  return (
    <div className="transition-transform hover:transform hover:scale-[1.02] hover:z-10">
      <h2 className="text-2xl font-bold text-gold-500 mb-4">Lender Information</h2>
      
      <div className="mt-4">
        <label className="block text-gray-300 mb-1">If using a lender, what ARV will they lend up to?</label>
        <div className="flex gap-2">
          <div className="w-1/3 flex items-center">
            <Input
              type="text"
              value={arvPercent}
              onChange={(e) => handlePercentInputChange(e.target.value, setArvPercent)}
              className="text-white bg-estate-800 border-estate-700"
              step="0.01"
            />
            <span className="ml-2 text-gold-500">%</span>
          </div>
          <div className="w-2/3 flex items-center">
            <span className="text-gold-500 mr-2">$</span>
            <Input 
              type="text" 
              value={arvLend.toLocaleString()} 
              readOnly 
              className="bg-estate-900 text-white border-estate-700"
            />
          </div>
        </div>
      </div>

      <div className="mt-6">
        <label className="block text-gray-300 mb-1">GAP Funds</label>
        <div className="text-2xl font-bold text-gold-500">
          {formatCurrency(gapFunds)}
        </div>
      </div>

      <div className="mt-8">
        <h3 className="text-xl font-bold text-gold-400 mb-4">Other Expenses:</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-300 mb-1">Misc</label>
            <div className="flex items-center">
              <span className="text-gold-500 mr-2">$</span>
              <Input
                type="text"
                value={miscExpense.toLocaleString()}
                onChange={(e) => handleCurrencyInputChange(e.target.value, setMiscExpense)}
                className="flex-1 text-white bg-estate-800 border-estate-700"
              />
            </div>
          </div>
          <div>
            <label className="block text-gray-300 mb-1">Insurance</label>
            <div className="flex items-center">
              <span className="text-gold-500 mr-2">$</span>
              <Input
                type="text"
                value={insurance.toLocaleString()}
                onChange={(e) => handleCurrencyInputChange(e.target.value, setInsurance)}
                className="flex-1 text-white bg-estate-800 border-estate-700"
              />
            </div>
          </div>
          <div>
            <label className="block text-gray-300 mb-1">Staging</label>
            <div className="flex items-center">
              <span className="text-gold-500 mr-2">$</span>
              <Input
                type="text"
                value={staging.toLocaleString()}
                onChange={(e) => handleCurrencyInputChange(e.target.value, setStaging)}
                className="flex-1 text-white bg-estate-800 border-estate-700"
              />
            </div>
          </div>
          <div>
            <label className="block text-gray-300 mb-1">Interest</label>
            <div className="flex items-center">
              <Input
                type="text"
                value={interestRate}
                onChange={(e) => handlePercentInputChange(e.target.value, setInterestRate)}
                className="flex-1 text-white bg-estate-800 border-estate-700"
                step="0.1"
              />
              <span className="ml-2 text-gold-500">%</span>
            </div>
          </div>
        </div>
        
        <div className="mt-4">
          <label className="block text-gray-300 mb-1">Monthly Interest</label>
          <div className="text-lg font-bold text-gold-500">
            {formatCurrency(monthlyInterest)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LenderSection;
