
import { useEffect } from "react";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import SellHero from "@/components/sell/SellHero";
import SellProcessSteps from "@/components/sell/SellProcessSteps";
import SellForm from "@/components/sell/SellForm";
import SellerTips from "@/components/sell/SellerTips";
import SellerTestimonials from "@/components/sell/SellerTestimonials";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { Link } from "react-router-dom";
import { useLanguage } from "@/context/LanguageContext";
import { useTheme } from "@/context/ThemeContext";

const Sell = () => {
  const { toast } = useToast();
  const { t } = useLanguage();
  const { theme } = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-estate-800 text-white' : 'bg-[#f0f5f9] text-[#333333]'} wp-block-lovable-sell-property`}>
      <Navbar />
      
      <SellHero />
      
      <section className="py-16 px-4 max-w-6xl mx-auto">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <p className="text-lg leading-relaxed">
            {t('sell.intro')}
          </p>
        </div>
        
        <SellProcessSteps />
        
        <div className="mt-20">
          <h2 className="text-3xl md:text-4xl font-display text-center mb-12">
            {t('sell.ready')}
          </h2>
          <SellForm />
        </div>
        
        <div className="mt-20">
          <h2 className="text-3xl md:text-4xl font-display text-center mb-12">
            {t('sell.tips')}
          </h2>
          <SellerTips />
        </div>
        
        <div className="mt-20">
          <h2 className="text-3xl md:text-4xl font-display text-center mb-12">
            {t('sell.testimonials')}
          </h2>
          <SellerTestimonials />
        </div>
        
        <div className="text-center mt-16">
          <Link to="/contact">
            <Button 
              className="bg-[#ffb347] hover:bg-[#e69a30] text-white font-bold py-3 px-8 rounded-md transition duration-300"
            >
              {t('sell.contact')}
            </Button>
          </Link>
        </div>
      </section>
      
      <Footer />
    </div>
  );
};

export default Sell;
