
import { CityInfo } from "../types/CommunityTypes";

export const EdinburgData: CityInfo = {
  headline: "Discover Modern Living in Edinburg",
  description: "Edinburg offers the perfect blend of small-town charm and modern amenities. As the county seat of Hidalgo County and home to the University of Texas Rio Grande Valley, Edinburg provides excellent educational opportunities, vibrant cultural experiences, and an emerging real estate market.",
  marketInfo: {
    avgPrice: "$265,000",
    trend: "Rising 4.5% annually",
    daysOnMarket: "35 days"
  },
  communities: [
    {
      name: "The Legends",
      description: "Premium gated community with modern architectural designs and family-friendly amenities",
      features: ["Contemporary homes", "Community park", "Walking trails"],
      priceRange: "$280,000 - $550,000",
      image: "https://images.unsplash.com/photo-1564013799919-ab600027ffc6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGx1eHVyeSUyMGhvbWV8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
    },
    {
      name: "Northgate Crossing",
      description: "Upscale neighborhood with excellent access to shopping and entertainment",
      features: ["Spacious lots", "Modern designs", "Near university"],
      priceRange: "$320,000 - $650,000",
      image: "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8aG91c2V8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
    }
  ],
  featuredProperties: [
    {
      id: 501,
      city: "Edinburg",
      title: "Contemporary Luxury Home",
      price: "$475,000",
      image: "/lovable-uploads/0f5438a9-1d76-44c0-a222-fb01f2287e9e.png",
      beds: 4,
      baths: 3.5,
      sqft: "3,100"
    },
    {
      id: 502,
      city: "Edinburg",
      title: "Modern Family Residence",
      price: "$395,000",
      image: "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8aG91c2V8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",
      beds: 3,
      baths: 2.5,
      sqft: "2,650"
    }
  ]
};
