
import { useState } from "react";
import { toast } from "sonner";

/**
 * Hook to handle video errors and fallbacks
 */
export const useVideoErrorHandler = (onFallback: () => void) => {
  const [videoError, setVideoError] = useState<string | null>(null);
  
  // Function to handle video errors
  const handleVideoError = (message: string = "There was an issue playing the video.") => {
    console.error("Video error:", message);
    setVideoError(message);
    toast.error(message);
    onFallback(); // Fall back to image or alternative
  };
  
  return {
    videoError,
    handleVideoError
  };
};
