
import React from "react";
import { Home, TrendingUp, Users, RefreshCw } from "lucide-react";
import { useMarketData } from "@/hooks/useMarketData";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";

interface MarketSnapshotProps {
  cityName: string;
  avgPrice?: string;
  trend?: string;
  daysOnMarket?: string;
  refreshInterval?: number; // in milliseconds
}

const defaultCityData = {
  "McAllen": {
    avgPrice: "$285,000",
    trend: "Rising 5% annually",
    daysOnMarket: "45 days",
    lastUpdated: "2025-03-01"
  },
  "Brownsville": {
    avgPrice: "$220,000",
    trend: "Rising 4% annually",
    daysOnMarket: "55 days",
    lastUpdated: "2025-03-01"
  },
  "South Padre Island": {
    avgPrice: "$450,000",
    trend: "Rising 7% annually",
    daysOnMarket: "60 days",
    lastUpdated: "2025-03-01"
  },
  "Harlingen": {
    avgPrice: "$195,000",
    trend: "Rising 3.5% annually",
    daysOnMarket: "50 days",
    lastUpdated: "2025-03-01"
  },
  "Mission": {
    avgPrice: "$240,000",
    trend: "Rising 4.5% annually",
    daysOnMarket: "48 days",
    lastUpdated: "2025-03-01"
  },
  "Edinburg": {
    avgPrice: "$250,000",
    trend: "Rising 5% annually",
    daysOnMarket: "43 days",
    lastUpdated: "2025-03-01"
  },
  "Pharr": {
    avgPrice: "$225,000",
    trend: "Rising 4% annually",
    daysOnMarket: "47 days",
    lastUpdated: "2025-03-01"
  },
  "Weslaco": {
    avgPrice: "$210,000",
    trend: "Rising 3.8% annually",
    daysOnMarket: "52 days",
    lastUpdated: "2025-03-01"
  },
  "Rio Grande City": {
    avgPrice: "$180,000",
    trend: "Rising 3% annually",
    daysOnMarket: "60 days",
    lastUpdated: "2025-03-01"
  },
  "San Juan": {
    avgPrice: "$200,000",
    trend: "Rising 3.5% annually",
    daysOnMarket: "55 days",
    lastUpdated: "2025-03-01"
  },
  "Port Isabel": {
    avgPrice: "$315,000",
    trend: "Rising 6% annually",
    daysOnMarket: "58 days",
    lastUpdated: "2025-03-01"
  },
  "Laguna Vista": {
    avgPrice: "$290,000",
    trend: "Rising 5.5% annually",
    daysOnMarket: "54 days",
    lastUpdated: "2025-03-01"
  },
  "Los Fresnos": {
    avgPrice: "$215,000",
    trend: "Rising 3.8% annually",
    daysOnMarket: "56 days",
    lastUpdated: "2025-03-01"
  },
  "San Benito": {
    avgPrice: "$190,000",
    trend: "Rising 3.2% annually",
    daysOnMarket: "58 days", 
    lastUpdated: "2025-03-01"
  },
  "La Feria": {
    avgPrice: "$175,000",
    trend: "Rising 3% annually",
    daysOnMarket: "60 days",
    lastUpdated: "2025-03-01"
  },
  "Santa Rosa": {
    avgPrice: "$165,000",
    trend: "Rising 2.8% annually",
    daysOnMarket: "62 days",
    lastUpdated: "2025-03-01"
  },
  "Combes": {
    avgPrice: "$160,000",
    trend: "Rising 2.5% annually",
    daysOnMarket: "65 days",
    lastUpdated: "2025-03-01"
  },
  "Primera": {
    avgPrice: "$170,000",
    trend: "Rising 2.7% annually",
    daysOnMarket: "63 days",
    lastUpdated: "2025-03-01"
  },
  "Palm Valley": {
    avgPrice: "$280,000",
    trend: "Rising 5.2% annually",
    daysOnMarket: "47 days",
    lastUpdated: "2025-03-01"
  },
  "Rio Hondo": {
    avgPrice: "$155,000",
    trend: "Rising 2.3% annually",
    daysOnMarket: "68 days",
    lastUpdated: "2025-03-01"
  },
  "Rangerville": {
    avgPrice: "$150,000",
    trend: "Rising 2.2% annually",
    daysOnMarket: "70 days",
    lastUpdated: "2025-03-01"
  },
  "Mercedes": {
    avgPrice: "$185,000",
    trend: "Rising 3.3% annually",
    daysOnMarket: "58 days",
    lastUpdated: "2025-03-01"
  },
  "Donna": {
    avgPrice: "$178,000",
    trend: "Rising 3.1% annually",
    daysOnMarket: "61 days",
    lastUpdated: "2025-03-01"
  },
  "Alamo": {
    avgPrice: "$210,000",
    trend: "Rising 3.9% annually",
    daysOnMarket: "54 days",
    lastUpdated: "2025-03-01"
  },
  "Hidalgo": {
    avgPrice: "$195,000",
    trend: "Rising 3.7% annually",
    daysOnMarket: "56 days",
    lastUpdated: "2025-03-01"
  },
  "Palmview": {
    avgPrice: "$205,000",
    trend: "Rising 3.8% annually",
    daysOnMarket: "52 days",
    lastUpdated: "2025-03-01"
  },
  "Sullivan City": {
    avgPrice: "$152,000",
    trend: "Rising 2.5% annually",
    daysOnMarket: "72 days",
    lastUpdated: "2025-03-01"
  },
  "Penitas": {
    avgPrice: "$160,000",
    trend: "Rising 2.7% annually",
    daysOnMarket: "68 days",
    lastUpdated: "2025-03-01"
  },
  "La Joya": {
    avgPrice: "$158,000",
    trend: "Rising 2.6% annually",
    daysOnMarket: "70 days",
    lastUpdated: "2025-03-01"
  },
  "Palmhurst": {
    avgPrice: "$230,000",
    trend: "Rising 4.2% annually",
    daysOnMarket: "49 days",
    lastUpdated: "2025-03-01"
  },
  "Alton": {
    avgPrice: "$185,000",
    trend: "Rising 3.3% annually",
    daysOnMarket: "58 days",
    lastUpdated: "2025-03-01"
  },
  "Roma": {
    avgPrice: "$165,000",
    trend: "Rising 2.8% annually",
    daysOnMarket: "67 days",
    lastUpdated: "2025-03-01"
  },
  "Progreso": {
    avgPrice: "$145,000",
    trend: "Rising 2.2% annually",
    daysOnMarket: "72 days",
    lastUpdated: "2025-03-01"
  },
  "Progreso Lakes": {
    avgPrice: "$175,000",
    trend: "Rising 3.0% annually",
    daysOnMarket: "65 days",
    lastUpdated: "2025-03-01"
  },
  "La Villa": {
    avgPrice: "$142,000",
    trend: "Rising 2.1% annually",
    daysOnMarket: "74 days",
    lastUpdated: "2025-03-01"
  },
  "Edcouch": {
    avgPrice: "$138,000",
    trend: "Rising 2.0% annually",
    daysOnMarket: "75 days",
    lastUpdated: "2025-03-01"
  },
  "Elsa": {
    avgPrice: "$145,000",
    trend: "Rising 2.2% annually",
    daysOnMarket: "73 days",
    lastUpdated: "2025-03-01"
  },
  "Monte Alto": {
    avgPrice: "$140,000",
    trend: "Rising 2.1% annually",
    daysOnMarket: "76 days",
    lastUpdated: "2025-03-01"
  },
  "Raymondville": {
    avgPrice: "$135,000",
    trend: "Rising 1.9% annually",
    daysOnMarket: "78 days",
    lastUpdated: "2025-03-01"
  },
  "Lyford": {
    avgPrice: "$132,000",
    trend: "Rising 1.8% annually",
    daysOnMarket: "80 days",
    lastUpdated: "2025-03-01"
  },
  "Sebastian": {
    avgPrice: "$130,000",
    trend: "Rising 1.7% annually",
    daysOnMarket: "82 days",
    lastUpdated: "2025-03-01"
  }
};

const getDefaultCityData = (cityName: string) => {
  return defaultCityData[cityName as keyof typeof defaultCityData] || {
    avgPrice: "$200,000",
    trend: "Stable market",
    daysOnMarket: "55 days",
    lastUpdated: "2025-03-01"
  };
};

const MarketSnapshot = ({ 
  cityName, 
  avgPrice, 
  trend, 
  daysOnMarket, 
  refreshInterval = 3600000 // default: refresh every hour
}: MarketSnapshotProps) => {
  const { marketData, isLoading, error, lastRefreshed, refreshData } = useMarketData(cityName, refreshInterval);
  
  const defaultData = getDefaultCityData(cityName);
  
  const displayData = {
    avgPrice: marketData?.avg_price || avgPrice || defaultData.avgPrice,
    trend: marketData?.trend || trend || defaultData.trend,
    daysOnMarket: marketData?.days_on_market || daysOnMarket || defaultData.daysOnMarket,
    lastUpdated: marketData?.last_updated ? new Date(marketData.last_updated).toLocaleDateString() : defaultData.lastUpdated
  };

  const handleRefresh = () => {
    refreshData();
    toast.info(`Refreshing market data for ${cityName}...`);
  };

  const formatLastRefreshed = () => {
    if (!lastRefreshed) return "";
    
    const now = new Date();
    const diffMs = now.getTime() - lastRefreshed.getTime();
    const diffMins = Math.round(diffMs / 60000);
    
    if (diffMins < 1) return "just now";
    if (diffMins === 1) return "1 minute ago";
    if (diffMins < 60) return `${diffMins} minutes ago`;
    
    const diffHours = Math.floor(diffMins / 60);
    if (diffHours === 1) return "1 hour ago";
    if (diffHours < 24) return `${diffHours} hours ago`;
    
    const diffDays = Math.floor(diffHours / 24);
    if (diffDays === 1) return "yesterday";
    if (diffDays < 30) return `${diffDays} days ago`;
    
    return lastRefreshed.toLocaleDateString();
  };

  // Always display the market snapshot for any city
  return (
    <div className="bg-gradient-to-r from-estate-800 to-estate-900 text-white py-8">
      <div className="container mx-auto px-4 max-w-6xl">
        <div className="text-center mb-4">
          <h2 className="text-2xl font-semibold mb-1">Real Estate Market Snapshot</h2>
          <p className="text-gold-400">{cityName}</p>
          {error && <p className="text-red-400 text-sm mt-1">Error loading market data</p>}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-center">
          <div>
            <div className="flex items-center justify-center mb-2">
              <Home className="w-5 h-5 text-gold-500 mr-2" />
              <span className="font-semibold">Average Home Price</span>
            </div>
            {isLoading ? (
              <Skeleton className="h-6 w-20 bg-gray-700 mx-auto" />
            ) : (
              <p className="text-xl">{displayData.avgPrice}</p>
            )}
          </div>
          <div>
            <div className="flex items-center justify-center mb-2">
              <TrendingUp className="w-5 h-5 text-gold-500 mr-2" />
              <span className="font-semibold">Market Trend</span>
            </div>
            {isLoading ? (
              <Skeleton className="h-6 w-32 bg-gray-700 mx-auto" />
            ) : (
              <p className="text-xl">{displayData.trend}</p>
            )}
          </div>
          <div>
            <div className="flex items-center justify-center mb-2">
              <Users className="w-5 h-5 text-gold-500 mr-2" />
              <span className="font-semibold">Average Days on Market</span>
            </div>
            {isLoading ? (
              <Skeleton className="h-6 w-16 bg-gray-700 mx-auto" />
            ) : (
              <p className="text-xl">{displayData.daysOnMarket}</p>
            )}
          </div>
        </div>
        
        <div className="flex justify-between items-center mt-4 text-xs text-gray-400">
          <div>
            <span>Last updated: {displayData.lastUpdated}</span>
          </div>
          <div className="flex items-center gap-2">
            <span>Data refreshed: {formatLastRefreshed()}</span>
            <Button 
              variant="outline" 
              size="sm" 
              className="h-6 px-2 border-gold-500 text-gold-500 hover:bg-gold-500 hover:text-black"
              onClick={handleRefresh}
            >
              <RefreshCw className="h-3 w-3 mr-1" />
              Refresh
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketSnapshot;
