
import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import ProjectBasicsTab from './ProjectBasicsTab';
import ManualCostsTab from './ManualCostsTab';

interface FormTabsProps {
  projectName: string;
  setProjectName: (value: string) => void;
  squareFootage: number;
  setSquareFootage: (value: number) => void;
  roomCount: number;
  setRoomCount: (value: number) => void;
  materialQuality: string;
  setMaterialQuality: (value: string) => void;
  laborRate: number;
  setLaborRate: (value: number) => void;
  duration: number;
  setDuration: (value: number) => void;
  permitsRequired: boolean;
  setPermitsRequired: (value: boolean) => void;
  additionalFees: number;
  setAdditionalFees: (value: number) => void;
  designFee: number;
  setDesignFee: (value: number) => void;
  contingencyPercentage: number;
  setContingencyPercentage: (value: number) => void;
  materialCost: number;
  setMaterialCost: (value: number) => void;
  suggestedMaterialCost: number;
  laborCost: number;
  setLaborCost: (value: number) => void;
  suggestedLaborCost: number;
  permitsCost: number;
  setPermitsCost: (value: number) => void;
  suggestedPermitsCost: number;
}

const FormTabs: React.FC<FormTabsProps> = (props) => {
  return (
    <Tabs defaultValue="basics" className="w-full">
      <TabsList className="bg-estate-800/70 mb-4 w-full flex border border-estate-600">
        <TabsTrigger 
          value="basics" 
          className="flex-1 text-gold-400 transition-all duration-200 hover:bg-estate-700 data-[state=active]:bg-estate-600 data-[state=active]:text-gold-300"
        >
          Project Basics
        </TabsTrigger>
        <TabsTrigger 
          value="costs" 
          className="flex-1 text-gold-400 transition-all duration-200 hover:bg-estate-700 data-[state=active]:bg-estate-600 data-[state=active]:text-gold-300"
        >
          Manual Costs
        </TabsTrigger>
      </TabsList>
      
      <TabsContent value="basics">
        <ProjectBasicsTab
          projectName={props.projectName}
          setProjectName={props.setProjectName}
          squareFootage={props.squareFootage}
          setSquareFootage={props.setSquareFootage}
          roomCount={props.roomCount}
          setRoomCount={props.setRoomCount}
          materialQuality={props.materialQuality}
          setMaterialQuality={props.setMaterialQuality}
          laborRate={props.laborRate}
          setLaborRate={props.setLaborRate}
          duration={props.duration}
          setDuration={props.setDuration}
          permitsRequired={props.permitsRequired}
          setPermitsRequired={props.setPermitsRequired}
          additionalFees={props.additionalFees}
          setAdditionalFees={props.setAdditionalFees}
          designFee={props.designFee}
          setDesignFee={props.setDesignFee}
          contingencyPercentage={props.contingencyPercentage}
          setContingencyPercentage={props.setContingencyPercentage}
        />
      </TabsContent>

      <TabsContent value="costs">
        <ManualCostsTab
          materialCost={props.materialCost}
          setMaterialCost={props.setMaterialCost}
          suggestedMaterialCost={props.suggestedMaterialCost}
          laborCost={props.laborCost}
          setLaborCost={props.setLaborCost}
          suggestedLaborCost={props.suggestedLaborCost}
          permitsCost={props.permitsCost}
          setPermitsCost={props.setPermitsCost}
          suggestedPermitsCost={props.suggestedPermitsCost}
        />
      </TabsContent>
    </Tabs>
  );
};

export default FormTabs;
