
import { CityInfo } from "../types/CommunityTypes";

export const McAllenData: CityInfo = {
  headline: "Discover Luxury Living in McAllen",
  description: "McAllen offers a perfect blend of urban amenities and suburban comfort, with vibrant shopping districts, exceptional dining, and access to international culture. As one of the fastest-growing cities in the Rio Grande Valley, McAllen presents excellent investment opportunities and an unmatched quality of life.",
  marketInfo: {
    avgPrice: "$285,000",
    trend: "Rising 5% annually",
    daysOnMarket: "45 days"
  },
  communities: [
    {
      name: "Tres Lagos",
      description: "An upscale master-planned community with beautiful lakes and parks",
      features: ["Resort-style pools", "Walking trails", "Top-rated schools"],
      priceRange: "$350,000 - $750,000",
      image: "https://images.unsplash.com/photo-1582407947304-fd86f028f716?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cmVzb3J0JTIwY29tbXVuaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
    },
    {
      name: "Sharyland Plantation",
      description: "Exclusive gated neighborhoods with luxury homes and golf course access",
      features: ["Golf course views", "Gated security", "Elegant designs"],
      priceRange: "$400,000 - $1,000,000+",
      image: "https://images.unsplash.com/photo-1564013799919-ab600027ffc6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGx1eHVyeSUyMGhvbWV8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
    }
  ],
  featuredProperties: [
    {
      id: 101,
      city: "McAllen",
      title: "Modern Luxury in Tres Lagos",
      price: "$425,000",
      image: "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8aG91c2V8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",
      beds: 4,
      baths: 3.5,
      sqft: "3,200"
    },
    {
      id: 102,
      city: "McAllen",
      title: "Elegant Estate Home",
      price: "$675,000",
      image: "https://images.unsplash.com/photo-1600596542815-ffad4c1539a9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8aG91c2V8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",
      beds: 5,
      baths: 4,
      sqft: "4,500"
    }
  ]
};
