
import React from "react";
import { useLanguage } from "@/context/LanguageContext";

const BlogHeader = () => {
  const { language } = useLanguage();

  return (
    <div className="mb-8">
      <h1 className="text-3xl font-bold mb-2 text-white">
        {language === 'es' ? 'Publicaciones del Blog' : 'Blog Posts'}
      </h1>
      <p className="text-gray-400">
        {language === 'es' 
          ? 'Explora nuestra colección de información y consejos sobre bienes raíces' 
          : 'Explore our collection of real estate insights and tips'}
      </p>
    </div>
  );
};

export default BlogHeader;
