
import ResidentialCard from "./ResidentialCard";

const ResidentialGrid = () => {
  const residentialProperties = [
    {
      id: 1,
      image: "https://images.unsplash.com/photo-1580587771525-78b9dba3b914?auto=format&fit=crop&q=80",
      title: "Luxury Villa with Pool",
      location: "North McAllen, TX",
      bedrooms: 5,
      bathrooms: 4.5,
      sqft: "4,200 sq ft",
      price: "$875,000",
      featured: true
    },
    {
      id: 2,
      image: "https://images.unsplash.com/photo-1600596542815-ffad4c1539a9?auto=format&fit=crop&q=80",
      title: "Modern Family Home",
      location: "Sharyland, TX",
      bedrooms: 4,
      bathrooms: 3,
      sqft: "2,800 sq ft",
      price: "$450,000"
    },
    {
      id: 3,
      image: "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?auto=format&fit=crop&q=80",
      title: "Spanish-Style Ranch",
      location: "South McAllen, TX",
      bedrooms: 3,
      bathrooms: 2.5,
      sqft: "2,100 sq ft",
      price: "$385,000"
    },
    {
      id: 4,
      image: "https://images.unsplash.com/photo-1575517111478-7f6afd0973db?auto=format&fit=crop&q=80",
      title: "Contemporary Townhouse",
      location: "Downtown McAllen, TX",
      bedrooms: 3,
      bathrooms: 2.5,
      sqft: "1,950 sq ft",
      price: "$320,000",
      featured: true
    },
    {
      id: 5,
      image: "https://images.unsplash.com/photo-1602343168117-bb8ffe3e2e9f?auto=format&fit=crop&q=80",
      title: "Single-Level Ranch",
      location: "Mission, TX",
      bedrooms: 3,
      bathrooms: 2,
      sqft: "1,800 sq ft",
      price: "$275,000"
    },
    {
      id: 6,
      image: "https://images.unsplash.com/photo-1625602812206-5ec545ca1231?auto=format&fit=crop&q=80",
      title: "Resort-Style Home",
      location: "North McAllen, TX",
      bedrooms: 4,
      bathrooms: 3.5,
      sqft: "3,500 sq ft",
      price: "$650,000"
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-12 gap-6 wp-block-lovable-residential-grid">
      {/* First featured property (spans 2 rows, 8 columns on md) */}
      <div className="md:col-span-8 md:row-span-2">
        <ResidentialCard property={residentialProperties[0]} className="h-full" />
      </div>
      
      {/* Second property (spans 4 columns) */}
      <div className="md:col-span-4">
        <ResidentialCard property={residentialProperties[1]} />
      </div>
      
      {/* Third property (spans 4 columns) */}
      <div className="md:col-span-4">
        <ResidentialCard property={residentialProperties[2]} />
      </div>
      
      {/* Fourth property (spans 8 columns, md) */}
      <div className="md:col-span-8">
        <ResidentialCard property={residentialProperties[3]} />
      </div>
      
      {/* Fifth property (spans 6 columns, md) */}
      <div className="md:col-span-6">
        <ResidentialCard property={residentialProperties[4]} />
      </div>
      
      {/* Sixth property (spans 6 columns, md) */}
      <div className="md:col-span-6">
        <ResidentialCard property={residentialProperties[5]} />
      </div>
    </div>
  );
};

export default ResidentialGrid;
