
import React, { useEffect } from 'react';
import { useYouTubeVideo } from '@/hooks/media/useYouTubeVideo';
import GlobalGlowFilter from '@/components/GlobalGlowFilter';
import { refreshGlowEffect } from '@/utils/glowTextUtils';
import YouTubeBackground from './YouTubeBackground';
import EstimateForm from './EstimateForm';
import AgentAttribution from './AgentAttribution';

const HomeEstimateHero: React.FC = () => {
  const { youtubeId, loadYoutubeVideo } = useYouTubeVideo();
  
  // Load YouTube video on component mount
  useEffect(() => {
    // Use the updated YouTube video URL
    const youtubeUrl = 'https://youtu.be/IOmaFURqJ8A';
    console.log('Loading YouTube video with URL:', youtubeUrl);
    loadYoutubeVideo(youtubeUrl);
    
    // Log the extracted YouTube ID for debugging
    setTimeout(() => {
      console.log('Current YouTube ID:', youtubeId);
    }, 500);
  }, [loadYoutubeVideo]); // Added loadYoutubeVideo to dependency array to fix React hook dependency warning

  // Add effect to ensure the glow is applied
  useEffect(() => {
    // Force refresh the glow effect after component mounts
    setTimeout(() => {
      refreshGlowEffect();
    }, 100);
    
    // And again after a delay to make sure it works
    setTimeout(() => {
      refreshGlowEffect();
    }, 1000);
  }, []);

  return (
    <div className="relative min-h-screen dark">
      {/* Add GlobalGlowFilter to ensure SVG filters are available */}
      <GlobalGlowFilter />
      
      {/* YouTube video background */}
      <YouTubeBackground youtubeId={youtubeId} />
      
      {/* Content positioned over the background */}
      <div className="container mx-auto px-4 relative z-10 pt-36 lg:pt-40">
        <div className="max-w-3xl">
          <h1 className="text-4xl md:text-6xl font-bold text-white mb-4 drop-shadow-lg">
            Find out what your home is really worth
          </h1>
          
          <EstimateForm />
          
          <AgentAttribution />
        </div>
      </div>
      
      {/* White strip for smooth transition */}
      <div className="absolute bottom-0 left-0 right-0 h-5 bg-white"></div>
    </div>
  );
};

export default HomeEstimateHero;
