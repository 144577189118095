
import { useState, useEffect } from 'react';

interface RentVsBuyInputs {
  purchasePrice: number;
  downPayment: number;
  interestRate: number;
  loanTerm: number;
  propertyTaxes: number;
  homeInsurance: number;
  maintenanceCosts: number;
  hoaFees: number;
  monthlyRent: number;
  rentGrowthRate: number;
  rentersInsurance: number;
  investmentPeriod: number;
  investmentReturn: number;
  priceAppreciation: number;
}

interface RentVsBuyResults {
  totalBuyingCost: number;
  totalRentingCost: number;
  netDifference: number;
  homeEquity: number;
  investmentEquity: number;
  isBuyingBetter: boolean;
  yearByYearData: Array<{
    year: number;
    buying: number;
    renting: number;
    buyingEquity: number;
    investmentEquity: number;
  }>;
}

export const useRentVsBuyCalculator = (inputs: RentVsBuyInputs): RentVsBuyResults => {
  const [results, setResults] = useState<RentVsBuyResults>({
    totalBuyingCost: 0,
    totalRentingCost: 0,
    netDifference: 0,
    homeEquity: 0,
    investmentEquity: 0,
    isBuyingBetter: false,
    yearByYearData: []
  });

  useEffect(() => {
    // Calculate mortgage payment
    const calculateMortgagePayment = (principal: number, interestRate: number, loanTermYears: number): number => {
      const monthlyRate = interestRate / 100 / 12;
      const numPayments = loanTermYears * 12;
      
      if (monthlyRate === 0) return principal / numPayments;
      
      return principal * 
        (monthlyRate * Math.pow(1 + monthlyRate, numPayments)) / 
        (Math.pow(1 + monthlyRate, numPayments) - 1);
    };

    // Calculate remaining loan balance after n years
    const calculateRemainingBalance = (principal: number, interestRate: number, loanTermYears: number, yearsElapsed: number): number => {
      const monthlyRate = interestRate / 100 / 12;
      const numPayments = loanTermYears * 12;
      const monthlyPayment = calculateMortgagePayment(principal, interestRate, loanTermYears);
      const paymentsRemaining = numPayments - (yearsElapsed * 12);
      
      if (monthlyRate === 0) {
        return principal - (monthlyPayment * (yearsElapsed * 12));
      }
      
      return (monthlyPayment / monthlyRate) * (1 - Math.pow(1 + monthlyRate, -paymentsRemaining));
    };

    // Calculate compound growth of an investment
    const calculateInvestmentGrowth = (principal: number, annualRate: number, years: number): number => {
      return principal * Math.pow(1 + annualRate / 100, years);
    };

    // Calculate the future value of regular investments
    const calculateFutureValueOfSeries = (payment: number, rate: number, periods: number): number => {
      if (rate === 0) return payment * periods;
      const r = rate / 100;
      return payment * ((Math.pow(1 + r, periods) - 1) / r);
    };

    // Start calculations
    const loanAmount = inputs.purchasePrice - inputs.downPayment;
    const monthlyMortgagePayment = calculateMortgagePayment(loanAmount, inputs.interestRate, inputs.loanTerm);
    
    const yearByYearData: Array<{
      year: number;
      buying: number;
      renting: number;
      buyingEquity: number;
      investmentEquity: number;
    }> = [];

    let totalBuyingCost = 0;
    let totalRentingCost = 0;
    let homeEquity = 0;
    let investmentEquity = 0;

    // Calculate year by year costs and equity
    for (let year = 1; year <= inputs.investmentPeriod; year++) {
      // Buying costs
      const yearlyMortgage = monthlyMortgagePayment * 12;
      const homeValue = inputs.purchasePrice * Math.pow(1 + inputs.priceAppreciation / 100, year);
      const remainingBalance = calculateRemainingBalance(loanAmount, inputs.interestRate, inputs.loanTerm, year);
      const yearHomeEquity = Math.max(0, homeValue - remainingBalance);
      
      const yearlyHomeInsurance = inputs.homeInsurance;
      const yearlyPropertyTaxes = inputs.propertyTaxes;
      const yearlyMaintenance = inputs.maintenanceCosts;
      const yearlyHoa = inputs.hoaFees * 12;
      
      const yearBuyingCost = yearlyMortgage + yearlyHomeInsurance + yearlyPropertyTaxes + yearlyMaintenance + yearlyHoa;
      totalBuyingCost += yearBuyingCost;
      
      // Renting costs
      const yearlyRent = inputs.monthlyRent * 12 * Math.pow(1 + inputs.rentGrowthRate / 100, year - 1);
      const yearlyRentersInsurance = inputs.rentersInsurance;
      
      const yearRentingCost = yearlyRent + yearlyRentersInsurance;
      totalRentingCost += yearRentingCost;
      
      // Investment equity (initial down payment + opportunity cost of the down payment)
      if (year === inputs.investmentPeriod) {
        // Final year calculation
        homeEquity = yearHomeEquity;
        
        // Down payment plus closing costs
        const initialInvestment = inputs.downPayment;
        const downPaymentGrowth = calculateInvestmentGrowth(initialInvestment, inputs.investmentReturn, inputs.investmentPeriod);
        
        // Monthly savings from the difference in payment (if any)
        const monthlySavings = Math.max(0, (yearlyMortgage / 12 + inputs.hoaFees) - inputs.monthlyRent);
        let savingsGrowth = 0;
        
        for (let i = 0; i < inputs.investmentPeriod; i++) {
          const savedInYear = monthlySavings * 12 * Math.pow(1 + inputs.rentGrowthRate / 100, i);
          savingsGrowth += calculateInvestmentGrowth(savedInYear, inputs.investmentReturn, inputs.investmentPeriod - i);
        }
        
        investmentEquity = downPaymentGrowth + savingsGrowth;
      }
      
      // Store year by year data for the chart
      const cumulativeBuying = yearByYearData.length > 0 
        ? yearByYearData[yearByYearData.length - 1].buying + yearBuyingCost 
        : yearBuyingCost;
        
      const cumulativeRenting = yearByYearData.length > 0 
        ? yearByYearData[yearByYearData.length - 1].renting + yearRentingCost 
        : yearRentingCost;
      
      yearByYearData.push({
        year,
        buying: cumulativeBuying,
        renting: cumulativeRenting,
        buyingEquity: yearHomeEquity,
        investmentEquity: calculateInvestmentGrowth(inputs.downPayment, inputs.investmentReturn, year) + 
                          (yearByYearData.length > 0 ? yearByYearData[yearByYearData.length - 1].investmentEquity * 0.1 : 0)
      });
    }

    const netDifference = totalRentingCost - totalBuyingCost;
    const isBuyingBetter = netDifference > 0;

    setResults({
      totalBuyingCost,
      totalRentingCost,
      netDifference,
      homeEquity,
      investmentEquity,
      isBuyingBetter,
      yearByYearData
    });

  }, [inputs]);

  return results;
};
