
import React from 'react';
import { TimelinePhase } from './RenovationEstimatorContainer';

interface ProjectTimelineProps {
  phases: TimelinePhase[];
  formatCurrency: (value: number) => string;
}

const ProjectTimeline: React.FC<ProjectTimelineProps> = ({ phases, formatCurrency }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-transparent">
        <thead>
          <tr className="border-b border-estate-700">
            <th className="py-2 px-4 text-left text-gold-500">Phase</th>
            <th className="py-2 px-4 text-left text-gold-500">Duration (Weeks)</th>
            <th className="py-2 px-4 text-left text-gold-500">Cost ($)</th>
          </tr>
        </thead>
        <tbody>
          {phases.map((phase, index) => (
            <tr key={index} className="border-b border-estate-700/50 hover:bg-estate-700/30">
              <td className="py-3 px-4 text-white">{phase.name}</td>
              <td className="py-3 px-4 text-white">{phase.duration}</td>
              <td className="py-3 px-4 text-white">{formatCurrency(phase.cost)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectTimeline;
