
import { Home, MapPin, DollarSign, Filter } from "lucide-react";

interface SearchCriteriaProps {
  propertyType: string;
  location: string;
  priceRange: number[];
}

const SearchCriteria = ({ propertyType, location, priceRange }: SearchCriteriaProps) => {
  // Helper function to get proper property type label
  const getPropertyTypeLabel = (type: string) => {
    switch(type) {
      case "residential": return "Residential";
      case "commercial": return "Commercial";
      case "land": return "Land";
      case "investment": return "Investment";
      default: return "All property types";
    }
  };
  
  return (
    <div className="mb-8">
      <div className="bg-black dark:bg-gray-900 p-5 rounded-lg shadow-sm border border-gray-800 dark:border-gray-700">
        <h4 className="font-medium text-white dark:text-gray-200 flex items-center mb-3">
          <Filter className="h-4 w-4 mr-2 text-gold-500" />
          Search Criteria
        </h4>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="flex items-center">
            <div className="h-8 w-8 rounded-full bg-blue-900/30 dark:bg-blue-900/30 flex items-center justify-center mr-3">
              <Home className="h-4 w-4 text-blue-400 dark:text-blue-400" />
            </div>
            <div>
              <p className="text-xs text-gray-400 dark:text-gray-400">Property Type</p>
              <p className="font-medium text-white">{getPropertyTypeLabel(propertyType)}</p>
            </div>
          </div>
          
          <div className="flex items-center">
            <div className="h-8 w-8 rounded-full bg-green-900/30 dark:bg-green-900/30 flex items-center justify-center mr-3">
              <MapPin className="h-4 w-4 text-green-400 dark:text-green-400" />
            </div>
            <div>
              <p className="text-xs text-gray-400 dark:text-gray-400">Location</p>
              <p className="font-medium text-white">{location || "All areas"}</p>
            </div>
          </div>
          
          <div className="flex items-center">
            <div className="h-8 w-8 rounded-full bg-gold-900/30 dark:bg-gold-900/30 flex items-center justify-center mr-3">
              <DollarSign className="h-4 w-4 text-gold-400 dark:text-gold-400" />
            </div>
            <div>
              <p className="text-xs text-gray-400 dark:text-gray-400">Price Range</p>
              <p className="font-medium text-white">Up to ${priceRange[0].toLocaleString()}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchCriteria;
