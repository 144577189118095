
import { useEffect, useState } from "react";
import { useLanguage } from "@/context/LanguageContext";

const ResidentialHero = () => {
  const [scrollY, setScrollY] = useState(0);
  const { t } = useLanguage();
  
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="relative h-[300px] md:h-[400px] overflow-hidden wp-block-lovable-residential-hero">
      {/* Parallax background */}
      <div 
        className="absolute inset-0 bg-cover bg-center" 
        style={{
          backgroundImage: "url('https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&fit=crop&q=80')",
          transform: `translateY(${scrollY * 0.3}px)`,
          backgroundPosition: `center ${50 + scrollY * 0.1}%`
        }}
      />
      
      {/* Dark overlay */}
      <div className="absolute inset-0 bg-black/50" />
      
      {/* Content */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-white px-4">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-display mb-4 text-center">
          {t('residential.title')}
        </h1>
        <p className="text-xl md:text-2xl opacity-90 text-center max-w-2xl">
          {t('residential.subtitle')}
        </p>
      </div>
    </div>
  );
};

export default ResidentialHero;
