
import React from 'react';
import { Home, Users } from 'lucide-react';
import GlowingText from '@/components/common/GlowingText';

const CalculatorHeader: React.FC = () => {
  return (
    <div className="text-center mb-6 overflow-visible">
      <div className="flex items-center justify-center mb-2 gap-3">
        <Home className="w-8 h-8 text-gold-400" />
        <GlowingText 
          text="House Hacking Calculator" 
          as="h1"
          className="text-4xl md:text-5xl font-bold overflow-visible"
          advanced={true} 
          glowColor="gold"
        />
        <Users className="w-8 h-8 text-gold-400" />
      </div>
      <p className="text-gray-300 mt-4 max-w-2xl mx-auto">
        See how much of your mortgage can be covered by renting part of your home. 
        House hacking helps you build equity while reducing your out-of-pocket housing costs.
      </p>
    </div>
  );
};

export default CalculatorHeader;
