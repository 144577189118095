
import { useState, useEffect } from "react";
import { useToast } from "@/hooks/use-toast";

// Define types for the interest rate data
export interface InterestRateData {
  rate: number;
  term: number;
  type: string;
  lastUpdated: string;
}

// Mock interest rate data - in a real app, this would come from an API
const mockRates: InterestRateData[] = [
  { rate: 6.25, term: 30, type: "Fixed", lastUpdated: new Date().toLocaleDateString() },
  { rate: 5.75, term: 15, type: "Fixed", lastUpdated: new Date().toLocaleDateString() },
  { rate: 5.5, term: 10, type: "Fixed", lastUpdated: new Date().toLocaleDateString() },
  { rate: 4.85, term: 5, type: "ARM", lastUpdated: new Date().toLocaleDateString() },
];

export const useInterestRates = () => {
  const { toast } = useToast();
  const [rates, setRates] = useState<InterestRateData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRates = async () => {
      setLoading(true);
      try {
        // In a real implementation, you would fetch from an actual API here
        // const response = await fetch('https://api.example.com/mortgage-rates');
        // const data = await response.json();
        
        // For now, we're using mock data with a timeout to simulate an API call
        setTimeout(() => {
          setRates(mockRates);
          setLoading(false);
        }, 1000);
      } catch (err) {
        console.error('Error fetching interest rates:', err);
        setError('Failed to fetch current interest rates');
        toast({
          title: "Error",
          description: "Failed to fetch current interest rates. Using default values.",
          variant: "destructive",
        });
        setRates(mockRates); // Fallback to mock data on error
        setLoading(false);
      }
    };

    fetchRates();
  }, [toast]);

  return { rates, loading, error };
};
