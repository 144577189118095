
import React from "react";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import BlogPostsTable from "@/components/blog/BlogPostsTable";
import BlogAdminPanel from "@/components/blog/BlogAdminPanel";
import { useState, useCallback } from "react";

const BlogAdmin = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  
  const handleRefresh = useCallback(() => {
    setRefreshKey(prev => prev + 1);
  }, []);
  
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow">
        <div className="container mx-auto px-4 py-8 max-w-6xl">
          <h1 className="text-3xl font-bold mb-8">Blog Administration</h1>
          
          <BlogAdminPanel onRefresh={handleRefresh} />
          
          <BlogPostsTable key={refreshKey} />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default BlogAdmin;
