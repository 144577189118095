
import React from "react";

const BlogPostLoading: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-16 max-w-6xl">
      <div className="animate-pulse">
        <div className="h-8 bg-gray-200 rounded mb-4 w-3/4"></div>
        <div className="h-64 bg-gray-200 rounded mb-4"></div>
        <div className="h-4 bg-gray-200 rounded mb-2"></div>
        <div className="h-4 bg-gray-200 rounded mb-2"></div>
        <div className="h-4 bg-gray-200 rounded mb-2"></div>
        <div className="h-4 bg-gray-200 rounded mb-2 w-2/3"></div>
      </div>
    </div>
  );
};

export default BlogPostLoading;
