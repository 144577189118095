
import React from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Send, User, Mail, MessageSquare, Phone } from "lucide-react";
import { useToast } from "@/hooks/use-toast";

const MexicanContactForm = () => {
  const { toast } = useToast();
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    toast({
      title: "¡Mensaje enviado!",
      description: "Nos pondremos en contacto contigo dentro de 24 horas.",
    });
  };

  return (
    <div className="bg-estate-900/90 p-8 rounded-xl border-0 shadow-2xl animate-fadeIn neon-glow-container entire-form-glow">
      <div className="neon-border"></div>
      <h2 className="text-3xl font-display mb-6 relative z-10 text-white">Envíanos un Mensaje</h2>
      <form onSubmit={handleSubmit} className="space-y-6 contact-form relative z-10" action="mailto:juan@juanjoseelizondo.com" method="post" encType="text/plain">
        <div className="form-group neon-input-container">
          <label htmlFor="name" className="block text-estate-300 mb-2 flex items-center">
            <User className="h-4 w-4 mr-2 text-blue-400" />
            Tu Nombre
          </label>
          <Input 
            id="name" 
            name="name"
            placeholder="Juan Pérez" 
            className="bg-estate-800/80 border-estate-700/50 text-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500/30 form-input neon-form-input"
            required
          />
        </div>
        <div className="form-group neon-input-container">
          <label htmlFor="email" className="block text-estate-300 mb-2 flex items-center">
            <Mail className="h-4 w-4 mr-2 text-blue-400" />
            Correo Electrónico
          </label>
          <Input 
            id="email" 
            name="email"
            type="email" 
            placeholder="juan@ejemplo.com" 
            className="bg-estate-800/80 border-estate-700/50 text-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500/30 form-input neon-form-input"
            required
          />
        </div>
        <div className="form-group neon-input-container">
          <label htmlFor="phone" className="block text-estate-300 mb-2 flex items-center">
            <Phone className="h-4 w-4 mr-2 text-blue-400" />
            Teléfono
          </label>
          <Input 
            id="phone" 
            name="phone"
            type="tel" 
            placeholder="+52 55 1234 5678" 
            className="bg-estate-800/80 border-estate-700/50 text-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500/30 form-input neon-form-input"
          />
        </div>
        <div className="form-group neon-input-container">
          <label htmlFor="message" className="block text-estate-300 mb-2 flex items-center">
            <MessageSquare className="h-4 w-4 mr-2 text-blue-400" />
            Mensaje
          </label>
          <Textarea 
            id="message" 
            name="message"
            rows={5} 
            placeholder="Cuéntanos sobre tus necesidades de inversión inmobiliaria..." 
            className="w-full bg-estate-800/80 border-estate-700/50 rounded-md p-3 text-white focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-500/30 transition form-textarea neon-form-input"
            required
          />
        </div>
        <Button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white form-submit neon-button">
          <Send className="w-4 h-4 mr-2" />
          Enviar Mensaje
        </Button>
      </form>
    </div>
  );
};

export default MexicanContactForm;
