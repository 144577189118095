
import { PropertyDetailsProps } from "./PropertyDetailsDialog";
import PropertyCard from "./PropertyCard";

interface PropertyGridProps {
  properties: PropertyDetailsProps[];
  isLoading: boolean;
  onViewDetails: (property: PropertyDetailsProps) => void;
}

const PropertyGrid = ({ properties, isLoading, onViewDetails }: PropertyGridProps) => {
  if (isLoading) {
    return (
      <div className="py-8 text-center">
        <div className="inline-block w-8 h-8 border-4 border-t-[#ffb347] border-r-[#ffb347] border-b-transparent border-l-transparent rounded-full animate-spin"></div>
        <p className="mt-4 text-gray-600 dark:text-gray-300">Loading properties...</p>
      </div>
    );
  }

  if (properties.length === 0) {
    return (
      <div className="text-center py-12">
        <p className="text-lg mb-4">No properties found matching your criteria.</p>
        <p>Try adjusting your search parameters for more results.</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
      {properties.map((property) => (
        <PropertyCard 
          key={property.id} 
          property={property} 
          onViewDetails={onViewDetails} 
        />
      ))}
    </div>
  );
};

export default PropertyGrid;
