
import { BlogPost, BlogImage } from "@/types/blog";

/**
 * Transforms and ensures a post object conforms to the BlogPost type
 */
export const transformPostData = (post: any): BlogPost => {
  console.log("Transforming post data:", post.title || 'Untitled Post');
  
  // Parse images if it's a string
  let parsedImages: BlogImage[] = [];
  
  if (post.images) {
    if (typeof post.images === 'string') {
      try {
        console.log("Parsing images from string:", post.images.substring(0, 100) + "...");
        parsedImages = JSON.parse(post.images);
      } catch (e) {
        console.error("Error parsing images JSON:", e);
      }
    } else if (Array.isArray(post.images)) {
      console.log("Images already in array format:", post.images.length);
      parsedImages = post.images.map((img: any) => ({
        url: img.url || "",
        caption: img.caption || "",
        sequence: img.sequence || 0
      }));
    } else if (post.images && typeof post.images === 'object') {
      console.log("Images in object format, converting to array");
      parsedImages = [{
        url: post.images.url || "",
        caption: post.images.caption || "",
        sequence: post.images.sequence || 0
      }];
    }
  }
  
  // Process the main image (image field) into images array if needed
  if (post.image && (!parsedImages.length || !parsedImages.some(img => img.url === post.image))) {
    console.log("Adding main image to images array:", post.image);
    parsedImages.push({
      url: post.image,
      caption: post.title || "Image",
      sequence: 0
    });
  }
  
  // Transform the post data to ensure it matches BlogPost type
  const transformedPost: BlogPost = {
    id: post.id || crypto.randomUUID(),
    title: post.title || "Untitled Post",
    excerpt: post.excerpt || "No excerpt available",
    content: post.content || "",
    image: post.image || "https://via.placeholder.com/800x400?text=No+Image",
    images: parsedImages,
    date: post.date || post.created_at || new Date().toISOString(),
    author: post.author || "Anonymous",
    category: post.category || "Uncategorized",
    // Preserve the isJuansBlog flag if it exists
    isJuansBlog: post.isJuansBlog === true
  };
  
  console.log("Transformed post:", {
    id: transformedPost.id,
    title: transformedPost.title,
    imagesCount: transformedPost.images.length,
    isJuansBlog: transformedPost.isJuansBlog
  });
  
  return transformedPost;
};
