
import React from 'react';
import { Button } from '@/components/ui/button';
import { Table, TableHeader, TableBody, TableHead, TableRow, TableCell } from '@/components/ui/table';
import { ResultsSectionProps } from './types';

const ResultsSection: React.FC<ResultsSectionProps> = ({
  holdingTimeData,
  includeMonthlyPayments,
  setIncludeMonthlyPayments,
  resetForm,
  formatCurrency,
  formatPercent
}) => {
  return (
    <div className="overflow-hidden transition-transform hover:transform hover:scale-[1.02] hover:z-10">
      <h2 className="text-2xl font-bold text-gold-500 mb-4">Results</h2>
      
      <div className="overflow-x-auto">
        <Table className="border-separate border-spacing-0 border border-estate-700 rounded-lg overflow-hidden w-full">
          <TableHeader className="bg-estate-800">
            <TableRow>
              <TableHead className="text-gold-500">Months</TableHead>
              <TableHead className="text-gold-500">Net Profit</TableHead>
              <TableHead className="text-gold-500">Total Out of Pocket</TableHead>
              <TableHead className="text-gold-500">Return % (Actual)</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {holdingTimeData.map((row) => (
              <TableRow key={`result-${row.months}`} className="odd:bg-estate-900 even:bg-estate-850">
                <TableCell className="text-white">{row.months}mos</TableCell>
                <TableCell className="text-gold-400">{formatCurrency(row.netProfit)}</TableCell>
                <TableCell className="text-white">{formatCurrency(row.totalOutOfPocket)}</TableCell>
                <TableCell className="text-white">{formatPercent(row.returnActual)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      
      <div className="mt-6">
        <div className="flex items-center mb-6">
          <input 
            type="checkbox" 
            id="monthlyPayments" 
            checked={includeMonthlyPayments}
            onChange={() => setIncludeMonthlyPayments(!includeMonthlyPayments)}
            className="mr-2 h-5 w-5"
          />
          <label htmlFor="monthlyPayments" className="text-white text-sm">
            Click Here to {includeMonthlyPayments ? 'remove' : 'include'} monthly payments and
            recalculate My Total Out of Pocket Expense.
          </label>
        </div>
        
        <Button 
          onClick={resetForm}
          className="bg-gold-500 hover:bg-gold-600 text-black font-bold px-6 w-full mb-2 calculator-reset-glow"
        >
          RESET FORM
        </Button>
      </div>
    </div>
  );
};

export default ResultsSection;
