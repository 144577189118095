
import PropertyCard from "./PropertyCard";
import { 
  Carousel, 
  CarouselContent, 
  CarouselItem, 
  CarouselNext, 
  CarouselPrevious 
} from "@/components/ui/carousel";

const PropertyGrid = () => {
  const properties = [
    {
      image: "/lovable-uploads/6e725c39-f7a8-48d1-b4d2-e5cd1cf43fbd.png",
      title: "Luxury Villa",
      location: "McAllen, TX",
      price: "$1,950,000",
    },
    {
      image: "/lovable-uploads/5eb1e5e4-922e-4f70-8e96-ad74c47098f8.png",
      title: "Commercial Warehouse",
      location: "Hidalgo, TX",
      price: "$3,200,000",
    },
    {
      image: "/lovable-uploads/326444d6-84da-4efd-99b9-a400be4196ae.png",
      title: "Modern Home",
      location: "Sharyland, TX",
      price: "$1,850,000",
    },
    {
      image: "/lovable-uploads/f912b308-0152-4006-ac12-c1116f6932f5.png",
      title: "Beachfront Resort",
      location: "South Padre Island, TX",
      price: "$4,750,000",
    },
    {
      image: "https://images.unsplash.com/photo-1518005020951-eccb494ad742?auto=format&fit=crop&w=800&q=75",
      title: "Forest Retreat",
      location: "Aspen, Colorado",
      price: "$2,450,000",
    },
    {
      image: "https://images.unsplash.com/photo-1524230572899-a752b3835840?auto=format&fit=crop&w=800&q=75",
      title: "Modern Villa",
      location: "Beverly Hills, CA",
      price: "$5,900,000",
    },
    {
      image: "https://images.unsplash.com/photo-1433832597046-4f10e10ac764?auto=format&fit=crop&w=800&q=75",
      title: "Urban Penthouse",
      location: "Manhattan, NY",
      price: "$3,750,000",
    },
  ];

  return (
    <div className="max-w-6xl mx-auto">
      <Carousel className="w-full">
        <CarouselContent className="px-1">
          {properties.map((property, index) => (
            <CarouselItem key={index} className="md:basis-1/3 lg:basis-1/3">
              <PropertyCard {...property} />
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious className="left-0 lg:-left-12 text-white border-gold-500 hover:bg-gold-700/20" />
        <CarouselNext className="right-0 lg:-right-12 text-white border-gold-500 hover:bg-gold-700/20" />
      </Carousel>
    </div>
  );
};

export default PropertyGrid;
