
import React from "react";
import { BlogImage } from "@/types/blog";

export const useImageCarousel = (
  images: BlogImage[] | string[] = [], 
  initialIndex: number = 0,
  autoScroll: boolean = false,
  interval: number = 5000 // Default to 5 seconds
) => {
  const [currentIndex, setCurrentIndex] = React.useState(initialIndex);
  const [totalImages, setTotalImages] = React.useState(0);
  const [currentImage, setCurrentImage] = React.useState<string>("");
  const [currentCaption, setCurrentCaption] = React.useState<string>("");
  const [hasMultipleImages, setHasMultipleImages] = React.useState(false);
  const [isAutoScrolling, setIsAutoScrolling] = React.useState(autoScroll);
  const autoScrollTimerRef = React.useRef<number | null>(null);

  React.useEffect(() => {
    if (!images || images.length === 0) {
      console.log("useImageCarousel: No images provided");
      setTotalImages(0);
      setCurrentImage("");
      setCurrentCaption("");
      setHasMultipleImages(false);
      return;
    }

    // Debug what we're receiving
    console.log("useImageCarousel initialized with:", {
      imageCount: images.length,
      firstImageType: typeof images[0],
      initialIndex,
      isArrayOfStrings: typeof images[0] === 'string',
      imagesArray: JSON.stringify(images).substring(0, 200) + "...",
      autoScroll
    });

    // Set total images
    setTotalImages(images.length);
    
    // Set hasMultipleImages
    setHasMultipleImages(images.length > 1);
    
    // Ensure currentIndex is within bounds
    const safeIndex = Math.min(Math.max(initialIndex, 0), images.length - 1);
    setCurrentIndex(safeIndex);
    
    // Update current image and caption based on index
    updateCurrentImage(safeIndex);
  }, [images, initialIndex]);

  // Setup auto-scrolling
  React.useEffect(() => {
    // Only set up auto-scrolling if enabled and there are multiple images
    if (isAutoScrolling && hasMultipleImages && totalImages > 1) {
      console.log(`Setting up auto-scroll with interval: ${interval}ms`);
      
      // Clear any existing timer
      if (autoScrollTimerRef.current) {
        window.clearInterval(autoScrollTimerRef.current);
      }
      
      // Set up a new timer
      autoScrollTimerRef.current = window.setInterval(() => {
        console.log("Auto-scrolling to next image");
        setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
      }, interval);
      
      // Return cleanup function
      return () => {
        if (autoScrollTimerRef.current) {
          console.log("Cleaning up auto-scroll timer");
          window.clearInterval(autoScrollTimerRef.current);
          autoScrollTimerRef.current = null;
        }
      };
    }
    
    // Clean up if auto-scrolling is disabled
    return () => {
      if (autoScrollTimerRef.current) {
        console.log("Cleaning up auto-scroll timer");
        window.clearInterval(autoScrollTimerRef.current);
        autoScrollTimerRef.current = null;
      }
    };
  }, [isAutoScrolling, hasMultipleImages, totalImages, interval]);

  // Helper function to update current image and caption
  const updateCurrentImage = (index: number) => {
    if (!images || images.length === 0 || index < 0 || index >= images.length) {
      return;
    }
    
    const currentItem = images[index];
    
    if (typeof currentItem === 'string') {
      setCurrentImage(currentItem);
      setCurrentCaption(`Image ${index + 1}`);
    } else {
      setCurrentImage(currentItem.url);
      setCurrentCaption(currentItem.caption || `Image ${index + 1}`);
    }
    
    console.log("useImageCarousel updated image:", {
      index,
      url: typeof currentItem === 'string' ? 
        currentItem.substring(0, 30) + "..." : 
        currentItem.url.substring(0, 30) + "...",
      caption: typeof currentItem === 'string' ? 
        `Image ${index + 1}` : 
        (currentItem.caption || `Image ${index + 1}`)
    });
  };

  React.useEffect(() => {
    // When currentIndex changes, update the current image and caption
    updateCurrentImage(currentIndex);
  }, [currentIndex, images]);

  // Function to navigate to the next image
  const nextImage = () => {
    if (totalImages <= 1) return;
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  // Function to navigate to the previous image
  const prevImage = () => {
    if (totalImages <= 1) return;
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
  };

  // Function to toggle auto-scrolling
  const toggleAutoScroll = () => {
    setIsAutoScrolling(prev => !prev);
  };

  // Pause auto-scrolling when user interacts with controls
  const pauseAutoScroll = () => {
    if (isAutoScrolling) {
      if (autoScrollTimerRef.current) {
        window.clearInterval(autoScrollTimerRef.current);
        autoScrollTimerRef.current = null;
      }
      setIsAutoScrolling(false);
    }
  };

  return {
    currentIndex,
    totalImages,
    currentImage,
    currentCaption,
    hasMultipleImages,
    isAutoScrolling,
    nextImage,
    prevImage,
    toggleAutoScroll,
    pauseAutoScroll
  };
};

