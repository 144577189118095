
import React from 'react';
import { Button } from '@/components/ui/button';

interface FormButtonsProps {
  resetForm: () => void;
  handleShare: () => void;
  handleExportPDF: () => void;
}

const FormButtons: React.FC<FormButtonsProps> = ({ resetForm, handleShare, handleExportPDF }) => {
  return (
    <div className="flex flex-col sm:flex-row gap-3 pt-4">
      <Button 
        variant="outline" 
        onClick={resetForm} 
        className="flex-1 bg-estate-800/50 hover:bg-estate-700 border-estate-600 text-gold-300 hover:text-gold-200 transition-all duration-200"
      >
        Reset
      </Button>
      <Button 
        variant="outline" 
        onClick={handleShare} 
        className="flex-1 bg-blue-800/50 hover:bg-blue-700 border-blue-600 text-blue-300 hover:text-blue-200 transition-all duration-200 shadow-sm shadow-blue-500/20"
      >
        Share
      </Button>
      <Button 
        variant="outline" 
        onClick={handleExportPDF} 
        className="flex-1 bg-green-800/50 hover:bg-green-700 border-green-600 text-green-300 hover:text-green-200 transition-all duration-200 shadow-sm shadow-green-500/20"
      >
        Export PDF
      </Button>
    </div>
  );
};

export default FormButtons;
