
import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { 
  Home, 
  MapPin, 
  Bath, 
  BedDouble, 
  Calendar, 
  Calculator, 
  ArrowRight, 
  PlayCircle,
  PauseCircle,
  ChevronLeft,
  ChevronRight
} from "lucide-react";
import { AspectRatio } from "@/components/ui/aspect-ratio";
import { useToast } from "@/hooks/use-toast";
import ImageCarousel from "@/components/common/ImageCarousel";

export interface PropertyDetailsProps {
  id: string;
  title: string;
  address: string;
  location: string;
  price: number;
  priceFormatted: string;
  beds: number;
  baths: number;
  sqft: number | string;
  image: string;
  description?: string;
  featured?: boolean;
  yearBuilt?: number;
  propertyType?: string;
  status?: string;
  additionalImages?: string[];
  audioTour?: string;
  amenities?: string[];
  neighborhood?: string;
  schoolDistrict?: string;
}

interface PropertyDetailsDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  property: PropertyDetailsProps | null;
}

const PropertyDetailsDialog = ({
  open,
  onOpenChange,
  property,
}: PropertyDetailsDialogProps) => {
  const { toast } = useToast();
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const audioRef = React.useRef<HTMLAudioElement>(null);

  if (!property) return null;

  // Generate a realistic description if none is provided
  const propertyDescription = property.description || 
    `Beautiful ${property.beds} bedroom, ${property.baths} bathroom ${property.propertyType || "home"} located in ${property.location}. This property features an open floor plan with ${typeof property.sqft === 'number' ? property.sqft.toLocaleString() : property.sqft} square feet of living space, modern appliances, and a spacious backyard. Perfect for families or investors looking for properties in the rapidly growing ${property.location} area.`;

  const handleContactAgent = () => {
    toast({
      title: "Contact request sent",
      description: `An agent will contact you soon about ${property.address}`,
    });
    onOpenChange(false);
  };
  
  const handleCalculatorClick = () => {
    // Use window.location instead of useNavigate
    window.location.href = `/mortgage-calculator?price=${property.price}`;
    onOpenChange(false);
  };

  const toggleAudio = () => {
    if (audioRef.current) {
      if (isPlayingAudio) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlayingAudio(!isPlayingAudio);
    }
  };

  // Create an array of images for the carousel
  const allImages = [property.image];
  if (property.additionalImages && property.additionalImages.length > 0) {
    allImages.push(...property.additionalImages);
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-3xl max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-xl sm:text-2xl font-bold">{property.title}</DialogTitle>
          <DialogDescription className="flex items-center text-foreground">
            <MapPin className="w-4 h-4 mr-1" />
            <span>{property.address}, {property.location}</span>
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-6">
          {/* Property Images Carousel */}
          <div className="relative">
            {allImages.length > 1 ? (
              <ImageCarousel 
                images={allImages} 
                height="h-[300px]" 
                showControls={true}
                showCaption={false}
                alt={property.title}
              />
            ) : (
              <AspectRatio ratio={16/9}>
                <img
                  src={property.image}
                  alt={property.title}
                  className="object-cover w-full h-full rounded-md"
                  onError={(e) => {
                    e.currentTarget.src = "https://images.unsplash.com/photo-1582268611958-ebfd161ef9cf?q=80&w=800";
                  }}
                />
              </AspectRatio>
            )}
            <div className="absolute top-3 right-3 bg-black/70 text-white text-sm font-semibold py-1 px-3 rounded-full">
              {property.priceFormatted}
            </div>
            {property.featured && (
              <div className="absolute top-3 left-3 bg-[#ffb347] text-white text-sm font-semibold py-1 px-3 rounded-full">
                Featured
              </div>
            )}
          </div>

          {/* Audio Tour Button */}
          {property.audioTour && (
            <div className="w-full">
              <Button
                variant="outline"
                onClick={toggleAudio}
                className="w-full flex items-center justify-center gap-2 border-[#ffb347] text-[#ffb347] hover:bg-[#fff8e6]"
              >
                {isPlayingAudio ? (
                  <>
                    <PauseCircle className="h-5 w-5" />
                    Pause Audio Tour
                  </>
                ) : (
                  <>
                    <PlayCircle className="h-5 w-5" />
                    Listen to Audio Tour
                  </>
                )}
              </Button>
              <audio ref={audioRef} src={property.audioTour} className="hidden" onEnded={() => setIsPlayingAudio(false)} />
            </div>
          )}

          {/* Property Status */}
          <div className="flex flex-wrap gap-2">
            <Badge className="bg-green-500 hover:bg-green-600">
              {property.status || "For Sale"}
            </Badge>
            <Badge className="bg-blue-500 hover:bg-blue-600">
              {property.propertyType || "Residential"}
            </Badge>
            {property.neighborhood && (
              <Badge className="bg-purple-500 hover:bg-purple-600">
                {property.neighborhood}
              </Badge>
            )}
            {property.schoolDistrict && (
              <Badge className="bg-indigo-500 hover:bg-indigo-600">
                School: {property.schoolDistrict}
              </Badge>
            )}
          </div>

          {/* Property Details */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 text-center">
            <div className="bg-gray-100 dark:bg-gray-800 p-3 rounded-md">
              <div className="flex flex-col items-center">
                <BedDouble className="w-5 h-5 mb-1" />
                <span className="text-sm font-medium">Bedrooms</span>
                <span className="text-lg font-bold">{property.beds}</span>
              </div>
            </div>
            <div className="bg-gray-100 dark:bg-gray-800 p-3 rounded-md">
              <div className="flex flex-col items-center">
                <Bath className="w-5 h-5 mb-1" />
                <span className="text-sm font-medium">Bathrooms</span>
                <span className="text-lg font-bold">{property.baths}</span>
              </div>
            </div>
            <div className="bg-gray-100 dark:bg-gray-800 p-3 rounded-md">
              <div className="flex flex-col items-center">
                <Home className="w-5 h-5 mb-1" />
                <span className="text-sm font-medium">Area</span>
                <span className="text-lg font-bold">{property.sqft} sq ft</span>
              </div>
            </div>
            <div className="bg-gray-100 dark:bg-gray-800 p-3 rounded-md">
              <div className="flex flex-col items-center">
                <Calendar className="w-5 h-5 mb-1" />
                <span className="text-sm font-medium">Year Built</span>
                <span className="text-lg font-bold">{property.yearBuilt || "2018"}</span>
              </div>
            </div>
          </div>

          {/* Property Description */}
          <div>
            <h3 className="text-lg font-bold mb-2">Description</h3>
            <p className="text-gray-600 dark:text-gray-300 whitespace-pre-line">
              {propertyDescription}
            </p>
          </div>

          {/* Amenities Section */}
          {property.amenities && property.amenities.length > 0 && (
            <div>
              <h3 className="text-lg font-bold mb-2">Amenities</h3>
              <ul className="grid grid-cols-2 gap-2">
                {property.amenities.map((amenity, index) => (
                  <li key={index} className="flex items-center">
                    <div className="w-2 h-2 bg-[#ffb347] rounded-full mr-2"></div>
                    {amenity}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Call to Action Buttons */}
          <div className="flex flex-col sm:flex-row gap-3 pt-4">
            <Button 
              className="flex-1 bg-[#ffb347] hover:bg-[#e69a30] text-white font-bold py-2 transition duration-300"
              onClick={handleContactAgent}
            >
              Contact Agent
              <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
            
            <Button 
              variant="outline" 
              className="flex-1 border-[#ffb347] text-[#ffb347] hover:bg-[#fff8e6]"
              onClick={handleCalculatorClick}
            >
              <Calculator className="mr-2 h-4 w-4" />
              Calculate Mortgage
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PropertyDetailsDialog;
