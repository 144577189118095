
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSEO } from '@/context/SEOContext';

const SEO: React.FC = () => {
  const { seoData } = useSEO();
  const {
    title,
    description,
    keywords = [], // Provide default empty array for keywords
    canonicalUrl,
    imageUrl,
    ogImage,
    ogType = 'website', // Default value
    twitterCard = 'summary_large_image', // Default value
    structuredData,
  } = seoData || {}; // Add fallback if seoData is undefined

  // Safe check for browser environment
  const origin = typeof window !== 'undefined' ? window.location.origin : '';
  
  // Build full canonical URL if it's a relative path
  const fullCanonicalUrl = canonicalUrl
    ? canonicalUrl.startsWith('http')
      ? canonicalUrl
      : `${origin}${canonicalUrl}`
    : typeof window !== 'undefined' ? window.location.href : '';

  // Default OG image if none provided
  const defaultOgImage = `${origin}/lovable-uploads/cb95bfda-3353-4813-a1fc-572342919299.png`;
  const ogImageUrl = imageUrl || ogImage || defaultOgImage;

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && keywords.length > 0 && (
        <meta name="keywords" content={keywords.join(', ')} />
      )}
      
      {/* Canonical URL */}
      <link rel="canonical" href={fullCanonicalUrl} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={fullCanonicalUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImageUrl} />
      
      {/* Twitter */}
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImageUrl} />
      
      {/* Structured Data for Rich Snippets */}
      {structuredData && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
