
import { useLanguage } from "@/context/LanguageContext";

const TabLocation = () => {
  const { language } = useLanguage();

  return (
    <div className="text-white space-y-4">
      <h3 className="text-2xl font-semibold text-white mb-4">
        Beneficios del Valle del Río Grande
      </h3>
      <p className="text-lg">Para inversionistas mexicanos, el Valle del Río Grande ofrece ventajas únicas que Juan puede destacar:</p>
      <ul className="list-disc pl-6 space-y-2 text-lg">
        <li><strong>Proximidad a México:</strong> La cercanía de la región a la frontera facilita a los inversionistas visitar y administrar propiedades, aumentando su atractivo para casas vacacionales o uso frecuente.</li>
        <li><strong>Afinidad cultural:</strong> La fuerte cultura mexicano-americana en el área proporciona un entorno familiar y cómodo, alineándose con las preferencias culturales.</li>
        <li><strong>Economía en crecimiento:</strong> El Valle del Río Grande está experimentando un crecimiento económico, con un mercado inmobiliario robusto que promete buenos retornos de inversión.</li>
        <li><strong>Opciones de propiedades diversas:</strong> Desde casas de lujo hasta propiedades de inversión, la región ofrece una amplia gama de opciones para adaptarse a diferentes presupuestos y propósitos.</li>
      </ul>
    </div>
  );
};

export default TabLocation;
