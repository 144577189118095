
import { useLanguage } from "@/context/LanguageContext";

const TabBackground = () => {
  const { language } = useLanguage();

  return (
    <div className="text-white space-y-4">
      <h3 className="text-2xl font-semibold text-white mb-4">
        Trasfondo y Conexión Cultural
      </h3>
      <p className="text-lg">
        La historia personal de Juan Elizondo es fundamental para su atractivo para inversionistas mexicanos. Criado en el norte de México, trae un entendimiento íntimo de la cultura mexicana, las preferencias y las dinámicas del mercado.
      </p>
      <p className="text-lg">
        Habiendo residido en el Valle del Río Grande durante más de 20 años, actúa como un puente entre los contextos mexicano y estadounidense, haciéndolo altamente relatable para clientes de Monterrey, Reynosa y la Ciudad de México.
      </p>
      <p className="text-lg">
        Esta competencia bicultural es particularmente valiosa para inversionistas que navegan en territorio desconocido, ya que fomenta la confianza y la comunicación. Su perspectiva dual le permite anticipar y abordar matices culturales, como preferencias por vecindarios orientados a la comunidad o proximidad a la frontera, que a menudo son prioridades para compradores mexicanos.
      </p>
    </div>
  );
};

export default TabBackground;
