
import React from 'react';

const CalculatorNotes: React.FC = () => {
  return (
    <div className="mt-12 bg-estate-900/70 p-6 rounded-2xl shadow-lg max-w-4xl mx-auto border border-gold-500/30 transition-all duration-300 hover:shadow-gold-500/20 hover:shadow-xl">
      <p className="text-gray-300 mb-4">
        *Total Out of Pocket = Closing Costs, Points, GAP(funds required to settle, if lender is being used),
        interest(if payments are being made), MISC expenses, Insurance, and Staging.
      </p>
      
      <p className="text-gray-300 mb-4">
        *Note - If your lender does not require monthly payments, your out of pocket costs will be reduced by
        the calculated interest amount above
      </p>
    </div>
  );
};

export default CalculatorNotes;
