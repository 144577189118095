
/**
 * Main text processing utilities for blog content
 */

import { findTextNodes } from './textFindUtils';
import { styleParagraphs, styleHeadings } from './elementStyleUtils';
import { removeDuplicateTitles, removeDuplicateTitlesAfterMeta } from './titleUtils';
import { 
  ensureContentSectionVisibility, 
  createContentSectionIfNeeded, 
  insertContentSection, 
  createPlaceholderContent 
} from './contentSectionUtils';
import { removeEmptyDivs, removeExtraLineBreaks, decodeHtmlEncodedContent } from './domCleanupUtils';

// Export findTextNodes for backward compatibility
export { findTextNodes };

/**
 * Process paragraphs and spacing in the blog content
 * @param contentElement - The DOM element containing the blog content
 */
export const processTextAndSpacing = (contentElement: HTMLDivElement): void => {
  // Debug the DOM structure to understand what we're working with
  console.log("Starting processTextAndSpacing - DOM structure analysis");
  
  // Style paragraphs for better readability
  styleParagraphs(contentElement.querySelectorAll('p'));

  // Style headings for better spacing
  styleHeadings(contentElement.querySelectorAll('h1, h2, h3, h4, h5, h6'));

  // Get the main title text
  const mainTitleElement = contentElement.querySelector('.main-title, h1');
  const titleText = mainTitleElement ? mainTitleElement.textContent?.trim() : '';
  console.log("Main title text:", titleText);

  // Remove duplicate titles that match the main title
  if (titleText) {
    removeDuplicateTitles(contentElement, titleText);
  }

  // Find the content section - this could be a div with class="content" or the root element
  let mainContentSection = contentElement.querySelector('.content');
  
  // If no content section was found, the root element might be the content
  if (!mainContentSection) {
    // Check if contentElement itself has the content class
    if (contentElement.classList.contains('content')) {
      mainContentSection = contentElement;
    } else {
      // Create a new content section with all the content
      console.log("No content section found, creating one");
      const newContentSection = createContentSectionIfNeeded(contentElement);
      
      if (newContentSection) {
        insertContentSection(contentElement, newContentSection);
        mainContentSection = newContentSection;
      } else {
        createPlaceholderContent(contentElement);
        return; // Exit early as we've added placeholder content
      }
    }
  }
  
  console.log("Content section found or created:", !!mainContentSection);
  
  // Now work with the identified or created content section
  if (mainContentSection) {
    // Decode any HTML-encoded content
    decodeHtmlEncodedContent(mainContentSection as HTMLElement);
    
    // Ensure the content section is visible
    ensureContentSectionVisibility(mainContentSection as HTMLElement, titleText || '');
    
    // Remove duplicate titles within the content section
    if (titleText) {
      const headingsInContent = mainContentSection.querySelectorAll('h1');
      headingsInContent.forEach(heading => {
        const headingText = heading.textContent?.trim() || '';
        if (headingText === titleText) {
          console.log("Removing duplicate h1 inside content:", headingText);
          heading.remove();
        }
      });
    }
  }
  
  // Clean up DOM elements
  removeEmptyDivs(contentElement);
  removeExtraLineBreaks(contentElement);
  
  // Final debug check
  console.log("End of processTextAndSpacing - content section processing complete");
};
