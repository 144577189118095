/**
 * Generates an SEO-friendly URL slug from a string
 * @param text - The text to convert to a slug
 * @returns SEO-friendly slug
 */
export const generateSlug = (text: string): string => {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')        // Replace spaces with -
    .replace(/&/g, '-and-')      // Replace & with 'and'
    .replace(/[^\w\-]+/g, '')    // Remove all non-word characters
    .replace(/\-\-+/g, '-')      // Replace multiple - with single -
    .replace(/^-+/, '')          // Trim - from start of text
    .replace(/-+$/, '');         // Trim - from end of text
};

/**
 * Optimize a title for SEO
 * @param title - The title to optimize
 * @param location - Optional location to append
 * @returns SEO-friendly title
 */
export const generateSEOTitle = (title: string, location?: string): string => {
  // Keep titles under 60 characters for optimal display in search results
  let seoTitle = title.trim();
  
  // Add location context if provided and not already in the title
  if (location && !seoTitle.toLowerCase().includes(location.toLowerCase())) {
    seoTitle = `${seoTitle} | ${location}`;
  }
  
  // Add branding if not too long
  if (seoTitle.length < 45 && !seoTitle.includes("Juan Elizondo")) {
    seoTitle = `${seoTitle} | Juan Elizondo Real Estate`;
  }
  
  // Truncate if still too long
  if (seoTitle.length > 60) {
    seoTitle = seoTitle.substring(0, 57) + '...';
  }
  
  return seoTitle;
};

/**
 * Generate meta description from content
 * @param content - The content to generate description from
 * @param maxLength - Maximum length (default 160 characters)
 * @returns SEO-friendly meta description
 */
export const generateMetaDescription = (content: string, maxLength = 160): string => {
  // Remove any HTML tags
  const plainText = content.replace(/<[^>]*>?/gm, '');
  
  // Truncate to specified length
  let description = plainText.substring(0, maxLength);
  
  // Make sure we don't cut in the middle of a word
  if (plainText.length > maxLength) {
    const lastSpace = description.lastIndexOf(' ');
    if (lastSpace > 0) {
      description = description.substring(0, lastSpace) + '...';
    }
  }
  
  return description;
};
