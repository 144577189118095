
import { useLanguage } from "@/context/LanguageContext";
import MexicanContactForm from "./MexicanContactForm";
import { Mail, Phone, Map, Clock } from "lucide-react";

const MexicanContactSection = () => {
  const { t } = useLanguage();
  
  return (
    <section className="py-20 bg-black/90 relative overflow-hidden">
      <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1636653338425-4746a8e53ea3')] bg-cover bg-center opacity-20 z-[-1]"></div>
      
      <div className="container mx-auto px-4 max-w-6xl relative z-10">
        <div className="text-center mb-12">
          <h2 
            className="text-4xl font-display mb-6 text-white" 
            data-text="Contacta a Juan Elizondo"
          >
            Contacta a Juan Elizondo
          </h2>
          <p className="text-gray-300 mb-8 max-w-2xl mx-auto">
            Estamos listos para ayudarte a encontrar la propiedad perfecta para tus objetivos de inversión en los Estados Unidos.
          </p>
        </div>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <MexicanContactForm />
          
          <div className="bg-estate-900/90 p-8 rounded-xl border-0 shadow-2xl neon-glow-container entire-form-glow">
            <div className="neon-border"></div>
            <h3 className="text-2xl font-display mb-6 text-white relative z-10">Información de Contacto</h3>
            
            <div className="space-y-6 relative z-10">
              <div className="flex items-start">
                <div className="bg-blue-900/40 p-3 rounded-full mr-4">
                  <Mail className="h-6 w-6 text-blue-400" />
                </div>
                <div>
                  <h4 className="text-lg font-medium text-white">Email</h4>
                  <a href="mailto:juan@juanjoseelizondo.com" className="text-blue-300 hover:text-blue-200 transition">
                    juan@juanjoseelizondo.com
                  </a>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="bg-blue-900/40 p-3 rounded-full mr-4">
                  <Phone className="h-6 w-6 text-blue-400" />
                </div>
                <div>
                  <h4 className="text-lg font-medium text-white">Teléfono</h4>
                  <a href="tel:+19565221481" className="text-blue-300 hover:text-blue-200 transition">
                    +1 (956) 522-1481
                  </a>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="bg-blue-900/40 p-3 rounded-full mr-4">
                  <Map className="h-6 w-6 text-blue-400" />
                </div>
                <div>
                  <h4 className="text-lg font-medium text-white">Oficina</h4>
                  <p className="text-blue-300">
                    RE/MAX Elite<br />
                    123 Palmas Reales, McAllen, TX 78501
                  </p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="bg-blue-900/40 p-3 rounded-full mr-4">
                  <Clock className="h-6 w-6 text-blue-400" />
                </div>
                <div>
                  <h4 className="text-lg font-medium text-white">Horario de Atención</h4>
                  <p className="text-blue-300">
                    Lunes a Viernes: 9:00 AM - 6:00 PM<br />
                    Sábados: 10:00 AM - 2:00 PM
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MexicanContactSection;
