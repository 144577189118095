
import React from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";

interface JsonSubmitFormProps {
  jsonContent: string;
  setJsonContent: (content: string) => void;
  useAirtableProcessor: boolean;
  setUseAirtableProcessor: (use: boolean) => void;
  handleExampleClick: () => void;
  isSubmitting: boolean;
  isOnline: boolean;
  edgeFunctionStatus: 'unknown' | 'available' | 'unavailable';
  handleSubmit: () => void;
}

const JsonSubmitForm: React.FC<JsonSubmitFormProps> = ({
  jsonContent,
  setJsonContent,
  useAirtableProcessor,
  setUseAirtableProcessor,
  handleExampleClick,
  isSubmitting,
  isOnline,
  edgeFunctionStatus,
  handleSubmit
}) => {
  return (
    <>
      <p className="mb-2 text-sm text-gray-600">
        Paste your JSON content below to create a custom blog post. The system accepts various formats including Airtable-style records.
      </p>
      <Textarea
        value={jsonContent}
        onChange={(e) => setJsonContent(e.target.value)}
        placeholder='{"fields": {"Blog Title": "My Blog Post", "blog content": "<p>This is my content</p>", "hero image": "https://example.com/image.jpg"}}'
        className="h-48 font-mono"
      />
      
      <div className="flex items-center space-x-2 mt-4">
        <Checkbox 
          id="airtable-processor" 
          checked={useAirtableProcessor}
          onCheckedChange={(checked) => setUseAirtableProcessor(checked as boolean)}
        />
        <Label htmlFor="airtable-processor">Process as Airtable-style data (with fields like "Blog Title", "Primary Keyword", etc.)</Label>
      </div>
      
      <div className="flex justify-end mt-2">
        <Button 
          variant="outline" 
          size="sm" 
          onClick={handleExampleClick}
          className="text-xs"
        >
          Load Example JSON
        </Button>
      </div>

      <div className="flex flex-col space-y-4 items-start mt-4">
        <Button
          onClick={handleSubmit}
          disabled={isSubmitting || !isOnline || edgeFunctionStatus === 'unavailable'}
          className="bg-[#FFD700] text-black hover:bg-[#FFD700]/80"
        >
          {isSubmitting ? "Creating Blog Post..." : "Submit Custom Content"}
        </Button>
      </div>
    </>
  );
};

export default JsonSubmitForm;
