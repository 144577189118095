
import React from "react";

interface ToastContentProps {
  postId: string;
}

export const ToastContent: React.FC<ToastContentProps> = ({ postId }) => (
  <div className="flex flex-col">
    <span>Blog post created successfully!</span>
    <a 
      href={`/blog/${postId}`} 
      className="text-blue-500 underline mt-1"
      onClick={(e) => {
        e.preventDefault();
        window.location.href = `/blog/${postId}`;
      }}
    >
      View post
    </a>
  </div>
);
