
import { Search } from "lucide-react";
import { Input } from "./ui/input";
import { useLanguage } from "@/context/LanguageContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SearchBar = () => {
  const { t } = useLanguage();
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  
  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Navigate to buy page with search query and scroll to the Matrix IDX search frame
    navigate('/buy', { 
      state: { 
        searchQuery: searchQuery.trim(),
        showSearch: true
      } 
    });
  };
  
  return (
    <form onSubmit={handleSearch} className="relative w-full max-w-3xl bg-black/80 rounded-full overflow-hidden shadow-xl border border-yellow-400/50">
      <Input
        type="text"
        placeholder="Search properties by location..."
        className="pl-16 pr-8 py-8 w-full border-none text-xl font-medium bg-transparent text-white"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <button 
        type="submit" 
        className="absolute left-4 top-1/2 -translate-y-1/2 text-yellow-400 w-6 h-6 flex items-center justify-center"
        aria-label="Search properties"
      >
        <Search className="w-6 h-6" />
      </button>
    </form>
  );
};

export default SearchBar;
