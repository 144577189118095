
import React, { useState, useEffect } from 'react';
import { Card } from '@/components/ui/card';
import EstimatorForm from './EstimatorForm';
import ResultsSection from './ResultsSection';
import ProjectTimeline from './ProjectTimeline';
import CalculatorHeader from './CalculatorHeader';

// Define the timeline phase type
export interface TimelinePhase {
  name: string;
  duration: number; // in weeks
  cost: number;
}

// Initial phases for the timeline
const initialPhases: TimelinePhase[] = [
  { name: 'Planning', duration: 1, cost: 1000 },
  { name: 'Demolition', duration: 1, cost: 2000 },
  { name: 'Construction', duration: 4, cost: 8000 },
  { name: 'Finishing', duration: 1, cost: 3000 },
  { name: 'Inspection', duration: 0.5, cost: 500 },
];

const RenovationEstimatorContainer: React.FC = () => {
  // Form state
  const [projectName, setProjectName] = useState<string>('Kitchen Remodel');
  const [squareFootage, setSquareFootage] = useState<number>(1500);
  const [roomCount, setRoomCount] = useState<number>(3);
  const [materialQuality, setMaterialQuality] = useState<string>('Budget');
  const [laborRate, setLaborRate] = useState<number>(50);
  const [duration, setDuration] = useState<number>(8);
  const [permitsRequired, setPermitsRequired] = useState<boolean>(false);
  const [additionalFees, setAdditionalFees] = useState<number>(500);
  const [designFee, setDesignFee] = useState<number>(1000);
  const [contingencyPercentage, setContingencyPercentage] = useState<number>(10);
  
  // Add manually editable cost states
  const [materialCost, setMaterialCost] = useState<number>(0);
  const [laborCost, setLaborCost] = useState<number>(0);
  const [permitsCost, setPermitsCost] = useState<number>(0);
  
  // Results state
  const [timelinePhases, setTimelinePhases] = useState<TimelinePhase[]>(initialPhases);
  
  // Calculate suggested values (these will be used as defaults)
  const suggestedMaterialCost = calculateMaterialCost(squareFootage, materialQuality);
  const suggestedLaborCost = calculateLaborCost(laborRate, duration);
  const suggestedPermitsCost = permitsRequired ? calculatePermitsCost(squareFootage) : 0;
  
  // Effect to set initial values and update when inputs change
  useEffect(() => {
    setMaterialCost(suggestedMaterialCost);
    setLaborCost(suggestedLaborCost);
    setPermitsCost(suggestedPermitsCost);
  }, [suggestedMaterialCost, suggestedLaborCost, suggestedPermitsCost]);

  const otherCosts = additionalFees + designFee;
  const subtotal = materialCost + laborCost + permitsCost + otherCosts;
  const contingency = (subtotal * contingencyPercentage) / 100;
  const totalCost = subtotal + contingency;

  // Reset form to default values
  const resetForm = () => {
    setProjectName('Kitchen Remodel');
    setSquareFootage(1500);
    setRoomCount(3);
    setMaterialQuality('Budget');
    setLaborRate(50);
    setDuration(8);
    setPermitsRequired(false);
    setAdditionalFees(500);
    setDesignFee(1000);
    setContingencyPercentage(10);
    setTimelinePhases(initialPhases);
    
    // Reset manual cost values to suggested values
    setMaterialCost(calculateMaterialCost(1500, 'Budget'));
    setLaborCost(calculateLaborCost(50, 8));
    setPermitsCost(0);
  };

  // Format currency
  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    }).format(value);
  };

  // Share functionality (could be expanded later)
  const handleShare = () => {
    alert('Share functionality to be implemented');
  };

  // Export PDF functionality (could be expanded later)
  const handleExportPDF = () => {
    alert('Export PDF functionality to be implemented');
  };

  return (
    <div className="container mx-auto px-4 max-w-4xl">
      <CalculatorHeader 
        title="Renovation Cost Estimator"
        subtitle="Quickly estimate the costs of your renovation project."
      />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <div className="md:col-span-2">
          <Card className="p-6 bg-estate-800/80 border border-estate-700">
            <EstimatorForm
              projectName={projectName}
              setProjectName={setProjectName}
              squareFootage={squareFootage}
              setSquareFootage={setSquareFootage}
              roomCount={roomCount}
              setRoomCount={setRoomCount}
              materialQuality={materialQuality}
              setMaterialQuality={setMaterialQuality}
              laborRate={laborRate}
              setLaborRate={setLaborRate}
              duration={duration}
              setDuration={setDuration}
              permitsRequired={permitsRequired}
              setPermitsRequired={setPermitsRequired}
              additionalFees={additionalFees}
              setAdditionalFees={setAdditionalFees}
              designFee={designFee}
              setDesignFee={setDesignFee}
              contingencyPercentage={contingencyPercentage}
              setContingencyPercentage={setContingencyPercentage}
              resetForm={resetForm}
              handleShare={handleShare}
              handleExportPDF={handleExportPDF}
              // Add new props
              materialCost={materialCost}
              setMaterialCost={setMaterialCost}
              suggestedMaterialCost={suggestedMaterialCost}
              laborCost={laborCost}
              setLaborCost={setLaborCost}
              suggestedLaborCost={suggestedLaborCost}
              permitsCost={permitsCost}
              setPermitsCost={setPermitsCost}
              suggestedPermitsCost={suggestedPermitsCost}
            />
          </Card>
        </div>
        <div className="md:col-span-1">
          <ResultsSection
            materialCost={materialCost}
            laborCost={laborCost}
            permitsCost={permitsCost}
            contingency={contingency}
            totalCost={totalCost}
            formatCurrency={formatCurrency}
          />
        </div>
      </div>
      
      <div className="mb-8">
        <Card className="p-6 bg-estate-800/80 border border-estate-700">
          <h2 className="text-xl font-bold text-white mb-4">Project Timeline</h2>
          <ProjectTimeline 
            phases={timelinePhases} 
            formatCurrency={formatCurrency} 
          />
        </Card>
      </div>
    </div>
  );
};

// Helper functions to calculate costs
function calculateMaterialCost(squareFootage: number, quality: string): number {
  const baseRatePerSqFt = {
    'Budget': 50,
    'Standard': 75,
    'Premium': 100,
    'Luxury': 150
  }[quality] || 50;
  
  return squareFootage * baseRatePerSqFt;
}

function calculateLaborCost(hourlyRate: number, weeks: number): number {
  // Assuming 40 hour work weeks, with 3 workers on average
  const totalHours = weeks * 40 * 3;
  return hourlyRate * totalHours;
}

function calculatePermitsCost(squareFootage: number): number {
  // Basic permit calculation based on square footage
  const baseFee = 500;
  const extraFee = Math.floor(squareFootage / 500) * 100;
  return baseFee + extraFee;
}

export default RenovationEstimatorContainer;
