
import React, { useState, useEffect } from 'react';
import { useLanguage } from '@/context/LanguageContext';
import { Button } from './ui/button';

const LanguageToggle = () => {
  const { language, setLanguage, t } = useLanguage();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [buttonText, setButtonText] = useState(t('language.toggle'));
  const [buttonWidth, setButtonWidth] = useState("80px");

  // Update button text when language changes, not during transitions
  useEffect(() => {
    if (!isTransitioning) {
      setButtonText(t('language.toggle'));
      setButtonWidth(language === 'en' ? "80px" : "80px");
    }
  }, [language, t, isTransitioning]);

  // Improved language toggle with better transition handling
  const toggleLanguage = () => {
    if (isTransitioning) return;
    
    setIsTransitioning(true);
    // Store new language but don't apply it immediately
    const newLanguage = language === 'en' ? 'es' : 'en';
    
    // Use opacity for transition instead of changing content immediately
    setTimeout(() => {
      setLanguage(newLanguage);
      
      // Longer delay to ensure complete transition
      setTimeout(() => {
        setIsTransitioning(false);
      }, 600);
    }, 100);
  };

  const buttonStyle = {
    transform: 'translateZ(0)',
    backfaceVisibility: 'hidden' as const,
    perspective: '1000px',
    willChange: 'transform',
    width: buttonWidth,
    minWidth: '80px',
    transition: 'all 0.5s cubic-bezier(0.23, 1, 0.32, 1)',
    position: 'relative' as const,
    whiteSpace: 'nowrap' as const
  };

  return (
    <Button 
      variant="ghost" 
      size="sm" 
      onClick={toggleLanguage}
      disabled={isTransitioning}
      className="hover:text-gold-500 transition duration-300 text-white"
      style={buttonStyle}
    >
      <span style={{ 
        display: 'inline-block', 
        minWidth: '60px', 
        textAlign: 'center',
        transition: 'opacity 0.3s ease',
        opacity: isTransitioning ? 0.5 : 1
      }}>
        {buttonText}
      </span>
    </Button>
  );
};

export default LanguageToggle;
