
import React from 'react';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { DollarSign, Home, Building, Layers } from 'lucide-react';

interface InputSectionProps {
  purchasePrice: number;
  setPurchasePrice: (value: number) => void;
  downPayment: number;
  setDownPayment: (value: number) => void;
  interestRate: number;
  setInterestRate: (value: number) => void;
  loanTerm: number;
  setLoanTerm: (value: number) => void;
  propertyTax: number;
  setPropertyTax: (value: number) => void;
  insurance: number;
  setInsurance: (value: number) => void;
  hoaFees: number;
  setHoaFees: (value: number) => void;
  additionalExpenses: number;
  setAdditionalExpenses: (value: number) => void;
  rentalIncomePerUnit: number;
  setRentalIncomePerUnit: (value: number) => void;
  occupiedUnits: number;
  setOccupiedUnits: (value: number) => void;
  totalUnits: number;
  setTotalUnits: (value: number) => void;
}

const InputSection: React.FC<InputSectionProps> = ({
  purchasePrice,
  setPurchasePrice,
  downPayment,
  setDownPayment,
  interestRate,
  setInterestRate,
  loanTerm,
  setLoanTerm,
  propertyTax,
  setPropertyTax,
  insurance,
  setInsurance,
  hoaFees,
  setHoaFees,
  additionalExpenses,
  setAdditionalExpenses,
  rentalIncomePerUnit,
  setRentalIncomePerUnit,
  occupiedUnits,
  setOccupiedUnits,
  totalUnits,
  setTotalUnits,
}) => {
  const handleNumberChange = (value: string, setter: (value: number) => void) => {
    const numValue = parseFloat(value);
    setter(isNaN(numValue) ? 0 : numValue);
  };

  const inputGroupClass = "mb-4";
  const inputLabelClass = "block text-sm font-medium text-gold-400 mb-1";
  const inputClass = "bg-estate-800 border border-gold-500/30 text-white placeholder-gray-500 rounded-md px-3 py-2 focus:ring-gold-500 focus:border-gold-500 w-full";
  const groupHeaderClass = "text-lg font-bold text-gold-300 mb-3 flex items-center gap-2 border-b border-gold-500/30 pb-2";
  
  return (
    <div className="space-y-6">
      {/* Property Purchase Section */}
      <div>
        <h3 className={groupHeaderClass}><Home className="w-5 h-5" /> Property Purchase</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className={inputGroupClass}>
            <Label htmlFor="purchasePrice" className={inputLabelClass}>Purchase Price ($)</Label>
            <Input
              id="purchasePrice"
              type="number"
              value={purchasePrice}
              onChange={(e) => handleNumberChange(e.target.value, setPurchasePrice)}
              className={inputClass}
            />
          </div>
          
          <div className={inputGroupClass}>
            <Label htmlFor="downPayment" className={inputLabelClass}>Down Payment ($)</Label>
            <Input
              id="downPayment"
              type="number"
              value={downPayment}
              onChange={(e) => handleNumberChange(e.target.value, setDownPayment)}
              className={inputClass}
            />
          </div>
          
          <div className={inputGroupClass}>
            <Label htmlFor="interestRate" className={inputLabelClass}>Loan Interest Rate (%)</Label>
            <Input
              id="interestRate"
              type="number"
              step="0.1"
              value={interestRate}
              onChange={(e) => handleNumberChange(e.target.value, setInterestRate)}
              className={inputClass}
            />
          </div>
          
          <div className={inputGroupClass}>
            <Label htmlFor="loanTerm" className={inputLabelClass}>Loan Term (years)</Label>
            <Input
              id="loanTerm"
              type="number"
              value={loanTerm}
              onChange={(e) => handleNumberChange(e.target.value, setLoanTerm)}
              className={inputClass}
            />
          </div>
        </div>
      </div>
      
      {/* Monthly Expenses Section */}
      <div>
        <h3 className={groupHeaderClass}><DollarSign className="w-5 h-5" /> Monthly Expenses</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className={inputGroupClass}>
            <Label htmlFor="propertyTax" className={inputLabelClass}>Property Tax ($/year)</Label>
            <Input
              id="propertyTax"
              type="number"
              value={propertyTax}
              onChange={(e) => handleNumberChange(e.target.value, setPropertyTax)}
              className={inputClass}
            />
          </div>
          
          <div className={inputGroupClass}>
            <Label htmlFor="insurance" className={inputLabelClass}>Insurance ($/year)</Label>
            <Input
              id="insurance"
              type="number"
              value={insurance}
              onChange={(e) => handleNumberChange(e.target.value, setInsurance)}
              className={inputClass}
            />
          </div>
          
          <div className={inputGroupClass}>
            <Label htmlFor="hoaFees" className={inputLabelClass}>HOA Fees ($/month)</Label>
            <Input
              id="hoaFees"
              type="number"
              value={hoaFees}
              onChange={(e) => handleNumberChange(e.target.value, setHoaFees)}
              className={inputClass}
            />
          </div>
          
          <div className={inputGroupClass}>
            <Label htmlFor="additionalExpenses" className={inputLabelClass}>Additional Expenses ($/month)</Label>
            <Input
              id="additionalExpenses"
              type="number"
              value={additionalExpenses}
              onChange={(e) => handleNumberChange(e.target.value, setAdditionalExpenses)}
              className={inputClass}
            />
          </div>
        </div>
      </div>
      
      {/* Rental Details Section */}
      <div>
        <h3 className={groupHeaderClass}><Building className="w-5 h-5" /> Rental Details</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className={inputGroupClass}>
            <Label htmlFor="rentalIncomePerUnit" className={inputLabelClass}>Rental Income Per Unit ($/month)</Label>
            <Input
              id="rentalIncomePerUnit"
              type="number"
              value={rentalIncomePerUnit}
              onChange={(e) => handleNumberChange(e.target.value, setRentalIncomePerUnit)}
              className={inputClass}
            />
          </div>
          
          <div className={inputGroupClass}>
            <Label htmlFor="occupiedUnits" className={inputLabelClass}>Occupied Units</Label>
            <Input
              id="occupiedUnits"
              type="number"
              min="0"
              max={totalUnits}
              value={occupiedUnits}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                setOccupiedUnits(isNaN(value) ? 0 : Math.min(value, totalUnits));
              }}
              className={inputClass}
            />
          </div>
          
          <div className={inputGroupClass}>
            <Label htmlFor="totalUnits" className={inputLabelClass}>Total Units</Label>
            <Input
              id="totalUnits"
              type="number"
              min="1"
              value={totalUnits}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                if (!isNaN(value) && value >= 1) {
                  setTotalUnits(value);
                  // Ensure occupied units doesn't exceed total
                  if (occupiedUnits > value) {
                    setOccupiedUnits(value);
                  }
                }
              }}
              className={inputClass}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputSection;
