
import React from "react";
import { BlogImage } from "@/types/blog";
import ImageCarousel from "@/components/common/ImageCarousel";

interface BlogPostCardImageProps {
  postImages: BlogImage[] | string[];
  mainImage?: string;
  isMcAllenPost?: boolean;
  isHovered?: boolean;
  title: string;
}

const BlogPostCardImage: React.FC<BlogPostCardImageProps> = ({
  postImages,
  mainImage,
  isMcAllenPost = false,
  isHovered = false,
  title
}) => {
  // Combine mainImage with postImages if provided and not already in postImages
  const allImages = React.useMemo(() => {
    // Debug what we're receiving
    console.log("BlogPostCardImage processing images:", {
      postImagesCount: postImages.length,
      mainImage: mainImage?.substring(0, 30) + "..." || "none",
      postImagesType: postImages.length > 0 ? typeof postImages[0] : "empty",
      title
    });

    // First, check if we're dealing with an array of strings or BlogImage objects
    if (postImages.length > 0 && typeof postImages[0] === 'string') {
      // We're working with string[]
      const stringImages = [...postImages] as string[];
      
      // Add mainImage if it's not already included
      if (mainImage && !stringImages.includes(mainImage)) {
        return [mainImage, ...stringImages] as string[];
      }
      
      return stringImages;
    } else {
      // We're working with BlogImage[]
      const blogImages = [...postImages] as BlogImage[];
      
      // Add mainImage if it's not already included
      if (mainImage && !blogImages.some(img => img.url === mainImage)) {
        return [{ url: mainImage, caption: title, sequence: 0 }, ...blogImages] as BlogImage[];
      }
      
      return blogImages;
    }
  }, [mainImage, postImages, title]);

  // Log what we're rendering
  React.useEffect(() => {
    console.log("BlogPostCardImage rendering:", {
      title,
      imagesCount: allImages.length,
      firstImage: allImages.length > 0 ? 
        (typeof allImages[0] === 'string' ? 
          allImages[0].substring(0, 30) + "..." : 
          allImages[0].url.substring(0, 30) + "..."
        ) : "none"
    });
  }, [allImages, title]);

  return (
    <div className="relative h-[200px] overflow-hidden">
      <ImageCarousel
        images={allImages}
        height="h-full"
        showControls={allImages.length > 1}
        showCaption={false}
        showCounter={allImages.length > 1}
        alt={title}
        className="transition-transform duration-700"
        autoScroll={allImages.length > 1}
        autoScrollInterval={7000} // Slightly longer interval for card images
        counterPosition="bottom-left" // Position counter at bottom-left instead of top-right
      />
      
      {isMcAllenPost && (
        <div className="absolute top-0 left-0 bg-amber-500 text-white px-2 py-1 text-xs font-semibold z-10">
          McAllen
        </div>
      )}
      
      {/* Overlay effect */}
      <div 
        className="absolute inset-0 bg-gradient-to-b from-transparent to-black/60 transition-opacity duration-500 z-10"
        style={{ opacity: isHovered ? 0.4 : 0.6 }}
      ></div>
    </div>
  );
};

export default BlogPostCardImage;
