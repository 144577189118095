
import React from 'react';
import { MapPin, Clock, Award } from 'lucide-react';
import { useLanguage } from '@/context/LanguageContext';

const WhyChooseUs = () => {
  const { t } = useLanguage();
  
  const benefits = [
    {
      icon: MapPin,
      title: "Local Expertise",
      description: "With years of experience in McAllen, Juan Elizondo knows the local market inside and out, ensuring accurate valuations."
    },
    {
      icon: Award,
      title: "RE/MAX Backing",
      description: "Backed by RE/MAX Elite, you get the support of a global real estate leader with cutting-edge tools and resources."
    },
    {
      icon: Clock,
      title: "Fast and Free",
      description: "Get a detailed home value estimate in under 2 minutes, completely free, with no obligation."
    }
  ];

  return (
    <section className="bg-gray-50 py-16">
      <div className="container mx-auto px-4 max-w-6xl">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
          Why Choose Juan Elizondo for Your Home Valuation?
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {benefits.map((benefit, index) => (
            <div key={index} className="flex flex-col items-center text-center">
              <div className="mb-4 bg-gold-100 p-4 rounded-full">
                <benefit.icon size={32} className="text-gold-500" />
              </div>
              <h3 className="text-xl font-bold mb-2">{benefit.title}</h3>
              <p className="text-gray-700">{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
