
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";

interface BlogPostNotFoundProps {
  onBack?: () => void;
}

const BlogPostNotFound: React.FC<BlogPostNotFoundProps> = ({ onBack }) => {
  const navigate = useNavigate();
  
  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate('/blog');
    }
  };
  
  return (
    <div className="container mx-auto px-4 py-16 text-center">
      <h2 className="text-2xl font-bold mb-4">Blog Post Not Found</h2>
      <p className="mb-8 text-gray-600 dark:text-gray-300">
        The blog post you're looking for doesn't exist or has been removed.
      </p>
      <Button 
        onClick={handleBack}
        className="bg-[#ffb347] hover:bg-[#f5a030]"
      >
        Back to Blog
      </Button>
    </div>
  );
};

export default BlogPostNotFound;
