
import { useState, useEffect } from "react";
import { useInterestRates, type InterestRateData } from "./interestRateUtils";
import InterestRateDisplay from "./InterestRateDisplay";
import MortgageForm from "./MortgageForm";
import PaymentSummary from "./PaymentSummary";

interface MortgageCalculatorProps {
  initialLoanAmount?: number;
}

const MortgageCalculator = ({ initialLoanAmount }: MortgageCalculatorProps = {}) => {
  const [loanAmount, setLoanAmount] = useState(initialLoanAmount || 300000);
  const [interestRate, setInterestRate] = useState(4.5);
  const [loanTerm, setLoanTerm] = useState(30);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const { rates, loading, error } = useInterestRates();
  const [selectedRate, setSelectedRate] = useState<InterestRateData | null>(null);

  // Apply initial loan amount when it changes
  useEffect(() => {
    if (initialLoanAmount) {
      setLoanAmount(initialLoanAmount);
    }
  }, [initialLoanAmount]);

  // Calculate monthly payment when inputs change
  useEffect(() => {
    const calculateMonthlyPayment = () => {
      // Convert annual rate to monthly rate and convert percentage to decimal
      const monthlyRate = interestRate / 100 / 12;
      // Convert years to months
      const termMonths = loanTerm * 12;
      
      if (monthlyRate === 0) {
        // Simple division for 0% interest
        return loanAmount / termMonths;
      }
      
      // Monthly payment formula: P × r(1 + r)^n / ((1 + r)^n - 1)
      const payment = loanAmount * 
        (monthlyRate * Math.pow(1 + monthlyRate, termMonths)) / 
        (Math.pow(1 + monthlyRate, termMonths) - 1);
      
      return payment;
    };

    const payment = calculateMonthlyPayment();
    setMonthlyPayment(isNaN(payment) || !isFinite(payment) ? 0 : payment);
  }, [loanAmount, interestRate, loanTerm]);

  // Apply selected rate
  const applySelectedRate = (rateInfo: InterestRateData) => {
    setInterestRate(rateInfo.rate);
    setLoanTerm(rateInfo.term);
    setSelectedRate(rateInfo);
  };

  return (
    <div className="max-w-4xl mx-auto py-12 px-4">
      <h1 className="text-4xl font-display text-center mb-8">Mortgage Calculator</h1>
      
      <p className="text-lg text-center mb-10 text-gray-600">
        Estimate your monthly mortgage payment by adjusting the values below.
      </p>
      
      {/* Current Rates Section */}
      <InterestRateDisplay 
        rates={rates} 
        loading={loading} 
        onRateSelected={applySelectedRate} 
        selectedRate={selectedRate}
      />
      
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Mortgage Details Form */}
        <MortgageForm 
          loanAmount={loanAmount}
          interestRate={interestRate}
          loanTerm={loanTerm}
          onLoanAmountChange={setLoanAmount}
          onInterestRateChange={setInterestRate}
          onLoanTermChange={setLoanTerm}
        />

        {/* Payment Summary */}
        <PaymentSummary 
          monthlyPayment={monthlyPayment}
          loanAmount={loanAmount}
          interestRate={interestRate}
          loanTerm={loanTerm}
        />
      </div>
    </div>
  );
};

export default MortgageCalculator;
