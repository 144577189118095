
import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ArrowRight, Calculator } from "lucide-react";
import { AspectRatio } from "@/components/ui/aspect-ratio";

interface PropertyCardProps {
  image: string;
  title: string;
  location: string;
  price: string;
}

const PropertyCard = ({ image, title, location, price }: PropertyCardProps) => {
  const navigate = useNavigate();
  
  const handleCalculatorClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate('/calculator', { 
      state: { 
        property: { image, title, location, price },
        price,
        from: window.location.pathname
      } 
    });
  };
  
  return (
    <Card className="overflow-hidden cursor-pointer hover:shadow-lg transition-all duration-300 group h-full">
      <CardContent className="p-0 h-full flex flex-col">
        <div className="relative overflow-hidden">
          <AspectRatio ratio={4/3}>
            <img 
              src={image} 
              alt={title} 
              className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-500"
            />
          </AspectRatio>
        </div>
        
        <div className="p-4 flex flex-col flex-grow">
          <h3 className="text-lg font-bold mb-1">{title}</h3>
          <p className="text-gray-500 mb-2">{location}</p>
          <p className="text-gold-500 font-bold text-xl mb-4">{price}</p>
          
          <div className="mt-auto flex gap-2">
            <Button className="flex-1 bg-gold-500 hover:bg-gold-600">
              View <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
            <Button 
              variant="outline" 
              className="px-3 border-gold-500 text-gold-500 hover:bg-gold-50"
              onClick={handleCalculatorClick}
              title="Calculate mortgage"
            >
              <Calculator className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default PropertyCard;
