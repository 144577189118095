
import React from 'react';
import { Button } from '@/components/ui/button';
import { RefreshCw, FileText } from 'lucide-react';

interface CalculatorButtonsProps {
  onReset: () => void;
  onExportPDF: () => void;
}

const CalculatorButtons: React.FC<CalculatorButtonsProps> = ({
  onReset,
  onExportPDF
}) => {
  return (
    <div className="flex flex-col sm:flex-row gap-3 mt-8">
      <Button 
        variant="outline" 
        onClick={onReset} 
        className="flex-1 bg-estate-800/50 hover:bg-estate-700 border-estate-600 text-gold-300 hover:text-gold-200 transition-all duration-200 flex items-center justify-center gap-2"
      >
        <RefreshCw className="w-4 h-4" />
        Reset
      </Button>
      
      <Button 
        variant="outline" 
        onClick={onExportPDF} 
        className="flex-1 bg-green-800/50 hover:bg-green-700 border-green-600 text-green-300 hover:text-green-200 transition-all duration-200 shadow-sm shadow-green-500/20 flex items-center justify-center gap-2"
      >
        <FileText className="w-4 h-4" />
        Generate PDF
      </Button>
    </div>
  );
};

export default CalculatorButtons;
