
/**
 * Utility functions to manipulate images in blog content
 */

import { findTextNodes } from './textUtils';

/**
 * Process images in the blog content
 * @param contentElement - The DOM element containing the blog content
 */
export const processImages = (contentElement: HTMLDivElement): void => {
  // Check if this is the Harlingen investment post
  const isHarlingenPost = contentElement.textContent?.includes("Investment properties in Harlingen");
  
  if (isHarlingenPost) {
    // Find and remove any existing hero section to eliminate the gap
    const existingHero = contentElement.querySelector('.hero');
    if (existingHero) {
      existingHero.parentNode?.removeChild(existingHero);
    }
    
    // Find and remove any standalone "Investment properties in Harlingen" header 
    // outside the main content to prevent duplication
    const contentSection = contentElement.querySelector('.content');
    const investmentTextNodes = findTextNodes(contentElement, "Investment properties in Harlingen");
    
    investmentTextNodes.forEach(node => {
      const parent = node.parentElement;
      // Only remove if it's not in the main content area
      if (parent && !parent.closest('.content') && !parent.classList.contains('main-title')) {
        const isHeading = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(parent.tagName);
        if (isHeading) {
          parent.parentNode?.removeChild(parent);
        }
      }
    });
  }
  
  // Process missing images with placeholders
  const emptyImages = contentElement.querySelectorAll('img[src=""]');
  
  // Replace empty images with a placeholder or hide them
  emptyImages.forEach(img => {
    // Set a placeholder image
    img.setAttribute('src', 'https://images.unsplash.com/photo-1499750310107-5fef28a66643?auto=format&fit=crop&w=800&q=80');
    
    // Ensure it's visible
    (img as HTMLElement).style.display = 'block';
    (img as HTMLElement).style.width = '100%';
    (img as HTMLElement).style.height = 'auto';
    (img as HTMLElement).style.objectFit = 'cover';
    
    const figure = img.closest('figure');
    if (figure) {
      (figure as HTMLElement).style.display = 'block';
      (figure as HTMLElement).style.margin = '0';
    }
  });

  // Remove duplicate hero sections within the main content
  const contentSection = contentElement.querySelector('.content');
  if (contentSection) {
    const heroSectionsInContent = contentSection.querySelectorAll('.hero, .hero-image, .hero-text');
    heroSectionsInContent.forEach(section => {
      section.parentNode?.removeChild(section);
    });
  }

  // Only remove investment-related images that are NOT in the main content
  const investmentTextNodes = findTextNodes(contentElement, "Investment properties in Harlingen");
  investmentTextNodes.forEach(node => {
    const parent = node.parentElement;
    if (parent && !parent.closest('.content') && !parent.classList.contains('main-title')) {
      // Look for any nearby images
      const nearbyImage = parent.querySelector('img');
      if (nearbyImage) {
        nearbyImage.parentNode?.removeChild(nearbyImage);
      }
    }
  });
  
  // Hide all figcaptions to remove image prompts
  const figcaptions = contentElement.querySelectorAll('figcaption');
  figcaptions.forEach(figcaption => {
    (figcaption as HTMLElement).style.display = 'none';
  });
};
