
/**
 * Utilities for extracting images from HTML content
 */

import { BlogImage } from "@/types/blog";

/**
 * Extract image URLs from HTML content
 * @param htmlContent - The HTML content from which to extract images
 * @returns Array of extracted image URLs
 */
export const extractImagesFromHtmlContent = (htmlContent: string): string[] => {
  if (!htmlContent) return [];
  
  try {
    // First, look for <img> tags
    const imgRegex = /<img[^>]+src=["']([^"'>]+)["']/g;
    let match;
    const imageUrls: string[] = [];
    
    // Find all image tags
    while ((match = imgRegex.exec(htmlContent)) !== null) {
      if (match[1] && !match[1].includes('data:image')) {
        imageUrls.push(match[1].trim());
      }
    }
    
    // Next, look for background-image CSS properties
    const bgImgRegex = /background-image\s*:\s*url\(['"]?([^'")\s]+)['"]?\)/g;
    while ((match = bgImgRegex.exec(htmlContent)) !== null) {
      if (match[1] && !match[1].includes('data:image')) {
        imageUrls.push(match[1].trim());
      }
    }
    
    // Look for IMAGE_PROMPT tags to find potential image references
    const promptRegex = /\[IMAGE_PROMPT(?:_START)?\]([\s\S]*?)\[IMAGE_PROMPT(?:_END)?\]/g;
    const promptMatches = htmlContent.matchAll(promptRegex);
    
    for (const promptMatch of promptMatches) {
      // Extract the prompt text
      const promptContent = promptMatch[1];
      console.log("Found image prompt:", promptContent.substring(0, 50) + "...");
    }
    
    // Log extracted images for debugging
    if (imageUrls.length > 0) {
      console.log(`Extracted ${imageUrls.length} images from HTML content`);
    } else {
      console.log('No images found in HTML content');
    }
    
    return [...new Set(imageUrls)]; // Remove duplicates
  } catch (error) {
    console.error('Error extracting images from HTML content:', error);
    return [];
  }
};

/**
 * Process and merge images from HTML content into blog post data
 * @param blogPost - The blog post data
 * @param extractedImages - Array of extracted image URLs
 * @returns Updated blog post with merged images
 */
export const processAndMergeImages = (blogPost: any, extractedImages: string[]): any => {
  const updatedPost = { ...blogPost };
  
  try {
    // Add extracted images to the blog post
    if (extractedImages.length > 0) {
      // Create image objects from URLs
      const imageObjects = extractedImages.map((url, index) => ({
        url,
        caption: `Image ${index + 1}`,
        sequence: blogPost.images?.length + index
      }));
      
      // Initialize images array if it doesn't exist
      if (!updatedPost.images) {
        updatedPost.images = [];
      }
      
      // Check if images is a string, try to parse it
      if (typeof updatedPost.images === 'string') {
        try {
          updatedPost.images = JSON.parse(updatedPost.images);
        } catch (e) {
          // If parsing fails, set as empty array
          updatedPost.images = [];
        }
      }
      
      // Ensure images is an array
      if (!Array.isArray(updatedPost.images)) {
        // If it's a single object, make it an array
        if (updatedPost.images && typeof updatedPost.images === 'object') {
          updatedPost.images = [updatedPost.images];
        } else {
          updatedPost.images = [];
        }
      }
      
      // Add new images, avoiding duplicates
      for (const image of imageObjects) {
        if (!updatedPost.images.some((img: any) => img.url === image.url)) {
          updatedPost.images.push(image);
        }
      }
      
      // If no main image (hero image) is set, use the first extracted image
      if (!updatedPost.image && imageObjects.length > 0) {
        updatedPost.image = imageObjects[0].url;
      }
    }
    
    return updatedPost;
  } catch (error) {
    console.error('Error processing and merging images:', error);
    return blogPost; // Return original if processing fails
  }
};
