
import React from "react";
import ThemeToggle from "../ThemeToggle";
import LanguageToggle from "../LanguageToggle";

const NavControls: React.FC = () => {
  return (
    <div className="flex items-center gap-2" style={{ transform: 'translateZ(0)' }}>
      <ThemeToggle />
      <LanguageToggle />
    </div>
  );
};

export default NavControls;
