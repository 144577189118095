
import React from 'react';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';

interface ProjectBasicsTabProps {
  projectName: string;
  setProjectName: (value: string) => void;
  squareFootage: number;
  setSquareFootage: (value: number) => void;
  roomCount: number;
  setRoomCount: (value: number) => void;
  materialQuality: string;
  setMaterialQuality: (value: string) => void;
  laborRate: number;
  setLaborRate: (value: number) => void;
  duration: number;
  setDuration: (value: number) => void;
  permitsRequired: boolean;
  setPermitsRequired: (value: boolean) => void;
  additionalFees: number;
  setAdditionalFees: (value: number) => void;
  designFee: number;
  setDesignFee: (value: number) => void;
  contingencyPercentage: number;
  setContingencyPercentage: (value: number) => void;
}

const ProjectBasicsTab: React.FC<ProjectBasicsTabProps> = (props) => {
  // Handle numeric input changes
  const handleNumericChange = (setter: (value: number) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value !== '' ? parseFloat(e.target.value) : 0;
    if (!isNaN(value)) {
      setter(value);
    }
  };

  return (
    <div className="space-y-4">
      <div>
        <Label htmlFor="projectName" className="text-gold-300 font-medium">Project Name</Label>
        <Input
          id="projectName"
          value={props.projectName}
          onChange={(e) => props.setProjectName(e.target.value)}
          className="mt-1 bg-estate-800/90 border-gold-700/50 text-gold-200 focus:border-gold-500 focus:ring-2 focus:ring-gold-500/30 placeholder-gold-600/50"
          placeholder="Enter project name"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <Label htmlFor="squareFootage" className="text-gold-300 font-medium">Total Square Footage</Label>
          <Input
            id="squareFootage"
            type="number"
            value={props.squareFootage}
            onChange={handleNumericChange(props.setSquareFootage)}
            className="mt-1 bg-estate-800/90 border-gold-700/50 text-gold-200 focus:border-gold-500 focus:ring-2 focus:ring-gold-500/30 placeholder-gold-600/50"
          />
        </div>
        
        <div>
          <Label htmlFor="roomCount" className="text-gold-300 font-medium">Number of Rooms</Label>
          <Input
            id="roomCount"
            type="number"
            value={props.roomCount}
            onChange={handleNumericChange(props.setRoomCount)}
            className="mt-1 bg-estate-800/90 border-gold-700/50 text-gold-200 focus:border-gold-500 focus:ring-2 focus:ring-gold-500/30 placeholder-gold-600/50"
          />
        </div>
      </div>

      <div>
        <Label htmlFor="materialQuality" className="text-gold-300 font-medium">Material Quality</Label>
        <Select 
          value={props.materialQuality} 
          onValueChange={props.setMaterialQuality}
        >
          <SelectTrigger className="mt-1 bg-estate-800/90 border-gold-700/50 text-gold-200 focus:border-gold-500 focus:ring-2 focus:ring-gold-500/30">
            <SelectValue placeholder="Select quality" />
          </SelectTrigger>
          <SelectContent className="bg-estate-800 border-gold-700/50 text-gold-200">
            <SelectItem value="Budget" className="hover:bg-estate-700 focus:bg-estate-700">Budget</SelectItem>
            <SelectItem value="Standard" className="hover:bg-estate-700 focus:bg-estate-700">Standard</SelectItem>
            <SelectItem value="Premium" className="hover:bg-estate-700 focus:bg-estate-700">Premium</SelectItem>
            <SelectItem value="Luxury" className="hover:bg-estate-700 focus:bg-estate-700">Luxury</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <Label htmlFor="laborRate" className="text-gold-300 font-medium">Labor Rate ($/Hour)</Label>
          <Input
            id="laborRate"
            type="number"
            value={props.laborRate}
            onChange={handleNumericChange(props.setLaborRate)}
            className="mt-1 bg-estate-800/90 border-gold-700/50 text-gold-200 focus:border-gold-500 focus:ring-2 focus:ring-gold-500/30 placeholder-gold-600/50"
          />
        </div>
        
        <div>
          <Label htmlFor="duration" className="text-gold-300 font-medium">Estimated Duration (Weeks)</Label>
          <Input
            id="duration"
            type="number"
            value={props.duration}
            onChange={handleNumericChange(props.setDuration)}
            className="mt-1 bg-estate-800/90 border-gold-700/50 text-gold-200 focus:border-gold-500 focus:ring-2 focus:ring-gold-500/30 placeholder-gold-600/50"
          />
        </div>
      </div>

      <div className="flex items-center space-x-2 p-3 border border-gold-600/30 rounded-md bg-estate-800/70">
        <Switch
          id="permitsRequired"
          checked={props.permitsRequired}
          onCheckedChange={props.setPermitsRequired}
          className="data-[state=checked]:bg-blue-600 data-[state=unchecked]:bg-estate-600"
        />
        <Label htmlFor="permitsRequired" className="cursor-pointer text-gold-300 font-medium">Permits Required?</Label>
        <span className="text-sm text-gold-400 ml-auto font-bold">
          {props.permitsRequired ? "Yes" : "No"}
        </span>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <Label htmlFor="additionalFees" className="text-gold-300 font-medium">Additional Fees ($)</Label>
          <Input
            id="additionalFees"
            type="number"
            value={props.additionalFees}
            onChange={handleNumericChange(props.setAdditionalFees)}
            className="mt-1 bg-estate-800/90 border-gold-700/50 text-gold-200 focus:border-gold-500 focus:ring-2 focus:ring-gold-500/30 placeholder-gold-600/50"
          />
        </div>
        
        <div>
          <Label htmlFor="designFee" className="text-gold-300 font-medium">Design Fee ($)</Label>
          <Input
            id="designFee"
            type="number"
            value={props.designFee}
            onChange={handleNumericChange(props.setDesignFee)}
            className="mt-1 bg-estate-800/90 border-gold-700/50 text-gold-200 focus:border-gold-500 focus:ring-2 focus:ring-gold-500/30 placeholder-gold-600/50"
          />
        </div>
      </div>

      <div>
        <Label htmlFor="contingencyPercentage" className="text-gold-300 font-medium">Contingency (%)</Label>
        <Input
          id="contingencyPercentage"
          type="number"
          value={props.contingencyPercentage}
          onChange={handleNumericChange(props.setContingencyPercentage)}
          className="mt-1 bg-estate-800/90 border-gold-700/50 text-gold-200 focus:border-gold-500 focus:ring-2 focus:ring-gold-500/30 placeholder-gold-600/50"
        />
      </div>
    </div>
  );
};

export default ProjectBasicsTab;
