
import React from 'react';
import { ResultsSectionProps } from './types';
import { Card } from '@/components/ui/card';
import { TrendingUp, TrendingDown, Home, Coins } from 'lucide-react';

const ResultsSection: React.FC<ResultsSectionProps> = ({ 
  totalBuyingCost, 
  totalRentingCost, 
  netDifference, 
  homeEquity,
  investmentEquity,
  formatCurrency,
  isBuyingBetter
}) => {
  return (
    <div className="mb-8">
      <h2 className="text-2xl font-bold text-gold-500 mb-4">Results Summary</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <Card className="p-4 bg-estate-800/60 border border-estate-700">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-semibold text-white">Cost of buying</h3>
            <Home size={20} className="text-green-500" />
          </div>
          <p className="text-xl font-bold text-green-500">{formatCurrency(totalBuyingCost)}</p>
        </Card>
        
        <Card className="p-4 bg-estate-800/60 border border-estate-700">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-semibold text-white">Cost of Renting</h3>
            <Coins size={20} className="text-blue-500" />
          </div>
          <p className="text-xl font-bold text-blue-500">{formatCurrency(totalRentingCost)}</p>
        </Card>
        
        <Card className="p-4 bg-estate-800/60 border border-estate-700">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-semibold text-white">Net Difference</h3>
            {isBuyingBetter ? (
              <TrendingDown size={20} className="text-green-500" />
            ) : (
              <TrendingUp size={20} className="text-red-500" />
            )}
          </div>
          <p className={`text-xl font-bold ${isBuyingBetter ? 'text-green-500' : 'text-red-500'}`}>
            {formatCurrency(Math.abs(netDifference))}
          </p>
        </Card>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Card className="p-4 bg-estate-800/60 border border-estate-700">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-semibold text-white">Home Equity</h3>
            <Home size={20} className="text-green-500" />
          </div>
          <p className="text-xl font-bold text-green-500">{formatCurrency(homeEquity)}</p>
        </Card>
        
        <Card className="p-4 bg-estate-800/60 border border-estate-700">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-semibold text-white">Investment Equity</h3>
            <TrendingUp size={20} className="text-purple-500" />
          </div>
          <p className="text-xl font-bold text-purple-500">{formatCurrency(investmentEquity)}</p>
        </Card>
      </div>
    </div>
  );
};

export default ResultsSection;
