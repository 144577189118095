
import React from 'react';
import { Home, HelpCircle, CheckCircle } from 'lucide-react';
import { useLanguage } from '@/context/LanguageContext';

const HowItWorks = () => {
  const { t } = useLanguage();
  
  const steps = [
    {
      icon: Home,
      title: "Enter Your Address",
      description: "Start by entering your home's address in McAllen, Texas, or the surrounding areas to get a personalized estimate."
    },
    {
      icon: HelpCircle,
      title: "Share a Few Details",
      description: "Answer a few quick questions about your home's condition and features to ensure the most accurate valuation."
    },
    {
      icon: CheckCircle,
      title: "Get Your Free Estimate",
      description: "Receive a detailed home value estimate in under 2 minutes, powered by local market data and RE/MAX expertise."
    }
  ];

  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-4 max-w-6xl">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
          How Does Juan Elizondo's Home Value Estimator Work?
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {steps.map((step, index) => (
            <div key={index} className="flex flex-col items-center text-center">
              <div className="mb-4 bg-gold-100 p-4 rounded-full">
                <step.icon size={32} className="text-gold-500" />
              </div>
              <h3 className="text-xl font-bold mb-2">{step.title}</h3>
              <p className="text-gray-700">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
