
import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { useLanguage } from "@/context/LanguageContext";
import { useState, useEffect } from "react";
import NavLink from "./NavLink";
import CommunitiesDropdown from "./CommunitiesDropdown";
import CalculatorsDropdown from "./CalculatorsDropdown";
import NavControls from "./NavControls";

interface DesktopNavLinksProps {
  communitiesOpen?: boolean;
  toggleCommunities?: () => void;
  setCommunitiesOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  blogCategoriesOpen?: boolean;
  toggleBlogCategories?: () => void;
  setBlogCategoriesOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  scrollToAbout?: (e: React.MouseEvent) => void;
  handleCitySelect?: (cityName: string) => void;
  handleCategorySelect?: (category: string) => void;
  dropdownRef?: React.RefObject<HTMLDivElement>;
  blogDropdownRef?: React.RefObject<HTMLDivElement>;
}

const DesktopNavLinks: React.FC<DesktopNavLinksProps> = ({
  communitiesOpen,
  toggleCommunities,
  setCommunitiesOpen,
  handleCitySelect,
  dropdownRef,
}) => {
  const location = useLocation();
  const { t } = useLanguage();

  const isActive = (path: string) => {
    return location.pathname === path ? "text-gold-500" : "";
  };

  const handleCloseCommunities = () => {
    if (setCommunitiesOpen) {
      setCommunitiesOpen(false);
    }
  };

  const linkStyle = {
    transform: 'translateZ(0)',
    backfaceVisibility: 'hidden' as const,
    perspective: '1000px',
    willChange: 'transform',
    padding: '0.5rem 0.75rem',
    fontSize: '1rem',
  };

  return (
    <div 
      className="hidden lg:flex items-center gap-4" 
      style={{
        display: 'flex !important', 
        visibility: 'visible' as const,
        transform: 'translateZ(0)',
        backfaceVisibility: 'hidden',
        perspective: '1000px',
        willChange: 'transform',
        justifyContent: 'flex-start',
        paddingLeft: '6rem'
      }}
    >
      {/* Main navigation links */}
      <NavLink to="/" text={t('nav.home')} isActive={isActive} style={linkStyle} />
      <NavLink to="/buy" text={t('nav.buy')} isActive={isActive} style={linkStyle} />
      <NavLink to="/sell" text={t('nav.sell')} isActive={isActive} style={linkStyle} />
      <NavLink to="/home-estimate" text={t('nav.home-estimate') || 'Home Estimates'} isActive={isActive} style={linkStyle} />
      
      {/* Calculators dropdown */}
      <CalculatorsDropdown linkStyle={linkStyle} />
      
      {/* Communities dropdown */}
      <CommunitiesDropdown 
        linkStyle={linkStyle}
        isActive={isActive}
        communitiesOpen={!!communitiesOpen}
        toggleCommunities={toggleCommunities || (() => {})}
        handleCloseCommunities={handleCloseCommunities}
        handleCitySelect={handleCitySelect || (() => {})}
        dropdownRef={dropdownRef || React.createRef()}
      />
      
      {/* Additional navigation links */}
      <NavLink to="/commercial" text={t('nav.commercial')} isActive={isActive} style={linkStyle} />
      <NavLink to="/residential" text={t('nav.residential')} isActive={isActive} style={linkStyle} />
      <NavLink to="/mexican-clients" text={t('nav.mexican-investors') || 'Paisanos'} isActive={isActive} style={linkStyle} />
      <NavLink to="/blog" text={t('nav.blog') || 'Blog'} isActive={isActive} style={linkStyle} />
      <NavLink to="/contact" text={t('nav.contact')} isActive={isActive} style={linkStyle} />
      
      {/* Theme and language toggles */}
      <NavControls />
    </div>
  );
};

export default DesktopNavLinks;
