
import { useEffect } from "react";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import ResidentialHero from "@/components/residential/ResidentialHero";
import ResidentialGrid from "@/components/residential/ResidentialGrid";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { useLanguage } from "@/context/LanguageContext";

const Residential = () => {
  const { toast } = useToast();
  const { t } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-black text-white">
      <Navbar />
      
      <ResidentialHero />
      
      <section className="py-16 px-4 max-w-6xl mx-auto wp-block-lovable-residential-intro">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <p className="text-lg leading-relaxed text-white/90">
            {t('residential.intro')}
          </p>
        </div>
        
        <h2 className="text-3xl md:text-4xl font-display text-center mb-12 text-white">{t('residential.featured')}</h2>
        
        <ResidentialGrid />
        
        <div className="text-center mt-12">
          <Button 
            className="bg-[#ffb347] hover:bg-[#e69a30] text-white font-bold py-3 px-8 rounded-md transition duration-300"
            onClick={() => {
              toast({
                title: t('toast.coming.soon'),
                description: t('toast.coming.soon.desc'),
              });
            }}
          >
            {t('residential.view.all')}
          </Button>
        </div>
      </section>
      
      <Footer />
    </div>
  );
};

export default Residential;
