
import React from 'react';
import { Input } from '@/components/ui/input';
import { AcquisitionSectionProps } from './types';

const AcquisitionSection: React.FC<AcquisitionSectionProps> = ({
  purchasePrice,
  setPurchasePrice,
  closingCostPercentBuy,
  setClosingCostPercentBuy,
  closingCostBuy,
  renovationCost,
  setRenovationCost,
  pointsPercent,
  setPointsPercent,
  pointsCost,
  totalAcquisition,
  handleCurrencyInputChange,
  handlePercentInputChange,
  formatCurrency
}) => {
  return (
    <div className="transition-transform hover:transform hover:scale-[1.02] hover:z-10">
      <h2 className="text-2xl font-bold text-gold-500 mb-4">Acquisition</h2>
      
      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Purchase</label>
        <div className="flex items-center">
          <span className="text-gold-500 mr-2">$</span>
          <Input
            type="text"
            value={purchasePrice.toLocaleString()}
            onChange={(e) => handleCurrencyInputChange(e.target.value, setPurchasePrice)}
            className="flex-1 text-white bg-estate-800 border-estate-700" 
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Closing costs</label>
        <div className="flex gap-2">
          <div className="w-1/3 flex items-center">
            <Input
              type="text"
              value={closingCostPercentBuy}
              onChange={(e) => handlePercentInputChange(e.target.value, setClosingCostPercentBuy)}
              className="text-white bg-estate-800 border-estate-700"
              step="0.01"
            />
            <span className="ml-2 text-gold-500">%</span>
          </div>
          <div className="w-2/3 flex items-center">
            <span className="text-gold-500 mr-2">$</span>
            <Input 
              type="text" 
              value={closingCostBuy.toLocaleString()} 
              readOnly 
              className="bg-estate-900 text-white border-estate-700"
            />
          </div>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Renovation</label>
        <div className="flex items-center">
          <span className="text-gold-500 mr-2">$</span>
          <Input
            type="text"
            value={renovationCost.toLocaleString()}
            onChange={(e) => handleCurrencyInputChange(e.target.value, setRenovationCost)}
            className="flex-1 text-white bg-estate-800 border-estate-700"
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Points (Enter 0% if Cash)</label>
        <div className="flex gap-2">
          <div className="w-1/3 flex items-center">
            <Input
              type="text"
              value={pointsPercent}
              onChange={(e) => handlePercentInputChange(e.target.value, setPointsPercent)}
              className="text-white bg-estate-800 border-estate-700"
              step="0.01"
            />
            <span className="ml-2 text-gold-500">%</span>
          </div>
          <div className="w-2/3 flex items-center">
            <span className="text-gold-500 mr-2">$</span>
            <Input 
              type="text" 
              value={pointsCost.toLocaleString()} 
              readOnly 
              className="bg-estate-900 text-white border-estate-700"
            />
          </div>
        </div>
      </div>

      <div className="mt-6">
        <label className="block text-gray-300 mb-1">Total Acquisition</label>
        <div className="text-2xl font-bold text-gold-500">
          {formatCurrency(totalAcquisition)}
        </div>
      </div>
    </div>
  );
};

export default AcquisitionSection;
