
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Briefcase, Building, Users, BadgeCheck, MapPin } from "lucide-react";
import TabBackground from "./TabBackground";
import TabExperience from "./TabExperience";
import TabServices from "./TabServices";
import TabLocation from "./TabLocation";
import TabTestimonials from "./TabTestimonials";
import { useState, useEffect, CSSProperties } from "react";

const TabsContainer = () => {
  const [hoveredTab, setHoveredTab] = useState<string | null>(null);

  // Add common tab styles with improved stability
  const tabStyle = (isHovered: boolean): CSSProperties => ({
    boxShadow: isHovered 
      ? '0 0 15px 8px rgba(59, 130, 246, 0.6), 0 0 30px rgba(59, 130, 246, 0.4)' 
      : '0 0 5px 2px rgba(59, 130, 246, 0.15)',
    transition: 'box-shadow 0.3s ease-in-out, width 0.5s cubic-bezier(0.23, 1, 0.32, 1)',
    color: '#FFFFFF',
    transform: 'translateZ(0)',
    backfaceVisibility: 'hidden' as 'hidden',
    perspective: '1000px',
    willChange: 'transform',
    minWidth: '150px', // Increased minimum width for stability
  });

  // Stable wrapper style to prevent shifts
  const tabContainerStyle: CSSProperties = {
    transform: 'translateZ(0)',
    backfaceVisibility: 'hidden' as 'hidden',
    perspective: '1000px',
    willChange: 'transform',
    position: 'relative',
  };

  return (
    <Tabs defaultValue="background" className="w-full">
      <TabsList 
        className="w-full flex flex-wrap justify-center gap-6 bg-transparent p-0 mb-10"
        style={tabContainerStyle}
      >
        <TabsTrigger 
          value="background" 
          className="flex-grow basis-40 flex items-center justify-center bg-gray-900 p-6 rounded-lg border border-gray-800 text-white text-xl"
          style={tabStyle(hoveredTab === "background")}
          onMouseEnter={() => setHoveredTab("background")}
          onMouseLeave={() => setHoveredTab(null)}
        >
          <Users className="w-7 h-7 mr-3" />
          <span style={{ minWidth: '120px', display: 'inline-block', textAlign: 'left' }}>
            Trasfondo
          </span>
        </TabsTrigger>
        
        <TabsTrigger 
          value="experience" 
          className="flex-grow basis-40 flex items-center justify-center bg-gray-900 p-6 rounded-lg border border-gray-800 text-white text-xl"
          style={tabStyle(hoveredTab === "experience")}
          onMouseEnter={() => setHoveredTab("experience")}
          onMouseLeave={() => setHoveredTab(null)}
        >
          <Briefcase className="w-7 h-7 mr-3" />
          <span style={{ minWidth: '120px', display: 'inline-block', textAlign: 'left' }}>
            Experiencia
          </span>
        </TabsTrigger>
        
        <TabsTrigger 
          value="services" 
          className="flex-grow basis-40 flex items-center justify-center bg-gray-900 p-6 rounded-lg border border-gray-800 text-white text-xl"
          style={tabStyle(hoveredTab === "services")}
          onMouseEnter={() => setHoveredTab("services")}
          onMouseLeave={() => setHoveredTab(null)}
        >
          <Building className="w-7 h-7 mr-3" />
          <span style={{ minWidth: '120px', display: 'inline-block', textAlign: 'left' }}>
            Servicios
          </span>
        </TabsTrigger>
        
        <TabsTrigger 
          value="location" 
          className="flex-grow basis-40 flex items-center justify-center bg-gray-900 p-6 rounded-lg border border-gray-800 text-white text-xl"
          style={tabStyle(hoveredTab === "location")}
          onMouseEnter={() => setHoveredTab("location")}
          onMouseLeave={() => setHoveredTab(null)}
        >
          <MapPin className="w-7 h-7 mr-3" />
          <span style={{ minWidth: '120px', display: 'inline-block', textAlign: 'left' }}>
            Ubicación
          </span>
        </TabsTrigger>
        
        <TabsTrigger 
          value="testimonials" 
          className="flex-grow basis-40 flex items-center justify-center bg-gray-900 p-6 rounded-lg border border-gray-800 text-white text-xl"
          style={tabStyle(hoveredTab === "testimonials")}
          onMouseEnter={() => setHoveredTab("testimonials")}
          onMouseLeave={() => setHoveredTab(null)}
        >
          <BadgeCheck className="w-7 h-7 mr-3" />
          <span style={{ minWidth: '120px', display: 'inline-block', textAlign: 'left' }}>
            Testimonios
          </span>
        </TabsTrigger>
      </TabsList>
      
      <div 
        className="bg-gray-900 p-8 rounded-lg shadow-lg border border-gray-800"
        style={tabContainerStyle}
      >
        <TabsContent value="background">
          <TabBackground />
        </TabsContent>
        
        <TabsContent value="experience">
          <TabExperience />
        </TabsContent>
        
        <TabsContent value="services">
          <TabServices />
        </TabsContent>
        
        <TabsContent value="location">
          <TabLocation />
        </TabsContent>
        
        <TabsContent value="testimonials">
          <TabTestimonials />
        </TabsContent>
      </div>
    </Tabs>
  );
};

export default TabsContainer;
