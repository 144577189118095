
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';

// Define the structure for SEO data
export interface SEOData {
  title: string;
  description: string;
  keywords: string[];
  canonicalUrl?: string;
  imageUrl?: string; // Add imageUrl property
  ogImage?: string;
  ogType?: 'website' | 'article' | 'profile';
  twitterCard?: 'summary' | 'summary_large_image';
  structuredData?: object;
}

interface SEOContextType {
  updateSEO: (data: Partial<SEOData>) => void;
  seoData: SEOData;
}

const defaultSEO: SEOData = {
  title: 'Juan Elizondo | Real Estate Professional in McAllen, TX',
  description: 'Juan Elizondo is a premier commercial and residential real estate agent based in McAllen, TX, serving clients throughout the Rio Grande Valley.',
  keywords: ['real estate', 'McAllen', 'Texas', 'Rio Grande Valley', 'residential real estate', 'commercial real estate'],
  ogType: 'website',
  twitterCard: 'summary_large_image',
};

const SEOContext = createContext<SEOContextType>({
  updateSEO: () => {},
  seoData: defaultSEO,
});

export const useSEO = () => useContext(SEOContext);

export const SEOProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [seoData, setSeoData] = useState<SEOData>(defaultSEO);

  const updateSEO = (data: Partial<SEOData>) => {
    setSeoData(prev => ({ ...prev, ...data }));
  };

  return (
    <SEOContext.Provider value={{ updateSEO, seoData }}>
      <HelmetProvider>
        {children}
      </HelmetProvider>
    </SEOContext.Provider>
  );
};
