
import { Button } from "@/components/ui/button";
import { RefreshCcw } from "lucide-react";

interface ResetButtonProps {
  onReset: () => void;
}

const ResetButton = ({ onReset }: ResetButtonProps) => {
  return (
    <Button
      onClick={onReset}
      variant="outline"
      className="w-full mt-4 calculator-reset-glow"
      style={{
        transition: 'all 0.3s ease'
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.boxShadow = '0 0 15px rgba(255, 179, 71, 0.5)';
        e.currentTarget.style.transform = 'translateY(-2px)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.boxShadow = 'none';
        e.currentTarget.style.transform = 'translateY(0)';
      }}
    >
      <RefreshCcw className="w-4 h-4 mr-2" />
      Reset
    </Button>
  );
};

export default ResetButton;
