
import { MessageCircle, Scale, Megaphone, Handshake, FileCheck } from "lucide-react";
import { useTheme } from "@/context/ThemeContext";

const SellProcessSteps = () => {
  const { theme } = useTheme();
  
  const steps = [
    {
      icon: <MessageCircle className="h-6 w-6 text-white" />,
      title: "Consultation",
      description: "Meet to discuss your property, goals, timeline, and the current market conditions."
    },
    {
      icon: <Scale className="h-6 w-6 text-white" />,
      title: "Valuation",
      description: "Receive a comprehensive market analysis to determine the optimal listing price for your property."
    },
    {
      icon: <Megaphone className="h-6 w-6 text-white" />,
      title: "Marketing",
      description: "Your property will be professionally photographed and marketed across multiple channels to attract qualified buyers."
    },
    {
      icon: <Handshake className="h-6 w-6 text-white" />,
      title: "Negotiate",
      description: "I'll handle all offers and negotiations, leveraging my expertise to secure the best terms for you."
    },
    {
      icon: <FileCheck className="h-6 w-6 text-white" />,
      title: "Close",
      description: "Manage all closing procedures, paperwork, and final steps to ensure a smooth transaction."
    }
  ];

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-3xl md:text-4xl font-display text-center mb-12">The Selling Process</h2>
      
      <div className="space-y-8">
        {steps.map((step, index) => (
          <div 
            key={index}
            className={`flex items-start gap-4 p-6 rounded-lg shadow-sm transition-all hover:shadow-md ${
              theme === 'dark' 
                ? 'bg-estate-700 text-white' 
                : 'bg-white text-estate-800'
            }`}
          >
            <div className="flex-shrink-0 w-12 h-12 rounded-full bg-[#ffb347] flex items-center justify-center">
              {step.icon}
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">{step.title}</h3>
              <p className={theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}>{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SellProcessSteps;
