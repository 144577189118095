
import { SupabaseBlogPost, BlogPost } from '@/types/blog';

// Transform a Supabase blog post to our frontend model
export const formatSupabaseBlogPost = (post: SupabaseBlogPost): BlogPost => {
  // Add debug logs to check for field name issues
  console.log(`Formatting post ${post.id}: Checking excerpt field:`, {
    excerpt: post.excerpt,
  });

  // Use the correct excerpt field name
  const excerptContent = post.excerpt || '';
  
  return {
    id: post.id,
    title: post.title || 'Untitled Post',
    content: post.content || '',
    excerpt: excerptContent,
    author: post.author || 'Unknown Author',
    date: post.created_at ? new Date(post.created_at).toISOString() : new Date().toISOString(),
    category: post.category || 'Uncategorized',
    image: post.image || ''
  };
};

// Export other functions from here
export * from './postTransformer';
export * from './formatters';
export * from './dateUtils';
export * from './excerptUtils';
export * from './imageExtractionUtils';
export * from './contentUtils';
export * from './validationUtils';

// Export the type explicitly
export type { SupabaseBlogPost };
