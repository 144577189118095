
import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Calculator, ChevronDown, ChevronUp } from "lucide-react";

interface CalculatorsDropdownProps {
  linkStyle: React.CSSProperties;
}

const CalculatorsDropdown: React.FC<CalculatorsDropdownProps> = ({ linkStyle }) => {
  const [calculatorsOpen, setCalculatorsOpen] = useState(false);
  const calculatorsDropdownRef = useRef<HTMLDivElement>(null);

  const toggleCalculators = () => {
    setCalculatorsOpen(!calculatorsOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (calculatorsDropdownRef.current && !calculatorsDropdownRef.current.contains(event.target as Node)) {
        setCalculatorsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div 
      className="relative" 
      ref={calculatorsDropdownRef}
      style={{
        transform: 'translateZ(0)',
        backfaceVisibility: 'hidden'
      }}
    >
      <button
        onClick={toggleCalculators}
        className={`font-medium hover:text-gold-500 transition-colors duration-300 flex items-center`}
        style={linkStyle}
      >
        Calculators
        <span className="ml-1">{calculatorsOpen ? <ChevronUp size={14} /> : <ChevronDown size={14} />}</span>
      </button>
      {calculatorsOpen && (
        <div className="absolute top-full left-0 mt-2 w-64 bg-white dark:bg-gray-900 shadow-lg rounded-md overflow-hidden z-50">
          <div className="py-2">
            <Link 
              to="/flip-calculator" 
              className="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 flex items-center"
              onClick={() => setCalculatorsOpen(false)}
            >
              <Calculator className="mr-2 h-4 w-4" />
              House Flipping Calculator
            </Link>
            <Link 
              to="/rental-calculator" 
              className="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 flex items-center"
              onClick={() => setCalculatorsOpen(false)}
            >
              <Calculator className="mr-2 h-4 w-4" />
              Rental Property Calculator
            </Link>
            <Link 
              to="/rent-vs-buy-calculator" 
              className="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 flex items-center"
              onClick={() => setCalculatorsOpen(false)}
            >
              <Calculator className="mr-2 h-4 w-4" />
              Rent vs Buy Calculator
            </Link>
            <Link 
              to="/mortgage-calculator" 
              className="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 flex items-center"
              onClick={() => setCalculatorsOpen(false)}
            >
              <Calculator className="mr-2 h-4 w-4" />
              Mortgage Calculator
            </Link>
            <Link 
              to="/renovation-estimator" 
              className="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 flex items-center"
              onClick={() => setCalculatorsOpen(false)}
            >
              <Calculator className="mr-2 h-4 w-4" />
              Renovation Cost Estimator
            </Link>
            <Link 
              to="/house-hacking-calculator" 
              className="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 flex items-center"
              onClick={() => setCalculatorsOpen(false)}
            >
              <Calculator className="mr-2 h-4 w-4" />
              House Hacking Calculator
            </Link>
            <Link 
              to="/split-calculator" 
              className="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 flex items-center"
              onClick={() => setCalculatorsOpen(false)}
            >
              <Calculator className="mr-2 h-4 w-4" />
              Split View Calculator
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalculatorsDropdown;
