
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";

const MexicanLegalConsiderations = () => {
  return (
    <section className="py-20 bg-black">
      <div className="container mx-auto px-4 max-w-5xl">
        <h2 className="text-3xl font-display text-center mb-14 text-shadow-gold">Consideraciones Legales y Fiscales</h2>
        
        <Accordion type="single" collapsible className="bg-gray-900 rounded-lg p-4">
          <AccordionItem value="item-1">
            <AccordionTrigger className="px-6 py-4 hover:bg-gray-800 rounded-md text-2xl font-medium text-white">
              Regulaciones para Propietarios Extranjeros
            </AccordionTrigger>
            <AccordionContent className="px-6 py-4 text-xl text-white">
              Como inversionista mexicano, puede legalmente ser propietario en Estados Unidos. Sin embargo, hay consideraciones específicas, incluyendo la Ley de Impuestos sobre Inversiones Extranjeras en Bienes Inmuebles (FIRPTA). Lo guiaré a través de estas regulaciones para garantizar el cumplimiento.
            </AccordionContent>
          </AccordionItem>
          
          <AccordionItem value="item-2">
            <AccordionTrigger className="px-6 py-4 hover:bg-gray-800 rounded-md text-2xl font-medium text-white">
              Implicaciones Fiscales para No Residentes
            </AccordionTrigger>
            <AccordionContent className="px-6 py-4 text-xl text-white">
              Los inversionistas no residentes enfrentan consideraciones fiscales únicas, incluidos impuestos a la propiedad, impuestos sobre ingresos por alquiler e impuestos sobre ganancias de capital al momento de la venta. Entender estos aspectos al inicio de su viaje de inversión es crucial para una planificación adecuada.
            </AccordionContent>
          </AccordionItem>
          
          <AccordionItem value="item-3">
            <AccordionTrigger className="px-6 py-4 hover:bg-gray-800 rounded-md text-2xl font-medium text-white">
              Estructurando Su Inversión
            </AccordionTrigger>
            <AccordionContent className="px-6 py-4 text-xl text-white">
              Hay varias formas de estructurar su inversión inmobiliaria en Estados Unidos, incluida la propiedad directa, LLC, corporaciones o sociedades. Cada una tiene diferentes implicaciones legales y fiscales que deben considerarse según sus objetivos específicos.
            </AccordionContent>
          </AccordionItem>
          
          <AccordionItem value="item-4">
            <AccordionTrigger className="px-6 py-4 hover:bg-gray-800 rounded-md text-2xl font-medium text-white">
              Banca y Financiamiento Transfronterizo
            </AccordionTrigger>
            <AccordionContent className="px-6 py-4 text-xl text-white">
              Entender sus opciones de financiamiento es esencial. Mientras algunos inversionistas mexicanos prefieren compras en efectivo, otros pueden beneficiarse de opciones hipotecarias en Estados Unidos. Puedo conectarlo con prestamistas experimentados en trabajar con clientes internacionales.
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </section>
  );
};

export default MexicanLegalConsiderations;
