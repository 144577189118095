
import { useState } from "react";
import { extractYouTubeVideoId } from "@/utils/media/videoUtils";
import { VideoInfo, YouTubeVideoSequence } from "@/utils/media/types";

/**
 * Hook to handle YouTube video loading and sequencing
 */
export const useYouTubeVideo = () => {
  const [youtubeId, setYoutubeId] = useState<string | null>(null);
  const [youtubeSequence, setYoutubeSequence] = useState<YouTubeVideoSequence | null>(null);
  
  // Function to load a YouTube video
  const loadYoutubeVideo = (videoUrl: string): boolean => {
    const youtubeVideoId = extractYouTubeVideoId(videoUrl);
    if (youtubeVideoId) {
      console.log("🎬 Loading YouTube video with ID:", youtubeVideoId);
      setYoutubeId(youtubeVideoId);
      
      return true;
    }
    return false;
  };

  // Function to load a YouTube video sequence (playlist)
  const loadYoutubeSequence = (videoUrls: string[]): boolean => {
    const videoIds = videoUrls
      .map(url => extractYouTubeVideoId(url))
      .filter((id): id is string => id !== null);
    
    if (videoIds.length > 0) {
      console.log("🎬 Loading YouTube video sequence with IDs:", videoIds);
      setYoutubeSequence({
        videoIds,
        currentIndex: 0
      });
      setYoutubeId(videoIds[0]);
      return true;
    }
    return false;
  };

  // Handle YouTube video sequence changes
  const handleVideoSequenceEnd = () => {
    if (!youtubeSequence) return;
    
    // Check if we have more videos in the sequence
    if (youtubeSequence.currentIndex < youtubeSequence.videoIds.length - 1) {
      // Move to the next video
      const nextIndex = youtubeSequence.currentIndex + 1;
      setYoutubeSequence({
        ...youtubeSequence,
        currentIndex: nextIndex
      });
      setYoutubeId(youtubeSequence.videoIds[nextIndex]);
      console.log(`Switching to next video in sequence: ${youtubeSequence.videoIds[nextIndex]}`);
    } else {
      // If we've reached the end of the sequence, loop back to the first video
      setYoutubeSequence({
        ...youtubeSequence,
        currentIndex: 0
      });
      setYoutubeId(youtubeSequence.videoIds[0]);
      console.log(`Looping back to first video in sequence: ${youtubeSequence.videoIds[0]}`);
    }
  };

  return {
    youtubeId,
    youtubeSequence,
    loadYoutubeVideo,
    loadYoutubeSequence,
    handleVideoSequenceEnd
  };
};
