
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";

interface BlogPostErrorProps {
  error: string;
  onBack?: () => void; // Make onBack optional
}

const BlogPostError: React.FC<BlogPostErrorProps> = ({ error, onBack }) => {
  const navigate = useNavigate();
  
  // Use provided onBack or fallback to navigate
  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate('/blog');
    }
  };
  
  return (
    <div className="container mx-auto px-4 py-16 text-center">
      <h2 className="text-2xl font-bold mb-4">Error loading blog post</h2>
      <p className="mb-8 text-red-500">{error}</p>
      <Button 
        onClick={handleBack}
        className="bg-[#ffb347] hover:bg-[#f5a030]"
      >
        Back to Blog
      </Button>
    </div>
  );
};

export default BlogPostError;
