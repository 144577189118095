
import React from 'react';
import FormTabs from './form/FormTabs';
import FormButtons from './form/FormButtons';

interface EstimatorFormProps {
  projectName: string;
  setProjectName: (value: string) => void;
  squareFootage: number;
  setSquareFootage: (value: number) => void;
  roomCount: number;
  setRoomCount: (value: number) => void;
  materialQuality: string;
  setMaterialQuality: (value: string) => void;
  laborRate: number;
  setLaborRate: (value: number) => void;
  duration: number;
  setDuration: (value: number) => void;
  permitsRequired: boolean;
  setPermitsRequired: (value: boolean) => void;
  additionalFees: number;
  setAdditionalFees: (value: number) => void;
  designFee: number;
  setDesignFee: (value: number) => void;
  contingencyPercentage: number;
  setContingencyPercentage: (value: number) => void;
  resetForm: () => void;
  handleShare: () => void;
  handleExportPDF: () => void;
  // Manual cost adjustment props
  materialCost: number;
  setMaterialCost: (value: number) => void;
  suggestedMaterialCost: number;
  laborCost: number;
  setLaborCost: (value: number) => void;
  suggestedLaborCost: number;
  permitsCost: number;
  setPermitsCost: (value: number) => void;
  suggestedPermitsCost: number;
}

const EstimatorForm: React.FC<EstimatorFormProps> = (props) => {
  return (
    <div className="space-y-4 bg-estate-900/70 p-4 rounded-lg border border-estate-700 shadow-lg">
      <FormTabs
        projectName={props.projectName}
        setProjectName={props.setProjectName}
        squareFootage={props.squareFootage}
        setSquareFootage={props.setSquareFootage}
        roomCount={props.roomCount}
        setRoomCount={props.setRoomCount}
        materialQuality={props.materialQuality}
        setMaterialQuality={props.setMaterialQuality}
        laborRate={props.laborRate}
        setLaborRate={props.setLaborRate}
        duration={props.duration}
        setDuration={props.setDuration}
        permitsRequired={props.permitsRequired}
        setPermitsRequired={props.setPermitsRequired}
        additionalFees={props.additionalFees}
        setAdditionalFees={props.setAdditionalFees}
        designFee={props.designFee}
        setDesignFee={props.setDesignFee}
        contingencyPercentage={props.contingencyPercentage}
        setContingencyPercentage={props.setContingencyPercentage}
        materialCost={props.materialCost}
        setMaterialCost={props.setMaterialCost}
        suggestedMaterialCost={props.suggestedMaterialCost}
        laborCost={props.laborCost}
        setLaborCost={props.setLaborCost}
        suggestedLaborCost={props.suggestedLaborCost}
        permitsCost={props.permitsCost}
        setPermitsCost={props.setPermitsCost}
        suggestedPermitsCost={props.suggestedPermitsCost}
      />

      <FormButtons
        resetForm={props.resetForm}
        handleShare={props.handleShare}
        handleExportPDF={props.handleExportPDF}
      />
    </div>
  );
};

export default EstimatorForm;
