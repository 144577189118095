
import React from "react";

interface BlogPostCardExcerptProps {
  excerpt: string;
}

const BlogPostCardExcerpt = ({ excerpt }: BlogPostCardExcerptProps) => {
  // Clean up excerpt if it contains HTML
  const cleanExcerpt = excerpt?.includes('<') 
    ? excerpt.replace(/<[^>]*>/g, ' ').replace(/\s+/g, ' ').trim()
    : excerpt;

  return (
    <div className="mb-4 blog-excerpt">
      <p className="line-clamp-2 text-gray-200">
        {cleanExcerpt || "No excerpt available for this post."}
      </p>
    </div>
  );
};

export default BlogPostCardExcerpt;
