import React from "react";

const GlobalGlowFilter = () => {
  return (
    <svg
      id="global-glow-filters"
      aria-hidden="true"
      style={{
        width: 0,
        height: 0,
        position: "absolute",
        top: 0,
        left: 0,
        overflow: "visible",
      }}
    >
      {/* Basic glow filter - increased intensity */}
      <defs>
        <filter
          id="glow-filter"
          x="-50%"
          y="-100%"
          width="200%"
          height="300%"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur stdDeviation="18" result="blur" />
          <feComposite in="SourceGraphic" in2="blur" operator="over" />
        </filter>

        {/* Apple-inspired advanced glow filter - gold/orange - increased intensity */}
        <filter
          id="advanced-glow"
          x="-50%"
          y="-200%"
          width="200%"
          height="500%"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur4" />
          <feGaussianBlur in="SourceGraphic" stdDeviation="30" result="blur19" />
          <feGaussianBlur in="SourceGraphic" stdDeviation="18" result="blur9" />
          <feGaussianBlur in="SourceGraphic" stdDeviation="40" result="blur30" />

          <feColorMatrix in="blur4" result="color-0-blur" type="matrix" values="1 0 0 0 0
                    0 0.9803921568627451 0 0 0
                    0 0 0.9647058823529412 0 0
                    0 0 0 1 0" />
          <feOffset in="color-0-blur" result="layer-0-offsetted" dx="0" dy="0" />

          <feColorMatrix in="blur19" result="color-1-blur" type="matrix" values="0.9156862745098039 0 0 0 0
                    0 0.59411764705882355 0 0 0
                    0 0 0.3627450980392157 0 0
                    0 0 0 1 0" />
          <feOffset in="color-1-blur" result="layer-1-offsetted" dx="0" dy="2" />

          <feColorMatrix in="blur9" result="color-2-blur" type="matrix" values="1 0 0 0 0
                    0 0.7666666666666666 0 0 0
                    0 0 0.46470588235294116 0 0
                    0 0 0 0.9 0" />
          <feOffset in="color-2-blur" result="layer-2-offsetted" dx="0" dy="2" />

          <feColorMatrix in="blur30" result="color-3-blur" type="matrix" values="1 0 0 0 0
                    0 0.611764705882353 0 0 0
                    0 0 0.39215686274509803 0 0
                    0 0 0 1 0" />
          <feOffset in="color-3-blur" result="layer-3-offsetted" dx="0" dy="2" />

          <feColorMatrix in="blur30" result="color-4-blur" type="matrix" values="0.5549019607843137 0 0 0 0
                    0 0.26470588235294117 0 0 0
                    0 0 0.1 0 0
                    0 0 0 1 0" />
          <feOffset in="color-4-blur" result="layer-4-offsetted" dx="0" dy="16" />

          <feMerge>
            <feMergeNode in="layer-0-offsetted" />
            <feMergeNode in="layer-1-offsetted" />
            <feMergeNode in="layer-2-offsetted" />
            <feMergeNode in="layer-3-offsetted" />
            <feMergeNode in="layer-4-offsetted" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>

        <filter
          id="advanced-glow-blue"
          x="-50%"
          y="-200%"
          width="200%"
          height="500%"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur4" />
          <feGaussianBlur in="SourceGraphic" stdDeviation="25" result="blur19" />
          <feGaussianBlur in="SourceGraphic" stdDeviation="15" result="blur9" />
          <feGaussianBlur in="SourceGraphic" stdDeviation="35" result="blur30" />

          <feColorMatrix in="blur4" result="color-0-blur" type="matrix" values="0 0 0 0 0.9
                    0 0 0 0 0.95
                    0 0 0 0 1
                    0 0 0 1 0" />
          <feOffset in="color-0-blur" result="layer-0-offsetted" dx="0" dy="0" />

          <feColorMatrix in="blur19" result="color-1-blur" type="matrix" values="0 0 0 0 0.2
                    0 0 0 0 0.5
                    0 0 0 0 0.8
                    0 0 0 1 0" />
          <feOffset in="color-1-blur" result="layer-1-offsetted" dx="0" dy="2" />

          <feColorMatrix in="blur9" result="color-2-blur" type="matrix" values="0 0 0 0 0.4
                    0 0 0 0 0.6
                    0 0 0 0 1
                    0 0 0 0.9 0" />
          <feOffset in="color-2-blur" result="layer-2-offsetted" dx="0" dy="2" />

          <feMerge>
            <feMergeNode in="layer-0-offsetted" />
            <feMergeNode in="layer-1-offsetted" />
            <feMergeNode in="layer-2-offsetted" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>

        <filter
          id="advanced-glow-purple"
          x="-50%"
          y="-200%"
          width="200%"
          height="500%"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur4" />
          <feGaussianBlur in="SourceGraphic" stdDeviation="25" result="blur19" />
          <feGaussianBlur in="SourceGraphic" stdDeviation="15" result="blur9" />
          <feGaussianBlur in="SourceGraphic" stdDeviation="35" result="blur30" />

          <feColorMatrix in="blur4" result="color-0-blur" type="matrix" values="0.95 0 0 0 0
                    0 0.9 0 0 0
                    0 0 0.95 0 0
                    0 0 0 1 0" />
          <feOffset in="color-0-blur" result="layer-0-offsetted" dx="0" dy="0" />

          <feColorMatrix in="blur19" result="color-1-blur" type="matrix" values="0.7 0 0 0 0
                    0 0.3 0 0 0
                    0 0 0.8 0 0
                    0 0 0 1 0" />
          <feOffset in="color-1-blur" result="layer-1-offsetted" dx="0" dy="2" />

          <feColorMatrix in="blur9" result="color-2-blur" type="matrix" values="0.6 0 0 0 0
                    0 0.2 0 0 0
                    0 0 0.7 0 0
                    0 0 0 0.9 0" />
          <feOffset in="color-2-blur" result="layer-2-offsetted" dx="0" dy="2" />

          <feMerge>
            <feMergeNode in="layer-0-offsetted" />
            <feMergeNode in="layer-1-offsetted" />
            <feMergeNode in="layer-2-offsetted" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
    </svg>
  );
};

export default GlobalGlowFilter;
