
// Default image path for all communities
export const DEFAULT_COMMUNITY_IMAGE = "/lovable-uploads/8b61c404-d0e9-49ee-994b-b9e4732565df.png";

// McAllen specific image path
export const MCALLEN_IMAGE = "/lovable-uploads/471b82af-992f-43e2-818b-55cf13a3dde6.png";

// Hidalgo specific image path
export const HIDALGO_IMAGE = "/lovable-uploads/5d117e8e-0dc8-4903-bf68-638dccb00842.png";

// South Padre Island specific image path
export const SPI_IMAGE = "/lovable-uploads/c41db19d-3c71-4b2b-b77b-e2cb56891f36.png";

// Brownsville specific image path
export const BROWNSVILLE_IMAGE = "/lovable-uploads/940d5d6d-ca53-4659-8542-20492c99cf6b.png";

// Mission specific image path
export const MISSION_IMAGE = "/lovable-uploads/d26ea204-1a95-475e-b31c-eabca148d7d6.png";

// Edinburg specific image path
export const EDINBURG_IMAGE = "/lovable-uploads/0f5438a9-1d76-44c0-a222-fb01f2287e9e.png";

// Harlingen specific image path
export const HARLINGEN_IMAGE = "/lovable-uploads/69e8c8b8-27bb-449d-953d-bed6e76760b5.png";

// Mercedes specific image path
export const MERCEDES_IMAGE = "/lovable-uploads/98b30f91-976e-4d00-89b9-98b18757afae.png";

// Featured Rio Grande Valley communities for the dropdown with image paths
export const featuredCommunities = [
  { name: "South Padre Island", image: SPI_IMAGE },
  { name: "Brownsville", image: BROWNSVILLE_IMAGE },
  { name: "Harlingen", image: HARLINGEN_IMAGE },
  { name: "Mercedes", image: MERCEDES_IMAGE },
  { name: "Weslaco", image: DEFAULT_COMMUNITY_IMAGE },
  { name: "Pharr", image: DEFAULT_COMMUNITY_IMAGE },
  { name: "San Juan", image: DEFAULT_COMMUNITY_IMAGE },
  { name: "Mission", image: MISSION_IMAGE },
  { name: "Edinburg", image: EDINBURG_IMAGE },
  { name: "McAllen", image: MCALLEN_IMAGE },
  { name: "Rio Grande City", image: DEFAULT_COMMUNITY_IMAGE },
  { name: "Port Isabel", image: DEFAULT_COMMUNITY_IMAGE },
  { name: "Hidalgo", image: HIDALGO_IMAGE },
];

// Additional smaller communities
export const additionalCommunities = [
  "Alamo", "Alton", "Combes", "Donna", "Edcouch", "Elsa", 
  "La Feria", "La Joya", "La Villa", "Laguna Vista", "Los Fresnos", 
  "Lyford", "Monte Alto", "Palmhurst", "Palmview", "Palm Valley", 
  "Penitas", "Primera", "Progreso", "Progreso Lakes", "Raymondville", 
  "Rangerville", "Rio Hondo", "Roma", "San Benito", "Santa Rosa", 
  "Sebastian", "Sullivan City"
];
