
import React from "react";
import { Button } from "@/components/ui/button";

const AboutJuan = () => {
  return (
    <div className="mt-20 bg-estate-800 text-white rounded-lg overflow-hidden">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="p-12 flex items-center">
          <div>
            <h2 className="text-3xl font-display mb-6">Juan Elizondo, Your Rio Grande Valley Real Estate Expert</h2>
            <p className="mb-6 text-estate-200">
              With deep knowledge of the Rio Grande Valley communities and a passion for helping clients find their perfect property, Juan Elizondo provides unmatched service and expertise throughout the region.
            </p>
            <p className="mb-6 text-estate-200">
              Whether you're looking to buy your dream home, sell your current property, or invest in the growing Rio Grande Valley market, Juan is dedicated to making your real estate journey smooth and successful.
            </p>
            <Button className="bg-gold-500 hover:bg-gold-600 text-white">Contact Juan</Button>
          </div>
        </div>
        <div className="relative bg-cover bg-center h-full min-h-[400px] overflow-hidden">
          {/* Remax background */}
          <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
            <img
              src="/lovable-uploads/8e1f1c51-05b6-4bf6-9820-9008ebf1477a.png"
              alt="RE/MAX balloon"
              className="w-full h-full object-contain opacity-40"
            />
          </div>
          <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
            <img 
              src="/lovable-uploads/d43397b6-a154-4bec-829d-f291ae2a0b34.png" 
              alt="Juan Elizondo" 
              className="w-[120%] h-[120%] object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutJuan;
