
import React from "react";
import BlogHeader from "@/components/blog/BlogHeader";
import BlogListing from "@/components/blog/BlogListing";
import { useBlogPosts } from "@/hooks/useBlogPosts";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import { useTheme } from "@/context/ThemeContext";
import "../styles/navigation.css";

const Blog = () => {
  const { blogPosts } = useBlogPosts();
  const { theme } = useTheme();
  
  const bgClass = theme === 'dark' ? "bg-gray-900" : "bg-gray-100";
  const textClass = theme === 'dark' ? "text-white" : "text-gray-900";

  return (
    <div className={`min-h-screen ${bgClass} ${textClass}`}>
      <Navbar />
      <div className="container mx-auto py-8 px-4">
        <BlogHeader />
        <BlogListing />
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
