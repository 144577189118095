
import { useLanguage } from "@/context/LanguageContext";
import SEOImage from "../SEOImage";
import ContactForm from "../contact/ContactForm";

const ContactSection = () => {
  const { t } = useLanguage();
  
  return (
    <section className="py-32 bg-background">
      <div className="container mx-auto px-4 max-w-6xl">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-start">
          {/* Image column - with two stacked images to match form height */}
          <div className="flex flex-col gap-2 h-full">
            {/* Top image - happy couple */}
            <div className="rounded-lg overflow-hidden shadow-xl h-auto" style={{ flex: '1' }}>
              <SEOImage 
                src="/lovable-uploads/687c4007-4a44-4d10-947a-8ec93c67f7c2.png" 
                alt="Happy couple in their beautiful modern home" 
                className="w-full h-full object-cover"
                width={600}
                height={400}
              />
            </div>
            
            {/* Bottom image - RE/MAX balloon logo with transparent background */}
            <div className="overflow-hidden flex items-center justify-center bg-black" style={{ flex: '1' }}>
              <SEOImage 
                src="/lovable-uploads/967535c9-c31e-4c4f-81e2-8015abebcc88.png" 
                alt="RE/MAX real estate balloon logo" 
                className="w-full h-auto object-contain mx-auto balloon-logo" 
                width={600}
                height={400}
              />
            </div>
          </div>
          
          {/* Contact form column - made taller */}
          <div className="flex flex-col h-full">
            <h2 
              className="text-4xl md:text-5xl font-display mb-6" 
              data-text={t('contact.ready')}
            >
              {t('contact.ready')}
            </h2>
            
            {/* Contact form - removing the subtitle and making the form taller */}
            <div className="flex-grow">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
