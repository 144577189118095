
// Credit score ranges
export const CREDIT_SCORE_RANGES = [
  { label: "Poor (300-579)", min: 300, max: 579, value: "poor" },
  { label: "Fair (580-669)", min: 580, max: 669, value: "fair" },
  { label: "Good (670-739)", min: 670, max: 739, value: "good" },
  { label: "Very Good (740-799)", min: 740, max: 799, value: "very-good" },
  { label: "Excellent (800-850)", min: 800, max: 850, value: "excellent" }
];

// Mortgage loan program types
export const MORTGAGE_PROGRAMS = [
  { value: "conventional", label: "Conventional", description: "Standard home loans with at least 3% down payment" },
  { value: "fha", label: "FHA", description: "For first-time homebuyers, low down payment (3.5%)" },
  { value: "va", label: "VA", description: "For veterans, service members, and eligible spouses" },
  { value: "usda", label: "USDA", description: "For rural properties with 0% down payment" },
  { value: "jumbo", label: "Jumbo", description: "For loan amounts exceeding conforming limits" }
];

// Loan structure types
export const LOAN_STRUCTURES = [
  { value: "fixed", label: "Fixed Rate" },
  { value: "arm-5-1", label: "5/1 ARM" },
  { value: "arm-7-1", label: "7/1 ARM" },
  { value: "arm-10-1", label: "10/1 ARM" }
];

// Mock interest rate data by credit score range and loan type
export const INTEREST_RATES = {
  mortgage: {
    "poor": 8.75,
    "fair": 7.25,
    "good": 6.25,
    "very-good": 5.5,
    "excellent": 5.0
  },
  auto: {
    "poor": 14.25,
    "fair": 10.5,
    "good": 7.5,
    "very-good": 5.25,
    "excellent": 4.0
  },
  personal: {
    "poor": 25.0,
    "fair": 18.5,
    "good": 14.0,
    "very-good": 10.5,
    "excellent": 8.0
  }
};

// Program-specific interest rate adjustments
export const PROGRAM_RATE_ADJUSTMENTS = {
  "conventional": { adjustment: 0.0 },
  "fha": { adjustment: -0.25 },
  "va": { adjustment: -0.5 },
  "usda": { adjustment: -0.25 },
  "jumbo": { adjustment: 0.5 }
};

// Structure-specific interest rate adjustments
export const STRUCTURE_RATE_ADJUSTMENTS = {
  "fixed": { adjustment: 0.0 },
  "arm-5-1": { adjustment: -0.75 },
  "arm-7-1": { adjustment: -0.5 },
  "arm-10-1": { adjustment: -0.25 }
};

// Down payment requirements by program
export const DOWN_PAYMENT_REQUIREMENTS = {
  "conventional": 0.03, // 3%
  "fha": 0.035, // 3.5%
  "va": 0, // 0%
  "usda": 0, // 0%
  "jumbo": 0.20 // 20%
};

// States for mortgage
export const STATES = [
  "Alabama", "Alaska", "Arizona", "Arkansas", "California", 
  "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", 
  "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", 
  "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", 
  "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", 
  "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", 
  "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", 
  "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", 
  "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", 
  "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
];

// Loan term options by loan type
export const LOAN_TERMS = {
  mortgage: [
    { value: "30", label: "30 years" },
    { value: "20", label: "20 years" },
    { value: "15", label: "15 years" },
    { value: "10", label: "10 years" }
  ],
  auto: [
    { value: "7", label: "7 years" },
    { value: "6", label: "6 years" },
    { value: "5", label: "5 years" },
    { value: "4", label: "4 years" },
    { value: "3", label: "3 years" }
  ],
  personal: [
    { value: "7", label: "7 years" },
    { value: "5", label: "5 years" },
    { value: "3", label: "3 years" },
    { value: "1", label: "1 year" }
  ]
};

// Default loan amounts by loan type
export const DEFAULT_LOAN_AMOUNTS = {
  mortgage: 300000,
  auto: 30000,
  personal: 10000
};

// Min/max loan amounts by loan type
export const LOAN_AMOUNT_RANGES = {
  mortgage: { min: 50000, max: 1000000, step: 10000 },
  auto: { min: 5000, max: 100000, step: 1000 },
  personal: { min: 1000, max: 50000, step: 500 }
};

// Loan Types
export type LoanType = "mortgage" | "auto" | "personal";
export type CreditScoreValue = "poor" | "fair" | "good" | "very-good" | "excellent";
export type MortgageProgramValue = "conventional" | "fha" | "va" | "usda" | "jumbo";
export type LoanStructureValue = "fixed" | "arm-5-1" | "arm-7-1" | "arm-10-1";

// Updated rates from Freddie Mac - these would be updated regularly in a real application
export const CURRENT_MARKET_RATES = {
  date: new Date().toLocaleDateString(),
  rates: {
    "conventional": {
      "30": 6.84,
      "20": 6.62,
      "15": 6.15,
      "10": 5.95
    },
    "fha": {
      "30": 6.59,
      "20": 6.37,
      "15": 5.90,
      "10": 5.70
    },
    "va": {
      "30": 6.34,
      "20": 6.12,
      "15": 5.65,
      "10": 5.45
    },
    "usda": {
      "30": 6.59,
      "20": 6.37,
      "15": 5.90,
      "10": 5.70
    },
    "jumbo": {
      "30": 7.34,
      "20": 7.12,
      "15": 6.65,
      "10": 6.45
    },
    "arm-5-1": 6.09,
    "arm-7-1": 6.34,
    "arm-10-1": 6.59
  }
};
