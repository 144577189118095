
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "@/components/ui/card";
import { Percent } from "lucide-react";
import { CreditScoreValue } from "./constants";
import { LoanResults, formatCurrency, getCreditScoreLabel } from "./utils";

interface ResultsDisplayProps {
  results: LoanResults;
  creditScore: CreditScoreValue;
  comparisonScore: CreditScoreValue;
}

const ResultsDisplay = ({ results, creditScore, comparisonScore }: ResultsDisplayProps) => {
  if (!results) return null;

  return (
    <div className="mt-8 space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card className="shadow-md">
          <CardHeader className="bg-gray-100 dark:bg-gray-800 pb-2">
            <CardTitle className="text-xl">
              With Your Credit Score
            </CardTitle>
            <CardDescription>
              {getCreditScoreLabel(creditScore)}
            </CardDescription>
          </CardHeader>
          <CardContent className="pt-4">
            <div className="space-y-3">
              <div className="flex justify-between">
                <span className="text-muted-foreground">Interest Rate:</span>
                <span className="font-semibold">{results.currentRate.toFixed(2)}%</span>
              </div>
              <div className="flex justify-between">
                <span className="text-muted-foreground">Monthly Payment:</span>
                <span className="font-semibold">{formatCurrency(results.currentMonthly)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-muted-foreground">Total of All Payments:</span>
                <span className="font-semibold">{formatCurrency(results.currentTotal)}</span>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className="shadow-md border-blue-800">
          <CardHeader className="bg-blue-900 text-white dark:bg-blue-950 pb-2">
            <CardTitle className="text-xl flex items-center">
              With Better Credit Score
              <span className="ml-2 inline-flex items-center justify-center text-xs font-medium h-5 px-2 rounded-full bg-blue-600 text-white">
                Savings
              </span>
            </CardTitle>
            <CardDescription className="text-blue-100">
              {getCreditScoreLabel(comparisonScore)}
            </CardDescription>
          </CardHeader>
          <CardContent className="pt-4">
            <div className="space-y-3">
              <div className="flex justify-between">
                <span className="text-muted-foreground">Interest Rate:</span>
                <span className="font-semibold text-green-600">{results.comparisonRate.toFixed(2)}%</span>
              </div>
              <div className="flex justify-between">
                <span className="text-muted-foreground">Monthly Payment:</span>
                <span className="font-semibold text-green-600">{formatCurrency(results.comparisonMonthly)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-muted-foreground">Total of All Payments:</span>
                <span className="font-semibold text-green-600">{formatCurrency(results.comparisonTotal)}</span>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      <Card className="bg-blue-50 border-blue-300 dark:bg-blue-950/30 dark:border-blue-800 shadow-md">
        <CardHeader className="pb-2">
          <CardTitle className="text-xl flex items-center">
            <Percent className="mr-2 h-5 w-5 text-blue-600" />
            Your Potential Savings
          </CardTitle>
        </CardHeader>
        <CardContent className="pt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col items-center justify-center p-4 bg-white dark:bg-gray-800 rounded-lg shadow-sm">
              <span className="text-sm text-muted-foreground mb-1">Monthly Savings</span>
              <span className="text-3xl font-bold text-green-600">{formatCurrency(results.monthlySavings)}</span>
            </div>
            <div className="flex flex-col items-center justify-center p-4 bg-white dark:bg-gray-800 rounded-lg shadow-sm">
              <span className="text-sm text-muted-foreground mb-1">Total Savings</span>
              <span className="text-3xl font-bold text-green-600">{formatCurrency(results.totalSavings)}</span>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ResultsDisplay;
