
import React from 'react';
import { Search } from "lucide-react";
import { Button } from "@/components/ui/button";
import { NavigateFunction } from "react-router-dom";

interface PropertySearchButtonProps {
  navigate?: NavigateFunction;
}

const PropertySearchButton: React.FC<PropertySearchButtonProps> = ({ navigate }) => {
  const handleClick = () => {
    if (navigate) {
      // Navigate to buy page and scroll to the property search section
      navigate('/buy', {
        state: { 
          showSearch: true
        }
      });
    } else {
      // Fallback to regular navigation when not in Router context
      window.location.href = '/buy#property-search';
    }
  };
  
  return (
    <div className="fixed left-5 bottom-5 z-40">
      <Button 
        variant="ghost" 
        size="lg" 
        className="h-14 rounded-full bg-yellow-400 hover:bg-yellow-500 text-black flex items-center gap-2 px-6 shadow-lg border-2 border-black"
        style={{ 
          boxShadow: '0 0 0 2px black',
          transition: 'all 0.3s ease'
        }}
        onClick={handleClick}
        onMouseEnter={(e) => {
          e.currentTarget.style.boxShadow = '0 0 20px 8px rgba(255, 179, 71, 0.6), 0 0 30px rgba(255, 179, 71, 0.4)';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.boxShadow = '0 0 0 2px black';
        }}
      >
        <Search className="h-5 w-5" />
        <span className="font-bold">Property Search</span>
      </Button>
    </div>
  );
};

export default PropertySearchButton;
