
import React from "react";
import { Building, Briefcase, ChartBar, Handshake, Users, MapPin, Search } from "lucide-react";
import { useLanguage } from "@/context/LanguageContext";

const CommercialExpertise = () => {
  const { t } = useLanguage();
  
  return (
    <section className="py-16 px-4 bg-estate-50 dark:bg-estate-900 rounded-lg">
      <div className="max-w-6xl mx-auto">
        {/* Section Header */}
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-display mb-4 text-estate-800 dark:text-white">
            {t('commercial.expertise.title') || "Commercial Real Estate Expertise"}
          </h2>
          <p className="text-lg text-estate-600 dark:text-estate-300 max-w-3xl mx-auto">
            {t('commercial.expertise.subtitle') || "With decades of combined experience, our team offers unparalleled knowledge and service in commercial real estate across the Rio Grande Valley, specializing in McAllen, Mission, and Edinburg properties."}
          </p>
        </div>

        {/* Expertise Highlights */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {/* Experience Card */}
          <div className="bg-white dark:bg-estate-800 rounded-lg p-8 shadow-md hover:shadow-lg transition-shadow">
            <div className="flex items-center mb-4">
              <div className="bg-gold-500/10 p-3 rounded-full mr-4">
                <Briefcase className="h-6 w-6 text-gold-500" />
              </div>
              <h3 className="text-xl font-semibold">{t('commercial.expertise.experience') || "Industry Experience"}</h3>
            </div>
            <p className="text-estate-600 dark:text-estate-300">
              {t('commercial.expertise.experience.text') || "With over 15 years in commercial real estate, we've built a reputation for finding ideal properties that meet our clients' specific business needs, from retail locations to office complexes and industrial facilities."}
            </p>
          </div>

          {/* Market Knowledge Card */}
          <div className="bg-white dark:bg-estate-800 rounded-lg p-8 shadow-md hover:shadow-lg transition-shadow">
            <div className="flex items-center mb-4">
              <div className="bg-gold-500/10 p-3 rounded-full mr-4">
                <ChartBar className="h-6 w-6 text-gold-500" />
              </div>
              <h3 className="text-xl font-semibold">{t('commercial.expertise.market') || "Market Insight"}</h3>
            </div>
            <p className="text-estate-600 dark:text-estate-300">
              {t('commercial.expertise.market.text') || "We continuously analyze local market trends, property values, and development potential in McAllen and the Rio Grande Valley to help you make informed decisions on your commercial investments."}
            </p>
          </div>

          {/* Network Card */}
          <div className="bg-white dark:bg-estate-800 rounded-lg p-8 shadow-md hover:shadow-lg transition-shadow">
            <div className="flex items-center mb-4">
              <div className="bg-gold-500/10 p-3 rounded-full mr-4">
                <Users className="h-6 w-6 text-gold-500" />
              </div>
              <h3 className="text-xl font-semibold">{t('commercial.expertise.network') || "Extensive Network"}</h3>
            </div>
            <p className="text-estate-600 dark:text-estate-300">
              {t('commercial.expertise.network.text') || "Our strong connections with property owners, developers, and investors throughout South Texas give our clients access to off-market opportunities and exclusive commercial listings before they hit the open market."}
            </p>
          </div>
        </div>

        {/* Services Section */}
        <div className="bg-white dark:bg-estate-800 rounded-lg p-8 shadow-lg">
          <h3 className="text-2xl font-display mb-6 text-center">
            {t('commercial.services.title') || "Comprehensive Commercial Real Estate Services"}
          </h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Left Column - Services List */}
            <div>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <div className="bg-gold-500/10 p-2 rounded-full mr-3 mt-1">
                    <Building className="h-4 w-4 text-gold-500" />
                  </div>
                  <div>
                    <h4 className="font-semibold mb-1">{t('commercial.services.acquisition') || "Property Acquisition"}</h4>
                    <p className="text-sm text-estate-600 dark:text-estate-300">
                      {t('commercial.services.acquisition.text') || "We help businesses find and secure the ideal commercial space for their operations, considering factors like location, accessibility, and growth potential."}
                    </p>
                  </div>
                </li>
                
                <li className="flex items-start">
                  <div className="bg-gold-500/10 p-2 rounded-full mr-3 mt-1">
                    <Building className="h-4 w-4 text-gold-500" />
                  </div>
                  <div>
                    <h4 className="font-semibold mb-1">{t('commercial.services.leasing') || "Commercial Leasing"}</h4>
                    <p className="text-sm text-estate-600 dark:text-estate-300">
                      {t('commercial.services.leasing.text') || "Expert guidance on negotiating favorable lease terms for your business needs, including rental rates, lease duration, and tenant improvement allowances."}
                    </p>
                  </div>
                </li>
                
                <li className="flex items-start">
                  <div className="bg-gold-500/10 p-2 rounded-full mr-3 mt-1">
                    <Handshake className="h-4 w-4 text-gold-500" />
                  </div>
                  <div>
                    <h4 className="font-semibold mb-1">{t('commercial.services.investment') || "Investment Advisory"}</h4>
                    <p className="text-sm text-estate-600 dark:text-estate-300">
                      {t('commercial.services.investment.text') || "Detailed analysis and strategic recommendations for commercial property investments with strong ROI potential in McAllen's growing market."}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            
            {/* Right Column - Additional Services */}
            <div>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <div className="bg-gold-500/10 p-2 rounded-full mr-3 mt-1">
                    <Building className="h-4 w-4 text-gold-500" />
                  </div>
                  <div>
                    <h4 className="font-semibold mb-1">{t('commercial.services.property') || "Property Management"}</h4>
                    <p className="text-sm text-estate-600 dark:text-estate-300">
                      {t('commercial.services.property.text') || "Comprehensive management services for commercial property owners, including tenant relations, maintenance coordination, and financial reporting."}
                    </p>
                  </div>
                </li>
                
                <li className="flex items-start">
                  <div className="bg-gold-500/10 p-2 rounded-full mr-3 mt-1">
                    <Search className="h-4 w-4 text-gold-500" />
                  </div>
                  <div>
                    <h4 className="font-semibold mb-1">{t('commercial.services.market.analysis') || "Market Analysis"}</h4>
                    <p className="text-sm text-estate-600 dark:text-estate-300">
                      {t('commercial.services.market.analysis.text') || "Detailed analysis of commercial real estate trends in the Rio Grande Valley to inform your business location and investment decisions."}
                    </p>
                  </div>
                </li>
                
                <li className="flex items-start">
                  <div className="bg-gold-500/10 p-2 rounded-full mr-3 mt-1">
                    <MapPin className="h-4 w-4 text-gold-500" />
                  </div>
                  <div>
                    <h4 className="font-semibold mb-1">{t('commercial.services.site') || "Site Selection"}</h4>
                    <p className="text-sm text-estate-600 dark:text-estate-300">
                      {t('commercial.services.site.text') || "Strategic guidance on selecting the optimal location for your business based on demographic analysis, foot traffic, and competition assessment."}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Client Success Highlight */}
        <div className="mt-16 bg-estate-800 text-white rounded-lg p-8 relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-estate-800 via-estate-800/80 to-transparent z-0"></div>
          <div className="relative z-10">
            <h3 className="text-2xl font-display mb-4">{t('commercial.success.title') || "Client Success Stories"}</h3>
            <p className="mb-6 text-estate-300">
              {t('commercial.success.text') || "We've helped numerous businesses across the Rio Grande Valley find their ideal commercial spaces. From retail locations to office complexes and industrial warehouses, our expertise spans all commercial property types and sectors."}
            </p>
            <div className="italic text-estate-300 border-l-4 border-gold-500 pl-4 py-2">
              {t('commercial.success.quote') || "\"Juan Elizondo's deep knowledge of the commercial market in McAllen was instrumental in helping us secure our new retail location. His expertise saved us time and money throughout the process, and his negotiation skills secured us favorable lease terms that will benefit our business for years to come.\""}
              <p className="text-gold-400 mt-2">— {t('commercial.success.client') || "Maria Rodriguez, Business Owner"}</p>
            </div>
          </div>
        </div>
        
        {/* Commercial Property Types */}
        <div className="mt-16 bg-white dark:bg-estate-800 rounded-lg p-8 shadow-lg">
          <h3 className="text-2xl font-display mb-6 text-center">
            {t('commercial.property.types.title') || "Commercial Property Specializations"}
          </h3>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            <div className="bg-estate-50 dark:bg-estate-700 p-6 rounded-lg text-center">
              <Building className="h-10 w-10 text-gold-500 mx-auto mb-4" />
              <h4 className="font-semibold mb-2">{t('commercial.property.office') || "Office Space"}</h4>
              <p className="text-sm text-estate-600 dark:text-estate-300">
                {t('commercial.property.office.text') || "From executive suites to entire office buildings, we help businesses find the perfect workspace."}
              </p>
            </div>
            
            <div className="bg-estate-50 dark:bg-estate-700 p-6 rounded-lg text-center">
              <Building className="h-10 w-10 text-gold-500 mx-auto mb-4" />
              <h4 className="font-semibold mb-2">{t('commercial.property.retail') || "Retail Properties"}</h4>
              <p className="text-sm text-estate-600 dark:text-estate-300">
                {t('commercial.property.retail.text') || "Prime retail locations in high-traffic areas to maximize your business visibility and customer flow."}
              </p>
            </div>
            
            <div className="bg-estate-50 dark:bg-estate-700 p-6 rounded-lg text-center">
              <Building className="h-10 w-10 text-gold-500 mx-auto mb-4" />
              <h4 className="font-semibold mb-2">{t('commercial.property.industrial') || "Industrial Spaces"}</h4>
              <p className="text-sm text-estate-600 dark:text-estate-300">
                {t('commercial.property.industrial.text') || "Warehouses, distribution centers, and manufacturing facilities with the specifications your operation requires."}
              </p>
            </div>
            
            <div className="bg-estate-50 dark:bg-estate-700 p-6 rounded-lg text-center">
              <Building className="h-10 w-10 text-gold-500 mx-auto mb-4" />
              <h4 className="font-semibold mb-2">{t('commercial.property.mixed') || "Mixed-Use Developments"}</h4>
              <p className="text-sm text-estate-600 dark:text-estate-300">
                {t('commercial.property.mixed.text') || "Modern mixed-use properties that combine retail, office, and residential spaces for dynamic business environments."}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommercialExpertise;
