
import { McAllenData } from "./McAllen";
import { BrownsvilleData } from "./Brownsville";
import { SouthPadreIslandData } from "./SouthPadreIsland";
import { MissionData } from "./Mission";
import { EdinburgData } from "./Edinburg";
import { HarlingenData } from "./Harlingen";
import { MercedesData } from "./Mercedes";
import { WeslacoData } from "./Weslaco";
import { CityInfo } from "../types/CommunityTypes";

// Combine all city data into a single object for easy access
export const cityInfo: Record<string, CityInfo> = {
  "McAllen": McAllenData,
  "Brownsville": BrownsvilleData,
  "South Padre Island": SouthPadreIslandData,
  "Mission": MissionData,
  "Edinburg": EdinburgData,
  "Harlingen": HarlingenData,
  "Mercedes": MercedesData,
  "Weslaco": WeslacoData
};

// Export individual city data for direct imports
export {
  McAllenData,
  BrownsvilleData,
  SouthPadreIslandData,
  MissionData,
  EdinburgData,
  HarlingenData,
  MercedesData,
  WeslacoData
};
