
import { supabase } from "@/integrations/supabase/client";

/**
 * Import an existing post from blog_posts to Juan's dedicated table
 */
export const importExistingPost = async (postId: string) => {
  try {
    // First fetch the post from the regular blog_posts table
    const { data, error } = await supabase
      .from('blog_posts')
      .select('*')
      .eq('id', postId)
      .single();
      
    if (error) {
      console.error("Error fetching post for import:", error);
      return { success: false, error: error.message };
    }
    
    if (!data) {
      return { success: false, error: "Post not found" };
    }
    
    // Now insert the post into juans_blogs
    const { data: insertedData, error: insertError } = await supabase
      .from('juans_blogs')
      .insert([{
        title: data.title,
        excerpt: data.excerpt,
        content: data.content,
        image: data.image,
        images: data.images,
        author: data.author,
        category: data.category,
        date: data.date || data.created_at
      }])
      .select()
      .single();
      
    if (insertError) {
      console.error("Error importing post to Juan's blog table:", insertError);
      return { success: false, error: insertError.message };
    }
    
    return { success: true, error: null, post: insertedData };
  } catch (error) {
    console.error("Unexpected error importing post:", error);
    return { 
      success: false, 
      error: error instanceof Error ? error.message : "An unexpected error occurred" 
    };
  }
};

/**
 * Alias to maintain backward compatibility
 */
export const importExistingBlogToJuansBlogs = importExistingPost;
