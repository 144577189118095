
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent } from "@/components/ui/card";
import { BlogPost, BlogImage } from "@/types/blog";
import BlogPostCardImage from "./card/BlogPostCardImage";
import BlogPostCardMeta from "./card/BlogPostCardMeta";
import BlogPostCardExcerpt from "./card/BlogPostCardExcerpt";
import BlogPostCardCategory from "./card/BlogPostCardCategory";
import BlogPostCardActions from "./card/BlogPostCardActions";

interface BlogPostCardProps {
  post: BlogPost;
}

const BlogPostCard = ({ post }: BlogPostCardProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [mainImageUrl, setMainImageUrl] = useState<string>("");
  const navigate = useNavigate();
  
  // Process images on component mount
  useEffect(() => {
    // Log the post data to help with debugging
    console.log(`Processing images for BlogPostCard: "${post.title}"`, {
      hasImages: post.images && post.images.length > 0,
      imageCount: post.images?.length || 0,
      mainImage: post.image || 'none'
    });
    
    // Determine the main image URL with clear priorities
    if (post.images && Array.isArray(post.images) && post.images.length > 0) {
      // First try to get URL from first image in the images array
      const firstImage = post.images[0];
      if (typeof firstImage === 'string') {
        setMainImageUrl(firstImage);
      } else if (firstImage && typeof firstImage === 'object' && firstImage.url) {
        setMainImageUrl(firstImage.url);
      }
    }
    
    // If no image found in the images array, fall back to the image field
    if (!mainImageUrl && post.image) {
      setMainImageUrl(post.image);
    }
    
    // Log the result
    console.log(`Selected main image for "${post.title}":`, mainImageUrl || 'No image found');
  }, [post]);

  const handleReadMore = () => {
    navigate(`/blog/${post.id}`);
  };

  return (
    <Card 
      className="overflow-hidden transition-all duration-300 hover:shadow-lg border border-gray-700 bg-gray-800 text-white h-full"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        boxShadow: isHovered 
          ? '0 0 20px 8px rgba(255, 179, 71, 0.6), 0 0 30px rgba(255, 179, 71, 0.4)' 
          : '0 0 12px 5px rgba(255, 179, 71, 0.35)',
        transform: isHovered ? 'translateY(-3px)' : 'translateY(0)'
      }}
    >
      <div className="relative">
        <BlogPostCardImage 
          postImages={post.images || []}
          mainImage={mainImageUrl || post.image}
          isMcAllenPost={post.title.includes("McAllen")}
          isHovered={isHovered}
          title={post.title}
        />
        
        <BlogPostCardCategory 
          category={post.category}
          isHovered={isHovered}
        />
      </div>
      
      <CardContent className="p-6 flex flex-col h-[calc(100%-200px)]">
        <BlogPostCardMeta 
          date={post.date}
          author={post.author}
        />
        
        <h2 className="text-xl font-bold text-white leading-tight mb-3 transition-all duration-300"
            style={{
              transform: isHovered ? 'translateY(-2px)' : 'translateY(0)',
              color: isHovered ? '#FFFFFF' : undefined,
              textShadow: isHovered ? '0 0 5px rgba(255, 179, 71, 0.4)' : 'none'
            }}>
          {post.title}
        </h2>
        
        <BlogPostCardExcerpt excerpt={post.excerpt} />
        
        <div className="mt-auto">
          <BlogPostCardActions 
            postId={post.id}
            title={post.title}
            isHovered={isHovered}
            onReadMore={handleReadMore}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default BlogPostCard;
