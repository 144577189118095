
import React from "react";

const ContactTestimonials = () => {
  return (
    <section className="py-16 bg-black">
      <div className="container mx-auto px-4 max-w-6xl text-center">
        <h2 className="text-4xl font-display mb-12">What Clients Say</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-estate-900 p-6 rounded-lg border border-estate-700 hover:border-gold-500 transition">
            <div className="flex flex-col h-full">
              <p className="text-estate-300 italic mb-6 flex-grow">
                "Juan helped us find our dream home in McAllen. His knowledge of the market and commitment to our needs made the process smooth and enjoyable."
              </p>
              <div className="mt-auto">
                <h3 className="font-semibold">Maria Rodriguez</h3>
                <p className="text-sm text-estate-400">Homeowner</p>
              </div>
            </div>
          </div>
          <div className="bg-estate-900 p-6 rounded-lg border border-estate-700 hover:border-gold-500 transition">
            <div className="flex flex-col h-full">
              <p className="text-estate-300 italic mb-6 flex-grow">
                "Working with Juan on our commercial property acquisition was a great experience. His professionalism and market insights were invaluable."
              </p>
              <div className="mt-auto">
                <h3 className="font-semibold">John Smith</h3>
                <p className="text-sm text-estate-400">Business Owner</p>
              </div>
            </div>
          </div>
          <div className="bg-estate-900 p-6 rounded-lg border border-estate-700 hover:border-gold-500 transition">
            <div className="flex flex-col h-full">
              <p className="text-estate-300 italic mb-6 flex-grow">
                "Juan's attention to detail and understanding of my investment goals led to finding the perfect property. I highly recommend his services."
              </p>
              <div className="mt-auto">
                <h3 className="font-semibold">Emily Johnson</h3>
                <p className="text-sm text-estate-400">Real Estate Investor</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactTestimonials;
