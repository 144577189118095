
import { useTheme } from "@/context/ThemeContext";

const MexicanIntroduction = () => {
  const { theme } = useTheme();
  
  // Use appropriate background color based on theme
  const bgClass = theme === 'dark' ? "bg-estate-900" : "bg-white";
  const textClass = theme === 'dark' ? "text-white" : "text-estate-900";

  return (
    <section className={`py-16 ${bgClass}`}>
      <div className="container mx-auto px-4 max-w-5xl">
        <div className="text-center mb-10">
          <h2 className="text-3xl font-display mb-6 text-shadow-gold">Bienvenido a los Servicios Inmobiliarios Premier de McAllen para Inversionistas Mexicanos</h2>
          <p className={`text-lg max-w-3xl mx-auto ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
            Como agente inmobiliario especializado en McAllen, TX, ayudo a clientes e inversionistas mexicanos a encontrar 
            la propiedad perfecta, ya sea para doble ciudadanía, crecimiento empresarial u oportunidades de ingresos pasivos. 
            Con mi profundo conocimiento de ambos mercados y consideraciones transfronterizas, hago que su viaje de inversión sea sin problemas.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MexicanIntroduction;
