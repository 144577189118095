import React, { useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import BlogPostContent from "@/components/blog/BlogPostContent";
import BlogPostHeader from "@/components/blog/BlogPostHeader";
import BlogPostSidebar from "@/components/blog/BlogPostSidebar";
import BlogPostLoading from "@/components/blog/BlogPostLoading";
import BlogPostError from "@/components/blog/BlogPostError";
import BlogPostNotFound from "@/components/blog/BlogPostNotFound";
import RelatedBlogPosts from "@/components/blog/RelatedBlogPosts";
import { useFetchBlogPost } from "@/hooks/blog/useFetchBlogPost";
import { BlogPost } from "@/types/blog";
import { toast } from "sonner";
import { useBlogPosts } from "@/hooks/useBlogPosts";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";
import { useImageCarousel } from "@/hooks/blog/useImageCarousel";

const BlogPostPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { post, loading, error } = useFetchBlogPost(id);
  const { blogPosts } = useBlogPosts();
  const contentRef = useRef<HTMLDivElement>(null);
  
  const {
    hasMultipleImages,
    currentImage,
    currentCaption,
    currentIndex,
    totalImages,
    nextImage,
    prevImage
  } = useImageCarousel(post?.images || []);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  
  const handleShare = () => {
    if (navigator.share && post) {
      navigator.share({
        title: post.title,
        text: post.excerpt,
        url: window.location.href,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing:', error));
    } else {
      navigator.clipboard.writeText(window.location.href)
        .then(() => toast.success("Link copied to clipboard"))
        .catch(() => toast.error("Failed to copy link"));
    }
  };

  const handleBackToBlog = () => {
    navigate('/blog');
  };
  
  if (loading) {
    return (
      <div className="min-h-screen flex flex-col">
        <Navbar />
        <main className="flex-grow container mx-auto px-4 py-8">
          <BlogPostLoading />
        </main>
        <Footer />
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="min-h-screen flex flex-col">
        <Navbar />
        <main className="flex-grow container mx-auto px-4 py-8">
          <BlogPostError error={error} onBack={() => navigate('/blog')} />
        </main>
        <Footer />
      </div>
    );
  }
  
  if (!post) {
    return (
      <div className="min-h-screen flex flex-col">
        <Navbar />
        <main className="flex-grow container mx-auto px-4 py-8">
          <BlogPostNotFound onBack={() => navigate('/blog')} />
        </main>
        <Footer />
      </div>
    );
  }
  
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow">
        <div className="container mx-auto px-4 py-8">
          <div className="mb-8">
            <Button 
              variant="outline" 
              onClick={handleBackToBlog}
              className="flex items-center gap-2 bg-gray-800 text-white hover:bg-gray-700"
            >
              <ArrowLeft size={16} />
              Back to Blogs
            </Button>
          </div>
          
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-8" ref={contentRef}>
            <div className="lg:col-span-3">
              <article className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
                <BlogPostHeader 
                  post={post} 
                  onShare={handleShare}
                  hasMultipleImages={hasMultipleImages}
                  currentImage={currentImage}
                  currentCaption={currentCaption}
                  currentImageIndex={currentIndex}
                  totalImages={totalImages}
                  prevImage={prevImage}
                  nextImage={nextImage}
                />
                <BlogPostContent 
                  content={post.content}
                  images={post.images} 
                  postTitle={post.title}
                />
              </article>
              
              <div className="mt-12">
                <RelatedBlogPosts 
                  currentPost={post}
                  allPosts={blogPosts}
                />
              </div>
            </div>
            
            <div className="lg:col-span-1">
              <BlogPostSidebar 
                post={post}
                allPosts={blogPosts}
              />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default BlogPostPage;
