
import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';
import { useLanguage } from '@/context/LanguageContext';
import GlowingText from '@/components/common/GlowingText';

const EstimateForm: React.FC = () => {
  const [address, setAddress] = useState('');
  const { toast } = useToast();
  const { t } = useLanguage();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!address.trim()) {
      toast({
        title: t('estimate.error.title') || "Address Required",
        description: t('estimate.error.description') || "Please enter your property address to get an estimate.",
        variant: "destructive",
      });
      return;
    }
    
    // In the future, this could navigate to a results page or submit to an API
    toast({
      title: t('estimate.success.title') || "Request Received",
      description: t('estimate.success.description') || "We'll process your home value estimate and contact you soon.",
    });
    
    // Clear the form
    setAddress('');
  };

  return (
    <form onSubmit={handleSubmit} className="mt-8">
      <div className="flex flex-col sm:flex-row gap-4 w-full max-w-xl">
        <input
          type="text"
          placeholder="Enter your address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="w-full px-6 py-4 text-lg border rounded-lg shadow-lg"
          aria-label="Property address"
        />
        <Button 
          type="submit"
          className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-4 h-auto text-lg font-medium rounded-lg"
        >
          Get estimate
        </Button>
      </div>
      
      {/* GlowingText component with advanced gold glowing effect */}
      <div className="mt-4">
        <GlowingText 
          text="Get a real-world home value estimate in less than 2 minutes"
          as="p"
          className="text-lg"
          glowColor="gold"
          advanced={true}
        />
      </div>
    </form>
  );
};

export default EstimateForm;
