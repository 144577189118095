
import { useState, useCallback, useEffect } from "react";
import { BlogPost } from "@/types/blog";
import { allBlogCategories } from "@/components/navbar/BlogCategoriesData";

export const useBlogListingState = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [visiblePosts, setVisiblePosts] = useState(8);
  const [activeCategory, setActiveCategory] = useState("All");

  // Listen for the clearFilters event
  useEffect(() => {
    const handleClearFilters = () => {
      console.log("Clearing all filters");
      setSearchQuery("");
      setActiveCategory("All");
    };

    window.addEventListener('clearFilters', handleClearFilters);
    return () => window.removeEventListener('clearFilters', handleClearFilters);
  }, []);
  
  const loadMorePosts = () => {
    setVisiblePosts(prev => prev + 4);
  };

  // Filter and process blog posts
  const processPosts = useCallback((allPosts: BlogPost[]) => {
    // Filter posts based on search query and category
    const filteredPosts = allPosts.filter(post => {
      const matchesSearch = post.title.toLowerCase().includes(searchQuery.toLowerCase()) || 
                           post.excerpt.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesCategory = activeCategory === "All" || post.category === activeCategory;
      return matchesSearch && matchesCategory;
    });
    
    // Sort posts by date (newest first)
    filteredPosts.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    
    // Posts to display based on the current page
    const displayedPosts = filteredPosts.slice(0, visiblePosts);
    
    return {
      categories: allBlogCategories,
      filteredPosts,
      displayedPosts
    };
  }, [activeCategory, searchQuery, visiblePosts]);

  return {
    searchQuery,
    setSearchQuery,
    visiblePosts,
    activeCategory,
    setActiveCategory,
    loadMorePosts,
    processPosts
  };
};
