
import { Building, Flag, MapPin } from "lucide-react";
import { useLanguage } from "@/context/LanguageContext";

const MexicanBenefits = () => {
  const { language } = useLanguage();
  
  return (
    <section className="py-16 bg-black">
      <div className="container mx-auto px-4 max-w-5xl">
        <h2 className="text-3xl font-display text-center mb-12 text-shadow-gold">Beneficios de Invertir en Bienes Raíces en Estados Unidos</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Benefit 1 */}
          <div className="bg-gray-900 p-8 rounded-lg shadow-md border border-gray-800">
            <div className="flex items-center mb-4">
              <Building className="w-8 h-8 text-[#d4af37] mr-3" />
              <h3 className="text-xl font-semibold">Mercado Estable y Crecimiento</h3>
            </div>
            <p className="text-gray-300">
              El mercado inmobiliario de McAllen ofrece estabilidad y crecimiento predecible en comparación con otras opciones de inversión, con menos volatilidad y potencial para apreciación a largo plazo.
            </p>
          </div>
          
          {/* Benefit 2 */}
          <div className="bg-gray-900 p-8 rounded-lg shadow-md border border-gray-800">
            <div className="flex items-center mb-4">
              <Flag className="w-8 h-8 text-[#d4af37] mr-3" />
              <h3 className="text-xl font-semibold">Diversifique Su Portafolio</h3>
            </div>
            <p className="text-gray-300">
              Distribuya el riesgo invirtiendo en diferentes entornos económicos, brindando protección contra fluctuaciones del mercado en su país de origen.
            </p>
          </div>
          
          {/* Benefit 3 */}
          <div className="bg-gray-900 p-8 rounded-lg shadow-md border border-gray-800">
            <div className="flex items-center mb-4">
              <MapPin className="w-8 h-8 text-[#d4af37] mr-3" />
              <h3 className="text-xl font-semibold">Ubicación Estratégica</h3>
            </div>
            <p className="text-gray-300">
              La proximidad de McAllen a la frontera lo convierte en un lugar ideal para inversionistas mexicanos, ofreciendo conveniencia y accesibilidad para operaciones comerciales y viajes.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MexicanBenefits;
