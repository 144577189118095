
import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent } from "@/components/ui/card";
import { ArrowRight } from "lucide-react";
import { BlogPost } from "@/types/blog";
import { useBlogPosts } from "@/hooks/useBlogPosts";

interface RelatedBlogPostsProps {
  currentPostId?: string | number;
  currentPost?: BlogPost;
  category?: string;
  allPosts?: BlogPost[];
  isJuansBlog?: boolean;
}

const RelatedBlogPosts: React.FC<RelatedBlogPostsProps> = ({ 
  currentPostId, 
  currentPost, 
  category, 
  allPosts,
  isJuansBlog = false
}) => {
  const navigate = useNavigate();
  const { blogPosts: fallbackPosts } = useBlogPosts();
  
  // Use provided ID or extract from currentPost
  const postId = currentPostId || (currentPost ? currentPost.id : null);
  
  // Use provided category or extract from currentPost
  const postCategory = category || (currentPost ? currentPost.category : '');
  
  // Use provided posts or fallback to all blog posts
  const postsToUse = allPosts || fallbackPosts;
  
  // Filter posts to show only those in the same category, excluding the current post
  const relatedPosts = postsToUse
    .filter(post => 
      post.category === postCategory && String(post.id) !== String(postId)
    )
    .slice(0, 3); // Limit to 3 related posts
  
  if (relatedPosts.length === 0) {
    return null;
  }
  
  return (
    <div className="border border-gray-200 rounded-lg p-4 bg-white dark:bg-gray-800 lg:sticky lg:top-[100px]">
      <h3 className="text-lg font-bold mb-4 text-gray-800 dark:text-gray-200">Related Articles</h3>
      
      <div className="space-y-3">
        {relatedPosts.map((post) => (
          <Card 
            key={post.id} 
            className="cursor-pointer hover:shadow-md transition-shadow border-gray-100 dark:border-gray-700 dark:bg-gray-700"
            onClick={() => {
              // Navigate to appropriate route based on post type
              const route = post.isJuansBlog ? `/blog/juan/${post.id}` : `/blog/${post.id}`;
              navigate(route);
            }}
          >
            <CardContent className="p-3">
              <div className="flex items-start space-x-3">
                <div className="w-16 h-16 flex-shrink-0 rounded overflow-hidden">
                  <img 
                    src={post.image} 
                    alt={post.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="flex-1 min-w-0">
                  <h4 className="text-sm font-medium line-clamp-2 mb-1 text-gray-800 dark:text-gray-200">
                    {post.title}
                  </h4>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {post.date}
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
      
      {relatedPosts.length > 0 && (
        <div 
          className="mt-3 text-sm text-[#ffb347] font-medium flex items-center cursor-pointer hover:text-[#f5a030]"
          onClick={() => navigate(`/blog?category=${encodeURIComponent(postCategory)}`)}
        >
          View all {postCategory} posts
          <ArrowRight className="ml-1 h-4 w-4" />
        </div>
      )}
    </div>
  );
};

export default RelatedBlogPosts;
