
import React, { useState, useEffect } from 'react';
import { useSEO } from '@/context/SEOContext';
import PurchaseInfoSection from './PurchaseInfoSection';
import MortgagePaymentSection from './MortgagePaymentSection';
import CashFlowSection from './CashFlowSection';
import ResultsSection from './ResultsSection';
import CalculatorHeader from './CalculatorHeader';
import CalculatorNotes from './CalculatorNotes';

const RentalCalculatorContainer: React.FC = () => {
  const { updateSEO } = useSEO();
  
  useEffect(() => {
    // Update SEO metadata for this page
    updateSEO({
      title: 'Rental Property Calculator | Juan Elizondo RE/MAX',
      description: 'Calculate your potential profits from rental properties in McAllen, TX with Juan Elizondo\'s Rental Property Calculator.',
      keywords: ['rental property', 'investment calculator', 'rental income', 'McAllen real estate', 'rental calculator'],
    });
    
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  // Purchase Info state
  const [purchasePrice, setPurchasePrice] = useState<number>(179900);
  const [downPaymentPercent, setDownPaymentPercent] = useState<number>(20);
  const [downPaymentAmount, setDownPaymentAmount] = useState<number>(35980);
  const [closingCostPercent, setClosingCostPercent] = useState<number>(4);
  const [closingCostAmount, setClosingCostAmount] = useState<number>(7196);
  const [repairs, setRepairs] = useState<number>(5000);
  const [firstMortgage, setFirstMortgage] = useState<number>(143920);
  const [totalCapitalInvestment, setTotalCapitalInvestment] = useState<number>(48176);

  // Mortgage Payment state
  const [mortgagePaymentMonthly, setMortgagePaymentMonthly] = useState<number>(0);
  const [mortgagePaymentYearly, setMortgagePaymentYearly] = useState<number>(0);
  const [interestRate, setInterestRate] = useState<number>(5.5);
  const [loanTerm, setLoanTerm] = useState<number>(30);
  const [isCalculatingPayment, setIsCalculatingPayment] = useState<boolean>(false);

  // Cash Flow state
  const [rentalIncome, setRentalIncome] = useState<number>(2550);
  const [rentalIncomeYearly, setRentalIncomeYearly] = useState<number>(30600);
  const [vacancyPercent, setVacancyPercent] = useState<number>(0);
  const [vacancyAmount, setVacancyAmount] = useState<number>(0);
  const [propertyTaxesMonthly, setPropertyTaxesMonthly] = useState<number>(338.42);
  const [propertyTaxesYearly, setPropertyTaxesYearly] = useState<number>(4061);
  const [landRent, setLandRent] = useState<number>(200);
  const [landRentYearly, setLandRentYearly] = useState<number>(2400);
  const [insurance, setInsurance] = useState<number>(125);
  const [insuranceYearly, setInsuranceYearly] = useState<number>(1500);
  const [propertyManagementPercent, setPropertyManagementPercent] = useState<number>(8);
  const [propertyManagementAmount, setPropertyManagementAmount] = useState<number>(2448);
  const [totalAnnualExpenses, setTotalAnnualExpenses] = useState<number>(10409);

  // Results state
  const [netIncomeMonthly, setNetIncomeMonthly] = useState<number>(1682.58);
  const [netIncomeYearly, setNetIncomeYearly] = useState<number>(20191);
  const [returnOnInvestment, setReturnOnInvestment] = useState<number>(42);
  const [capRate, setCapRate] = useState<number>(11.22);

  // Calculate down payment amount when purchase price or percentage changes
  useEffect(() => {
    const calculatedDownPayment = Math.round(purchasePrice * (downPaymentPercent / 100));
    setDownPaymentAmount(calculatedDownPayment);
  }, [purchasePrice, downPaymentPercent]);

  // Calculate closing costs when purchase price or percentage changes
  useEffect(() => {
    const calculatedClosingCost = Math.round(purchasePrice * (closingCostPercent / 100));
    setClosingCostAmount(calculatedClosingCost);
  }, [purchasePrice, closingCostPercent]);

  // Calculate first mortgage amount
  useEffect(() => {
    const calculatedFirstMortgage = purchasePrice - downPaymentAmount;
    setFirstMortgage(calculatedFirstMortgage);
  }, [purchasePrice, downPaymentAmount]);

  // Calculate total capital investment
  useEffect(() => {
    const calculatedTotalCapitalInvestment = downPaymentAmount + closingCostAmount + repairs;
    setTotalCapitalInvestment(calculatedTotalCapitalInvestment);
  }, [downPaymentAmount, closingCostAmount, repairs]);

  // Calculate yearly rental income
  useEffect(() => {
    const calculatedYearlyRental = rentalIncome * 12;
    setRentalIncomeYearly(calculatedYearlyRental);
  }, [rentalIncome]);

  // Calculate vacancy amount
  useEffect(() => {
    const calculatedVacancyAmount = Math.round(rentalIncomeYearly * (vacancyPercent / 100));
    setVacancyAmount(calculatedVacancyAmount);
  }, [rentalIncomeYearly, vacancyPercent]);

  // Calculate yearly property taxes
  useEffect(() => {
    const calculatedYearlyPropertyTaxes = Math.round(propertyTaxesMonthly * 12);
    setPropertyTaxesYearly(calculatedYearlyPropertyTaxes);
  }, [propertyTaxesMonthly]);

  // Calculate yearly land rent
  useEffect(() => {
    const calculatedYearlyLandRent = landRent * 12;
    setLandRentYearly(calculatedYearlyLandRent);
  }, [landRent]);

  // Calculate yearly insurance
  useEffect(() => {
    const calculatedYearlyInsurance = insurance * 12;
    setInsuranceYearly(calculatedYearlyInsurance);
  }, [insurance]);

  // Calculate property management amount
  useEffect(() => {
    const calculatedPMAmount = Math.round(rentalIncomeYearly * (propertyManagementPercent / 100));
    setPropertyManagementAmount(calculatedPMAmount);
  }, [rentalIncomeYearly, propertyManagementPercent]);

  // Calculate total annual expenses
  useEffect(() => {
    const calculatedTotalExpenses = propertyTaxesYearly + landRentYearly + insuranceYearly + propertyManagementAmount + mortgagePaymentYearly + vacancyAmount;
    setTotalAnnualExpenses(calculatedTotalExpenses);
  }, [propertyTaxesYearly, landRentYearly, insuranceYearly, propertyManagementAmount, mortgagePaymentYearly, vacancyAmount]);

  // Calculate net income
  useEffect(() => {
    const calculatedYearlyNetIncome = rentalIncomeYearly - totalAnnualExpenses;
    setNetIncomeYearly(calculatedYearlyNetIncome);
    
    const calculatedMonthlyNetIncome = calculatedYearlyNetIncome / 12;
    setNetIncomeMonthly(calculatedMonthlyNetIncome);
  }, [rentalIncomeYearly, totalAnnualExpenses]);

  // Calculate ROI and CAP Rate
  useEffect(() => {
    if (totalCapitalInvestment > 0) {
      const calculatedROI = (netIncomeYearly / totalCapitalInvestment) * 100;
      setReturnOnInvestment(Math.round(calculatedROI * 10) / 10);
    } else {
      setReturnOnInvestment(0);
    }
    
    if (purchasePrice > 0) {
      const calculatedCapRate = (netIncomeYearly / purchasePrice) * 100;
      setCapRate(Math.round(calculatedCapRate * 100) / 100);
    } else {
      setCapRate(0);
    }
  }, [netIncomeYearly, totalCapitalInvestment, purchasePrice]);

  // Format currency
  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    }).format(value);
  };

  // Format percentage
  const formatPercent = (value: number): string => {
    return `${value}%`;
  };

  // Handle input changes with formatting
  const handleCurrencyInputChange = (value: string, setter: React.Dispatch<React.SetStateAction<number>>) => {
    const cleanValue = value.replace(/[^0-9.]/g, '');
    setter(parseFloat(cleanValue) || 0);
  };

  // Handle percentage input changes
  const handlePercentInputChange = (value: string, setter: React.Dispatch<React.SetStateAction<number>>) => {
    const cleanValue = value.replace(/[^0-9.]/g, '');
    setter(parseFloat(cleanValue) || 0);
  };

  const resetForm = () => {
    // Reset to default values
    setPurchasePrice(179900);
    setDownPaymentPercent(20);
    setClosingCostPercent(4);
    setRepairs(5000);
    setInterestRate(5.5);
    setLoanTerm(30);
    setRentalIncome(2550);
    setVacancyPercent(0);
    setPropertyTaxesMonthly(338.42);
    setLandRent(200);
    setInsurance(125);
    setPropertyManagementPercent(8);
  };

  const cardClass = "bg-estate-900/70 p-6 rounded-2xl shadow-lg border border-gold-500/30 transition-all duration-300 hover:shadow-gold-500/20 hover:shadow-xl hover:bg-estate-900/90";

  return (
    <div className="container mx-auto px-4">
      <CalculatorHeader title="Juan's Rental Property Calculator" />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        {/* Card 1: Purchase Info */}
        <div className={cardClass}>
          <PurchaseInfoSection
            purchasePrice={purchasePrice}
            setPurchasePrice={setPurchasePrice}
            downPaymentPercent={downPaymentPercent}
            setDownPaymentPercent={setDownPaymentPercent}
            downPaymentAmount={downPaymentAmount}
            closingCostPercent={closingCostPercent}
            setClosingCostPercent={setClosingCostPercent}
            closingCostAmount={closingCostAmount}
            repairs={repairs}
            setRepairs={setRepairs}
            firstMortgage={firstMortgage}
            totalCapitalInvestment={totalCapitalInvestment}
            formatCurrency={formatCurrency}
            formatPercent={formatPercent}
            handleCurrencyInputChange={handleCurrencyInputChange}
            handlePercentInputChange={handlePercentInputChange}
          />
        </div>
        
        {/* Card 2: Mortgage Payment */}
        <div className={cardClass}>
          <MortgagePaymentSection
            mortgagePaymentMonthly={mortgagePaymentMonthly}
            mortgagePaymentYearly={mortgagePaymentYearly}
            interestRate={interestRate}
            setInterestRate={setInterestRate}
            loanTerm={loanTerm}
            setLoanTerm={setLoanTerm}
            firstMortgage={firstMortgage}
            isCalculatingPayment={isCalculatingPayment}
            formatCurrency={formatCurrency}
            formatPercent={formatPercent}
            handleCurrencyInputChange={handleCurrencyInputChange}
            handlePercentInputChange={handlePercentInputChange}
          />
        </div>
        
        {/* Card 3: Cash Flow */}
        <div className={cardClass}>
          <CashFlowSection
            rentalIncome={rentalIncome}
            setRentalIncome={setRentalIncome}
            rentalIncomeYearly={rentalIncomeYearly}
            vacancyPercent={vacancyPercent}
            setVacancyPercent={setVacancyPercent}
            vacancyAmount={vacancyAmount}
            propertyTaxesMonthly={propertyTaxesMonthly}
            setPropertyTaxesMonthly={setPropertyTaxesMonthly}
            propertyTaxesYearly={propertyTaxesYearly}
            landRent={landRent}
            setLandRent={setLandRent}
            landRentYearly={landRentYearly}
            insurance={insurance}
            setInsurance={setInsurance}
            insuranceYearly={insuranceYearly}
            propertyManagementPercent={propertyManagementPercent}
            setPropertyManagementPercent={setPropertyManagementPercent}
            propertyManagementAmount={propertyManagementAmount}
            totalAnnualExpenses={totalAnnualExpenses}
            formatCurrency={formatCurrency}
            formatPercent={formatPercent}
            handleCurrencyInputChange={handleCurrencyInputChange}
            handlePercentInputChange={handlePercentInputChange}
          />
        </div>
        
        {/* Card 4: Results */}
        <div className={cardClass}>
          <ResultsSection
            netIncomeMonthly={netIncomeMonthly}
            netIncomeYearly={netIncomeYearly}
            returnOnInvestment={returnOnInvestment}
            capRate={capRate}
            totalCapitalInvestment={totalCapitalInvestment}
            resetForm={resetForm}
            formatCurrency={formatCurrency}
            formatPercent={formatPercent}
          />
        </div>
      </div>

      <CalculatorNotes />
    </div>
  );
};

export default RentalCalculatorContainer;
