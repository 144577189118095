
import React, { useState, useEffect } from 'react';
import { Calculator } from 'lucide-react';
import { Card } from '@/components/ui/card';
import { toast } from 'sonner';
import CalculatorHeader from './CalculatorHeader';
import InputSection from './InputSection';
import ResultsSection from './ResultsSection';
import CalculatorButtons from './CalculatorButtons';

const HouseHackingCalculator: React.FC = () => {
  // Purchase inputs
  const [purchasePrice, setPurchasePrice] = useState<number>(300000);
  const [downPayment, setDownPayment] = useState<number>(60000);
  const [interestRate, setInterestRate] = useState<number>(4.5);
  const [loanTerm, setLoanTerm] = useState<number>(30);
  
  // Monthly expenses
  const [propertyTax, setPropertyTax] = useState<number>(3000);
  const [insurance, setInsurance] = useState<number>(1200);
  const [hoaFees, setHoaFees] = useState<number>(200);
  const [additionalExpenses, setAdditionalExpenses] = useState<number>(300);
  
  // Rental details
  const [rentalIncomePerUnit, setRentalIncomePerUnit] = useState<number>(1500);
  const [occupiedUnits, setOccupiedUnits] = useState<number>(1);
  const [totalUnits, setTotalUnits] = useState<number>(2);
  
  // Calculated results
  const [monthlyMortgagePayment, setMonthlyMortgagePayment] = useState<number>(0);
  const [monthlyHousingCosts, setMonthlyHousingCosts] = useState<number>(0);
  const [monthlyRentalIncome, setMonthlyRentalIncome] = useState<number>(0);
  const [netMonthlyCashFlow, setNetMonthlyCashFlow] = useState<number>(0);
  const [annualROI, setAnnualROI] = useState<number>(0);
  
  // Calculate mortgage payment
  useEffect(() => {
    const calculateMortgage = () => {
      const principal = purchasePrice - downPayment;
      const monthlyRate = interestRate / 100 / 12;
      const payments = loanTerm * 12;
      
      if (monthlyRate === 0) {
        return principal / payments;
      }
      
      const mortgage = principal * (monthlyRate * Math.pow(1 + monthlyRate, payments)) / 
                      (Math.pow(1 + monthlyRate, payments) - 1);
      
      return isFinite(mortgage) ? mortgage : 0;
    };
    
    setMonthlyMortgagePayment(calculateMortgage());
  }, [purchasePrice, downPayment, interestRate, loanTerm]);
  
  // Calculate all results
  useEffect(() => {
    // Monthly housing costs
    const monthlyPropertyTax = propertyTax / 12;
    const monthlyInsurance = insurance / 12;
    const totalMonthlyHousingCosts = monthlyMortgagePayment + monthlyPropertyTax + 
                                    monthlyInsurance + hoaFees + additionalExpenses;
    
    // Monthly rental income
    const totalMonthlyRentalIncome = rentalIncomePerUnit * occupiedUnits;
    
    // Net monthly cash flow
    const netCashFlow = totalMonthlyRentalIncome - totalMonthlyHousingCosts;
    
    // Annual ROI calculation
    const annualNetIncome = netCashFlow * 12;
    const annualROIValue = downPayment > 0 ? (annualNetIncome / downPayment) * 100 : 0;
    
    setMonthlyHousingCosts(totalMonthlyHousingCosts);
    setMonthlyRentalIncome(totalMonthlyRentalIncome);
    setNetMonthlyCashFlow(netCashFlow);
    setAnnualROI(annualROIValue);
  }, [
    monthlyMortgagePayment,
    propertyTax,
    insurance,
    hoaFees,
    additionalExpenses,
    rentalIncomePerUnit,
    occupiedUnits,
    downPayment
  ]);
  
  const handleReset = () => {
    setPurchasePrice(300000);
    setDownPayment(60000);
    setInterestRate(4.5);
    setLoanTerm(30);
    setPropertyTax(3000);
    setInsurance(1200);
    setHoaFees(200);
    setAdditionalExpenses(300);
    setRentalIncomePerUnit(1500);
    setOccupiedUnits(1);
    setTotalUnits(2);
  };
  
  const handleExportPDF = () => {
    // PDF export functionality would be implemented here
    console.log("Exporting PDF...");
    // For now, just show a toast notification
    toast("PDF Generation coming soon!");
  };
  
  return (
    <div className="container mx-auto px-4 py-8">
      <CalculatorHeader />
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-12">
        {/* Input Section */}
        <Card className="bg-estate-900/70 border-gold-500/30 shadow-lg p-6 rounded-xl">
          <InputSection 
            purchasePrice={purchasePrice}
            setPurchasePrice={setPurchasePrice}
            downPayment={downPayment}
            setDownPayment={setDownPayment}
            interestRate={interestRate}
            setInterestRate={setInterestRate}
            loanTerm={loanTerm}
            setLoanTerm={setLoanTerm}
            propertyTax={propertyTax}
            setPropertyTax={setPropertyTax}
            insurance={insurance}
            setInsurance={setInsurance}
            hoaFees={hoaFees}
            setHoaFees={setHoaFees}
            additionalExpenses={additionalExpenses}
            setAdditionalExpenses={setAdditionalExpenses}
            rentalIncomePerUnit={rentalIncomePerUnit}
            setRentalIncomePerUnit={setRentalIncomePerUnit}
            occupiedUnits={occupiedUnits}
            setOccupiedUnits={setOccupiedUnits}
            totalUnits={totalUnits}
            setTotalUnits={setTotalUnits}
          />
        </Card>
        
        {/* Results Section */}
        <Card className="bg-estate-900/70 border-gold-500/30 shadow-lg p-6 rounded-xl">
          <ResultsSection 
            monthlyHousingCosts={monthlyHousingCosts}
            monthlyRentalIncome={monthlyRentalIncome}
            netMonthlyCashFlow={netMonthlyCashFlow}
            annualROI={annualROI}
          />
          
          <CalculatorButtons 
            onReset={handleReset}
            onExportPDF={handleExportPDF}
          />
        </Card>
      </div>
    </div>
  );
};

export default HouseHackingCalculator;
