
import { useState, useEffect } from "react";
import { useToast } from "@/hooks/use-toast";
import { Location } from "react-router-dom";
import { useMarketData } from "@/hooks/useMarketData";
import PropertyDetailsDialog, { PropertyDetailsProps } from "./PropertyDetailsDialog";
import SearchForm from "./SearchForm";
import SearchCriteria from "./SearchCriteria";
import MarketSnapshot from "./MarketSnapshot";
import PropertyGrid from "./PropertyGrid";
import { generateProperties } from "./utils/PropertyDataGenerator";

interface BuySearchFormProps {
  routerLocation?: Location;
}

const BuySearchForm = ({ routerLocation }: BuySearchFormProps) => {
  const { toast } = useToast();
  const [propertyType, setPropertyType] = useState("");
  const [location, setLocation] = useState("");
  const [priceRange, setPriceRange] = useState([200000]);
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [properties, setProperties] = useState<PropertyDetailsProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<PropertyDetailsProps | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  
  // Get search params from location state
  const searchParams = routerLocation?.state;
  
  // Fetch market data if location is available
  const { marketData } = useMarketData(location || searchParams?.searchQuery);
  
  // Effect to handle incoming search parameters
  useEffect(() => {
    if (searchParams?.showSearch) {
      if (searchParams?.searchQuery) {
        setLocation(searchParams.searchQuery);
      }
      
      // Automatically initiate search if coming from a search link
      if (searchParams?.initiateSearch) {
        setTimeout(() => {
          setSearchInitiated(true);
          fetchProperties();
          
          toast({
            title: "Property search initiated!",
            description: `Showing ${propertyType || "all"} properties in ${searchParams.searchQuery || location || "all areas"} up to $${priceRange[0].toLocaleString()}`,
          });
        }, 300);
      }
    }
  }, [searchParams, propertyType, location, priceRange, toast]);
  
  const fetchProperties = () => {
    setIsLoading(true);
    
    // Generate 6 properties with our utility
    const newProperties = generateProperties(6, propertyType, location || searchParams?.searchQuery, priceRange);
    
    setTimeout(() => {
      setProperties(newProperties);
      setIsLoading(false);
    }, 800); // Simulate loading time
  };
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSearchInitiated(true);
    fetchProperties();
    
    toast({
      title: "Property search initiated!",
      description: `Showing ${propertyType || "all"} properties in ${location || "all areas"} up to $${priceRange[0].toLocaleString()}`,
    });
  };

  const handleViewDetails = (property: PropertyDetailsProps) => {
    setSelectedProperty(property);
    setDialogOpen(true);
  };

  return (
    <div className="bg-black dark:bg-gray-900 p-8 rounded-xl shadow-lg max-w-4xl mx-auto text-white">
      <SearchForm
        propertyType={propertyType}
        setPropertyType={setPropertyType}
        location={location}
        setLocation={setLocation}
        priceRange={priceRange}
        setPriceRange={setPriceRange}
        onSubmit={handleSubmit}
      />
      
      {/* Display search results if search is initiated */}
      {searchInitiated && (
        <div className="mt-12">
          <h3 className="text-2xl font-display font-bold mb-6 text-white dark:text-white">Search Results</h3>
          
          {/* Market data if available */}
          <MarketSnapshot marketData={marketData} />
          
          <SearchCriteria
            propertyType={propertyType}
            location={location}
            priceRange={priceRange}
          />
          
          <PropertyGrid
            properties={properties}
            isLoading={isLoading}
            onViewDetails={handleViewDetails}
          />
        </div>
      )}
      
      {/* Property Details Dialog */}
      <PropertyDetailsDialog
        open={dialogOpen}
        onOpenChange={setDialogOpen}
        property={selectedProperty}
      />
    </div>
  );
};

export default BuySearchForm;
