
import React, { useEffect, useRef } from 'react';

const ChatBot: React.FC = () => {
  const scriptLoaded = useRef(false);
  const chatInitialized = useRef(false);

  useEffect(() => {
    // Only load the script once and track initialization
    if (!scriptLoaded.current) {
      console.log("Loading ElevenLabs Convai script");
      
      const script = document.createElement('script');
      script.id = 'elevenlabs-convai-script';
      script.src = 'https://elevenlabs.io/convai-widget/index.js';
      script.async = true;
      script.type = 'text/javascript';
      
      // Track when script loads
      script.onload = () => {
        console.log("ElevenLabs Convai script loaded successfully");
        
        // Now that script is loaded, we can safely add the widget element
        if (!document.querySelector('elevenlabs-convai')) {
          console.log("Adding ElevenLabs Convai element to page");
          const convaiElement = document.createElement('elevenlabs-convai');
          convaiElement.setAttribute('agent-id', '0tmJVDIYEtsT1MzC0zid');
          document.body.appendChild(convaiElement);
          chatInitialized.current = true;
        }
      };
      
      document.body.appendChild(script);
      scriptLoaded.current = true;
    }
    
    return () => {
      // Don't remove on component unmount as we want persistence across page navigation
      // The cleanup will only happen when the app completely unmounts
    };
  }, []);

  // ChatBot doesn't render anything directly - the script adds the widget to the DOM
  return null;
};

export default ChatBot;
