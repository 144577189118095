
import { useState } from "react";
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Percent, RefreshCw } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { type InterestRateData } from "./interestRateUtils";

interface InterestRateDisplayProps {
  rates: InterestRateData[];
  loading: boolean;
  onRateSelected: (rate: InterestRateData) => void;
  selectedRate: InterestRateData | null;
}

const InterestRateDisplay = ({
  rates,
  loading,
  onRateSelected,
  selectedRate,
}: InterestRateDisplayProps) => {
  const { toast } = useToast();

  const handleApplyRate = (rateInfo: InterestRateData) => {
    onRateSelected(rateInfo);
    toast({
      title: "Rate Applied",
      description: `Applied ${rateInfo.rate}% for ${rateInfo.term} years (${rateInfo.type})`,
    });
  };

  return (
    <Card className="mb-8 shadow-lg">
      <CardHeader>
        <CardTitle className="flex items-center">
          <Percent className="mr-2 h-5 w-5 text-[#ffb347]" /> 
          Current Mortgage Rates
        </CardTitle>
        <CardDescription>
          Market rates as of {!loading && rates.length > 0 ? rates[0].lastUpdated : 'today'}
        </CardDescription>
      </CardHeader>
      <CardContent>
        {loading ? (
          <div className="flex items-center justify-center p-4">
            <RefreshCw className="h-5 w-5 mr-2 animate-spin" />
            <span>Loading current rates...</span>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {rates.map((rate, index) => (
              <Card key={index} className={`hover:shadow-md transition duration-300 ${selectedRate?.term === rate.term && selectedRate?.rate === rate.rate ? 'border-[#ffb347] border-2' : ''}`}>
                <CardContent className="p-4">
                  <div className="text-lg font-bold">{rate.rate}%</div>
                  <div className="text-sm text-gray-500">{rate.term} Year {rate.type}</div>
                  <Button 
                    variant="outline" 
                    size="sm" 
                    className="mt-2 w-full"
                    onClick={() => handleApplyRate(rate)}
                  >
                    Apply Rate
                  </Button>
                </CardContent>
              </Card>
            ))}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default InterestRateDisplay;
