
import React from 'react';
import { Input } from '@/components/ui/input';
import { CashFlowSectionProps } from './types';

const CashFlowSection: React.FC<CashFlowSectionProps> = ({
  rentalIncome,
  setRentalIncome,
  rentalIncomeYearly,
  vacancyPercent,
  setVacancyPercent,
  vacancyAmount,
  propertyTaxesMonthly,
  setPropertyTaxesMonthly,
  propertyTaxesYearly,
  landRent,
  setLandRent,
  landRentYearly,
  insurance,
  setInsurance,
  insuranceYearly,
  propertyManagementPercent,
  setPropertyManagementPercent,
  propertyManagementAmount,
  totalAnnualExpenses,
  formatCurrency,
  handleCurrencyInputChange,
  handlePercentInputChange
}) => {
  return (
    <div className="transition-transform hover:transform hover:scale-[1.02] hover:z-10">
      <h2 className="text-2xl font-bold text-gold-500 mb-4">Cash Flow</h2>
      
      <div className="grid grid-cols-2 gap-x-4">
        <div className="text-center mb-3">
          <h3 className="text-gold-400">Monthly</h3>
        </div>
        <div className="text-center mb-3">
          <h3 className="text-gold-400">Yearly</h3>
        </div>
      </div>
      
      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Rental Income</label>
        <div className="grid grid-cols-2 gap-x-4">
          <div className="flex items-center">
            <span className="text-gold-500 mr-2">$</span>
            <Input
              type="text"
              value={rentalIncome.toLocaleString()}
              onChange={(e) => handleCurrencyInputChange(e.target.value, setRentalIncome)}
              className="flex-1 text-white bg-estate-800 border-estate-700" 
            />
          </div>
          <div className="flex items-center">
            <span className="text-gold-500 mr-2">$</span>
            <Input
              type="text"
              value={rentalIncomeYearly.toLocaleString()}
              readOnly
              className="flex-1 text-white bg-estate-900 border-estate-700" 
            />
          </div>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Vacancy</label>
        <div className="grid grid-cols-2 gap-x-4">
          <div className="flex items-center">
            <Input
              type="text"
              value={vacancyPercent}
              onChange={(e) => handlePercentInputChange(e.target.value, setVacancyPercent)}
              className="flex-1 text-white bg-estate-800 border-estate-700" 
            />
            <span className="ml-2 text-gold-500">%</span>
          </div>
          <div className="flex items-center">
            <span className="text-gold-500 mr-2">$</span>
            <Input
              type="text"
              value={vacancyAmount.toLocaleString()}
              readOnly
              className="flex-1 text-white bg-estate-900 border-estate-700" 
            />
          </div>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Property Taxes</label>
        <div className="grid grid-cols-2 gap-x-4">
          <div className="flex items-center">
            <span className="text-gold-500 mr-2">$</span>
            <Input
              type="text"
              value={propertyTaxesMonthly.toLocaleString()}
              onChange={(e) => handleCurrencyInputChange(e.target.value, setPropertyTaxesMonthly)}
              className="flex-1 text-white bg-estate-800 border-estate-700" 
            />
          </div>
          <div className="flex items-center">
            <span className="text-gold-500 mr-2">$</span>
            <Input
              type="text"
              value={propertyTaxesYearly.toLocaleString()}
              readOnly
              className="flex-1 text-white bg-estate-900 border-estate-700" 
            />
          </div>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Land Rent</label>
        <div className="grid grid-cols-2 gap-x-4">
          <div className="flex items-center">
            <span className="text-gold-500 mr-2">$</span>
            <Input
              type="text"
              value={landRent.toLocaleString()}
              onChange={(e) => handleCurrencyInputChange(e.target.value, setLandRent)}
              className="flex-1 text-white bg-estate-800 border-estate-700" 
            />
          </div>
          <div className="flex items-center">
            <span className="text-gold-500 mr-2">$</span>
            <Input
              type="text"
              value={landRentYearly.toLocaleString()}
              readOnly
              className="flex-1 text-white bg-estate-900 border-estate-700" 
            />
          </div>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Insurance</label>
        <div className="grid grid-cols-2 gap-x-4">
          <div className="flex items-center">
            <span className="text-gold-500 mr-2">$</span>
            <Input
              type="text"
              value={insurance.toLocaleString()}
              onChange={(e) => handleCurrencyInputChange(e.target.value, setInsurance)}
              className="flex-1 text-white bg-estate-800 border-estate-700" 
            />
          </div>
          <div className="flex items-center">
            <span className="text-gold-500 mr-2">$</span>
            <Input
              type="text"
              value={insuranceYearly.toLocaleString()}
              readOnly
              className="flex-1 text-white bg-estate-900 border-estate-700" 
            />
          </div>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-1">Property Management</label>
        <div className="grid grid-cols-2 gap-x-4">
          <div className="flex items-center">
            <Input
              type="text"
              value={propertyManagementPercent}
              onChange={(e) => handlePercentInputChange(e.target.value, setPropertyManagementPercent)}
              className="flex-1 text-white bg-estate-800 border-estate-700" 
            />
            <span className="ml-2 text-gold-500">%</span>
          </div>
          <div className="flex items-center">
            <span className="text-gold-500 mr-2">$</span>
            <Input
              type="text"
              value={propertyManagementAmount.toLocaleString()}
              readOnly
              className="flex-1 text-white bg-estate-900 border-estate-700" 
            />
          </div>
        </div>
      </div>

      <div className="mt-6">
        <label className="block text-gray-300 mb-1">Total annual expenses</label>
        <div className="text-xl font-bold text-gold-500">
          {formatCurrency(totalAnnualExpenses)}
        </div>
      </div>
    </div>
  );
};

export default CashFlowSection;
