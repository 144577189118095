import { useState, useEffect } from "react";
import { BlogPost, SupabaseBlogPost } from "@/types/blog";
import { supabase } from "@/integrations/supabase/client";
import { blogPosts as defaultBlogPosts } from "@/data/blogPosts";
import { formatSupabaseBlogPost } from "@/utils/blog";

interface FetchBlogPostResult {
  post: BlogPost | null;
  loading: boolean;
  error: string | null;
}

/**
 * Hook to fetch a blog post by ID from either default posts or Supabase
 */
export const useFetchBlogPost = (id: string | undefined): FetchBlogPostResult => {
  const [post, setPost] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  useEffect(() => {
    const fetchPost = async () => {
      try {
        if (!id) {
          console.error("No post ID provided");
          setError("No post ID provided");
          setLoading(false);
          return;
        }
        
        console.log(`Fetching blog post with ID: ${id}`);
        setLoading(true);
        setError(null);
        
        // First check if it's in the default posts
        const defaultPost = defaultBlogPosts.find(p => String(p.id) === id);
        
        if (defaultPost) {
          console.log("Post found in default posts:", defaultPost.title);
          setPost(defaultPost);
          setLoading(false);
          return;
        }
        
        console.log("Post not found in default posts, checking Supabase...");
        
        // If not found, check in Supabase
        const { data, error } = await supabase
          .from('blog_posts')
          .select('*')
          .eq('id', id)
          .single();
        
        if (error) {
          console.error("Error fetching blog post from Supabase:", error);
          setError(`Error fetching blog post: ${error.message}`);
          setLoading(false);
          return;
        }
        
        if (data) {
          console.log("Supabase returned raw data:", data);
          // Log the image fields specifically to debug
          console.log("Image fields in post:", {
            image: data.image, // ACTUALLY contains multiple images (array)
            images: data.images // ACTUALLY contains the hero image (single)
          });
          
          // Use formatSupabaseBlogPost for consistent formatting
          const formattedPost = formatSupabaseBlogPost(data as SupabaseBlogPost);
          console.log("Formatted blog post:", formattedPost);
          setPost(formattedPost);
        } else {
          console.error("Blog post not found in Supabase");
          setError("Blog post not found");
        }
      } catch (error) {
        console.error("Unexpected error in fetchPost:", error);
        setError(`Unexpected error: ${error instanceof Error ? error.message : String(error)}`);
      } finally {
        setLoading(false);
      }
    };
    
    fetchPost();
  }, [id]);
  
  return { post, loading, error };
};
