
import { CityMarketData } from "@/hooks/useMarketData";

interface MarketSnapshotProps {
  marketData: CityMarketData | null;
}

const MarketSnapshot = ({ marketData }: MarketSnapshotProps) => {
  if (!marketData) return null;

  return (
    <div className="mb-6 p-4 bg-blue-50 dark:bg-blue-900/30 rounded-lg">
      <h4 className="font-bold text-lg mb-2">Market Snapshot: {marketData.city_name}</h4>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-sm">
        <div>
          <span className="font-semibold block">Average Price:</span>
          <span>{marketData.avg_price}</span>
        </div>
        <div>
          <span className="font-semibold block">Market Trend:</span>
          <span>{marketData.trend}</span>
        </div>
        <div>
          <span className="font-semibold block">Days on Market:</span>
          <span>{marketData.days_on_market}</span>
        </div>
      </div>
    </div>
  );
};

export default MarketSnapshot;
