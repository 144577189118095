
import React from 'react';

const CalculatorNotes: React.FC = () => {
  return (
    <div className="mt-16 text-gray-400 text-sm max-w-3xl mx-auto">
      <h3 className="text-gold-500 text-lg mb-2">Important Notes:</h3>
      <ul className="list-disc pl-5 space-y-2">
        <li>This calculator provides estimates based on your inputs and should be used for informational purposes only.</li>
        <li>Return on Investment (ROI) is calculated as yearly net income divided by total capital investment.</li>
        <li>CAP Rate is calculated as yearly net income divided by property purchase price.</li>
        <li>Consult with a financial advisor before making investment decisions.</li>
      </ul>
    </div>
  );
};

export default CalculatorNotes;
