import { useEffect, useRef } from "react";
import { handleVideoError } from "@/utils/media/videoUtils";
import { MediaType } from "@/utils/media/types";

/**
 * Hook to handle video playback and errors
 */
export const useVideoPlayback = (
  mediaType: MediaType,
  mediaLoaded: boolean,
  onError: () => void
) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  // Test the actual playability of the video
  useEffect(() => {
    if (mediaType === "video" && mediaLoaded && videoRef.current) {
      const video = videoRef.current;
      let playbackTimeout: number | null = null;
      
      // Handle metadata loading (dimensions and duration)
      const handleLoadedMetadata = () => {
        console.log(`Video metadata loaded: duration=${video.duration}, width=${video.videoWidth}, height=${video.videoHeight}`);
        if (video.duration <= 0 || video.videoWidth <= 0 || video.videoHeight <= 0) {
          console.error("Invalid video metadata detected");
          handleVideoError("The video file appears to be corrupted or improperly encoded.", onError);
        } else {
          console.log("✅ Video metadata validated successfully");
          
          // Start playback manually to force any playback issues to surface
          video.play().catch(err => {
            console.error("Error during video.play():", err);
            handleVideoError("Browser couldn't play the video: " + err.message, onError);
          });
          
          // Set a timeout to check if video actually starts playing
          playbackTimeout = window.setTimeout(() => {
            if (video.currentTime <= 0) {
              console.error("Video failed to start playback after 3 seconds");
              handleVideoError("Video failed to start playing. It may be using an unsupported format or it's too large.", onError);
            }
          }, 3000);
        }
      };

      // Handle errors during playback
      const handleError = (e: Event) => {
        const errorEvent = e as ErrorEvent;
        console.error("Video error event:", errorEvent);
        const errorMessage = video.error 
          ? `Error code: ${video.error.code}, message: ${video.error.message}`
          : "Unknown video error";
        handleVideoError(`The video couldn't be played. ${errorMessage}`, onError);
      };
      
      // Handle successful start of playback
      const handleCanPlay = () => {
        console.log("✅ Video can play");
      };
      
      // Handle stalled playback
      const handleStalled = () => {
        console.error("Video playback stalled");
        handleVideoError("Video playback stalled. The file might be too large for smooth streaming.", onError);
      };
      
      // Attach all event listeners
      video.addEventListener('loadedmetadata', handleLoadedMetadata);
      video.addEventListener('error', handleError);
      video.addEventListener('canplay', handleCanPlay);
      video.addEventListener('stalled', handleStalled);

      return () => {
        // Clean up event listeners and timeout
        if (playbackTimeout) {
          clearTimeout(playbackTimeout);
        }
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
        video.removeEventListener('error', handleError);
        video.removeEventListener('canplay', handleCanPlay);
        video.removeEventListener('stalled', handleStalled);
      };
    }
  }, [mediaType, mediaLoaded, onError]);

  return { videoRef };
};
