
import React from 'react';
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import SEO from '@/components/SEO';
import ChatBot from '@/components/common/ChatBot';
import PropertySearchButton from '@/components/common/PropertySearchButton';
import QuickLinks from '@/components/common/QuickLinks';
import HouseHackingCalculator from '@/components/house-hacking/HouseHackingCalculator';
import { useEffect } from 'react';
import { useSEO } from '@/context/SEOContext';

const HouseHackingCalculatorPage: React.FC = () => {
  const { updateSEO } = useSEO();
  
  useEffect(() => {
    // Update SEO metadata for this page
    updateSEO({
      title: 'House Hacking Calculator | Juan Elizondo RE/MAX',
      description: 'Evaluate the financial benefits of house hacking. Calculate how renting part of your home can offset your mortgage and improve ROI.',
      keywords: ['house hacking', 'rental calculator', 'investment calculator', 'McAllen real estate', 'rental income'],
    });
    
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="min-h-screen flex flex-col bg-black text-white">
      <SEO />
      <Navbar />
      
      <main className="flex-grow">
        <div className="pt-48 pb-28">
          <HouseHackingCalculator />
        </div>
      </main>
      
      <Footer />
      
      <ChatBot />
      <PropertySearchButton />
      <QuickLinks />
    </div>
  );
};

export default HouseHackingCalculatorPage;
