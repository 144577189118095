
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import BuyHero from "@/components/buy/BuyHero";
import BuyProcessSteps from "@/components/buy/BuyProcessSteps";
import BuyerTips from "@/components/buy/BuyerTips";
import MatrixIDXFrame from "@/components/buy/MatrixIDXFrame";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { Link } from "react-router-dom";
import { useLanguage } from "@/context/LanguageContext";
import { Calculator } from "lucide-react";
import BuySearchForm from "@/components/buy/BuySearchForm";

const Buy = () => {
  const { toast } = useToast();
  const { t } = useLanguage();
  const location = useLocation();
  const searchFormRef = useRef<HTMLDivElement>(null);
  
  // Extract search query from location state
  const searchQuery = location.state?.searchQuery || '';

  useEffect(() => {
    // First scroll to top of page
    window.scrollTo(0, 0);
    
    // If coming from a search link, scroll to the search form
    if (location.state?.showSearch && searchFormRef.current) {
      // Add a slight delay to ensure DOM is fully rendered
      setTimeout(() => {
        searchFormRef.current?.scrollIntoView({ 
          behavior: 'smooth', 
          block: 'start' 
        });
        
        // Add an offset to account for fixed header
        const offset = 80; // Adjust this value based on your header height
        const elementPosition = searchFormRef.current.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }, 500);
    }
  }, [location.state]);

  return (
    <div className="min-h-screen bg-background">
      <Navbar />
      
      <BuyHero />
      
      <section className="py-16 px-4 max-w-6xl mx-auto">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <p className="text-lg leading-relaxed">
            {t('buy.intro')}
          </p>
        </div>
        
        <BuyProcessSteps />
        
        <div id="property-search" className="mt-20 scroll-mt-24" ref={searchFormRef}>
          <h2 className="text-3xl md:text-4xl font-display text-center mb-8">
            {t('buy.find')}
          </h2>
          
          {/* Custom search form added here */}
          <BuySearchForm routerLocation={location} />
          
          {/* Only show Matrix if we don't have search params */}
          {!location.state?.showSearch && (
            <div className="mt-12 bg-black dark:bg-gray-900 rounded-xl shadow-xl overflow-hidden text-white">
              <div className="bg-black dark:bg-gray-900 p-6 border-b border-gray-800 dark:border-gray-700">
                <h3 className="text-2xl font-display font-bold text-center mb-4">GMAR Property Search Tool</h3>
                <p className="text-center text-gray-400 dark:text-gray-300 max-w-2xl mx-auto">
                  Use our powerful Matrix MLS search to find available properties. Enter your search criteria below to view current listings.
                </p>
              </div>
              
              <MatrixIDXFrame 
                idxUrl="https://matrix.gmar.org/Matrix/public/IDX.aspx?idx=5ec94a0" 
                searchQuery={searchQuery}
              />
            </div>
          )}
        </div>
        
        <div className="mt-20">
          <h2 className="text-3xl md:text-4xl font-display text-center mb-12">
            {t('buy.tips')}
          </h2>
          <BuyerTips />
        </div>
        
        {/* Mortgage Calculator Link */}
        <div className="mt-20 max-w-3xl mx-auto bg-black text-white p-8 rounded-xl shadow-md text-center">
          <h3 className="text-2xl md:text-3xl font-display mb-4">Mortgage and Loan Calculators</h3>
          <p className="text-lg mb-6">
            Plan your home purchase by estimating your monthly payments and understanding how your credit score impacts your loan costs.
          </p>
          <Link to="/mortgage-calculator">
            <Button 
              className="bg-[#ffb347] hover:bg-[#e69a30] text-white font-bold py-3 px-8 rounded-md transition duration-300"
              size="lg"
            >
              <Calculator className="mr-2 h-5 w-5" /> Use Our Mortgage Tools
            </Button>
          </Link>
        </div>
        
        <div className="text-center mt-16">
          <Link to="/contact">
            <Button 
              className="bg-[#ffb347] hover:bg-[#e69a30] text-white font-bold py-3 px-8 rounded-md transition duration-300"
            >
              {t('buy.contact')}
            </Button>
          </Link>
        </div>
      </section>
      
      <Footer />
    </div>
  );
};

export default Buy;
