
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useCityState = (communities: string[]) => {
  const [activeCity, setActiveCity] = useState<string>("");
  const [expandedCity, setExpandedCity] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash) {
      const city = decodeURIComponent(location.hash.substring(1)).replace(/-/g, ' ');
      const validCity = communities.find(c => c.toLowerCase() === city.toLowerCase());
      
      if (validCity) {
        setActiveCity(validCity);
        setExpandedCity(validCity);
      }
    }
  }, [location, communities]);

  const toggleCityExpansion = (city: string) => {
    setExpandedCity(expandedCity === city ? null : city);
  };

  const handleCitySelect = (city: string) => {
    const citySlug = city.toLowerCase().replace(/\s+/g, '-');
    navigate(`/communities#${citySlug}`);
    setActiveCity(city);
  };

  return {
    activeCity,
    expandedCity,
    toggleCityExpansion,
    handleCitySelect
  };
};
