
import { BlogPost } from "@/types/blog";

export const blogPosts: BlogPost[] = [
  {
    id: "1", // Changed from number to string
    title: "5 Essential Tips for First-Time Home Buyers in McAllen",
    excerpt: "Navigating the home buying process can be daunting for first-time buyers. Here are five essential tips to help you make informed decisions in the McAllen real estate market.",
    content: "Full article content goes here...",
    image: "https://images.unsplash.com/photo-1560518883-ce09059eeffa?auto=format&fit=crop&w=800&q=80",
    date: "March 1, 2025",
    author: "Juan Elizondo",
    category: "Buying"
  },
  {
    id: "2", // Changed from number to string
    title: "The Impact of Economic Growth on McAllen's Real Estate Market",
    excerpt: "McAllen's economic development is creating new opportunities for real estate investors. Learn how recent growth is affecting property values and market trends.",
    content: "Full article content goes here...",
    image: "https://images.unsplash.com/photo-1560520653-9e0e4c89eb11?auto=format&fit=crop&w=800&q=80",
    date: "February 22, 2025",
    author: "Juan Elizondo",
    category: "Market Trends"
  },
  {
    id: "3", // Changed from number to string
    title: "Luxury Home Features That Increase Property Value",
    excerpt: "Investing in the right luxury features can significantly boost your property's market value. Discover which high-end amenities provide the best return on investment.",
    content: "Full article content goes here...",
    image: "https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?auto=format&fit=crop&w=800&q=80",
    date: "February 15, 2025",
    author: "Maria Rodriguez",
    category: "Luxury"
  },
  {
    id: "4", // Changed from number to string
    title: "How to Stage Your Home for a Quick Sale",
    excerpt: "Proper staging can help your home sell faster and at a better price. Learn professional staging techniques that won't break your budget.",
    content: "Full article content goes here...",
    image: "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?auto=format&fit=crop&w=800&q=80",
    date: "February 8, 2025",
    author: "Juan Elizondo",
    category: "Selling"
  },
  {
    id: "5", // Changed from number to string
    title: "Commercial Real Estate Opportunities in Downtown McAllen",
    excerpt: "Downtown McAllen is experiencing a renaissance with new businesses and increased foot traffic. Explore the commercial real estate opportunities in this growing urban center.",
    content: "Full article content goes here...",
    image: "https://images.unsplash.com/photo-1582378898768-217dee83d4e6?auto=format&fit=crop&w=800&q=80", 
    date: "January 30, 2025",
    author: "Robert Johnson",
    category: "Commercial"
  },
  {
    id: "6", // Changed from number to string
    title: "Renovating vs. Buying New: Making the Right Choice",
    excerpt: "Should you purchase a fixer-upper or move into a new build? This comprehensive guide will help you weigh the pros and cons for your specific situation.",
    content: "Full article content goes here...",
    image: "https://images.unsplash.com/photo-1556912173-3bb406ef7e8d?auto=format&fit=crop&w=800&q=80",
    date: "January 22, 2025",
    author: "Juan Elizondo",
    category: "Buying"
  },
  {
    id: "7", // Changed from number to string
    title: "Sustainable Building Practices in Modern Real Estate",
    excerpt: "Eco-friendly homes are increasingly popular among buyers. Discover how sustainable building practices are shaping the future of real estate in McAllen.",
    content: "Full article content goes here...",
    image: "https://images.unsplash.com/photo-1560749003-f4b1e17e2dff?auto=format&fit=crop&w=800&q=80",
    date: "January 15, 2025",
    author: "Sofia Martinez",
    category: "Sustainability"
  },
  {
    id: "8", // Changed from number to string
    title: "Investment Opportunities in McAllen's Rental Market",
    excerpt: "With McAllen's growing population and strong job market, the rental sector presents attractive investment opportunities. Learn what neighborhoods offer the best returns.",
    content: "Full article content goes here...",
    image: "https://images.unsplash.com/photo-1571055107559-3e67626fa8be?auto=format&fit=crop&w=800&q=80",
    date: "January 8, 2025",
    author: "Juan Elizondo",
    category: "Investment"
  }
];
