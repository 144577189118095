
import React, { useState, useEffect } from 'react';
import { useSEO } from '@/context/SEOContext';
import AcquisitionSection from '@/components/flip-calculator/AcquisitionSection';
import LenderSection from '@/components/flip-calculator/LenderSection';
import SaleSection from '@/components/flip-calculator/SaleSection';
import ResultsSection from '@/components/flip-calculator/ResultsSection';
import CalculatorHeader from '@/components/flip-calculator/CalculatorHeader';
import CalculatorNotes from '@/components/flip-calculator/CalculatorNotes';

const FlipCalculatorContainer: React.FC = () => {
  const { updateSEO } = useSEO();
  
  useEffect(() => {
    // Update SEO metadata for this page
    updateSEO({
      title: 'House Flipping Calculator | Juan Elizondo RE/MAX',
      description: 'Calculate your potential profits when flipping houses in McAllen, TX with Juan Elizondo\'s House Flipping Calculator.',
      keywords: ['house flipping', 'property calculator', 'investment calculator', 'McAllen real estate', 'flip calculator'],
    });
    
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  // State for acquisition inputs
  const [purchasePrice, setPurchasePrice] = useState<number>(120000);
  const [closingCostPercentBuy, setClosingCostPercentBuy] = useState<number>(3.5);
  const [closingCostBuy, setClosingCostBuy] = useState<number>(4200);
  const [renovationCost, setRenovationCost] = useState<number>(55000);
  const [pointsPercent, setPointsPercent] = useState<number>(2);
  const [pointsCost, setPointsCost] = useState<number>(2400);
  const [totalAcquisition, setTotalAcquisition] = useState<number>(181600);

  // State for lender inputs
  const [arvPercent, setArvPercent] = useState<number>(70);
  const [arvLend, setArvLend] = useState<number>(175000);
  const [gapFunds, setGapFunds] = useState<number>(6600);

  // State for other expenses
  const [miscExpense, setMiscExpense] = useState<number>(1000);
  const [insurance, setInsurance] = useState<number>(1000);
  const [staging, setStaging] = useState<number>(1800);
  const [interestRate, setInterestRate] = useState<number>(10);
  const [interestRateDecimal, setInterestRateDecimal] = useState<number>(0.1);
  const [monthlyInterest, setMonthlyInterest] = useState<number>(1458.33);

  // State for sale inputs
  const [salePrice, setSalePrice] = useState<number>(250000);
  const [closingCostPercentSell, setClosingCostPercentSell] = useState<number>(1.25);
  const [closingCostSell, setClosingCostSell] = useState<number>(3125);
  const [sellerConcessionPercent, setSellerConcessionPercent] = useState<number>(3);
  const [sellerConcessionAmount, setSellerConcessionAmount] = useState<number>(7500);
  const [commissionsPercent, setCommissionsPercent] = useState<number>(6);
  const [commissionsAmount, setCommissionsAmount] = useState<number>(15000);
  const [totalCostOfSale, setTotalCostOfSale] = useState<number>(25625);
  const [netSalesPrice, setNetSalesPrice] = useState<number>(224375);

  // State for monthly payments option
  const [includeMonthlyPayments, setIncludeMonthlyPayments] = useState<boolean>(true);

  // Fixed values for calculating total out of pocket
  const [otherExpensesTotal, setOtherExpensesTotal] = useState<number>(3800);

  // State for holding time calculations
  const [holdingTimeData, setHoldingTimeData] = useState<Array<{
    months: number;
    interest: number;
    expensePlusInterest: number;
    closingCosts: number;
    totalAllIn: number;
    netProfit: number;
    totalOutOfPocket: number;
    returnActual: number;
    returnAnnualized: number;
    roiCashOnCash: number;
  }>>([]);
  
  // Calculate closing costs on purchase price change
  useEffect(() => {
    const calculatedClosingCostBuy = Math.round(purchasePrice * (closingCostPercentBuy / 100));
    setClosingCostBuy(calculatedClosingCostBuy);
  }, [purchasePrice, closingCostPercentBuy]);

  // Calculate points cost on purchase price change (NOT on ARV lend amount)
  useEffect(() => {
    // Calculate points based on the purchase price, not ARV
    const calculatedPointsCost = Math.round(purchasePrice * (pointsPercent / 100));
    setPointsCost(calculatedPointsCost);
  }, [purchasePrice, pointsPercent]);

  // Calculate total acquisition costs
  useEffect(() => {
    const calculatedTotalAcquisition = purchasePrice + closingCostBuy + renovationCost + pointsCost;
    setTotalAcquisition(calculatedTotalAcquisition);
  }, [purchasePrice, closingCostBuy, renovationCost, pointsCost]);

  // Calculate ARV lend amount
  useEffect(() => {
    const calculatedArvLend = Math.round(salePrice * (arvPercent / 100));
    setArvLend(calculatedArvLend);
  }, [salePrice, arvPercent]);

  // Calculate gap funds
  useEffect(() => {
    // Calculate gap funds as the difference between total acquisition and ARV lend amount
    const calculatedGapFunds = totalAcquisition - arvLend;
    setGapFunds(calculatedGapFunds > 0 ? calculatedGapFunds : 0);
  }, [totalAcquisition, arvLend]);

  // Calculate closing costs on sale
  useEffect(() => {
    const calculatedClosingCostSell = Math.round(salePrice * (closingCostPercentSell / 100));
    setClosingCostSell(calculatedClosingCostSell);
  }, [salePrice, closingCostPercentSell]);

  // Calculate seller concession
  useEffect(() => {
    const calculatedSellerConcession = Math.round(salePrice * (sellerConcessionPercent / 100));
    setSellerConcessionAmount(calculatedSellerConcession);
  }, [salePrice, sellerConcessionPercent]);

  // Calculate commissions
  useEffect(() => {
    const calculatedCommissions = Math.round(salePrice * (commissionsPercent / 100));
    setCommissionsAmount(calculatedCommissions);
  }, [salePrice, commissionsPercent]);

  // Calculate total cost of sale
  useEffect(() => {
    const calculatedTotalCostOfSale = closingCostSell + sellerConcessionAmount + commissionsAmount;
    setTotalCostOfSale(calculatedTotalCostOfSale);
  }, [closingCostSell, sellerConcessionAmount, commissionsAmount]);

  // Calculate net sales price
  useEffect(() => {
    const calculatedNetSalesPrice = salePrice - totalCostOfSale;
    setNetSalesPrice(calculatedNetSalesPrice);
  }, [salePrice, totalCostOfSale]);

  // Convert interest rate to decimal and calculate monthly interest
  useEffect(() => {
    setInterestRateDecimal(interestRate / 100);
    
    // Calculate monthly interest (10% of ARV per year, divided by 12)
    const annualInterest = arvLend * (interestRate / 100);
    const calculatedMonthlyInterest = Math.round((annualInterest / 12) * 100) / 100;
    setMonthlyInterest(calculatedMonthlyInterest);
  }, [interestRate, arvLend]);

  // Update other expenses total
  useEffect(() => {
    setOtherExpensesTotal(miscExpense + insurance + staging);
  }, [miscExpense, insurance, staging]);

  // Calculate holding time data with corrected calculations
  useEffect(() => {
    const data = [];
    
    // Fixed values that don't change with months
    const fixedOutOfPocket = closingCostBuy + pointsCost + gapFunds + otherExpensesTotal;
    
    for (let i = 3; i <= 12; i++) {
      // Interest calculation (correct: 10% of ARV per year, divided by 12, times months)
      const totalInterest = includeMonthlyPayments ? Math.round(monthlyInterest * i) : 0;
      
      // Total costs calculation
      const totalAllIn = totalAcquisition + otherExpensesTotal + totalInterest;
      
      // Net profit calculation
      const netProfit = netSalesPrice - totalAllIn;
      
      // Total out of pocket calculation
      const totalOutOfPocket = fixedOutOfPocket + totalInterest;
      
      // Return calculations
      const returnActual = (netProfit / totalOutOfPocket) * 100;
      const returnAnnualized = returnActual * (12 / i);
      const roiCashOnCash = (netProfit / totalAcquisition) * 100;
      
      data.push({
        months: i,
        interest: totalInterest,
        expensePlusInterest: totalInterest,
        closingCosts: closingCostBuy,
        totalAllIn: totalAllIn,
        netProfit: netProfit,
        totalOutOfPocket: totalOutOfPocket,
        returnActual: returnActual,
        returnAnnualized: returnAnnualized,
        roiCashOnCash: roiCashOnCash
      });
    }
    
    setHoldingTimeData(data);
  }, [
    arvLend, monthlyInterest, closingCostBuy, pointsCost, gapFunds,
    otherExpensesTotal, totalAcquisition, netSalesPrice,
    includeMonthlyPayments
  ]);

  // Format currency 
  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    }).format(value);
  };

  // Format percentage
  const formatPercent = (value: number): string => {
    return `${value.toFixed(1)}%`;
  };

  // Handle input changes with formatting
  const handleCurrencyInputChange = (value: string, setter: React.Dispatch<React.SetStateAction<number>>) => {
    const cleanValue = value.replace(/[^0-9.]/g, '');
    setter(parseFloat(cleanValue) || 0);
  };

  // Handle percentage input changes with decimal support
  const handlePercentInputChange = (value: string, setter: React.Dispatch<React.SetStateAction<number>>) => {
    // Allow decimal numbers in percentage fields
    const cleanValue = value.replace(/[^0-9.]/g, '');
    setter(parseFloat(cleanValue) || 0);
  };

  const resetForm = () => {
    setPurchasePrice(120000);
    setClosingCostPercentBuy(3.5);
    setRenovationCost(55000);
    setPointsPercent(2);
    setSalePrice(250000);
    setClosingCostPercentSell(1.25);
    setSellerConcessionPercent(3);
    setCommissionsPercent(6);
    setArvPercent(70);
    setMiscExpense(1000);
    setInsurance(1000);
    setStaging(1800);
    setInterestRate(10);
    setIncludeMonthlyPayments(true);
  };

  const cardClass = "bg-estate-900/70 p-6 rounded-2xl shadow-lg border border-gold-500/30 transition-all duration-300 hover:shadow-gold-500/20 hover:shadow-xl hover:bg-estate-900/90";

  return (
    <div className="container mx-auto px-4">
      <CalculatorHeader />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        {/* Card 1: Acquisition */}
        <div className={cardClass}>
          <AcquisitionSection
            purchasePrice={purchasePrice}
            setPurchasePrice={setPurchasePrice}
            closingCostPercentBuy={closingCostPercentBuy}
            setClosingCostPercentBuy={setClosingCostPercentBuy}
            closingCostBuy={closingCostBuy}
            renovationCost={renovationCost}
            setRenovationCost={setRenovationCost}
            pointsPercent={pointsPercent}
            setPointsPercent={setPointsPercent}
            pointsCost={pointsCost}
            totalAcquisition={totalAcquisition}
            handleCurrencyInputChange={handleCurrencyInputChange}
            handlePercentInputChange={handlePercentInputChange}
            formatCurrency={formatCurrency}
          />
        </div>
        
        {/* Card 2: Lender Information */}
        <div className={cardClass}>
          <LenderSection
            arvPercent={arvPercent}
            setArvPercent={setArvPercent}
            arvLend={arvLend}
            gapFunds={gapFunds}
            miscExpense={miscExpense}
            setMiscExpense={setMiscExpense}
            insurance={insurance}
            setInsurance={setInsurance}
            staging={staging}
            setStaging={setStaging}
            interestRate={interestRate}
            setInterestRate={setInterestRate}
            monthlyInterest={monthlyInterest}
            handleCurrencyInputChange={handleCurrencyInputChange}
            handlePercentInputChange={handlePercentInputChange}
            formatCurrency={formatCurrency}
          />
        </div>
        
        {/* Card 3: Sale Information */}
        <div className={cardClass}>
          <SaleSection
            salePrice={salePrice}
            setSalePrice={setSalePrice}
            closingCostPercentSell={closingCostPercentSell}
            setClosingCostPercentSell={setClosingCostPercentSell}
            closingCostSell={closingCostSell}
            sellerConcessionPercent={sellerConcessionPercent}
            setSellerConcessionPercent={setSellerConcessionPercent}
            sellerConcessionAmount={sellerConcessionAmount}
            commissionsPercent={commissionsPercent}
            setCommissionsPercent={setCommissionsPercent}
            commissionsAmount={commissionsAmount}
            totalCostOfSale={totalCostOfSale}
            netSalesPrice={netSalesPrice}
            handleCurrencyInputChange={handleCurrencyInputChange}
            handlePercentInputChange={handlePercentInputChange}
            formatCurrency={formatCurrency}
          />
        </div>
        
        {/* Card 4: Results Table */}
        <div className={`${cardClass} overflow-hidden`}>
          <ResultsSection
            holdingTimeData={holdingTimeData}
            includeMonthlyPayments={includeMonthlyPayments}
            setIncludeMonthlyPayments={setIncludeMonthlyPayments}
            resetForm={resetForm}
            formatCurrency={formatCurrency}
            formatPercent={formatPercent}
          />
        </div>
      </div>

      <CalculatorNotes />
    </div>
  );
};

export default FlipCalculatorContainer;
