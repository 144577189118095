
import React from 'react';
import { Card } from '@/components/ui/card';
import { Home, Hammer, FileText, AlertTriangle, DollarSign } from 'lucide-react';

interface ResultsSectionProps {
  materialCost: number;
  laborCost: number;
  permitsCost: number;
  contingency: number;
  totalCost: number;
  formatCurrency: (value: number) => string;
}

const ResultsSection: React.FC<ResultsSectionProps> = ({
  materialCost,
  laborCost,
  permitsCost,
  contingency,
  totalCost,
  formatCurrency
}) => {
  return (
    <div className="space-y-4">
      <Card className="p-4 bg-gold-500/20 border border-gold-500">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-lg font-semibold text-white">Total Estimated Cost</h3>
          <DollarSign size={20} className="text-gold-500" />
        </div>
        <p className="text-2xl font-bold text-gold-500">{formatCurrency(totalCost)}</p>
      </Card>
      
      <Card className="p-4 bg-blue-900/30 border border-blue-700">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-lg font-semibold text-white">Materials Cost</h3>
          <Home size={20} className="text-blue-500" />
        </div>
        <p className="text-xl font-bold text-blue-500">{formatCurrency(materialCost)}</p>
      </Card>
      
      <Card className="p-4 bg-green-900/30 border border-green-700">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-lg font-semibold text-white">Labor Cost</h3>
          <Hammer size={20} className="text-green-500" />
        </div>
        <p className="text-xl font-bold text-green-500">{formatCurrency(laborCost)}</p>
      </Card>
      
      <Card className="p-4 bg-purple-900/30 border border-purple-700">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-lg font-semibold text-white">Permits Cost</h3>
          <FileText size={20} className="text-purple-500" />
        </div>
        <p className="text-xl font-bold text-purple-500">{formatCurrency(permitsCost)}</p>
      </Card>
      
      <Card className="p-4 bg-amber-900/30 border border-amber-700">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-lg font-semibold text-white">Contingency</h3>
          <AlertTriangle size={20} className="text-amber-500" />
        </div>
        <p className="text-xl font-bold text-amber-500">{formatCurrency(contingency)}</p>
      </Card>
    </div>
  );
};

export default ResultsSection;
