
import React from 'react';

const EmptyContentMessage: React.FC = () => {
  return (
    <div className="p-4 bg-amber-50 border border-amber-200 rounded-md mb-6">
      <h3 className="text-amber-800 font-medium mb-2">Content Missing</h3>
      <p className="text-amber-700">
        This post appears to have been created without content. 
        When submitting blog content, make sure to include one of these fields:
      </p>
      <ul className="list-disc ml-5 mt-2 text-amber-700">
        <li><code className="bg-amber-100 px-1 rounded">"content"</code>: HTML content for the blog post</li>
        <li><code className="bg-amber-100 px-1 rounded">"introduction"</code>: Text for the introduction paragraph</li>
      </ul>
    </div>
  );
};

export default EmptyContentMessage;
