
import { useState, useEffect } from "react";
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "@/components/ui/card";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { CreditCard, BadgeDollarSign, Wallet, Calculator } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { 
  INTEREST_RATES, 
  DEFAULT_LOAN_AMOUNTS, 
  LoanType,
  CreditScoreValue,
  MortgageProgramValue,
  LoanStructureValue,
  CURRENT_MARKET_RATES
} from "./loan-savings/constants";
import { 
  calculateMonthlyPayment, 
  LoanResults, 
  ChartDataItem,
  getCreditScoreLabel,
  getCurrentInterestRate,
  getAdjustedInterestRate,
  calculateMortgageInsurance,
  calculateProgramFees
} from "./loan-savings/utils";
import LoanForm from "./loan-savings/LoanForm";
import ResultsDisplay from "./loan-savings/ResultsDisplay";
import SavingsChart from "./loan-savings/SavingsChart";
import { LOAN_TERMS } from "./loan-savings/constants";

const LoanSavingsCalculator = () => {
  const { toast } = useToast();
  const [loanType, setLoanType] = useState<LoanType>("mortgage");
  const [loanAmount, setLoanAmount] = useState(300000);
  const [loanTerm, setLoanTerm] = useState("30");
  const [state, setState] = useState("Texas");
  const [creditScore, setCreditScore] = useState<CreditScoreValue>("good");
  const [comparisonScore, setComparisonScore] = useState<CreditScoreValue>("excellent");
  const [mortgageProgram, setMortgageProgram] = useState<MortgageProgramValue>("conventional");
  const [loanStructure, setLoanStructure] = useState<LoanStructureValue>("fixed");
  const [results, setResults] = useState<LoanResults | null>(null);
  const [chartData, setChartData] = useState<ChartDataItem[]>([]);
  const [hoveredTab, setHoveredTab] = useState<string | null>(null);

  // Reset loan amount when loan type changes
  useEffect(() => {
    setLoanAmount(DEFAULT_LOAN_AMOUNTS[loanType]);
    setLoanTerm(LOAN_TERMS[loanType][0].value);
  }, [loanType]);

  // Handle calculate button click
  const handleCalculate = () => {
    if (!creditScore || !comparisonScore || creditScore === comparisonScore) {
      toast({
        title: "Please select different credit score ranges",
        description: "The comparison credit score needs to be different from your current credit score.",
        variant: "destructive",
      });
      return;
    }

    let currentRate, comparisonRate;
    const termYears = parseInt(loanTerm);
    
    if (loanType === "mortgage" && mortgageProgram && loanStructure) {
      // For mortgage loans, get rates based on the selected program, structure, and credit score
      try {
        const baseCurrentRate = getCurrentInterestRate(mortgageProgram, loanStructure, loanTerm);
        const baseComparisonRate = baseCurrentRate;
        
        currentRate = getAdjustedInterestRate(baseCurrentRate, creditScore);
        comparisonRate = getAdjustedInterestRate(baseComparisonRate, comparisonScore);
      } catch (error) {
        console.error("Error calculating rates:", error);
        // Fallback to the old method if there's an error
        currentRate = INTEREST_RATES[loanType][creditScore];
        comparisonRate = INTEREST_RATES[loanType][comparisonScore];
      }
    } else {
      // For other loan types, use the old method
      currentRate = INTEREST_RATES[loanType][creditScore];
      comparisonRate = INTEREST_RATES[loanType][comparisonScore];
    }
    
    const currentMonthly = calculateMonthlyPayment(loanAmount, currentRate, termYears);
    const comparisonMonthly = calculateMonthlyPayment(loanAmount, comparisonRate, termYears);
    
    const currentTotal = currentMonthly * termYears * 12;
    const comparisonTotal = comparisonMonthly * termYears * 12;
    
    const monthlySavings = currentMonthly - comparisonMonthly;
    const totalSavings = currentTotal - comparisonTotal;

    setResults({
      currentRate,
      currentMonthly,
      currentTotal,
      comparisonRate,
      comparisonMonthly,
      comparisonTotal,
      monthlySavings,
      totalSavings
    });

    // Create chart data
    setChartData([
      {
        name: getCreditScoreLabel(creditScore),
        "Monthly Payment": Math.round(currentMonthly),
        "Total Cost": Math.round(currentTotal / 1000),
      },
      {
        name: getCreditScoreLabel(comparisonScore),
        "Monthly Payment": Math.round(comparisonMonthly),
        "Total Cost": Math.round(comparisonTotal / 1000),
      }
    ]);

    toast({
      title: "Calculation Complete",
      description: `We've calculated your potential savings with a ${getCreditScoreLabel(comparisonScore)} credit score.`,
    });
  };

  return (
    <Card className="w-full shadow-lg">
      <CardHeader className="bg-gradient-to-r from-blue-50 to-indigo-50 dark:from-blue-950 dark:to-indigo-950 rounded-t-lg">
        <CardTitle className="flex items-center text-3xl font-display">
          <Calculator className="mr-2 h-6 w-6 text-[#ffb347]" /> 
          Loan Savings Calculator
        </CardTitle>
        <CardDescription className="text-base">
          See how your credit score affects your loan costs and potential savings
        </CardDescription>
      </CardHeader>
      <CardContent className="p-6">
        <Tabs defaultValue="mortgage" onValueChange={(value) => setLoanType(value as LoanType)}>
          <div className="relative mb-6">
            <TabsList className="flex w-full gap-2 bg-transparent p-0">
              <TabsTrigger 
                value="mortgage" 
                className={`relative overflow-hidden flex-1 flex flex-col items-center justify-center gap-2 py-5 px-2 rounded-lg text-black font-medium transition-all duration-300 bg-[#FEF700]
                  ${loanType === "mortgage" ? "shadow-lg" : ""}`}
                onMouseEnter={() => setHoveredTab("mortgage")}
                onMouseLeave={() => setHoveredTab(null)}
                style={{
                  transform: (hoveredTab === "mortgage" || loanType === "mortgage") ? "translateY(-4px)" : "translateY(0)",
                  boxShadow: (hoveredTab === "mortgage" || loanType === "mortgage") 
                    ? '0 0 20px 8px rgba(59, 130, 246, 0.6), 0 0 30px rgba(59, 130, 246, 0.4)' 
                    : '0 0 12px 5px rgba(59, 130, 246, 0.35)'
                }}
              >
                {/* Blue glow effect on hover - only at the bottom */}
                {(hoveredTab === "mortgage" || loanType === "mortgage") && (
                  <span className="absolute inset-0 z-0">
                    <span className="absolute bottom-0 left-0 right-0 h-6 bg-[#3b82f6] blur-xl opacity-60 transform translate-y-2"></span>
                  </span>
                )}
                
                {/* Icon and content */}
                <span className="relative z-10 flex flex-col items-center">
                  <CreditCard className="h-6 w-6 mb-1 text-black" /> 
                  <span className="text-black font-bold">Mortgage</span>
                </span>
              </TabsTrigger>
              
              <TabsTrigger 
                value="auto" 
                className={`relative overflow-hidden flex-1 flex flex-col items-center justify-center gap-2 py-5 px-2 rounded-lg text-black font-medium transition-all duration-300 bg-[#FEF700]
                  ${loanType === "auto" ? "shadow-lg" : ""}`}
                onMouseEnter={() => setHoveredTab("auto")}
                onMouseLeave={() => setHoveredTab(null)}
                style={{
                  transform: (hoveredTab === "auto" || loanType === "auto") ? "translateY(-4px)" : "translateY(0)",
                  boxShadow: (hoveredTab === "auto" || loanType === "auto") 
                    ? '0 0 20px 8px rgba(59, 130, 246, 0.6), 0 0 30px rgba(59, 130, 246, 0.4)' 
                    : '0 0 12px 5px rgba(59, 130, 246, 0.35)'
                }}
              >
                {/* Blue glow effect on hover - only at the bottom */}
                {(hoveredTab === "auto" || loanType === "auto") && (
                  <span className="absolute inset-0 z-0">
                    <span className="absolute bottom-0 left-0 right-0 h-6 bg-[#3b82f6] blur-xl opacity-60 transform translate-y-2"></span>
                  </span>
                )}
                
                {/* Icon and content */}
                <span className="relative z-10 flex flex-col items-center">
                  <BadgeDollarSign className="h-6 w-6 mb-1 text-black" /> 
                  <span className="text-black font-bold">Auto Loan</span>
                </span>
              </TabsTrigger>
              
              <TabsTrigger 
                value="personal" 
                className={`relative overflow-hidden flex-1 flex flex-col items-center justify-center gap-2 py-5 px-2 rounded-lg text-black font-medium transition-all duration-300 bg-[#FEF700]
                  ${loanType === "personal" ? "shadow-lg" : ""}`}
                onMouseEnter={() => setHoveredTab("personal")}
                onMouseLeave={() => setHoveredTab(null)}
                style={{
                  transform: (hoveredTab === "personal" || loanType === "personal") ? "translateY(-4px)" : "translateY(0)",
                  boxShadow: (hoveredTab === "personal" || loanType === "personal") 
                    ? '0 0 20px 8px rgba(59, 130, 246, 0.6), 0 0 30px rgba(59, 130, 246, 0.4)' 
                    : '0 0 12px 5px rgba(59, 130, 246, 0.35)'
                }}
              >
                {/* Blue glow effect on hover - only at the bottom */}
                {(hoveredTab === "personal" || loanType === "personal") && (
                  <span className="absolute inset-0 z-0">
                    <span className="absolute bottom-0 left-0 right-0 h-6 bg-[#3b82f6] blur-xl opacity-60 transform translate-y-2"></span>
                  </span>
                )}
                
                {/* Icon and content */}
                <span className="relative z-10 flex flex-col items-center">
                  <Wallet className="h-6 w-6 mb-1 text-black" /> 
                  <span className="text-black font-bold">Personal Loan</span>
                </span>
              </TabsTrigger>
            </TabsList>
          </div>

          {/* Form */}
          <LoanForm 
            loanType={loanType}
            loanAmount={loanAmount}
            loanTerm={loanTerm}
            state={state}
            creditScore={creditScore}
            comparisonScore={comparisonScore}
            onLoanAmountChange={setLoanAmount}
            onLoanTermChange={setLoanTerm}
            onStateChange={setState}
            onCreditScoreChange={setCreditScore}
            onComparisonScoreChange={setComparisonScore}
            onCalculate={handleCalculate}
            mortgageProgram={mortgageProgram}
            loanStructure={loanStructure}
            onMortgageProgramChange={setMortgageProgram}
            onLoanStructureChange={setLoanStructure}
          />

          {/* Results */}
          {results && (
            <>
              <ResultsDisplay 
                results={results}
                creditScore={creditScore}
                comparisonScore={comparisonScore}
              />
              
              {/* Chart visualization */}
              <SavingsChart chartData={chartData} />
            </>
          )}
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default LoanSavingsCalculator;
