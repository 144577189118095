
import React, { useEffect } from "react";
import { BlogPost } from "@/types/blog";
import ImageCarousel from "@/components/common/ImageCarousel";
import SEOImage from "@/components/SEOImage";

interface BlogPostImageProps {
  post: BlogPost;
  height?: string;
  currentImage?: string;
  currentCaption?: string;
  hasMultipleImages?: boolean;
  currentImageIndex?: number;
  totalImages?: number;
  prevImage?: () => void;
  nextImage?: () => void;
  altText?: string;
}

const BlogPostImage: React.FC<BlogPostImageProps> = ({
  post,
  height = "h-[300px] sm:h-[400px]",
  currentImage,
  currentCaption,
  hasMultipleImages = false,
  currentImageIndex = 0,
  totalImages = 0,
  prevImage = () => {},
  nextImage = () => {},
  altText
}) => {
  // Enhanced debug logging to track image issues
  useEffect(() => {
    console.log("BlogPostImage rendering:", {
      postTitle: post.title,
      hasImages: Boolean(post.images),
      imageCount: post.images?.length || 0,
      mainImage: post.image?.substring(0, 30) + "..." || "none",
      firstImage: post.images?.[0]?.url?.substring(0, 30) + "..." || "none",
      currentImage: currentImage?.substring(0, 30) + "..." || "none",
      imagesArray: post.images ? JSON.stringify(post.images).substring(0, 100) + "..." : "none",
      hasMultipleImages,
      totalImages
    });
  }, [post, currentImage, hasMultipleImages, totalImages]);

  // Use ImageCarousel when we have images array with multiple images
  if (post.images && post.images.length > 0) {
    console.log(`Using ImageCarousel with ${post.images.length} images, hasMultipleImages=${hasMultipleImages}`);
    
    // If we have external controls provided, use them
    if (currentImage && hasMultipleImages && prevImage && nextImage) {
      return (
        <div className={`relative ${height} overflow-hidden bg-gray-900`}>
          <SEOImage 
            src={currentImage} 
            alt={currentCaption || altText || post.title} 
            className="w-full h-full object-cover"
            priority={true}
          />
          
          {hasMultipleImages && totalImages > 1 && (
            <>
              <button 
                className="absolute left-2 top-1/2 -translate-y-1/2 bg-black/60 text-white rounded-full h-10 w-10 hover:bg-black/80 z-20"
                onClick={prevImage}
                aria-label="Previous image"
              >
                <span className="sr-only">Previous</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
              </button>
              <button 
                className="absolute right-2 top-1/2 -translate-y-1/2 bg-black/60 text-white rounded-full h-10 w-10 hover:bg-black/80 z-20"
                onClick={nextImage}
                aria-label="Next image"
              >
                <span className="sr-only">Next</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </button>
              
              {currentCaption && (
                <div className="absolute bottom-0 left-0 right-0 bg-black/70 text-white p-3 z-10">
                  <p className="text-sm">{currentCaption}</p>
                </div>
              )}
              
              <div className="absolute bottom-3 left-3 bg-black/70 text-white px-2 py-1 rounded-full text-sm z-10">
                {currentImageIndex + 1} / {totalImages}
              </div>
            </>
          )}
        </div>
      );
    } else {
      // Use the built-in carousel with its own controls
      return (
        <ImageCarousel
          images={post.images}
          height={height}
          className="rounded-t-lg"
          alt={altText || post.title}
          showControls={true}
          showCaption={true}
          showCounter={true}
          counterPosition="bottom-left"
        />
      );
    }
  }
  
  // Fallback to image field if no currentImage but post has an image field
  if (currentImage) {
    return (
      <div className={`relative ${height} overflow-hidden bg-gray-900`}>
        <SEOImage 
          src={currentImage} 
          alt={altText || post.title} 
          className="w-full h-full object-cover"
          priority={true}
        />
      </div>
    );
  }
  
  if (post.image) {
    return (
      <div className={`relative ${height} overflow-hidden bg-gray-900`}>
        <SEOImage 
          src={post.image} 
          alt={altText || post.title} 
          className="w-full h-full object-cover"
          priority={true}
        />
      </div>
    );
  }
  
  // Final fallback - no images available message
  return (
    <div className={`${height} bg-gray-800 flex items-center justify-center`}>
      <p className="text-gray-400">No images available</p>
    </div>
  );
};

export default BlogPostImage;
