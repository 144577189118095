
import React from 'react';
import { BuyingInputProps } from './types';
import { Input } from '@/components/ui/input';
import { Slider } from '@/components/ui/slider';

const BuyingInputs: React.FC<BuyingInputProps> = ({
  purchasePrice,
  setPurchasePrice,
  downPayment,
  setDownPayment,
  interestRate,
  setInterestRate,
  loanTerm,
  setLoanTerm,
  propertyTaxes,
  setPropertyTaxes,
  homeInsurance,
  setHomeInsurance,
  maintenanceCosts,
  setMaintenanceCosts,
  hoaFees,
  setHoaFees,
  priceAppreciation,
  setPriceAppreciation,
  formatCurrency,
  formatPercent,
  handleCurrencyInputChange,
  handlePercentInputChange
}) => {
  const downPaymentPercent = purchasePrice > 0 ? (downPayment / purchasePrice) * 100 : 0;

  return (
    <div className="transition-transform hover:transform hover:scale-[1.01] hover:z-10">
      <h2 className="text-2xl font-bold text-gold-500 mb-4">Buying Parameters</h2>
      
      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="purchasePrice" className="block text-sm font-medium text-gray-300 mb-1">
              Home Purchase Price ($)
            </label>
            <Input
              id="purchasePrice"
              type="text"
              className="border-gray-700 bg-gray-800 text-white"
              value={purchasePrice.toLocaleString()}
              onChange={(e) => handleCurrencyInputChange(e.target.value, setPurchasePrice)}
            />
          </div>
          <div>
            <label htmlFor="downPayment" className="block text-sm font-medium text-gray-300 mb-1">
              Down Payment ({downPaymentPercent.toFixed(1)}%)
            </label>
            <Input
              id="downPayment"
              type="text"
              className="border-gray-700 bg-gray-800 text-white"
              value={downPayment.toLocaleString()}
              onChange={(e) => handleCurrencyInputChange(e.target.value, setDownPayment)}
            />
          </div>
        </div>

        <div>
          <label htmlFor="interestRate" className="block text-sm font-medium text-gray-300 mb-1">
            Mortgage Interest Rate ({interestRate}%)
          </label>
          <div className="flex items-center space-x-2">
            <Slider 
              id="interestRate" 
              defaultValue={[interestRate]} 
              max={10} 
              step={0.125}
              value={[interestRate]}
              onValueChange={(value) => setInterestRate(value[0])}
              className="flex-grow"
            />
            <Input
              type="number"
              value={interestRate}
              onChange={(e) => setInterestRate(parseFloat(e.target.value) || 0)}
              className="w-20 border-gray-700 bg-gray-800 text-white"
              step={0.125}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="loanTerm" className="block text-sm font-medium text-gray-300 mb-1">
              Loan Term (years)
            </label>
            <Input
              id="loanTerm"
              type="number"
              className="border-gray-700 bg-gray-800 text-white"
              value={loanTerm}
              onChange={(e) => setLoanTerm(parseInt(e.target.value) || 0)}
              min={1}
              max={50}
            />
          </div>
          <div>
            <label htmlFor="propertyTaxes" className="block text-sm font-medium text-gray-300 mb-1">
              Property Taxes ($/year)
            </label>
            <Input
              id="propertyTaxes"
              type="text"
              className="border-gray-700 bg-gray-800 text-white"
              value={propertyTaxes.toLocaleString()}
              onChange={(e) => handleCurrencyInputChange(e.target.value, setPropertyTaxes)}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="homeInsurance" className="block text-sm font-medium text-gray-300 mb-1">
              Home Insurance ($/year)
            </label>
            <Input
              id="homeInsurance"
              type="text"
              className="border-gray-700 bg-gray-800 text-white"
              value={homeInsurance.toLocaleString()}
              onChange={(e) => handleCurrencyInputChange(e.target.value, setHomeInsurance)}
            />
          </div>
          <div>
            <label htmlFor="maintenanceCosts" className="block text-sm font-medium text-gray-300 mb-1">
              Maintenance Costs ($/year)
            </label>
            <Input
              id="maintenanceCosts"
              type="text"
              className="border-gray-700 bg-gray-800 text-white"
              value={maintenanceCosts.toLocaleString()}
              onChange={(e) => handleCurrencyInputChange(e.target.value, setMaintenanceCosts)}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="hoaFees" className="block text-sm font-medium text-gray-300 mb-1">
              HOA Fees ($/month)
            </label>
            <Input
              id="hoaFees"
              type="text"
              className="border-gray-700 bg-gray-800 text-white"
              value={hoaFees.toLocaleString()}
              onChange={(e) => handleCurrencyInputChange(e.target.value, setHoaFees)}
            />
          </div>
          <div>
            <label htmlFor="priceAppreciation" className="block text-sm font-medium text-gray-300 mb-1">
              Home Price Appreciation (%)
            </label>
            <Input
              id="priceAppreciation"
              type="text"
              className="border-gray-700 bg-gray-800 text-white"
              value={priceAppreciation}
              onChange={(e) => handlePercentInputChange(e.target.value, setPriceAppreciation)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyingInputs;
