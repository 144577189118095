
import React, { useState } from "react";
import { useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { BlogPost, SupabaseBlogPost } from "@/types/blog";
import { formatSupabaseBlogPost } from "@/utils/blog";
import { 
  Table, 
  TableBody, 
  TableCaption, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Pencil, Eye, Trash2, RefreshCw } from "lucide-react";
import { Link } from "react-router-dom";
import { toast } from "sonner";

const BlogPostsTable = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        const { data, error } = await supabase
          .from('blog_posts')
          .select('*')
          .order('created_at', { ascending: false });
        
        if (error) {
          console.error("Error fetching blog posts:", error);
          toast.error("Failed to load blog posts");
          return;
        }
        
        if (data) {
          const formattedPosts = data.map((post: SupabaseBlogPost) => 
            formatSupabaseBlogPost(post)
          );
          setPosts(formattedPosts);
        }
      } catch (error) {
        console.error("Unexpected error:", error);
        toast.error("An unexpected error occurred");
      } finally {
        setLoading(false);
      }
    };
    
    fetchPosts();
  }, [refreshKey]);

  const handleRefresh = () => {
    setRefreshKey(prev => prev + 1);
    toast.info("Refreshing blog posts...");
  };

  const handleDelete = async (id: string | number) => {
    if (!confirm(`Are you sure you want to delete post "${posts.find(p => p.id === id)?.title}"?`)) {
      return;
    }
    
    try {
      const { error } = await supabase
        .from('blog_posts')
        .delete()
        .eq('id', String(id)); // Convert id to string to ensure compatibility
      
      if (error) {
        console.error("Error deleting post:", error);
        toast.error(`Failed to delete post: ${error.message}`);
        return;
      }
      
      toast.success("Post deleted successfully");
      setPosts(posts.filter(post => post.id !== id));
    } catch (error) {
      console.error("Unexpected error:", error);
      toast.error("An unexpected error occurred");
    }
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Blog Posts Database</h2>
        <Button 
          onClick={handleRefresh} 
          className="flex items-center gap-2"
          variant="outline"
          disabled={loading}
        >
          <RefreshCw size={16} className={loading ? "animate-spin" : ""} />
          Refresh
        </Button>
      </div>
      
      <div className="border rounded-md overflow-hidden">
        <Table>
          <TableCaption>
            {loading 
              ? "Loading blog posts..." 
              : posts.length > 0 
                ? `Showing ${posts.length} blog posts` 
                : "No blog posts found"}
          </TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">ID</TableHead>
              <TableHead>Title</TableHead>
              <TableHead>Author</TableHead>
              <TableHead>Category</TableHead>
              <TableHead>Date</TableHead>
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {posts.map((post) => (
              <TableRow key={post.id}>
                <TableCell className="font-mono text-xs">
                  {typeof post.id === 'string' && post.id.startsWith('default-')
                    ? "Default" 
                    : truncateText(String(post.id), 8)}
                </TableCell>
                <TableCell className="font-medium">{truncateText(post.title, 40)}</TableCell>
                <TableCell>{post.author}</TableCell>
                <TableCell>{post.category}</TableCell>
                <TableCell>{post.date}</TableCell>
                <TableCell className="text-right">
                  <div className="flex justify-end gap-2">
                    <Button asChild variant="ghost" size="icon">
                      <Link to={`/blog/${post.id}`}>
                        <Eye size={16} />
                      </Link>
                    </Button>
                    {typeof post.id === 'string' && !post.id.startsWith('default-') && (
                      <Button 
                        variant="ghost" 
                        size="icon"
                        onClick={() => handleDelete(post.id)}
                      >
                        <Trash2 size={16} className="text-red-500" />
                      </Button>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default BlogPostsTable;
