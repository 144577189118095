
import { Check } from "lucide-react";

const BuyProcessSteps = () => {
  const steps = [
    {
      number: 1,
      title: "Pre-approval",
      description: "Get pre-approved for a mortgage to know your budget and show sellers you're serious."
    },
    {
      number: 2,
      title: "Search",
      description: "Find properties that fit your criteria, whether you're looking for a family home or investment property."
    },
    {
      number: 3,
      title: "Offer",
      description: "Make an offer on a property you love, with my guidance on competitive pricing strategy."
    },
    {
      number: 4,
      title: "Negotiate",
      description: "Work with me to negotiate the best terms, considering price, repairs, and closing timeline."
    },
    {
      number: 5,
      title: "Close",
      description: "Finalize the transaction, complete all paperwork, and move into your new property."
    }
  ];

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-3xl md:text-4xl font-display text-center mb-12">The Buying Process</h2>
      
      <div className="space-y-8">
        {steps.map((step) => (
          <div 
            key={step.number}
            className="flex items-start gap-4 bg-card p-6 rounded-lg shadow-sm transition-all hover:shadow-md"
          >
            <div className="flex-shrink-0 w-12 h-12 rounded-full bg-[#ffb347] flex items-center justify-center text-white font-bold">
              {step.number}
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2 text-card-foreground">{step.title}</h3>
              <p className="text-muted-foreground">{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BuyProcessSteps;
