
import { Card, CardContent } from "@/components/ui/card";
import { QuoteIcon } from "lucide-react";
import { useTheme } from "@/context/ThemeContext";

const SellerTestimonials = () => {
  const { theme } = useTheme();

  const testimonials = [
    {
      quote: "Juan made selling my home an incredibly smooth process. His market knowledge and negotiation skills helped me get top dollar for my property.",
      name: "Maria Rodriguez",
      location: "McAllen, TX"
    },
    {
      quote: "I was worried about selling during a challenging market, but Juan's strategic approach and marketing expertise attracted multiple offers within days.",
      name: "David Chen",
      location: "Mission, TX"
    },
    {
      quote: "The personalized attention and regular updates throughout the selling process gave me peace of mind. I couldn't be happier with the results.",
      name: "Sarah Johnson",
      location: "Pharr, TX"
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {testimonials.map((testimonial, index) => (
        <Card 
          key={index} 
          className={`border-none shadow-md hover:shadow-lg transition-shadow duration-300 ${
            theme === 'dark' ? 'bg-estate-700' : 'bg-white'
          }`}
        >
          <CardContent className="p-6">
            <QuoteIcon className="h-8 w-8 text-[#ffb347] mb-4 opacity-50" />
            <p className={theme === 'dark' ? 'text-gray-300 italic mb-4' : 'text-gray-600 italic mb-4'}>"{testimonial.quote}"</p>
            <div className="pt-2 border-t border-gray-200">
              <p className="font-bold">{testimonial.name}</p>
              <p className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>{testimonial.location}</p>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default SellerTestimonials;
