
import React, { useEffect } from "react";
import SearchBar from "../SearchBar";
import { useLanguage } from "@/context/LanguageContext";
import { refreshGlowEffect } from "@/utils/glowTextUtils";

const HeroContent = () => {
  const { t } = useLanguage();
  
  // Force refresh the glow effect after component mounts
  useEffect(() => {
    const timer = setTimeout(() => {
      refreshGlowEffect();
    }, 500);
    
    return () => clearTimeout(timer);
  }, []);
  
  return (
    <div className="relative z-10 w-full px-4 sm:px-6 text-center flex flex-col h-full">
      {/* Top section with core values */}
      <div className="flex-grow"></div>
      
      {/* Position the search bar below the title and tagline */}
      <div className="absolute left-0 right-0 bottom-[20%] z-20">
        <div className="w-full max-w-3xl mx-auto px-4">
          <SearchBar />
        </div>
      </div>
    </div>
  );
};

export default HeroContent;
